.InfoCards {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // grid-gap: 30px;
    margin-bottom: 160px;
    margin-top: 40px;
    max-width: 1130px;
    


    @media screen and (min-width: 600px) {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
    }

    .InfoCard {
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        padding: 30px 40px 26px;
        background-repeat: no-repeat;
        background-position: bottom 18px right 25px;
        color: #2c2f3c;
        font-size: 14px;
        line-height: 1.5;
        min-height: 130px;
        margin: 10px;

        p, h3 {
            margin: 0 0 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        h3 {
            font-weight: 600;
        }
        ul {
            margin: 0;
            padding: 0 0 0 30px;
        }

        a {
            color: #008dfb;
            border-bottom: 2px solid transparent;
            padding-bottom: 1px;
            &:hover {
                border-bottom-color: #bce2ff;
            }
        }
    }
}