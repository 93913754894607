body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* @font-face {
  font-family: 'Monoid';
  src: local('Monoid'), url(./fonts/Monoid-Regular.ttf) format('truetype');
} */

.keepInTouchIcon {
  display: inline-block;
  cursor: pointer; }
  .keepInTouchIcon img {
    display: block;
    width: 24px;
    height: 24px; }

.Header {
  height: 60px;
  text-align: center;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: #f8f8f5e0; }
  .Header .HeaderTabs {
    display: flex;
    height: 61px;
    justify-content: left;
    padding-left: 90px; }
    .Header .HeaderTabs .athletes {
      transform: scale(-1, 1);
      margin-top: 18px; }
      .Header .HeaderTabs .athletes .tooltiptext {
        transform: scale(-1, 1);
        min-width: 80px;
        margin-left: -50px;
        top: 180%;
        line-height: 15px;
        font-size: 12px;
        padding: 5px 10px;
        z-index: 5; }
        .Header .HeaderTabs .athletes .tooltiptext em {
          color: #71e1f4;
          font-style: normal;
          display: block;
          white-space: nowrap; }
    .Header .HeaderTabs .athletesNoneSelected {
      transform: scale(-1, 1);
      margin-right: -5px;
      margin-top: 18px; }
    .Header .HeaderTabs .tabSeparator {
      width: 1px;
      background-color: #cfd8dc;
      height: 30px;
      margin-top: 15px;
      margin-right: 15px; }
  .Header .HeaderTab {
    outline: none;
    height: 61px;
    line-height: 61px;
    padding: 0 15px;
    font-weight: 500;
    font-size: 14px;
    color: #2c2f3c;
    position: relative;
    overflow: hidden;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    @media screen and (max-width: 500px) {
      .Header .HeaderTab {
        font-size: 12px; } }
    .Header .HeaderTab:hover {
      color: #008dfb; }
      .Header .HeaderTab:hover::after {
        transform: translateX(10px);
        transition: transform 300ms;
        transition-timing-function: ease-out; }
    .Header .HeaderTab::after {
      transform: translateX(-80px);
      content: " ";
      display: block;
      position: absolute;
      bottom: 15px;
      left: 5px;
      width: 50px;
      border-bottom: 1px solid #008dfb; }
    .Header .HeaderTab.active {
      color: #000000;
      font-weight: 600;
      cursor: default; }
      .Header .HeaderTab.active::after {
        display: block;
        transform: translateX(10px); }
    .Header .HeaderTab:hover {
      color: #000000; }
  .Header .HeaderTitle {
    line-height: 60px;
    font-size: 23px;
    font-weight: 600;
    color: #2c2f3c; }
    @media screen and (max-width: 700px) {
      .Header .HeaderTitle {
        text-align: center;
        font-size: 16px; } }
    @media screen and (max-width: 500px) {
      .Header .HeaderTitle {
        text-align: center;
        font-size: 14px; } }
  .Header .rightbar-container {
    position: absolute;
    top: 0;
    right: 0; }
    .Header .rightbar-container .rightbar-container-block.search-icon-container {
      padding: 2px 25px 0 0; }
      .Header .rightbar-container .rightbar-container-block.search-icon-container .tooltiptext {
        min-width: 80px;
        margin-left: -50px;
        top: 155%;
        line-height: 15px;
        font-size: 12px;
        padding: 5px 10px; }
        .Header .rightbar-container .rightbar-container-block.search-icon-container .tooltiptext em {
          color: #71e1f4;
          font-style: normal;
          display: block;
          white-space: nowrap; }
    .Header .rightbar-container .rightbar-container-block.profile-icon-container {
      padding: 7px 25px 0 0; }
      @media screen and (max-width: 600px) {
        .Header .rightbar-container .rightbar-container-block.profile-icon-container {
          padding: 7px 10px 0 0; } }
    .Header .rightbar-container .rightbar-container-block .rightbar-container-row-1 {
      padding-top: 18px; }
    .Header .rightbar-container .rightbar-container-block.PagesNavigation {
      height: 40px;
      display: flex;
      align-items: center;
      border-right: 1px solid #cfd8dc;
      margin-top: 10px;
      margin-right: 20px;
      padding-right: 20px;
      font-size: 12px; }
      @media screen and (max-width: 600px) {
        .Header .rightbar-container .rightbar-container-block.PagesNavigation {
          padding-right: 0;
          border-right: 0; } }
      .Header .rightbar-container .rightbar-container-block.PagesNavigation strong {
        padding: 0 5px; }
        @media screen and (max-width: 600px) {
          .Header .rightbar-container .rightbar-container-block.PagesNavigation strong {
            padding: 0 4px; } }
      .Header .rightbar-container .rightbar-container-block.PagesNavigation a {
        border: none;
        padding: 0 5px;
        cursor: pointer;
        background: transparent;
        position: relative; }
        .Header .rightbar-container .rightbar-container-block.PagesNavigation a:hover .background {
          transform: scale(1); }
        .Header .rightbar-container .rightbar-container-block.PagesNavigation a .background {
          display: block;
          transform: scale(0);
          transition: transform 200ms;
          transition-timing-function: ease-out;
          position: absolute;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          background-color: rgba(204, 232, 254, 0.3);
          top: 0px;
          left: 4px;
          z-index: 8; }

.Sidebar .tooltip .tooltiptext {
  min-width: 80px !important;
  margin-left: -48px !important;
  top: 127% !important;
  font-family: "Montserrat", sans-serif;
  font-size: 13px; }

.Sidebar .SidebarButton {
  position: absolute;
  left: 20px;
  top: 12px;
  cursor: pointer; }
  .Sidebar .SidebarButton img {
    position: relative;
    z-index: 5; }
  .Sidebar .SidebarButton .background {
    transform: scale(0);
    transition: transform 200ms;
    transition-timing-function: ease-out;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: #f3f3ee;
    top: -7px;
    left: -7px; }
  .Sidebar .SidebarButton:hover .background {
    transform: scale(1); }

.SidebarContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  position: fixed;
  z-index: 10;
  transform: translateX(-800px);
  transition: transform 800ms;
  transition-timing-function: ease;
  background: #fff;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  left: 10px;
  border-radius: 12px;
  width: calc(100vw - 20px);
  top: 45px; }
  @media screen and (min-width: 801px) {
    .SidebarContent {
      width: 330px;
      height: calc(100vh - 20px);
      top: 10px; } }
  .SidebarContent.open {
    transform: translateX(0px); }
  .SidebarContent button.close {
    display: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #6b7c88;
    background: #fff;
    outline: none; }
    .SidebarContent button.close:hover {
      color: #008dfb; }
  .SidebarContent .ClearIcon {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer; }
  @media screen and (min-width: 801px) {
    .SidebarContent button.close {
      display: block; }
    .SidebarContent .ClearIcon {
      display: none; } }
  .SidebarContent .Separator {
    border-bottom: 1px solid #e9e9e9;
    margin-top: 30px; }
  .SidebarContent .SidebarMyRaceLogo {
    display: block;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding-top: 10px; }
    @media screen and (min-width: 801px) {
      .SidebarContent .SidebarMyRaceLogo {
        margin: 0 0 0 30px;
        justify-content: flex-start; } }
  .SidebarContent .SidebarNavigation {
    text-transform: uppercase;
    overflow: hidden;
    padding-top: 20px;
    flex-grow: 1; }
    .SidebarContent .SidebarNavigation a {
      font-size: 14px;
      font-weight: 600;
      color: #2c2f3c;
      text-align: left;
      padding: 9px 0 7px 30px;
      position: relative;
      display: flex;
      align-items: center; }
      .SidebarContent .SidebarNavigation a img.LinkIcon {
        margin-right: 10px; }
      .SidebarContent .SidebarNavigation a:hover {
        color: #008dfb; }
        .SidebarContent .SidebarNavigation a:hover::after {
          transform: translateX(10px);
          transition: transform 300ms;
          transition-timing-function: ease-out; }
      .SidebarContent .SidebarNavigation a::after {
        transform: translateX(-50px);
        content: " ";
        display: block;
        position: absolute;
        bottom: 0;
        left: 20px;
        width: 30px;
        border-bottom: 1px solid #008dfb; }
    .SidebarContent .SidebarNavigation .SidebarLinkDisabled {
      color: #008dfb;
      cursor: default; }
    .SidebarContent .SidebarNavigation .Separator {
      margin-top: 20px;
      margin-bottom: 20px; }
  .SidebarContent .SidebarFooter {
    min-height: 80px;
    display: flex;
    justify-content: center; }

.SidebarCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 8; }

.PageInfoModalPopup {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  position: fixed;
  right: 20px;
  top: 70px;
  z-index: 7777;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 355px;
  box-sizing: border-box;
  border-radius: 6px;
  max-height: calc(100vh - 80px);
  display: flex;
  padding-top: 30px; }
  .PageInfoModalPopup .IconSwappable.tooltip .tooltiptext {
    min-width: 40px !important;
    margin-left: -30px !important;
    top: 160% !important;
    line-height: 15px !important;
    font-size: 11px !important;
    padding: 5px 10px !important; }
    .PageInfoModalPopup .IconSwappable.tooltip .tooltiptext em {
      color: #fcb452;
      font-style: normal;
      display: block;
      white-space: nowrap; }
  .PageInfoModalPopup header {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 1;
    border-radius: 6px; }
    .PageInfoModalPopup header .ClearIcon {
      cursor: pointer;
      max-width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 25px;
      z-index: 10; }
      .PageInfoModalPopup header .ClearIcon:hover .background {
        transform: scale(1); }
      .PageInfoModalPopup header .ClearIcon .background {
        transform: scale(0);
        transition: transform 200ms;
        transition-timing-function: ease-out;
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: rgba(204, 232, 254, 0.3);
        top: -6px;
        left: -5px;
        z-index: -5; }
  .PageInfoModalPopup .modal_content {
    overflow: auto; }
    .PageInfoModalPopup .modal_content hr {
      border-top: 1px solid #e9e9e9; }
    .PageInfoModalPopup .modal_content ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0px; }
      .PageInfoModalPopup .modal_content ul li {
        margin-left: 0;
        padding-left: 25px; }
        .PageInfoModalPopup .modal_content ul li::before {
          content: "\2022";
          color: #2c2f3c;
          display: inline-block;
          width: 15px;
          margin-left: -15px;
          font-weight: 900;
          font-size: 12px; }
    .PageInfoModalPopup .modal_content .infoBlock {
      display: flex;
      align-items: center;
      font-weight: 500; }
      .PageInfoModalPopup .modal_content .infoBlock ul {
        margin-right: 15px; }
        .PageInfoModalPopup .modal_content .infoBlock ul li:not(:last-child) {
          padding-bottom: 5px; }
        .PageInfoModalPopup .modal_content .infoBlock ul li::before {
          color: #008dfb; }
      .PageInfoModalPopup .modal_content .infoBlock a:hover {
        text-decoration: underline; }

.ReactModalPortal .info_button_modal,
.PageInfoModal .info_button_modal {
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 7; }
  .ReactModalPortal .info_button_modal.tooltip .tooltiptext,
  .PageInfoModal .info_button_modal.tooltip .tooltiptext {
    min-width: 40px !important;
    margin-left: -54px !important;
    top: 160% !important;
    line-height: 15px !important;
    font-size: 11px !important;
    padding: 5px 10px !important; }
    .ReactModalPortal .info_button_modal.tooltip .tooltiptext em,
    .PageInfoModal .info_button_modal.tooltip .tooltiptext em {
      color: #fcb452;
      font-style: normal;
      display: block;
      white-space: nowrap; }
  @media screen and (max-width: 600px) {
    .ReactModalPortal .info_button_modal,
    .PageInfoModal .info_button_modal {
      display: none; } }

.ReactModalPortal .info_button_modal {
  float: left;
  margin-right: 8px; }

.modal_content {
  /* padding: 0 30px; */
  padding: 0px 30px 15px 30px; }

.modal_content h3 {
  padding-bottom: 15px;
  border-bottom: 1px solid black; }

.modal_content p {
  position: relative; }

.modal_content strong {
  font-weight: 600; }
  .modal_content strong:hover {
    background-color: rgba(204, 232, 254, 0.3); }

.ReactModalPortal .ReactModal__Overlay {
  background-color: rgba(25, 42, 48, 0.5) !important;
  z-index: 10 !important; }

.ReactModalPortal .ReactModal__Content {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  position: relative !important;
  top: auto !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important;
  max-width: 660px;
  margin: 100px auto;
  border: none !important;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), inset 10px 0 0 0 #008dfb;
  border-radius: 0 !important; }

.ReactModalPortal .ReactModal__Content .close {
  position: absolute;
  right: 10px;
  top: 7px;
  border: none;
  color: #6b7c88;
  padding: 7px 9px;
  outline: none;
  cursor: pointer; }
  .ReactModalPortal .ReactModal__Content .close:hover {
    color: #008dfb; }

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 400ms ease-in-out; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.BeaconMR {
  position: absolute;
  top: 0;
  left: -8px;
  height: 0;
  width: 0;
  /*
    position: absolute;
    top: 0;
    left: 0;
    / * background-color: #FF3800; * /
    height: 0;
    width: 0;
    / * border-radius: 50%; * /
    z-index: 5;
    border-width: 9px 12px 14px 4px;
    border-color: transparent transparent red transparent;
    border-style: solid;
    transform: rotate(-73deg);
    */
  /* .pointer */
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 5; }
  .BeaconMR .pointer {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    border-style: solid; }
    .BeaconMR .pointer:before {
      /* the pulse */
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 0;
      top: 0;
      background-color: transparent;
      border-radius: 50%;
      box-shadow: 0px 0px 2px 2px #FF3800;
      -webkit-animation: active 2s infinite linear;
      animation: active 2s infinite linear; }
    .BeaconMR .pointer.right {
      border-color: transparent transparent transparent #FF3800;
      border-width: 8px 0px 8px 16px;
      -webkit-animation: jitter-right 3s infinite linear;
      animation: jitter-right 3s infinite linear; }
      .BeaconMR .pointer.right:before {
        /* the pulse offset */
        left: -18px;
        top: -8px; }
    .BeaconMR .pointer.down {
      border-color: #FF3800 transparent transparent transparent;
      border-width: 16px 8px 0px 8px;
      -webkit-animation: jitter-down 3s infinite linear;
      animation: jitter-down 3s infinite linear; }
      .BeaconMR .pointer.down:before {
        /* the pulse offset */
        left: -8px;
        top: -18px; }
    .BeaconMR .pointer.left {
      border-color: transparent #FF3800 transparent transparent;
      border-width: 8px 16px 8px 0px;
      -webkit-animation: jitter-left 3s infinite linear;
      animation: jitter-left 3s infinite linear; }
      .BeaconMR .pointer.left:before {
        /* the pulse offset */
        left: 4px;
        top: -8px; }
    .BeaconMR .pointer.up {
      border-color: transparent transparent #FF3800 transparent;
      border-width: 0px 8px 16px 8px;
      -webkit-animation: jitter-up 3s infinite linear;
      animation: jitter-up 3s infinite linear; }
      .BeaconMR .pointer.up:before {
        /* the pulse offset */
        left: -4px;
        top: 0px; }
  .BeaconMR:hover .BeaconTooltip {
    display: block; }
  .BeaconMR .BeaconTooltip {
    display: none; }
    .BeaconMR .BeaconTooltip .BeaconText {
      position: absolute;
      top: 22px;
      left: 0;
      width: 100px;
      background-color: #222;
      color: #fff;
      border-radius: 3px;
      padding: 5px 10px;
      margin-left: -50px;
      text-align: center; }
      .BeaconMR .BeaconTooltip .BeaconText.left {
        top: 40px; }
      .BeaconMR .BeaconTooltip .BeaconText.right {
        top: 24px; }
      .BeaconMR .BeaconTooltip .BeaconText.down {
        top: 40px; }

@-webkit-keyframes active {
  0% {
    -webkit-transform: scale(0.1);
    opacity: 1; }
  70% {
    -webkit-transform: scale(2.5);
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes active {
  0% {
    transform: scale(0.1);
    opacity: 1; }
  70% {
    transform: scale(2.5);
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes jitter-down {
  0% {
    transform: translate(0, 0px); }
  5% {
    transform: translate(2px, 0px); }
  10% {
    transform: translate(-2px, 0px); }
  15% {
    transform: translate(2px, 0px); }
  20% {
    transform: translate(-2px, 0px); }
  25% {
    transform: translate(2px, 0px); }
  30% {
    transform: translate(-2px, 0px); }
  35% {
    transform: translate(2px, 0px); }
  40% {
    transform: translate(-2px, 0px); }
  45% {
    transform: translate(2px, 0px); }
  50% {
    transform: translate(-2px, 0px); }
  55% {
    transform: translate(0px, 0px); }
  100% {
    transform: translate(0, 0); } }

@keyframes jitter-down {
  0% {
    transform: translate(0, 0px); }
  5% {
    transform: translate(2px, 0px); }
  10% {
    transform: translate(-2px, 0px); }
  15% {
    transform: translate(2px, 0px); }
  20% {
    transform: translate(-2px, 0px); }
  25% {
    transform: translate(2px, 0px); }
  30% {
    transform: translate(-2px, 0px); }
  35% {
    transform: translate(2px, 0px); }
  40% {
    transform: translate(-2px, 0px); }
  45% {
    transform: translate(2px, 0px); }
  50% {
    transform: translate(-2px, 0px); }
  55% {
    transform: translate(0px, 0px); }
  100% {
    transform: translate(0, 0); } }

@-webkit-keyframes jitter-up {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(2px, 0px); }
  55% {
    transform: translate(-2px, 0px); }
  60% {
    transform: translate(2px, 0px); }
  65% {
    transform: translate(-2px, 0px); }
  70% {
    transform: translate(2px, 0px); }
  75% {
    transform: translate(-2px, 0px); }
  80% {
    transform: translate(2px, 0px); }
  85% {
    transform: translate(-2px, 0px); }
  90% {
    transform: translate(2px, 0px); }
  95% {
    transform: translate(-2px, 0px); }
  100% {
    transform: translate(0, 0px); } }

@keyframes jitter-up {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(2px, 0px); }
  55% {
    transform: translate(-2px, 0px); }
  60% {
    transform: translate(2px, 0px); }
  65% {
    transform: translate(-2px, 0px); }
  70% {
    transform: translate(2px, 0px); }
  75% {
    transform: translate(-2px, 0px); }
  80% {
    transform: translate(2px, 0px); }
  85% {
    transform: translate(-2px, 0px); }
  90% {
    transform: translate(2px, 0px); }
  95% {
    transform: translate(-2px, 0px); }
  100% {
    transform: translate(0, 0px); } }

@-webkit-keyframes jitter-right {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(0, 2px); }
  55% {
    transform: translate(0px, -2px); }
  60% {
    transform: translate(0px, 2px); }
  65% {
    transform: translate(0px, -2px); }
  70% {
    transform: translate(0px, 2px); }
  75% {
    transform: translate(0px, -2px); }
  80% {
    transform: translate(0px, 2px); }
  85% {
    transform: translate(0px, -2px); }
  90% {
    transform: translate(0px, 2px); }
  95% {
    transform: translate(0px, -2px); }
  100% {
    transform: translate(0, 0px); } }

@keyframes jitter-right {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(0, 2px); }
  55% {
    transform: translate(0px, -2px); }
  60% {
    transform: translate(0px, 2px); }
  65% {
    transform: translate(0px, -2px); }
  70% {
    transform: translate(0px, 2px); }
  75% {
    transform: translate(0px, -2px); }
  80% {
    transform: translate(0px, 2px); }
  85% {
    transform: translate(0px, -2px); }
  90% {
    transform: translate(0px, 2px); }
  95% {
    transform: translate(0px, -2px); }
  100% {
    transform: translate(0, 0px); } }

@-webkit-keyframes jitter-left {
  0% {
    transform: translate(0, 0px); }
  5% {
    transform: translate(0, 2px); }
  10% {
    transform: translate(0, -2px); }
  15% {
    transform: translate(0, 2px); }
  20% {
    transform: translate(0, -2px); }
  25% {
    transform: translate(0, 2px); }
  30% {
    transform: translate(0, -2px); }
  35% {
    transform: translate(0, 2px); }
  40% {
    transform: translate(0, -2px); }
  45% {
    transform: translate(0, 2px); }
  50% {
    transform: translate(0, -2px); }
  55% {
    transform: translate(0px, 0px); }
  100% {
    transform: translate(0, 0); } }

@keyframes jitter-left {
  0% {
    transform: translate(0, 0px); }
  5% {
    transform: translate(0, 2px); }
  10% {
    transform: translate(0, -2px); }
  15% {
    transform: translate(0, 2px); }
  20% {
    transform: translate(0, -2px); }
  25% {
    transform: translate(0, 2px); }
  30% {
    transform: translate(0, -2px); }
  35% {
    transform: translate(0, 2px); }
  40% {
    transform: translate(0, -2px); }
  45% {
    transform: translate(0, 2px); }
  50% {
    transform: translate(0, -2px); }
  55% {
    transform: translate(0px, 0px); }
  100% {
    transform: translate(0, 0); } }

.LabelUI {
  display: flex;
  margin: 20px 0;
  justify-content: center;
  align-items: center; }
  .LabelUI i {
    flex-grow: 1;
    background: #e9e9e9;
    height: 1px; }
  .LabelUI span {
    padding: 3px 14px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
    margin: 0 10px;
    color: #2c2f3c;
    background-color: #e1f1fe; }

.SearchModalContent {
  position: fixed;
  top: 15px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  background-color: #fff;
  box-sizing: border-box;
  min-height: 150px;
  max-height: calc(100vh - 30px);
  z-index: 10;
  text-align: left;
  margin-left: 0;
  border-radius: 8px;
  width: calc(100vw - 20px);
  left: 10px;
  padding: 30px 16px 0;
  display: flex;
  flex-direction: column; }
  .SearchModalContent .SearchModalContentBody {
    flex: 1 1 auto;
    overflow: auto; }
  @media screen and (min-width: 600px) {
    .SearchModalContent {
      left: 50%;
      width: 710px;
      margin-left: -350px;
      padding: 30px 50px 40px 40px; } }
  .SearchModalContent .SearchInputContainer {
    padding-left: 35px;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 10px 7px;
    display: flex;
    align-items: center;
    background-color: #f9f9f6;
    border-radius: 30px; }
    .SearchModalContent .SearchInputContainer .SearchInput {
      box-sizing: border-box;
      width: 100%;
      padding: 3px 6px;
      line-height: 30px;
      border: 0;
      font-size: 18px;
      font-family: "Montserrat", sans-serif;
      background-color: transparent; }
      .SearchModalContent .SearchInputContainer .SearchInput::-webkit-input-placeholder {
        color: #909fa9; }
      .SearchModalContent .SearchInputContainer .SearchInput::-ms-input-placeholder {
        color: #909fa9; }
      .SearchModalContent .SearchInputContainer .SearchInput::placeholder {
        color: #909fa9; }
      .SearchModalContent .SearchInputContainer .SearchInput:focus {
        outline: none; }
    .SearchModalContent .SearchInputContainer .ClearIcon {
      cursor: pointer;
      width: 14px;
      height: 14px;
      margin-right: 10px; }
      .SearchModalContent .SearchInputContainer .ClearIcon:hover .background {
        transform: scale(1); }
      .SearchModalContent .SearchInputContainer .ClearIcon .background {
        transform: scale(0);
        transition: transform 200ms;
        transition-timing-function: ease-out;
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: rgba(204, 232, 254, 0.3);
        top: -6px;
        left: -5px;
        z-index: -5; }
  .SearchModalContent .close {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    color: #6b7c88;
    padding: 7px 9px;
    cursor: pointer; }
    .SearchModalContent .close:hover {
      color: #008dfb; }
  .SearchModalContent .separator {
    display: flex;
    width: 100%;
    align-items: center; }
    .SearchModalContent .separator i {
      height: 2px;
      background: #e3e3e3;
      flex-grow: 1; }
    .SearchModalContent .separator span {
      padding-left: 10px;
      padding-right: 20px;
      color: #6b7c88;
      font-size: 13px; }
  .SearchModalContent .SearchResults {
    display: table;
    width: 100%; }
    .SearchModalContent .SearchResults .searchResultIcon {
      width: 20px;
      background-size: 15px;
      background-repeat: no-repeat;
      background-position: center left;
      padding-right: 10px; }
    .SearchModalContent .SearchResults .SearchResult {
      display: table-row; }
      .SearchModalContent .SearchResults .SearchResult .SearchResultTitle {
        line-height: 24px; }
      .SearchModalContent .SearchResults .SearchResult .AthleteNoOfOccurences {
        min-width: 100px; }
        .SearchModalContent .SearchResults .SearchResult .AthleteNoOfOccurences .muted {
          color: #909fa9;
          margin-left: 3px; }
      .SearchModalContent .SearchResults .SearchResult > * {
        display: table-cell; }
    .SearchModalContent .SearchResults + .separator {
      margin-top: 20px; }
  .SearchModalContent .SearchResult em {
    color: #008dfb;
    font-weight: 600;
    font-style: normal; }
  .SearchModalContent .QuickLinks {
    padding-top: 10px; }
    .SearchModalContent .QuickLinks h5 {
      margin: 15px 0; }
    .SearchModalContent .QuickLinks .QuickLink {
      color: #008dfb;
      display: inline-block;
      margin-right: 10px;
      cursor: pointer; }
      .SearchModalContent .QuickLinks .QuickLink:hover {
        text-decoration: underline; }
  .SearchModalContent .AppliedFilters .AppliedFilter {
    display: flex;
    align-items: center; }
    .SearchModalContent .AppliedFilters .AppliedFilter .filterIcon {
      width: 14px;
      height: 14px;
      cursor: pointer;
      display: block;
      background-size: 14px;
      margin-right: 7px; }
    .SearchModalContent .AppliedFilters .AppliedFilter .filterTitle {
      flex-grow: 1; }
      .SearchModalContent .AppliedFilters .AppliedFilter .filterTitle .dot {
        padding: 0 10px; }
    .SearchModalContent .AppliedFilters .AppliedFilter .closeAppliedFilter {
      float: right;
      font-size: 30px;
      line-height: 9px;
      width: 20px;
      height: 20px;
      text-align: center;
      cursor: pointer;
      color: #008dfb;
      font-weight: 100;
      box-sizing: border-box;
      order: 3; }
      .SearchModalContent .AppliedFilters .AppliedFilter .closeAppliedFilter:hover {
        background-color: #008dfb;
        color: #fff; }
      .SearchModalContent .AppliedFilters .AppliedFilter .closeAppliedFilter ~ .FilterIcon {
        max-width: 32px; }
        .SearchModalContent .AppliedFilters .AppliedFilter .closeAppliedFilter ~ .FilterIcon .icon, .SearchModalContent .AppliedFilters .AppliedFilter .closeAppliedFilter ~ .FilterIcon .hover {
          width: 16px;
          height: 16px; }
      .SearchModalContent .AppliedFilters .AppliedFilter .closeAppliedFilter:hover ~ .FilterIcon .hover {
        display: block; }
      .SearchModalContent .AppliedFilters .AppliedFilter .closeAppliedFilter:hover ~ .FilterIcon .icon {
        display: none; }
      .SearchModalContent .AppliedFilters .AppliedFilter .closeAppliedFilter:hover ~ .filterTitle {
        color: #6b7c88; }

.RaceSearchResult {
  cursor: pointer; }
  .RaceSearchResult:hover {
    background-color: rgba(204, 232, 254, 0.3); }

.AthleteSearchResult {
  cursor: pointer; }
  .AthleteSearchResult .AthleteNoOfOccurences .muted {
    display: none; }
  .AthleteSearchResult .UserIcon {
    width: 24px;
    height: 16px; }
    .AthleteSearchResult .UserIcon .icon {
      display: none; }
    .AthleteSearchResult .UserIcon:hover {
      background-color: #008dfb; }
    .AthleteSearchResult .UserIcon .tooltiptext {
      min-width: 33px !important;
      top: 139% !important;
      left: 30% !important; }
      .AthleteSearchResult .UserIcon .tooltiptext em {
        font-size: 11px;
        color: #fcb452;
        font-style: normal;
        font-weight: 500;
        display: block;
        white-space: nowrap; }
  .AthleteSearchResult .padder {
    width: 40px; }
  .AthleteSearchResult:hover {
    background-color: rgba(204, 232, 254, 0.3); }
    .AthleteSearchResult:hover .UserIcon .icon {
      display: block; }
    .AthleteSearchResult:hover .AthleteNoOfOccurences .muted {
      display: inline-block; }

.SearchCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5; }

.searchIcon {
  display: inline-block;
  cursor: pointer; }
  .searchIcon img {
    display: block; }
  .searchIcon:hover .background {
    transform: scale(1); }
  .searchIcon .background {
    transform: scale(0);
    transition: transform 200ms;
    transition-timing-function: ease-out;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: rgba(204, 232, 254, 0.3);
    top: -11px;
    left: -10px;
    z-index: -5; }

.IconSwappable, .IconSwappable > * {
  background-repeat: no-repeat;
  background-position: center;
  min-width: 10px;
  min-height: 10px;
  width: 100%;
  height: 100%; }

.IconSwappable .icon {
  display: block; }

.IconSwappable .hover {
  display: none; }

.IconSwappable:hover .icon {
  display: none !important; }

.IconSwappable:hover .hover {
  display: block; }

.MyRaceTooltip {
  background-color: #008dfb !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  padding: 8px 8px 8px 8px !important; }
  .MyRaceTooltip:after {
    border-bottom-color: #008dfb !important; }

.MyRaceTooltipNoArrow {
  background-color: #008dfb !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  padding: 8px 8px 8px 8px !important; }
  .MyRaceTooltipNoArrow:after {
    border-color: transparent !important; }

.MyRaceTooltipDark {
  background-color: #2c2f3c !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  padding: 8px 8px 8px 8px !important; }
  .MyRaceTooltipDark:after {
    border-bottom-color: #2c2f3c !important; }

.MyRaceTooltipDarkNoArrow {
  background-color: #2c2f3c !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  padding: 6px 8px 6px 8px !important; }
  .MyRaceTooltipDarkNoArrow:after {
    border-bottom-color: transparent !important; }

.MyRaceTooltipDarkLeftArrow {
  background-color: #2c2f3c !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  padding: 8px 8px 8px 8px !important; }
  .MyRaceTooltipDarkLeftArrow:after {
    border-color: transparent !important; }

.MyRaceTooltipDarkRightArrow {
  background-color: #113b76 !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  padding: 8px 8px 8px 8px !important; }
  .MyRaceTooltipDarkRightArrow:after {
    border-color: transparent !important; }

.MyRaceTooltipLight {
  background-color: #cce8fe !important;
  color: #000 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  padding: 8px 8px 8px 8px !important; }
  .MyRaceTooltipLight:after {
    border-bottom-color: transparent !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important; }

.LoginPopupContainer {
  position: relative; }
  .LoginPopupContainer .LoginIcon {
    width: 40px;
    height: 40px;
    border: 3px solid #cce8fe;
    border-radius: 100%;
    cursor: pointer; }
    .LoginPopupContainer .LoginIcon:hover {
      background-color: #008dfb;
      border-color: #008dfb; }
  .LoginPopupContainer .isDropdownOpen {
    background-color: #FFF;
    border-color: #cce8fe; }

.LoginModalContent {
  position: fixed;
  top: 0;
  left: 50%;
  width: 480px;
  margin-left: -240px;
  padding: 0 30px 40px;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
  box-sizing: border-box;
  min-height: 150px;
  max-height: 100vh;
  overflow: auto;
  z-index: 900;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4); }
  .LoginModalContent .tooltiptext {
    min-width: 33px !important;
    top: 139% !important;
    left: 65% !important; }
    .LoginModalContent .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }
  @media screen and (min-width: 801px) {
    .LoginModalContent {
      top: 110px; } }
  .LoginModalContent .Label {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: center; }
    .LoginModalContent .Label i {
      flex-grow: 1;
      background: #e9e9e9;
      height: 1px; }
    .LoginModalContent .Label span {
      padding: 3px 14px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 600;
      margin: 0 10px;
      color: #2c2f3c;
      background-color: #e1f1fe; }
  .LoginModalContent img.runner {
    display: block;
    margin: 45px auto 10px; }
  .LoginModalContent h1 {
    text-align: center;
    margin: 0;
    font-size: 28px;
    color: #2c2f3c; }
  .LoginModalContent .logo {
    display: block;
    text-align: center;
    margin: 20px 0 10px; }
  .LoginModalContent p {
    text-align: center; }
  .LoginModalContent .tagline {
    font-size: 12px;
    color: #2c2f3c;
    font-weight: 600;
    margin: 20px 0; }
  @media screen and (max-width: 600px) {
    .LoginModalContent {
      left: 0;
      margin-left: 0;
      width: 100vw;
      padding-top: 40px; } }
  .LoginModalContent .FormErrorMessage {
    color: #c11515;
    padding: 0px 20px 0px;
    text-align: center; }
  .LoginModalContent .secure-message {
    text-align: center;
    font-size: 13px;
    color: #2c2f3c;
    margin-bottom: 0;
    margin-left: 30px;
    margin-right: 30px; }
  .LoginModalContent .control {
    width: calc(100% - 40px);
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
    max-width: 320px; }
    .LoginModalContent .control .ControlIcon {
      display: flex;
      width: 38px;
      height: 34px;
      position: absolute;
      top: 1px;
      right: 0px;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #929292; }
  .LoginModalContent .control.PasswordField {
    display: flex;
    align-items: center; }
    .LoginModalContent .control.PasswordField label {
      flex-grow: 1; }
      .LoginModalContent .control.PasswordField label input {
        border-radius: 30px; }
    .LoginModalContent .control.PasswordField .RevealButton {
      cursor: pointer;
      height: 36px;
      line-height: 36px;
      padding-right: 10px;
      padding-top: 4px;
      box-sizing: border-box;
      position: absolute;
      right: 0;
      top: 10; }
    .LoginModalContent .control.PasswordField .IconSwappableReveal .icon {
      display: block; }
    .LoginModalContent .control.PasswordField .IconSwappableReveal .valid {
      display: none; }
    .LoginModalContent .control.PasswordField .IconSwappableReveal .invalid {
      display: none; }
    .LoginModalContent .control.PasswordField.invalid .IconSwappableReveal .icon {
      display: none; }
    .LoginModalContent .control.PasswordField.invalid .IconSwappableReveal .valid {
      display: none; }
    .LoginModalContent .control.PasswordField.invalid .IconSwappableReveal .invalid {
      display: block; }
    .LoginModalContent .control.PasswordField.valid .IconSwappableReveal .icon {
      display: none; }
    .LoginModalContent .control.PasswordField.valid .IconSwappableReveal .valid {
      display: block; }
    .LoginModalContent .control.PasswordField.valid .IconSwappableReveal .invalid {
      display: none; }
  .LoginModalContent .rememberpass {
    text-align: center;
    margin: 20px 0;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .LoginModalContent input[type="password"], .LoginModalContent input[type="email"], .LoginModalContent input[type="text"] {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin: 10px 0;
    padding: 10px;
    border-radius: 30px;
    height: 36px;
    padding-left: 20px;
    background-color: #f1f7fd;
    border: 2px solid #f1f7fd; }
    .LoginModalContent input[type="password"]:hover, .LoginModalContent input[type="email"]:hover, .LoginModalContent input[type="text"]:hover {
      border-color: #ddedfd; }
    .LoginModalContent input[type="password"]:focus, .LoginModalContent input[type="email"]:focus, .LoginModalContent input[type="text"]:focus {
      outline: none;
      border-color: #ddedfd !important;
      background-color: #fff !important; }
    .LoginModalContent input[type="password"]::-webkit-input-placeholder, .LoginModalContent input[type="email"]::-webkit-input-placeholder, .LoginModalContent input[type="text"]::-webkit-input-placeholder {
      color: #000;
      font-weight: 600; }
    .LoginModalContent input[type="password"]::-ms-input-placeholder, .LoginModalContent input[type="email"]::-ms-input-placeholder, .LoginModalContent input[type="text"]::-ms-input-placeholder {
      color: #000;
      font-weight: 600; }
    .LoginModalContent input[type="password"]::placeholder, .LoginModalContent input[type="email"]::placeholder, .LoginModalContent input[type="text"]::placeholder {
      color: #000;
      font-weight: 600; }
  .LoginModalContent .invalid input[type="password"], .LoginModalContent .invalid input[type="email"], .LoginModalContent .invalid input[type="text"] {
    background-color: #ffe1d9;
    border-color: #ffe1d9; }
    .LoginModalContent .invalid input[type="password"]:hover, .LoginModalContent .invalid input[type="email"]:hover, .LoginModalContent .invalid input[type="text"]:hover {
      border-color: #f9e2d9; }
  .LoginModalContent .valid input[type="password"], .LoginModalContent .valid input[type="email"], .LoginModalContent .valid input[type="text"] {
    background-color: #f0faf3;
    border-color: #f0faf3; }
    .LoginModalContent .valid input[type="password"]:hover, .LoginModalContent .valid input[type="email"]:hover, .LoginModalContent .valid input[type="text"]:hover {
      border-color: #daf4e0; }
  .LoginModalContent input[type="submit"] {
    display: block;
    /* width: 100%; */
    border: none;
    padding: 5px 40px 5px 30px;
    background: #008dfb;
    /* margin-top: 20px; */
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;
    margin: 15px auto 30px;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-position: right 23px top 7px;
    outline: none; }
  .LoginModalContent .ForgotPasswordContainer {
    width: calc(100% - 40px);
    max-width: 320px;
    margin: 0 auto;
    text-align: right;
    padding-right: 25px; }
    .LoginModalContent .ForgotPasswordContainer .ForgotPasswordLink {
      display: inline-block;
      cursor: pointer;
      font-size: 11px;
      color: #2c2f3c; }
      .LoginModalContent .ForgotPasswordContainer .ForgotPasswordLink:hover {
        text-decoration: underline; }
  .LoginModalContent .buttons {
    display: flex;
    justify-content: center;
    margin-top: 15px; }
    .LoginModalContent .buttons button, .LoginModalContent .buttons .button {
      box-sizing: border-box;
      display: block;
      background: #008dfb;
      color: #ffffff;
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
      padding: 1px 25px;
      margin: 0 10px;
      border: 2px solid #008dfb;
      font-family: inherit;
      cursor: pointer;
      border-radius: 17px; }
      .LoginModalContent .buttons button:hover, .LoginModalContent .buttons .button:hover {
        border: solid 2px rgba(0, 0, 0, 0.05);
        background-color: #0086ef; }
      .LoginModalContent .buttons button.secondary, .LoginModalContent .buttons .button.secondary {
        background-color: #fff;
        color: #008dfb;
        border-color: #cce8fe; }
        .LoginModalContent .buttons button.secondary:hover, .LoginModalContent .buttons .button.secondary:hover {
          border: solid 2px rgba(0, 141, 251, 0.5); }
      .LoginModalContent .buttons button[disabled], .LoginModalContent .buttons .button[disabled] {
        background: #e4e4e4;
        color: #ffffff;
        border: 2px solid #d6d6d6;
        cursor: default; }
  .LoginModalContent input:focus, .LoginModalContent button:focus {
    outline: none; }
  .LoginModalContent .ClearIcon {
    cursor: pointer;
    max-width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 25px;
    z-index: 10; }
    .LoginModalContent .ClearIcon:hover .background {
      transform: scale(1); }
    .LoginModalContent .ClearIcon .background {
      transform: scale(0);
      transition: transform 200ms;
      transition-timing-function: ease-out;
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: rgba(204, 232, 254, 0.3);
      top: -6px;
      left: -5px;
      z-index: -5; }

.LoginTabs {
  display: flex;
  margin-bottom: 20px; }
  .LoginTabs > * {
    padding: 15px;
    flex: 1 1;
    border-bottom: 1px solid gray;
    text-align: center;
    cursor: pointer;
    color: #008dfb;
    font-size: 14px;
    font-weight: bold; }
    .LoginTabs > *.active {
      border-bottom-color: black;
      color: #2c2f3c;
      cursor: default; }

.withLine {
  display: flex;
  justify-content: center;
  align-items: center; }
  .withLine:before, .withLine:after {
    display: block;
    content: " ";
    flex-grow: 1;
    border-bottom: 1px solid #e9e9e9; }
  .withLine:before {
    margin-right: 7px; }
  .withLine:after {
    margin-left: 7px; }

.LoggedInUserInitials {
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  /* display: flex; */
  height: 50px;
  width: 50px;
  /* justify-content: center; */
  /* align-items: center; */
  font-size: 21px;
  border-radius: 100%;
  line-height: 43px;
  text-align: center;
  color: #008dfb;
  border: 3px solid #cfd8dc; }
  .LoggedInUserInitials img {
    width: 36px;
    height: 36px;
    border: 0px solid #fff;
    border-radius: 39px;
    position: absolute;
    top: 4px;
    left: 4px; }
  .LoggedInUserInitials:hover {
    background-color: #008dfb;
    border-color: #cce8fe;
    color: #fff; }
  .LoggedInUserInitials.withAvatarImage:hover {
    background-color: transparent; }
  .LoggedInUserInitials.uploadedImage {
    padding: 4px; }
    .LoggedInUserInitials.uploadedImage .userAvatar {
      width: 74px;
      height: 74px; }

.LoginCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11; }

.LoginSocial {
  text-align: center;
  color: #2c2f3c;
  margin-bottom: 25px; }
  .LoginSocial .LoginSocialIcons {
    display: flex;
    justify-content: center; }
    .LoginSocial .LoginSocialIcons .Icon {
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      margin: 0px 6px;
      border: 1px solid #008dfb;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
      .LoginSocial .LoginSocialIcons .Icon:hover {
        border-color: transparent;
        box-shadow: 0px 0px 0px 4px rgba(121, 158, 187, 0.3); }

.IconSwappableValid, .IconSwappableValid > * {
  background-repeat: no-repeat;
  background-position: center;
  min-width: 10px;
  min-height: 10px;
  width: 100%;
  height: 100%;
  background-size: 16px; }

.invalid .IconSwappableValid .icon {
  display: none !important; }

.invalid .IconSwappableValid .valid {
  display: none !important; }

.invalid .IconSwappableValid .invalid {
  display: block; }

.valid .IconSwappableValid .icon {
  display: none !important; }

.valid .IconSwappableValid .valid {
  display: block; }

.valid .IconSwappableValid .invalid {
  display: none !important; }

.IconSwappableValid .icon {
  display: block; }

.IconSwappableValid .valid {
  display: none; }

.IconSwappableValid .invalid {
  display: none; }

.DropdownMenu {
  box-sizing: border-box;
  position: absolute;
  top: -7777px;
  left: -7777px;
  z-index: 7;
  min-width: 200px;
  min-height: 10px;
  background-color: #fff;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 200ms;
  padding: 10px 15px 15px 15px;
  font-family: Montserrat,sans-serif;
  font-weight: 400; }
  .DropdownMenu.show {
    opacity: 1;
    top: 60px;
    left: -18px; }
  .DropdownMenu .hr {
    height: 1px;
    background-color: #e9e9e9;
    margin: 5px 0 8px; }
    .DropdownMenu .hr.spacer {
      background-color: transparent;
      height: 5px;
      margin: 0; }
  .DropdownMenu .Option {
    text-align: left;
    font-size: 14px;
    cursor: pointer;
    display: block;
    font-weight: 500;
    border-radius: 20px;
    color: #008dfb;
    padding: 3px 10px;
    margin: 3px 0; }
    .DropdownMenu .Option:hover {
      background-color: #f1f7fd;
      color: #2c2f3c; }
    .DropdownMenu .Option.primary {
      color: #2c2f3c; }
    .DropdownMenu .Option.secondary {
      font-size: 12px;
      color: #2c2f3c;
      font-weight: 400;
      display: flex; }
      .DropdownMenu .Option.secondary:hover {
        background-color: transparent; }
        .DropdownMenu .Option.secondary:hover .title {
          text-decoration: underline;
          color: #2c2f3c; }
    .DropdownMenu .Option.bold {
      font-weight: 600; }
    .DropdownMenu .Option .icon {
      margin-right: 15px; }
      .DropdownMenu .Option .icon.hover {
        display: none; }
      .DropdownMenu .Option .icon img {
        width: 16px; }
    .DropdownMenu .Option:hover .icon {
      display: none; }
      .DropdownMenu .Option:hover .icon.hover {
        display: block; }
    .DropdownMenu .Option.logout {
      font-weight: 600; }
      .DropdownMenu .Option.logout:hover {
        color: #fff;
        background-color: #008dfb; }

.LoginDropdownCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(25, 42, 48, 0.1);
  z-index: 7; }

.ToggleButton {
  justify-content: center;
  display: flex; }
  .ToggleButton > div {
    background-color: #fff;
    color: #008dfb;
    border-top: 1px solid #008dfb;
    border-bottom: 1px solid #008dfb;
    border-left: 1px solid #008dfb;
    padding: 5px 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    position: relative; }
    .ToggleButton > div:hover {
      background-color: #2c2f3c;
      color: #71e1f4; }
    .ToggleButton > div:first-child {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      border-right-width: 0;
      padding-left: 18px; }
      .ToggleButton > div:first-child:not(.on):hover {
        border-left-color: #eff8fe; }
    .ToggleButton > div:last-child {
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      padding-right: 18px;
      border-top: 1px solid #008dfb;
      border-bottom: 1px solid #008dfb;
      border-right: 1px solid #008dfb; }
      .ToggleButton > div:last-child:not(.on):hover {
        border-right-color: #2c2f3c; }
    .ToggleButton > div.on {
      background-color: #008dfb;
      color: #fff; }
    .ToggleButton > div:not(.on) {
      cursor: pointer; }
      .ToggleButton > div:not(.on):hover {
        border-top-color: #2c2f3c;
        border-bottom-color: #2c2f3c; }
  .ToggleButton.ToggleGraphTable, .ToggleButton.ToggleInterval, .ToggleButton.ToggleLoginSignup {
    z-index: 3;
    position: relative;
    padding: 0; }
    .ToggleButton.ToggleGraphTable > div, .ToggleButton.ToggleInterval > div, .ToggleButton.ToggleLoginSignup > div {
      padding: 2px 12px;
      text-transform: none;
      font-size: 12px;
      font-weight: 600; }

.KeepInTouchModalCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5; }

.KeepInTouchModalForm {
  font-family: "Montserrat", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 12px;
  background-color: #fff;
  padding: 20px 0 20px 0;
  box-sizing: border-box;
  min-height: 150px;
  max-height: 100vh;
  overflow: auto;
  z-index: 10;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3), 0 1px 5px rgba(0, 0, 0, 0.2);
  width: calc(100vw - 40px);
  margin: 20px; }
  .KeepInTouchModalForm .HeroImage {
    position: relative; }
    .KeepInTouchModalForm .HeroImage img.CityBackground {
      width: 100%;
      margin-bottom: 18px; }
    .KeepInTouchModalForm .HeroImage img.Runner {
      position: absolute;
      width: 80px;
      height: 80px;
      bottom: -5px;
      left: 50%;
      margin-left: -40px; }
  .KeepInTouchModalForm .ModalContent {
    padding: 20px 30px; }
  @media screen and (min-width: 801px) {
    .KeepInTouchModalForm {
      margin: 0;
      margin-left: -240px;
      top: 40px;
      width: 480px;
      left: 50%;
      border-radius: 12px; } }
  .KeepInTouchModalForm .ClearIcon {
    cursor: pointer;
    max-width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 25px;
    z-index: 10; }
    .KeepInTouchModalForm .ClearIcon:hover .background {
      transform: scale(1); }
    .KeepInTouchModalForm .ClearIcon .background {
      transform: scale(0);
      transition: transform 200ms;
      transition-timing-function: ease-out;
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: rgba(204, 232, 254, 0.3);
      top: -6px;
      left: -5px;
      z-index: -5; }
  .KeepInTouchModalForm .closeKeepInTouchModal {
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 25px;
    color: #525151;
    cursor: pointer; }
  .KeepInTouchModalForm .Logo {
    margin-top: 10px;
    text-align: center;
    max-width: 108px;
    margin: 10px auto 0; }
    .KeepInTouchModalForm .Logo img {
      display: inline-block !important;
      max-width: 100%; }
    @media screen and (min-width: 801px) {
      .KeepInTouchModalForm .Logo {
        max-width: none; } }
  .KeepInTouchModalForm .title {
    font-size: 16px;
    font-weight: 800;
    color: #2c2f3c;
    margin: 10px 0;
    text-align: center; }
    @media screen and (min-width: 801px) {
      .KeepInTouchModalForm .title {
        font-size: 28px;
        margin: 5px 0 5px; } }
  .KeepInTouchModalForm p {
    font-size: 12px;
    color: #2c2f3c;
    line-height: 1.5;
    display: block;
    margin: 1em auto !important;
    max-width: 500px;
    font-weight: 600;
    text-align: center;
    max-width: 370px; }
    .KeepInTouchModalForm p.light {
      font-weight: 400; }
  .KeepInTouchModalForm .Label {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: center; }
    .KeepInTouchModalForm .Label i {
      flex-grow: 1;
      background: #e9e9e9;
      height: 1px; }
    .KeepInTouchModalForm .Label span {
      padding: 3px 14px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 600;
      margin: 0 10px;
      color: #2c2f3c;
      background-color: #e1f1fe; }
  .KeepInTouchModalForm label {
    display: block;
    margin: 20px auto;
    text-align: left;
    box-sizing: border-box;
    max-width: 320px; }
    .KeepInTouchModalForm label .label {
      display: block;
      font-size: 12px;
      color: #4a4a4a;
      font-weight: 800;
      margin-left: 21px;
      margin-bottom: 5px; }
    .KeepInTouchModalForm label input {
      background-color: #e3f2ff;
      color: #2c2f3c;
      border: none;
      display: block;
      width: 100%;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: right 10px center;
      font-size: 12px;
      padding: 10px 22px;
      background-size: 16px;
      border-radius: 16px; }
      .KeepInTouchModalForm label input::-webkit-input-placeholder {
        color: #000;
        font-weight: 600; }
      .KeepInTouchModalForm label input::-ms-input-placeholder {
        color: #000;
        font-weight: 600; }
      .KeepInTouchModalForm label input::placeholder {
        color: #000;
        font-weight: 600; }
      @media screen and (min-width: 801px) {
        .KeepInTouchModalForm label input {
          border-radius: 21px; } }
    .KeepInTouchModalForm label.error input {
      background-color: rgba(255, 56, 0, 0.1); }
    @media screen and (min-width: 801px) {
      .KeepInTouchModalForm label {
        margin: 30px auto; } }
  .KeepInTouchModalForm .buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px; }
    .KeepInTouchModalForm .buttons button, .KeepInTouchModalForm .buttons .button {
      box-sizing: border-box;
      display: block;
      background: #008dfb;
      color: #ffffff;
      font-size: 17px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
      padding: 4px 20px;
      margin: 0 10px;
      border: 2px solid #008dfb;
      font-family: inherit;
      cursor: pointer;
      border-radius: 17px; }
      .KeepInTouchModalForm .buttons button:hover, .KeepInTouchModalForm .buttons .button:hover {
        border: solid 2px rgba(0, 0, 0, 0.05);
        background-color: #0086ef; }
      .KeepInTouchModalForm .buttons button.secondary, .KeepInTouchModalForm .buttons .button.secondary {
        background-color: #fff;
        color: #008dfb;
        border-color: #cce8fe; }
        .KeepInTouchModalForm .buttons button.secondary:hover, .KeepInTouchModalForm .buttons .button.secondary:hover {
          border: solid 2px rgba(0, 141, 251, 0.5); }
      .KeepInTouchModalForm .buttons button[disabled], .KeepInTouchModalForm .buttons .button[disabled] {
        background: #e4e4e4;
        color: #ffffff;
        border: 2px solid #d6d6d6;
        cursor: default; }
  .KeepInTouchModalForm input:focus, .KeepInTouchModalForm button:focus {
    outline: none; }
  .KeepInTouchModalForm .ThankYou {
    text-align: center; }
    .KeepInTouchModalForm .ThankYou .ThankYouFinishIcon {
      margin: 30px auto 10px;
      width: 100px; }
  .KeepInTouchModalForm img.runnergif {
    position: fixed;
    bottom: 0px;
    left: 50%;
    width: 100px;
    margin-left: -50px;
    /* <- this has to be half of the width */
    z-index: -1; }
  .KeepInTouchModalForm .tooltiptext {
    min-width: 33px !important;
    top: 139% !important;
    left: 65% !important; }
    .KeepInTouchModalForm .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }

.HeaderSearchBar {
  width: 620px;
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: auto;
  position: relative;
  top: 25px;
  border-radius: 45px;
  padding: 0 18px 0 40px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 10px 11px;
  align-items: center; }
  .HeaderSearchBar input[type=text] {
    flex-grow: 1;
    box-sizing: border-box;
    border: none;
    border-radius: 45px;
    height: 40px;
    padding: 3px 6px 2px 6px;
    line-height: 40px;
    border: 0;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    background-color: transparent; }
    .HeaderSearchBar input[type=text]::-webkit-input-placeholder {
      color: #909fa9;
      font-size: 14px; }
    .HeaderSearchBar input[type=text]::-ms-input-placeholder {
      color: #909fa9;
      font-size: 14px; }
    .HeaderSearchBar input[type=text]::placeholder {
      color: #909fa9;
      font-size: 14px; }
    .HeaderSearchBar input[type=text]:focus {
      outline: none; }
  .HeaderSearchBar .SearchFilterOptions {
    font-size: 12px;
    color: #2c2f3c; }
    .HeaderSearchBar .SearchFilterOptions .SearchFilterOption {
      color: #008dfb;
      cursor: pointer;
      font-weight: 600; }
      .HeaderSearchBar .SearchFilterOptions .SearchFilterOption.selected {
        color: #2c2f3c;
        cursor: default;
        font-weight: 400; }
    .HeaderSearchBar .SearchFilterOptions .dot {
      font-size: 13px;
      font-weight: 600;
      padding: 0 7px; }

.MainAthleteBackground {
  flex-flow: 1;
  background-color: #f3f3ee;
  background-size: 1700px;
  background-repeat: no-repeat;
  background-position: bottom 0px center;
  top: 0;
  left: 0;
  z-index: -1; }
  @media screen and (min-width: 951px) {
    .MainAthleteBackground {
      position: absolute;
      height: 460px;
      width: 100vw; } }

.MainAthleteBlock {
  background-position: bottom -19px center;
  background-size: 1700px;
  background-repeat: no-repeat;
  display: flex; }
  .MainAthleteBlock .card {
    position: relative; }
    .MainAthleteBlock .card .tooltip .tooltiptext {
      min-width: 85px;
      font-weight: 500;
      top: 120%;
      line-height: 15px;
      font-size: 10px;
      padding: 5px 10px; }
      .MainAthleteBlock .card .tooltip .tooltiptext .lightBlueTooltip {
        font-size: 12px;
        color: #71e1f4; }
      .MainAthleteBlock .card .tooltip .tooltiptext em {
        color: #fcb452;
        font-style: normal;
        font-size: 12px;
        display: block;
        white-space: nowrap; }
    .MainAthleteBlock .card .tooltip .welcome {
      height: 30px; }
  .MainAthleteBlock .nextPage, .MainAthleteBlock .prevPage {
    position: absolute;
    top: 160px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 5px 4px 1px 5px;
    border-radius: 50%;
    z-index: 5;
    cursor: pointer; }
    .MainAthleteBlock .nextPage img, .MainAthleteBlock .prevPage img {
      position: relative;
      left: 0;
      transition: left 80ms; }
  .MainAthleteBlock .nextPage {
    right: -20px; }
    .MainAthleteBlock .nextPage:hover img {
      left: 5px; }
    .MainAthleteBlock .nextPage .tooltiptext {
      left: 0px !important; }
  .MainAthleteBlock .prevPage {
    left: -20px; }
    .MainAthleteBlock .prevPage img {
      transform: rotate(180deg); }
    .MainAthleteBlock .prevPage:hover img {
      left: -5px; }
    .MainAthleteBlock .prevPage .tooltiptext {
      left: 0px !important; }
  @media screen and (min-width: 951px) {
    .MainAthleteBlock {
      /* desktop */
      margin-top: 50px; } }
  .MainAthleteBlock > * {
    flex-grow: 1; }
  .MainAthleteBlock .imgLeft, .MainAthleteBlock .imgRight {
    position: relative;
    /* background-size: contain;
        background-repeat: no-repeat;
        transition: background-position 30s;
        top: -50px; */
    top: -28px;
    overflow: hidden; }
    .MainAthleteBlock .imgLeft .backgroundImage, .MainAthleteBlock .imgRight .backgroundImage {
      min-height: 260px;
      background-size: cover;
      background-position: left;
      margin-top: 85px; }
  .MainAthleteBlock .imgLeft .backgroundImage {
    background-position: left; }
  .MainAthleteBlock .imgRight .backgroundImage {
    background-position: right; }
  .MainAthleteBlock .card {
    background-color: #fff;
    /* margin: 47px auto; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 15px;
    max-width: calc(100vw - 40px);
    box-sizing: border-box;
    min-height: 100px; }
    @media only screen and (min-width: 950px) {
      .MainAthleteBlock .card {
        max-width: 520px;
        margin: 0px auto 20px auto; } }
    .MainAthleteBlock .card .cardheader {
      bottom: 15px;
      margin-bottom: -15px;
      font-size: 15px; }
      .MainAthleteBlock .card .cardheader span {
        font-size: 18px;
        margin: 0 5px 0 0;
        cursor: default; }
      .MainAthleteBlock .card .cardheader strong {
        font-weight: 600;
        font-size: 15px;
        color: #008dfb; }
        .MainAthleteBlock .card .cardheader strong:hover {
          background-color: rgba(204, 232, 254, 0.3);
          color: #2c2f3c;
          cursor: pointer; }
      .MainAthleteBlock .card .cardheader > * {
        background: #fff;
        padding: 0 10px;
        line-height: 30px;
        color: inherit;
        text-decoration: none; }
        .MainAthleteBlock .card .cardheader > *:not(:first-child):before {
          display: inline-block;
          content: '';
          width: 0.2rem;
          height: 0.2rem;
          border-radius: 0.1rem;
          background-color: #008dfb;
          margin-right: 0.5rem;
          margin-left: -10px;
          position: relative;
          top: -4px; }
    .MainAthleteBlock .card .cardfooter {
      top: 15px;
      margin-top: -15px; }
      .MainAthleteBlock .card .cardfooter > * {
        background: #fff;
        padding: 0 15px;
        line-height: 30px;
        /* font-size: 25px; */
        background-repeat: no-repeat;
        background-position: 8px 0px;
        background-size: 20%;
        font-size: 13px;
        font-weight: 500;
        color: #2c2f3c; }
      .MainAthleteBlock .card .cardfooter .time {
        padding: 0 15px 0 40px; }
    .MainAthleteBlock .card .cardheader, .MainAthleteBlock .card .cardfooter {
      height: 30px;
      position: relative;
      display: flex;
      justify-content: center; }
    .MainAthleteBlock .card .cardcontent {
      flex: 1 1;
      padding: 20px 29px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .MainAthleteBlock .card .cardcontent .MarathonTitle, .MainAthleteBlock .card .cardcontent .MarathonDates {
        display: block;
        text-align: center;
        line-height: 22px; }
      .MainAthleteBlock .card .cardcontent .MarathonTitle .tooltip .tooltiptext {
        margin-left: -57px;
        margin-top: 35px;
        min-width: 100px;
        font-weight: 400;
        font-size: 11px;
        color: #fff; }
        .MainAthleteBlock .card .cardcontent .MarathonTitle .tooltip .tooltiptext em {
          color: #fcb452; }
      .MainAthleteBlock .card .cardcontent .MarathonTitle span {
        color: #008dfb;
        font-size: 13px; }
        .MainAthleteBlock .card .cardcontent .MarathonTitle span:hover {
          color: #2c2f3c;
          border-radius: 18px;
          background-color: rgba(204, 232, 254, 0.3); }
        @media only screen and (min-width: 950px) {
          .MainAthleteBlock .card .cardcontent .MarathonTitle span {
            font-size: 15px; } }
      .MainAthleteBlock .card .cardcontent .AthleteName {
        font-size: 16px;
        padding-bottom: 10px; }
        .MainAthleteBlock .card .cardcontent .AthleteName .AthleteNameLink:hover {
          border-radius: 18px;
          color: #2c2f3c;
          background-color: rgba(204, 232, 254, 0.3); }
      .MainAthleteBlock .card .cardcontent .AthleteFlag {
        padding-right: 10px; }
      .MainAthleteBlock .card .cardcontent .MarathonDates {
        color: #2c2f3c;
        margin-bottom: 10px;
        font-size: 11px; }
        @media screen and (min-width: 801px) {
          .MainAthleteBlock .card .cardcontent .MarathonDates {
            font-size: 13px; } }
      .MainAthleteBlock .card .cardcontent .WelcomeBib {
        flex-grow: 1; }
      .MainAthleteBlock .card .cardcontent .Label {
        display: flex;
        margin-top: 0px;
        justify-content: center;
        align-items: center; }
        .MainAthleteBlock .card .cardcontent .Label span {
          padding: 3px 14px;
          border-radius: 20px;
          font-size: 12px;
          font-weight: 600;
          margin: 0 10px;
          color: #2c2f3c;
          background-color: #e1f1fe; }
        .MainAthleteBlock .card .cardcontent .Label i {
          flex-grow: 1;
          background: #e9e9e9;
          height: 1px; }
        .MainAthleteBlock .card .cardcontent .Label .tooltip .tooltiptext {
          min-width: 85px;
          background-color: black !important;
          border-radius: 0px;
          left: -37%;
          font-weight: 500;
          top: 150%;
          line-height: 15px;
          font-size: 11px;
          padding: 5px 10px; }
          .MainAthleteBlock .card .cardcontent .Label .tooltip .tooltiptext em {
            color: #fcb452;
            font-style: normal;
            font-size: 11px;
            display: block;
            white-space: nowrap; }
        .MainAthleteBlock .card .cardcontent .Label .tooltip .welcome {
          height: 30px; }
      .MainAthleteBlock .card .cardcontent .EdgesLine {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        line-height: 20px;
        color: #2c2f3c;
        position: relative;
        font-weight: normal;
        color: #2c2f3c; }
        .MainAthleteBlock .card .cardcontent .EdgesLine strong {
          font-weight: 600; }
        .MainAthleteBlock .card .cardcontent .EdgesLine .left-aligned {
          text-align: left; }
        .MainAthleteBlock .card .cardcontent .EdgesLine .right-aligned {
          text-align: right; }
      .MainAthleteBlock .card .cardcontent .extrapad {
        padding-top: 8px; }
      .MainAthleteBlock .card .cardcontent .MakeItYours {
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        font-weight: normal;
        color: #2c2f3c; }
      .MainAthleteBlock .card .cardcontent .ThisIsMe {
        font-family: "Montserrat", sans-serif;
        border: none;
        border-radius: 12px;
        padding: 4px 14px 3px;
        margin-bottom: 10px;
        color: #008DFB;
        font-weight: 800;
        font-size: 15px;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        cursor: pointer; }
        .MainAthleteBlock .card .cardcontent .ThisIsMe:hover {
          background-color: rgba(204, 232, 254, 0.3);
          color: #2c2f3c;
          /* background: #bbdaff; */ }
        .MainAthleteBlock .card .cardcontent .ThisIsMe .tooltiptext {
          font-family: "Montserrat", sans-serif;
          margin-left: -57px;
          margin-top: 5px;
          min-width: 100px;
          font-weight: 400;
          font-size: 10px; }
          .MainAthleteBlock .card .cardcontent .ThisIsMe .tooltiptext em {
            font-size: 12px; }
        .MainAthleteBlock .card .cardcontent .ThisIsMe:focus {
          outline: none; }

.IntervalAnalysisBib {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .IntervalAnalysisBib .tooltiptext {
    min-width: 33px !important;
    top: 40% !important;
    z-index: 8 !important; }
    .IntervalAnalysisBib .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }
  .IntervalAnalysisBib .details {
    flex-grow: 1;
    align-items: center; }
    .IntervalAnalysisBib .details dt {
      font-size: 11px;
      padding: 0px 0px 7px 0px; }
      @media screen and (min-width: 801px) {
        .IntervalAnalysisBib .details dt {
          font-size: 12px;
          padding: 0px; } }
    .IntervalAnalysisBib .details dd {
      font-size: 14px;
      padding: 0px 0px 7px 0px; }
      .IntervalAnalysisBib .details dd .SegmentNavigation.IntervalControls {
        top: -3px;
        margin-bottom: -5px; }
      @media screen and (min-width: 801px) {
        .IntervalAnalysisBib .details dd {
          font-size: 15px; } }
  .IntervalAnalysisBib h6 {
    display: grid;
    grid-template-columns: 148px 2fr;
    grid-column-gap: 15px;
    font-weight: 600;
    font-size: 12px;
    color: #2c2f3c;
    align-items: center;
    margin: 7px 0 8px; }
    .IntervalAnalysisBib h6 span {
      text-align: right; }
    .IntervalAnalysisBib h6 span:last-child {
      height: 1px;
      background-color: #e9e9e9; }
  .IntervalAnalysisBib dd {
    position: relative; }
  .IntervalAnalysisBib .InfoModal {
    top: -3px;
    right: -20px;
    position: absolute;
    height: 15px; }
  .IntervalAnalysisBib .splitsResult {
    padding-left: 10px; }
  .IntervalAnalysisBib .plus {
    color: #28bb28; }
  .IntervalAnalysisBib .minus {
    color: #ff3800; }
  .IntervalAnalysisBib .IntervalAnalysisFooter {
    border-top: 1px solid #e9e9e9;
    padding: 17px 5px 10px 5px;
    position: relative;
    top: 22px;
    display: flex;
    justify-content: space-between; }

.toggle-units-container {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .toggle-units-container.on .handle {
    right: 0; }
  .toggle-units-container.on .units-label-english {
    font-weight: 600;
    color: #6b7c88;
    cursor: pointer; }
  .toggle-units-container.on .units-label-metric {
    color: #000;
    font-weight: 600;
    cursor: default; }
  .toggle-units-container .units-label-english {
    color: #000;
    font-weight: 600;
    cursor: default; }
  .toggle-units-container .units-label-metric {
    font-weight: 600;
    color: #6b7c88;
    cursor: pointer; }
  .toggle-units-container:not(.on) .handle {
    left: 0; }

.units-label {
  cursor: pointer;
  font-size: 11px; }
  @media screen and (min-width: 801px) {
    .units-label {
      font-size: 13px; } }

.switch {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 20px;
  cursor: pointer;
  margin: 0 10px; }

.switch .handle {
  display: inline-block;
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #008dfb; }

.switch .track {
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 35px;
  height: 14px;
  border-radius: 7px;
  background-color: #cce8fe; }

.switch.on .handle {
  left: auto;
  right: 0; }

.toggle-age-grade-container {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .toggle-age-grade-container .dot {
    font-weight: 600;
    display: inline-block;
    padding: 0 2px; }
  .toggle-age-grade-container .factor {
    font-weight: 600; }
  .toggle-age-grade-container .numericFont {
    font-family: "Roboto Mono", monospace; }
  .toggle-age-grade-container.on {
    /* color: #008dfb; */
    color: #000000; }
    .toggle-age-grade-container.on .dot {
      color: #008dfb; }
    .toggle-age-grade-container.on .factor {
      color: #000; }
  .toggle-age-grade-container:not(.on) {
    color: #909fa9; }
  .toggle-age-grade-container:hover input, .toggle-age-grade-container:hover label {
    cursor: pointer; }
  .toggle-age-grade-container:hover .label_name {
    color: #6b7c88; }
  .toggle-age-grade-container .age-grade-label {
    cursor: pointer;
    font-weight: 600; }
  .toggle-age-grade-container .CheckboxControl {
    position: relative; }
    .toggle-age-grade-container .CheckboxControl .checkbtn {
      position: absolute;
      top: 1px;
      left: 0px;
      display: none; }
      .toggle-age-grade-container .CheckboxControl .checkbtn.unselected {
        display: block; }
    .toggle-age-grade-container .CheckboxControl input[type=checkbox] {
      display: none; }
      .toggle-age-grade-container .CheckboxControl input[type=checkbox]:checked + label .unselected {
        display: none; }
      .toggle-age-grade-container .CheckboxControl input[type=checkbox]:checked + label .unselected_hover {
        display: none; }
      .toggle-age-grade-container .CheckboxControl input[type=checkbox]:checked + label .selected {
        display: block; }
    .toggle-age-grade-container .CheckboxControl label {
      padding: 2px 2px 2px 25px;
      font-size: 11px; }
      @media screen and (min-width: 801px) {
        .toggle-age-grade-container .CheckboxControl label {
          font-size: 13px; } }
      .toggle-age-grade-container .CheckboxControl label:hover .unselected {
        display: none; }
      .toggle-age-grade-container .CheckboxControl label:hover .unselected_hover {
        display: block; }
      .toggle-age-grade-container .CheckboxControl label:hover .selected {
        display: none; }

.SegmentNavigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  position: relative; }
  .SegmentNavigation .label {
    font-size: 11px;
    color: #6b7c88;
    font-weight: 600;
    min-width: 40px;
    text-align: center; }
  .SegmentNavigation .IntervalControlBtn {
    margin: 0 0; }
    .SegmentNavigation .IntervalControlBtn .BtnImage {
      cursor: pointer;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 25px; }
      .SegmentNavigation .IntervalControlBtn .BtnImage img {
        display: block;
        width: 13px;
        height: 18px; }
    .SegmentNavigation .IntervalControlBtn .BtnImage.active {
      display: flex; }
      .SegmentNavigation .IntervalControlBtn .BtnImage.active:hover {
        background-color: rgba(204, 232, 254, 0.3); }
    .SegmentNavigation .IntervalControlBtn .BtnImage.disabled {
      display: none;
      cursor: default; }
    .SegmentNavigation .IntervalControlBtn.disabled .BtnImage.active {
      display: none; }
    .SegmentNavigation .IntervalControlBtn.disabled .BtnImage.disabled {
      display: flex;
      cursor: default; }
  .SegmentNavigation .IntervalIndex {
    font-size: 11px;
    color: #909fa9;
    padding: 0 3px;
    text-align: center; }

.InfoModal {
  display: inline-block;
  position: relative; }

.ReactModalPortal .info_button_modal,
.InfoModal .info_button_modal {
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 7; }
  @media screen and (max-width: 600px) {
    .ReactModalPortal .info_button_modal,
    .InfoModal .info_button_modal {
      display: none; } }
  .ReactModalPortal .info_button_modal .tooltiptext,
  .InfoModal .info_button_modal .tooltiptext {
    min-width: 33px !important;
    top: 0% !important;
    left: 225% !important; }
    .ReactModalPortal .info_button_modal .tooltiptext em,
    .InfoModal .info_button_modal .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }
    .ReactModalPortal .info_button_modal .tooltiptext:after,
    .InfoModal .info_button_modal .tooltiptext:after {
      display: none; }

.ReactModalPortal .info_button_modal {
  float: left;
  margin-right: 8px; }

.ElevationGainIcon img {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: 2px; }

.PartnerModalCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5; }

.PartnerModalForm {
  position: fixed;
  left: 0;
  font-size: 12px;
  background-color: #fff;
  box-sizing: border-box;
  min-height: 150px;
  max-height: 100vh;
  overflow: auto;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3), 0 1px 5px rgba(0, 0, 0, 0.2);
  width: calc(100vw - 40px);
  margin: 20px;
  top: 0px; }
  .PartnerModalForm > * {
    flex: 1 0; }
  @media screen and (min-width: 801px) {
    .PartnerModalForm {
      display: flex;
      top: 28px; } }
  .PartnerModalForm .LeftPane {
    position: relative;
    background-color: #fff; }
    @media screen and (min-width: 801px) {
      .PartnerModalForm .LeftPane {
        background-color: #f8f9f5; } }
  .PartnerModalForm .MainPane .MainModalContent {
    padding: 0 30px 40px 30px; }
    @media screen and (min-width: 801px) {
      .PartnerModalForm .MainPane .MainModalContent {
        padding: 40px 30px; } }
  @media screen and (min-width: 801px) {
    .PartnerModalForm {
      top: 40px;
      margin: 0 0 0 -400px;
      width: 800px;
      left: 50%;
      border-radius: 12px; } }
  .PartnerModalForm .ClearIcon {
    cursor: pointer;
    max-width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 25px; }
    .PartnerModalForm .ClearIcon:hover .background {
      transform: scale(1); }
    .PartnerModalForm .ClearIcon .background {
      transform: scale(0);
      transition: transform 200ms;
      transition-timing-function: ease-out;
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: rgba(204, 232, 254, 0.3);
      top: -6px;
      left: -5px;
      z-index: -5; }
  .PartnerModalForm .Label {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: center; }
    .PartnerModalForm .Label i {
      flex-grow: 1;
      background: #e9e9e9;
      height: 1px; }
    .PartnerModalForm .Label span {
      padding: 3px 14px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 600;
      margin: 0 10px;
      color: #2c2f3c;
      background-color: #e1f1fe; }
  .PartnerModalForm .closePartnerModal {
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 25px;
    color: #525151;
    cursor: pointer; }
  .PartnerModalForm .Logo {
    display: none;
    margin-top: 35px;
    text-align: center;
    max-width: 108px;
    margin: 20px auto 0; }
    .PartnerModalForm .Logo img {
      display: inline-block !important;
      max-width: 100%;
      width: 175px; }
    @media screen and (min-width: 801px) {
      .PartnerModalForm .Logo {
        max-width: none;
        display: block; } }
  .PartnerModalForm .title {
    font-size: 12px;
    font-weight: 800;
    color: #2c2f3c;
    text-align: center;
    margin: 40px 0 0; }
    @media screen and (min-width: 801px) {
      .PartnerModalForm .title {
        font-size: 24px;
        margin: 70px 20px; } }
  .PartnerModalForm p {
    font-size: 12px;
    color: #2c2f3c;
    line-height: 1.5;
    display: block;
    margin: 1em auto !important;
    max-width: 500px;
    font-weight: 600;
    text-align: center; }
  .PartnerModalForm label {
    display: block;
    margin: 10px auto;
    text-align: left;
    box-sizing: border-box;
    max-width: 460px; }
    .PartnerModalForm label .label {
      display: block;
      font-size: 11px;
      color: #2c2f3c;
      font-weight: normal;
      margin-left: 21px;
      margin-bottom: 5px; }
    .PartnerModalForm label input {
      font-family: "Montserrat", sans-serif;
      background-color: #e3f2ff;
      color: #2c2f3c;
      border: none;
      display: block;
      width: 100%;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: right 10px center;
      font-size: 12px;
      padding: 10px 22px;
      background-size: 20px;
      border-radius: 16px; }
      .PartnerModalForm label input::-webkit-input-placeholder {
        color: #000;
        font-weight: 600; }
      .PartnerModalForm label input::-ms-input-placeholder {
        color: #000;
        font-weight: 600; }
      .PartnerModalForm label input::placeholder {
        color: #000;
        font-weight: 600; }
      @media screen and (min-width: 801px) {
        .PartnerModalForm label input {
          padding: 10px 22px;
          background-size: 16px;
          border-radius: 21px; } }
    .PartnerModalForm label.error input {
      padding: 14px 22px;
      background-color: rgba(255, 56, 0, 0.1); }
    @media screen and (min-width: 801px) {
      .PartnerModalForm label {
        font-size: 14px;
        margin: 15px auto; } }
  .PartnerModalForm .buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px; }
    .PartnerModalForm .buttons button, .PartnerModalForm .buttons .button {
      box-sizing: border-box;
      display: block;
      background: #008dfb;
      color: #ffffff;
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
      padding: 4px 20px;
      margin: 0 10px;
      border: 2px solid #008dfb;
      font-family: inherit;
      cursor: pointer;
      border-radius: 17px; }
      .PartnerModalForm .buttons button:hover, .PartnerModalForm .buttons .button:hover {
        border: solid 2px rgba(0, 0, 0, 0.05);
        background-color: #0086ef; }
      .PartnerModalForm .buttons button.secondary, .PartnerModalForm .buttons .button.secondary {
        background-color: #fff;
        color: #008dfb;
        border-color: #cce8fe; }
        .PartnerModalForm .buttons button.secondary:hover, .PartnerModalForm .buttons .button.secondary:hover {
          border: solid 2px rgba(0, 141, 251, 0.5); }
      .PartnerModalForm .buttons button[disabled], .PartnerModalForm .buttons .button[disabled] {
        background: #e4e4e4;
        color: #ffffff;
        border: 2px solid #d6d6d6;
        cursor: default; }
  .PartnerModalForm input:focus, .PartnerModalForm button:focus {
    outline: none; }
  .PartnerModalForm .ThankYou .ThankYouFinishIcon {
    margin: 30px auto 10px;
    width: 100px; }
  .PartnerModalForm .HeroImage {
    position: absolute;
    max-width: 100%;
    width: 100%;
    bottom: 30px;
    display: none; }
    @media screen and (min-width: 801px) {
      .PartnerModalForm .HeroImage {
        display: block; } }
    .PartnerModalForm .HeroImage img.CityBackground {
      width: 100%;
      margin-bottom: 18px; }
    .PartnerModalForm .HeroImage img.Runner {
      position: absolute;
      width: 80px;
      height: 80px;
      bottom: -5px;
      left: 50%;
      margin-left: -40px; }
  .PartnerModalForm .tooltiptext {
    min-width: 33px !important;
    top: 139% !important;
    left: 65% !important; }
    .PartnerModalForm .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }

.RequestRaceModalCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5; }

.RequestRaceModalForm {
  position: fixed;
  left: 0;
  font-size: 12px;
  background-color: #fff;
  box-sizing: border-box;
  min-height: 150px;
  max-height: 100vh;
  overflow: auto;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3), 0 1px 5px rgba(0, 0, 0, 0.2);
  width: calc(100vw - 40px);
  margin: 20px;
  top: 0; }
  .RequestRaceModalForm > * {
    flex: 1 0; }
  @media screen and (min-width: 801px) {
    .RequestRaceModalForm {
      display: flex;
      top: 28px; } }
  .RequestRaceModalForm .LeftPane {
    position: relative;
    background-color: #fff; }
    @media screen and (min-width: 801px) {
      .RequestRaceModalForm .LeftPane {
        background-color: #f8f9f5; } }
  .RequestRaceModalForm .MainPane .MainModalContent {
    padding: 0 30px 40px 30px; }
    @media screen and (min-width: 801px) {
      .RequestRaceModalForm .MainPane .MainModalContent {
        padding: 40px 30px; } }
  @media screen and (min-width: 801px) {
    .RequestRaceModalForm {
      top: 40px;
      margin: 0 0 0 -400px;
      width: 800px;
      left: 50%;
      border-radius: 12px; } }
  .RequestRaceModalForm .ClearIcon {
    cursor: pointer;
    max-width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 25px; }
    .RequestRaceModalForm .ClearIcon:hover .background {
      transform: scale(1); }
    .RequestRaceModalForm .ClearIcon .background {
      transform: scale(0);
      transition: transform 200ms;
      transition-timing-function: ease-out;
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: rgba(204, 232, 254, 0.3);
      top: -6px;
      left: -5px;
      z-index: -5; }
  .RequestRaceModalForm .Label {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: center; }
    .RequestRaceModalForm .Label i {
      flex-grow: 1;
      background: #e9e9e9;
      height: 1px; }
    .RequestRaceModalForm .Label span {
      padding: 3px 14px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 600;
      margin: 0 10px;
      color: #2c2f3c;
      background-color: #e1f1fe; }
  .RequestRaceModalForm .closeRequestRaceModal {
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 25px;
    color: #525151;
    cursor: pointer; }
  .RequestRaceModalForm .Logo {
    margin-top: 35px;
    text-align: center;
    max-width: 108px;
    margin: 20px auto 0;
    display: none; }
    .RequestRaceModalForm .Logo img {
      display: inline-block !important;
      max-width: 100%;
      width: 175px; }
    @media screen and (min-width: 801px) {
      .RequestRaceModalForm .Logo {
        display: block;
        max-width: none; } }
  .RequestRaceModalForm .title {
    font-size: 12px;
    font-weight: 800;
    color: #2c2f3c;
    text-align: center;
    margin: 40px 0 0;
    max-width: 290px; }
    @media screen and (min-width: 801px) {
      .RequestRaceModalForm .title {
        margin: 70px auto;
        font-size: 24px; } }
  .RequestRaceModalForm p {
    font-size: 12px;
    color: #2c2f3c;
    line-height: 1.5;
    display: block;
    margin: 1em auto !important;
    max-width: 500px;
    font-weight: 600;
    text-align: center; }
  .RequestRaceModalForm label {
    display: block;
    margin: 10px auto;
    text-align: left;
    box-sizing: border-box;
    max-width: 460px; }
    .RequestRaceModalForm label .label {
      display: block;
      font-size: 11px;
      color: #2c2f3c;
      font-weight: normal;
      margin-left: 21px;
      margin-bottom: 5px; }
    .RequestRaceModalForm label input {
      font-family: "Montserrat", sans-serif;
      background-color: #e3f2ff;
      color: #2c2f3c;
      border: none;
      display: block;
      width: 100%;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: right 10px center;
      font-size: 12px;
      padding: 10px 22px;
      background-size: 20px;
      border-radius: 16px; }
      .RequestRaceModalForm label input::-webkit-input-placeholder {
        color: #000;
        font-weight: 600; }
      .RequestRaceModalForm label input::-ms-input-placeholder {
        color: #000;
        font-weight: 600; }
      .RequestRaceModalForm label input::placeholder {
        color: #000;
        font-weight: 600; }
      @media screen and (min-width: 801px) {
        .RequestRaceModalForm label input {
          padding: 10px 22px;
          background-size: 16px;
          border-radius: 21px; } }
    .RequestRaceModalForm label.error input {
      padding: 14px 22px;
      background-color: rgba(255, 56, 0, 0.1); }
    @media screen and (min-width: 801px) {
      .RequestRaceModalForm label {
        font-size: 14px;
        margin: 15px auto; } }
  .RequestRaceModalForm .buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px; }
    .RequestRaceModalForm .buttons button, .RequestRaceModalForm .buttons .button {
      box-sizing: border-box;
      display: block;
      background: #008dfb;
      color: #ffffff;
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
      padding: 4px 20px;
      margin: 0 10px;
      border: 2px solid #008dfb;
      font-family: inherit;
      cursor: pointer;
      border-radius: 17px; }
      .RequestRaceModalForm .buttons button:hover, .RequestRaceModalForm .buttons .button:hover {
        border: solid 2px rgba(0, 0, 0, 0.05);
        background-color: #0086ef; }
      .RequestRaceModalForm .buttons button.secondary, .RequestRaceModalForm .buttons .button.secondary {
        background-color: #fff;
        color: #008dfb;
        border-color: #cce8fe; }
        .RequestRaceModalForm .buttons button.secondary:hover, .RequestRaceModalForm .buttons .button.secondary:hover {
          border: solid 2px rgba(0, 141, 251, 0.5); }
      .RequestRaceModalForm .buttons button[disabled], .RequestRaceModalForm .buttons .button[disabled] {
        background: #e4e4e4;
        color: #ffffff;
        border: 2px solid #d6d6d6;
        cursor: default; }
  .RequestRaceModalForm input:focus, .RequestRaceModalForm button:focus {
    outline: none; }
  .RequestRaceModalForm .ThankYou .ThankYouFinishIcon {
    margin: 30px auto 10px;
    width: 100px; }
  .RequestRaceModalForm .HeroImage {
    position: absolute;
    max-width: 100%;
    width: 100%;
    bottom: 30px;
    display: none; }
    @media screen and (min-width: 801px) {
      .RequestRaceModalForm .HeroImage {
        display: block; } }
    .RequestRaceModalForm .HeroImage img.CityBackground {
      width: 100%;
      margin-bottom: 18px; }
    .RequestRaceModalForm .HeroImage img.Runner {
      position: absolute;
      width: 80px;
      height: 80px;
      bottom: -5px;
      left: 50%;
      margin-left: -40px; }
  .RequestRaceModalForm .tooltiptext {
    min-width: 33px !important;
    top: 139% !important;
    left: 65% !important; }
    .RequestRaceModalForm .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }

.Footer {
  margin-top: 0px;
  padding: 30px 0 5px;
  background-color: #fff;
  padding: 0 11vw; }
  .Footer p {
    margin: 6px 0; }
  .Footer p, .Footer a, .Footer a:link, .Footer a:visited, .Footer a:hover, .Footer a:active {
    color: #2c2f3c; }
    .Footer p:hover, .Footer a:hover, .Footer a:link:hover, .Footer a:visited:hover, .Footer a:hover:hover, .Footer a:active:hover {
      text-decoration: underline; }
  .Footer .Footer-logo {
    margin-bottom: 10px;
    display: block; }
    .Footer .Footer-logo img {
      display: block;
      width: 105px;
      margin: 0 auto; }
    @media only screen and (min-width: 700px) {
      .Footer .Footer-logo {
        text-align: left;
        margin: 0 0 10px; }
        .Footer .Footer-logo img {
          margin: 0; } }
  .Footer .Footer-columns {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding-top: 20px;
    font-size: 12px; }
    .Footer .Footer-columns > div:first-child {
      grid-column: 1 / 3; }
    .Footer .Footer-columns .info {
      text-align: center; }
    @media only screen and (min-width: 700px) {
      .Footer .Footer-columns {
        display: flex; }
        .Footer .Footer-columns .info {
          text-align: left; } }
    .Footer .Footer-columns .info, .Footer .Footer-columns h6 {
      color: #909fa9;
      font-size: 11px;
      margin: 0 0 15px 0; }
    .Footer .Footer-columns h6 {
      text-transform: uppercase;
      font-weight: 600; }
    .Footer .Footer-columns > div {
      flex-grow: 1;
      flex-basis: 0;
      padding: 5px; }
    .Footer .Footer-columns > div.Footer-contact {
      flex-grow: 2; }
  .Footer .Footer-copy-line {
    justify-content: space-between;
    font-size: 11px;
    color: #6b7c88;
    padding: 15px 0;
    margin-top: 15px;
    border-top: 1px solid #e9e9e9;
    text-align: center; }
    .Footer .Footer-copy-line .Footer-copy-container {
      display: flex;
      flex-direction: column; }
      .Footer .Footer-copy-line .Footer-copy-container .Footer-copyright {
        order: 777; }
    @media only screen and (min-width: 700px) {
      .Footer .Footer-copy-line {
        display: flex;
        text-align: left; }
        .Footer .Footer-copy-line .Footer-copy-container {
          display: block; } }
    .Footer .Footer-copy-line .Footer-copyright, .Footer .Footer-copy-line .Footer-privacy, .Footer .Footer-copy-line a {
      display: inline-block; }
      @media only screen and (min-width: 700px) {
        .Footer .Footer-copy-line .Footer-copyright, .Footer .Footer-copy-line .Footer-privacy, .Footer .Footer-copy-line a {
          margin-right: 20px; } }
    .Footer .Footer-copy-line .Footer-copyright, .Footer .Footer-copy-line a {
      color: #6b7c88;
      margin-top: 20px;
      margin-bottom: 8px; }
      @media only screen and (min-width: 700px) {
        .Footer .Footer-copy-line .Footer-copyright, .Footer .Footer-copy-line a {
          margin-top: 0;
          margin-bottom: 0; } }
    .Footer .Footer-copy-line .Footer-privacy, .Footer .Footer-copy-line a {
      color: #2c2f3c; }

.footerImage {
  margin-top: 50px;
  max-width: 100%;
  display: block; }

.IntervalAnalysis {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .IntervalAnalysis .RaceCourseContainerOuter {
    overflow: hidden;
    position: relative;
    top: -20px; }
  .IntervalAnalysis .RaceCourseContainer {
    overflow: auto;
    max-width: 100vw;
    padding: 0px 0 70px;
    position: relative;
    top: 20px; }
  .IntervalAnalysis .RaceCourse {
    min-width: 1000px;
    display: flex;
    border: 1px solid #e9e9e9;
    margin: 35px 0px -30px;
    position: relative;
    /* .Interval */ }
    .IntervalAnalysis .RaceCourse .D3ElevationProfile {
      position: absolute;
      top: 0;
      left: 0; }
    .IntervalAnalysis .RaceCourse .start,
    .IntervalAnalysis .RaceCourse .finish {
      /* width: 38px; */
      /* width: 3vw; */
      cursor: pointer;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none;
      width: 30px;
      height: 143px;
      box-shadow: 0 1px 0 0 #cce8fe, 0 -1px 0 0 #cce8fe;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: 0px;
      color: #113b76;
      background-color: #cce8fe; }
      .IntervalAnalysis .RaceCourse .start.disabled,
      .IntervalAnalysis .RaceCourse .finish.disabled {
        color: #757575;
        background-color: #ececec;
        cursor: default; }
    .IntervalAnalysis .RaceCourse .start.active,
    .IntervalAnalysis .RaceCourse .finish.active {
      background-color: #cce8fe;
      color: #113b76; }
    .IntervalAnalysis .RaceCourse .start > .vertical-text,
    .IntervalAnalysis .RaceCourse .finish > .vertical-text {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(90deg); }
    .IntervalAnalysis .RaceCourse .IntervalLabel {
      font-size: 10px;
      font-weight: normal;
      color: #6b7c88; }
    .IntervalAnalysis .RaceCourse .IntervalLabelZero {
      position: absolute;
      bottom: -20px;
      left: -10px;
      width: 20px;
      text-align: center;
      font-family: "Roboto Mono", monospace; }
    .IntervalAnalysis .RaceCourse .Interval {
      flex-grow: 0;
      flex-shrink: 0;
      min-height: 50px;
      min-width: 30px;
      position: relative;
      border-right: 1px solid #e9e9e9;
      cursor: pointer;
      box-sizing: border-box;
      border: 1px solid transparent;
      font-family: "Roboto Mono", monospace; }
      .IntervalAnalysis .RaceCourse .Interval:first-child {
        border-left: 1px solid #e9e9e9; }
      .IntervalAnalysis .RaceCourse .Interval.selected {
        border-top: 2px solid #fcb452;
        border-bottom: 1px solid black;
        cursor: default; }
        .IntervalAnalysis .RaceCourse .Interval.selected.FirstInterval {
          border-left: 2px solid #fcb452; }
        .IntervalAnalysis .RaceCourse .Interval.selected.LastInterval {
          border-right: 2px solid #fcb452; }
        .IntervalAnalysis .RaceCourse .Interval.selected .HighlightFirstSelected, .IntervalAnalysis .RaceCourse .Interval.selected .HighlightLastSelected {
          background-color: #008dfb;
          width: 10px;
          height: 10px;
          bottom: -5px;
          border-radius: 5px;
          position: absolute;
          z-index: 7; }
        .IntervalAnalysis .RaceCourse .Interval.selected .HighlightFirstSelected {
          left: -5px; }
        .IntervalAnalysis .RaceCourse .Interval.selected .HighlightLastSelected {
          right: -5px; }
      .IntervalAnalysis .RaceCourse .Interval .IntervalLabel {
        position: absolute;
        width: 100px;
        white-space: nowrap; }
        .IntervalAnalysis .RaceCourse .Interval .IntervalLabel.In {
          right: 3px;
          text-align: right;
          font-size: 11px; }
          .IntervalAnalysis .RaceCourse .Interval .IntervalLabel.In.Top {
            color: #008dfb;
            font-weight: bold; }
          .IntervalAnalysis .RaceCourse .Interval .IntervalLabel.In.Bottom {
            color: #2c2f3c;
            bottom: 0; }
        .IntervalAnalysis .RaceCourse .Interval .IntervalLabel.Out {
          color: gray;
          right: -50px;
          text-align: center; }
          .IntervalAnalysis .RaceCourse .Interval .IntervalLabel.Out.Top {
            top: -20px; }
          .IntervalAnalysis .RaceCourse .Interval .IntervalLabel.Out.Bottom {
            bottom: -20px;
            font-weight: normal; }
      .IntervalAnalysis .RaceCourse .Interval.last .Out {
        right: -30px !important;
        text-align: right !important; }
    .IntervalAnalysis .RaceCourse .RaceCourseIntervals {
      display: flex;
      flex-grow: 1;
      position: relative; }
      .IntervalAnalysis .RaceCourse .RaceCourseIntervals .Interval {
        position: relative; }
        .IntervalAnalysis .RaceCourse .RaceCourseIntervals .Interval:hover {
          background-color: rgba(74, 74, 74, 0.05); }

.ResultsSummaryBib {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .ResultsSummaryBib .tooltiptext {
    min-width: 33px !important;
    top: 0% !important;
    z-index: 8 !important; }
    .ResultsSummaryBib .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }
    .ResultsSummaryBib .tooltiptext:after {
      display: none; }
  .ResultsSummaryBib .details {
    flex-grow: 1;
    align-items: center; }
    .ResultsSummaryBib .details dt {
      font-size: 11px;
      padding: 0px 0px 7px 0px; }
      @media screen and (min-width: 801px) {
        .ResultsSummaryBib .details dt {
          font-size: 12px;
          padding: 0px; } }
    .ResultsSummaryBib .details dd {
      font-size: 14px;
      padding: 0px 0px 7px 0px; }
      @media screen and (min-width: 801px) {
        .ResultsSummaryBib .details dd {
          font-size: 15px; } }
    .ResultsSummaryBib .details.pad {
      padding-left: 23px; }
  .ResultsSummaryBib h6 {
    display: grid;
    grid-template-columns: 148px 2fr;
    grid-column-gap: 15px;
    font-weight: 600;
    font-size: 12px;
    color: #2c2f3c;
    align-items: center;
    margin: 7px 0 8px;
    padding-left: 5px; }
    .ResultsSummaryBib h6 span {
      text-align: right; }
    .ResultsSummaryBib h6 span:last-child {
      height: 1px;
      background-color: #e9e9e9; }
  .ResultsSummaryBib dd {
    position: relative; }
  .ResultsSummaryBib .InfoModal {
    top: -3px;
    right: -20px;
    position: absolute;
    height: 15px; }
  .ResultsSummaryBib .splitsResult {
    padding-left: 10px;
    font-size: 12px;
    font-weight: 500; }
  .ResultsSummaryBib .plus {
    color: #28bb28; }
  .ResultsSummaryBib .minus {
    color: #ff3800; }
  .ResultsSummaryBib .ResultsSummaryFooter {
    border-top: 1px solid #e9e9e9;
    padding: 17px 5px 10px 5px;
    position: relative;
    top: 22px;
    display: flex;
    justify-content: space-between; }

.ResultsSummary {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .ResultsSummary .RaceCourseContainerOuter {
    overflow: hidden;
    position: relative;
    top: -20px; }
  .ResultsSummary .RaceCourseContainer {
    overflow: auto;
    max-width: 100vw;
    padding: 0px 0 70px;
    position: relative;
    top: 20px; }
  .ResultsSummary .RaceCourse {
    min-width: 1000px;
    display: flex;
    border: 1px solid #e9e9e9;
    margin: 35px 0px -30px;
    position: relative;
    /* .Interval */ }
    .ResultsSummary .RaceCourse .D3ElevationProfile {
      position: absolute;
      top: 0;
      left: 0; }
    .ResultsSummary .RaceCourse .start,
    .ResultsSummary .RaceCourse .finish {
      /* width: 38px; */
      /* width: 3vw; */
      width: 30px;
      height: 143px;
      box-shadow: 0 1px 0 0 #cce8fe, 0 -1px 0 0 #cce8fe;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: 0px;
      color: #113b76;
      background-color: #cce8fe; }
    .ResultsSummary .RaceCourse .start.active,
    .ResultsSummary .RaceCourse .finish.active {
      background-color: #cce8fe;
      color: #113b76; }
    .ResultsSummary .RaceCourse .start > .vertical-text,
    .ResultsSummary .RaceCourse .finish > .vertical-text {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(90deg); }
    .ResultsSummary .RaceCourse .IntervalLabel {
      font-size: 11px;
      color: #909fa9;
      font-weight: normal;
      font-family: "Roboto Mono", monospace; }
    .ResultsSummary .RaceCourse .IntervalLabelZero {
      position: absolute;
      bottom: -20px;
      left: -10px;
      width: 20px;
      text-align: center;
      color: #909fa9;
      font-weight: normal;
      font-family: "Roboto Mono", monospace; }
    .ResultsSummary .RaceCourse .Interval {
      flex-grow: 0;
      flex-shrink: 0;
      min-height: 50px;
      min-width: 30px;
      position: relative;
      border-right: 1px solid #e9e9e9; }
      .ResultsSummary .RaceCourse .Interval:first-child {
        border-left: 1px solid #e9e9e9; }
      .ResultsSummary .RaceCourse .Interval .IntervalLabel {
        position: absolute;
        width: 100px;
        white-space: nowrap; }
        .ResultsSummary .RaceCourse .Interval .IntervalLabel.In {
          right: 3px;
          text-align: right; }
          .ResultsSummary .RaceCourse .Interval .IntervalLabel.In.Top {
            color: #008dfb; }
          .ResultsSummary .RaceCourse .Interval .IntervalLabel.In.Bottom {
            color: black;
            bottom: 0; }
        .ResultsSummary .RaceCourse .Interval .IntervalLabel.Out {
          color: gray;
          right: -50px;
          text-align: center; }
          .ResultsSummary .RaceCourse .Interval .IntervalLabel.Out.Top {
            top: -20px; }
          .ResultsSummary .RaceCourse .Interval .IntervalLabel.Out.Bottom {
            bottom: -20px; }
      .ResultsSummary .RaceCourse .Interval.last .Out {
        right: -30px !important;
        text-align: right !important; }
    .ResultsSummary .RaceCourse .RaceCourseIntervals {
      display: flex;
      flex-grow: 1;
      position: relative; }
      .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval {
        position: relative; }
        .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval:hover {
          background-color: rgba(74, 74, 74, 0.02); }
        .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval .IntervalInfoBlock {
          position: absolute;
          top: 3px;
          right: 3px;
          width: 155px;
          min-height: 40px;
          background-color: #f0f9ff;
          border-radius: 2px;
          box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1); }
          .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval .IntervalInfoBlock dl {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 10px;
            font-weight: 400;
            font-size: 11px; }
            .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval .IntervalInfoBlock dl .intervalBold {
              font-style: normal;
              font-weight: bold;
              color: #008dfb; }
            .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval .IntervalInfoBlock dl .units {
              font-size: 9px; }
            .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval .IntervalInfoBlock dl dt, .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval .IntervalInfoBlock dl dd {
              margin: 0 0 0 10px;
              padding: 0;
              white-space: nowrap;
              line-height: 15px;
              color: #2c2f3c; }
              .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval .IntervalInfoBlock dl dt .plus, .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval .IntervalInfoBlock dl dd .plus {
                color: #28bb28; }
              .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval .IntervalInfoBlock dl dt .minus, .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval .IntervalInfoBlock dl dd .minus {
                color: #ff3800; }
            .ResultsSummary .RaceCourse .RaceCourseIntervals .Interval .IntervalInfoBlock dl dt {
              text-align: right; }
    .ResultsSummary .RaceCourse .TableContainer {
      flex-grow: 1;
      display: flex;
      font-size: 13px;
      background-color: #fff; }
      .ResultsSummary .RaceCourse .TableContainer p {
        margin: 0;
        line-height: 28px; }
      .ResultsSummary .RaceCourse .TableContainer .highlight {
        background: rgba(204, 232, 254, 0.3); }
      .ResultsSummary .RaceCourse .TableContainer .TableHeader {
        flex-grow: 2;
        min-width: 150px;
        text-align: right;
        min-width: 180px;
        max-width: 180px; }
        .ResultsSummary .RaceCourse .TableContainer .TableHeader p {
          padding-right: 10px; }
      .ResultsSummary .RaceCourse .TableContainer .IntervalData {
        flex-grow: 1;
        text-align: center;
        border-left: 1px solid #e9e9e9; }
        .ResultsSummary .RaceCourse .TableContainer .IntervalData .splits {
          color: #2c2f3c;
          font-weight: normal; }
        .ResultsSummary .RaceCourse .TableContainer .IntervalData .distance {
          font-weight: bold; }
        .ResultsSummary .RaceCourse .TableContainer .IntervalData .pace {
          color: #008dfb;
          font-weight: bold; }
        .ResultsSummary .RaceCourse .TableContainer .IntervalData .diff {
          font-size: 11px; }
          .ResultsSummary .RaceCourse .TableContainer .IntervalData .diff.plus {
            color: #ff3800; }
          .ResultsSummary .RaceCourse .TableContainer .IntervalData .diff.minus {
            color: #28bb28; }
        .ResultsSummary .RaceCourse .TableContainer .IntervalData .time {
          color: #2c2f3c; }
      @media screen and (max-width: 600px) {
        .ResultsSummary .RaceCourse .TableContainer {
          /* .TableContainer */ } }

.MainAthleteBlockHeader {
  display: flex; }
  .MainAthleteBlockHeader .userAvatarContainer {
    display: block;
    border: 2px solid #cce8fe;
    width: 52px;
    height: 52px; }
    .MainAthleteBlockHeader .userAvatarContainer img {
      width: 48px;
      height: 48px; }
    .MainAthleteBlockHeader .userAvatarContainer.uploadedImage {
      padding: 0px; }
      .MainAthleteBlockHeader .userAvatarContainer.uploadedImage .userAvatar {
        width: 48px;
        height: 48px; }

.SidebarOpenButton {
  width: 20px !important;
  height: 54px !important;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  position: fixed;
  transform: translateX(0px);
  top: 80px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 800ms;
  z-index: 7; }
  .SidebarOpenButton:hover {
    background-color: #113b76; }
  .SidebarOpenButton.hidden {
    transform: translateX(800px); }

.MapSidebar {
  z-index: 7;
  position: fixed;
  right: 10px;
  top: 70px;
  border-radius: 12px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  transform: translateX(0px);
  transition: transform 800ms;
  /* sidebar content */ }
  .MapSidebar.hidden {
    transform: translateX(-5000px); }
  .MapSidebar.closed {
    transform: translateX(1000px); }
  .MapSidebar.open {
    width: 330px;
    height: calc(100vh - 85px);
    overflow: auto;
    border-left: 1px solid #dedede; }
  .MapSidebar .HideButton {
    cursor: pointer;
    color: gray;
    display: block;
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 12px; }
    .MapSidebar .HideButton:hover {
      color: #008dfb; }
  .MapSidebar .MapSidebarContent {
    font-family: "Montserrat", sans-serif; }
    .MapSidebar .MapSidebarContent .EdgesLine {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      font-weight: 600;
      color: #2c2f3c;
      position: relative;
      top: -5px;
      margin: 0 20px; }
    .MapSidebar .MapSidebarContent .toggle-units-container {
      justify-content: center;
      font-size: 13px;
      padding: 0;
      margin: 4px 10px 10px 10px;
      border-top: 1px solid #e9e9e9;
      padding-top: 15px; }
    .MapSidebar .MapSidebarContent .centered {
      text-align: center; }
    .MapSidebar .MapSidebarContent .toprow {
      display: flex;
      margin-top: 40px;
      justify-content: space-evenly; }
      .MapSidebar .MapSidebarContent .toprow .searchIcon {
        position: relative;
        right: 0;
        top: 0; }
    .MapSidebar .MapSidebarContent .toggle-age-grade-container {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      margin-top: 15px; }
    .MapSidebar .MapSidebarContent .RaceDistance {
      display: flex;
      margin-top: 20px;
      justify-content: center;
      align-items: center; }
      .MapSidebar .MapSidebarContent .RaceDistance span {
        background-color: #e1f1fe;
        padding: 3px 14px;
        border-radius: 20px;
        color: #2c2f3c;
        text-transform: none;
        font-size: 12px;
        font-weight: 600;
        margin: 0 10px; }
      .MapSidebar .MapSidebarContent .RaceDistance i {
        flex-grow: 1;
        background: #e9e9e9;
        height: 1px; }
    .MapSidebar .MapSidebarContent .raceLogoContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px; }
      .MapSidebar .MapSidebarContent .raceLogoContainer img.raceLogoImage {
        max-width: 180px;
        margin-top: 15px; }
    .MapSidebar .MapSidebarContent .RaceName {
      margin-top: 20px;
      font-weight: 600;
      font-size: 13px;
      color: #008dfb; }
    .MapSidebar .MapSidebarContent .RaceDate {
      color: #2c2f3c;
      font-size: 13px;
      margin-top: 8px; }
    .MapSidebar .MapSidebarContent .cityStateCountry {
      margin: 5px;
      font-size: 18px; }
    .MapSidebar .MapSidebarContent .AthleteName {
      font-weight: 600;
      font-size: 15px;
      color: #008dfb; }
    .MapSidebar .MapSidebarContent .AthleteInfo {
      color: #2c2f3c;
      margin-top: 7px; }
      .MapSidebar .MapSidebarContent .AthleteInfo .division {
        font-weight: 600; }
    .MapSidebar .MapSidebarContent .dot {
      color: #008dfb;
      display: inline-block;
      padding: 0 6px;
      margin: 0;
      font-weight: 600; }
    .MapSidebar .MapSidebarContent .ToggleInterval {
      margin-top: 18px; }
    .MapSidebar .MapSidebarContent .IntervalAnalysisBib .details {
      font-size: 12px;
      line-height: 22px; }
      .MapSidebar .MapSidebarContent .IntervalAnalysisBib .details dl {
        grid-template-columns: 1.1fr 2fr; }
        .MapSidebar .MapSidebarContent .IntervalAnalysisBib .details dl:first-child {
          margin-right: 0; }
      .MapSidebar .MapSidebarContent .IntervalAnalysisBib .details dd {
        text-align: left; }
    .MapSidebar .MapSidebarContent .D3ElevationProfile svg {
      display: block;
      margin: 0 auto; }
    .MapSidebar .MapSidebarContent .IntervalsContainer {
      display: flex;
      width: 330px;
      margin: 0 auto; }
      .MapSidebar .MapSidebarContent .IntervalsContainer .Interval {
        height: 1px; }
        .MapSidebar .MapSidebarContent .IntervalsContainer .Interval.selected {
          box-sizing: border-box;
          height: 41px;
          border-bottom: 1px solid #92cf48;
          position: relative;
          top: -40px;
          margin-bottom: -20px;
          background-color: #fbfbfb;
          z-index: -1; }
    .MapSidebar .MapSidebarContent .IntervalControls {
      display: flex;
      justify-content: center;
      align-items: center; }
      .MapSidebar .MapSidebarContent .IntervalControls .label {
        font-size: 11px;
        color: #6b7c88;
        font-weight: 600;
        min-width: 40px;
        text-align: center; }
      .MapSidebar .MapSidebarContent .IntervalControls .IntervalControlBtn {
        margin: 0 16px; }
        .MapSidebar .MapSidebarContent .IntervalControls .IntervalControlBtn .BtnImage {
          cursor: pointer; }
          .MapSidebar .MapSidebarContent .IntervalControls .IntervalControlBtn .BtnImage img {
            display: block;
            width: 20px;
            height: 20px; }
        .MapSidebar .MapSidebarContent .IntervalControls .IntervalControlBtn .BtnImage.active {
          display: block; }
        .MapSidebar .MapSidebarContent .IntervalControls .IntervalControlBtn .BtnImage.disabled {
          display: none; }
        .MapSidebar .MapSidebarContent .IntervalControls .IntervalControlBtn.disabled .BtnImage.active {
          display: none; }
        .MapSidebar .MapSidebarContent .IntervalControls .IntervalControlBtn.disabled .BtnImage.disabled {
          display: block; }
      .MapSidebar .MapSidebarContent .IntervalControls .IntervalIndex {
        font-size: 11px;
        color: #909fa9;
        min-width: 40px;
        text-align: center; }
    .MapSidebar .MapSidebarContent .SponsorsCarousel {
      margin-top: 30px;
      margin-bottom: 5px;
      display: flex;
      width: 200px;
      height: 100px;
      margin: 0 auto;
      justify-content: center;
      align-items: center; }
      .MapSidebar .MapSidebarContent .SponsorsCarousel img {
        margin-top: 20px;
        max-width: 100%;
        max-height: 100px; }
    .MapSidebar .MapSidebarContent .SegmentNavigation.IntervalControls {
      position: absolute;
      top: 0px;
      right: 0; }
      .MapSidebar .MapSidebarContent .SegmentNavigation.IntervalControls .IntervalControlBtn {
        margin: 0 6px; }

.OpenButton {
  width: 20px;
  height: 54px;
  background-color: #fff;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  position: fixed;
  bottom: 320px;
  left: 0;
  transform: translateX(0px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5;
  transition: transform 800ms; }
  .OpenButton.hidden {
    transform: translateX(-800px); }
  .OpenButton:hover {
    background-color: #113b76; }

.MapControls {
  position: fixed;
  left: 15px;
  bottom: 45px;
  z-index: 7;
  background-color: #fff;
  display: flex;
  font-size: 13px;
  padding-bottom: 10px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  transform: translateX(0px);
  transition: transform 800ms;
  border-radius: 12px; }
  .MapControls.hidden {
    transform: translateX(-1000px); }
  .MapControls h3 {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    padding: 20px 0 30px 0;
    text-transform: uppercase; }
  .MapControls .Legend {
    margin-right: 20px;
    color: #2c2f3c; }
    .MapControls .Legend .LegendSeparator {
      position: absolute;
      border-bottom: 1px solid #e9e9e9;
      top: 50px;
      left: 10px;
      right: 10px; }
    .MapControls .Legend h3 {
      padding-left: 70px; }
    .MapControls .Legend dl {
      display: grid;
      grid-template-columns: 70px 1fr;
      margin: 0;
      max-width: 170px; }
      .MapControls .Legend dl dt {
        margin: 0 0 20px 0;
        display: flex;
        justify-content: center;
        align-items: center; }
      .MapControls .Legend dl dd {
        margin: 0; }
  .MapControls .Pitch {
    margin-right: 30px; }
    .MapControls .Pitch ul {
      display: block;
      padding: 0;
      margin: 0; }
      .MapControls .Pitch ul li {
        display: block;
        font-weight: 600;
        color: #909fa9; }
        .MapControls .Pitch ul li label {
          cursor: pointer; }
        .MapControls .Pitch ul li input[type="radio"] {
          cursor: pointer; }
          .MapControls .Pitch ul li input[type="radio"]:checked {
            cursor: default; }
            .MapControls .Pitch ul li input[type="radio"]:checked + label {
              cursor: default;
              color: #2c2f3c; }
        .MapControls .Pitch ul li label {
          display: inline-block;
          margin-left: 8px;
          margin-bottom: 16px; }
  .MapControls .hideButton {
    position: absolute;
    bottom: 10px;
    right: 15px;
    cursor: pointer;
    color: #6b7c88; }
    .MapControls .hideButton:hover {
      color: #008dfb; }

.RadioButtonControl {
  position: relative; }
  .RadioButtonControl .radiobtn {
    position: absolute;
    top: 2px;
    left: 5px;
    display: none; }
    .RadioButtonControl .radiobtn.unselected {
      display: block; }
  .RadioButtonControl input[type=radio] {
    display: none; }
    .RadioButtonControl input[type=radio]:checked + label .unselected {
      display: none; }
    .RadioButtonControl input[type=radio]:checked + label .unselected_hover {
      display: none; }
    .RadioButtonControl input[type=radio]:checked + label .selected {
      display: block; }
  .RadioButtonControl label {
    padding: 2px 2px 2px 20px; }
    .RadioButtonControl label:hover {
      color: #6b7c88; }
      .RadioButtonControl label:hover .unselected {
        display: none; }
      .RadioButtonControl label:hover .unselected_hover {
        display: block; }
      .RadioButtonControl label:hover .selected {
        display: none; }

.Finish {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #f3f3ee; }
  .Finish .titleHeader {
    margin: -20px 0 24px 0; }
    @media screen and (max-width: 850px) {
      .Finish .titleHeader {
        margin: 0px 0 24px 0; } }
    @media screen and (max-width: 500px) {
      .Finish .titleHeader {
        margin: 10px 0 24px 0; } }
  .Finish.closed {
    background-color: #f5fbff; }
  .Finish .FinishContent {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
    padding-bottom: 156px;
    background-size: 112%;
    background-position: bottom left;
    background-repeat: no-repeat;
    display: block;
    margin: 50px auto 0px auto;
    min-height: 100px;
    max-width: 350px; }
    @media screen and (min-width: 500px) {
      .Finish .FinishContent {
        max-width: 500px; } }
    @media screen and (min-width: 801px) {
      .Finish .FinishContent {
        max-width: 900px; } }
    .Finish .FinishContent.open {
      background-color: rgba(255, 255, 255, 0.1); }
    .Finish .FinishContent.closed {
      background-color: #fff; }
    .Finish .FinishContent .FinishHeader {
      display: flex;
      justify-content: center;
      padding: 10px 40px 10px 40px; }
      @media screen and (min-width: 801px) {
        .Finish .FinishContent .FinishHeader {
          padding: 30px 40px 10px 40px;
          justify-content: center; } }
      .Finish .FinishContent .FinishHeader img {
        max-width: 100px; }
        @media screen and (min-width: 801px) {
          .Finish .FinishContent .FinishHeader img {
            max-width: 200px; } }
      .Finish .FinishContent .FinishHeader .FinishHeaderLogoLink {
        display: none; }
        @media screen and (min-width: 801px) {
          .Finish .FinishContent .FinishHeader .FinishHeaderLogoLink {
            display: block; } }
      .Finish .FinishContent .FinishHeader a.FinishTopSponsorLink {
        display: block;
        max-width: 240px;
        max-height: 50px;
        margin: 0;
        position: relative; }
        .Finish .FinishContent .FinishHeader a.FinishTopSponsorLink img.FinishTopSponsorImage {
          max-width: 100%;
          max-height: 45px; }
    .Finish .FinishContent .LineTitle {
      text-align: center;
      margin: 0px 20px;
      border-bottom: 1px solid #e9e9e9; }
      .Finish .FinishContent .LineTitle span {
        margin: 0 10px;
        font-weight: bold;
        color: #2c2f3c;
        display: block;
        padding-bottom: 10px; }
      .Finish .FinishContent .LineTitle i {
        display: none; }
      @media screen and (min-width: 801px) {
        .Finish .FinishContent .LineTitle {
          display: flex;
          align-items: center;
          text-align: left;
          border-bottom: none; }
          .Finish .FinishContent .LineTitle i {
            display: block;
            height: 1px;
            background-color: #e9e9e9;
            flex: 1 1; }
          .Finish .FinishContent .LineTitle span {
            font-size: 15px;
            padding-bottom: 0; } }
    .Finish .FinishContent .SubtitleLine {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 6px 25px;
      font-size: 13px;
      font-weight: 600;
      color: #2c2f3c; }
      .Finish .FinishContent .SubtitleLine div i {
        font-style: normal;
        color: #008dfb;
        font-weight: bold; }
        .Finish .FinishContent .SubtitleLine div i.dot {
          display: inline-block;
          padding: 0 3px; }
      @media screen and (min-width: 801px) {
        .Finish .FinishContent .SubtitleLine {
          font-size: 13px; } }
    .Finish .FinishContent .AthleteInfo {
      text-align: center;
      margin-top: -42px;
      font-size: 15px;
      font-weight: 500;
      color: #2c2f3c; }
      .Finish .FinishContent .AthleteInfo .nextPage, .Finish .FinishContent .AthleteInfo .prevPage {
        line-height: 1em;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 9px 8px 5px 9px;
        border-radius: 50%;
        z-index: 2; }
        .Finish .FinishContent .AthleteInfo .nextPage img, .Finish .FinishContent .AthleteInfo .prevPage img {
          position: relative;
          left: 0;
          transition: left 80ms; }
      .Finish .FinishContent .AthleteInfo .nextPage {
        right: -448px;
        top: -186px; }
        .Finish .FinishContent .AthleteInfo .nextPage:hover img {
          left: 5px; }
        @media screen and (min-width: 1100px) {
          .Finish .FinishContent .AthleteInfo .nextPage {
            left: 448px; } }
        @media screen and (max-width: 801px) {
          .Finish .FinishContent .AthleteInfo .nextPage {
            left: 253px; } }
        @media screen and (max-width: 500px) {
          .Finish .FinishContent .AthleteInfo .nextPage {
            left: 175px; } }
      .Finish .FinishContent .AthleteInfo .prevPage {
        left: -450px;
        top: 77px; }
        .Finish .FinishContent .AthleteInfo .prevPage img {
          transform: rotate(180deg); }
        .Finish .FinishContent .AthleteInfo .prevPage:hover img {
          left: -5px; }
        @media screen and (min-width: 1100px) {
          .Finish .FinishContent .AthleteInfo .prevPage {
            left: -451px; } }
        @media screen and (max-width: 801px) {
          .Finish .FinishContent .AthleteInfo .prevPage {
            left: -253px; } }
        @media screen and (max-width: 500px) {
          .Finish .FinishContent .AthleteInfo .prevPage {
            left: -175px; } }
      .Finish .FinishContent .AthleteInfo .tooltip .tooltiptext {
        margin-left: -57px;
        margin-top: 5px;
        min-width: 100px;
        font-weight: 400;
        font-size: 11px;
        color: #fff; }
        .Finish .FinishContent .AthleteInfo .tooltip .tooltiptext em {
          color: #fcb452; }
      .Finish .FinishContent .AthleteInfo .Hometown {
        font-size: 15px;
        font-weight: 500; }
        .Finish .FinishContent .AthleteInfo .Hometown .countryFlag {
          font-size: 19px;
          line-height: 13px;
          display: inline-block;
          padding-right: 4px;
          position: relative;
          top: 2px;
          -webkit-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
      .Finish .FinishContent .AthleteInfo .AthleteName {
        font-size: 26px;
        font-weight: 600;
        margin: 26px 0 0px 0;
        color: #008dfb; }
        .Finish .FinishContent .AthleteInfo .AthleteName a:hover {
          cursor: pointer;
          padding-left: 16px;
          padding-right: 16px;
          color: #2c2f3c;
          border-radius: 18px;
          background-color: rgba(204, 232, 254, 0.3); }
      .Finish .FinishContent .AthleteInfo .time {
        display: inline-block;
        background-repeat: no-repeat;
        font-size: 28px;
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 15px;
        padding-left: 39px;
        background-position: 0 2px;
        background-size: 27px; }
      .Finish .FinishContent .AthleteInfo .pace {
        font-size: 13px;
        font-weight: normal;
        margin-top: -4px; }
        .Finish .FinishContent .AthleteInfo .pace strong {
          display: inline-block;
          margin: 0 4px;
          font-size: 16px;
          position: relative;
          top: 1.5px;
          font-weight: 600; }
        @media screen and (min-width: 801px) {
          .Finish .FinishContent .AthleteInfo .pace {
            font-size: 13px; }
            .Finish .FinishContent .AthleteInfo .pace strong {
              font-size: 16px; } }
      .Finish .FinishContent .AthleteInfo .RaceTypeLabelContainer {
        display: flex;
        justify-content: center;
        margin-top: 22px;
        margin-bottom: -34px; }
        .Finish .FinishContent .AthleteInfo .RaceTypeLabelContainer .RaceTypeLabel {
          margin: 10px;
          padding: 3px 14px;
          border-radius: 20px;
          border: none;
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          font-style: normal;
          color: #2c2f3c;
          box-sizing: border-box;
          background-color: #e1f1fe; }
        @media screen and (min-width: 801px) {
          .Finish .FinishContent .AthleteInfo .RaceTypeLabelContainer {
            font-size: 16px; } }
    .Finish .FinishContent .cityImg {
      height: 150px;
      background-size: cover;
      margin-top: 20px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px; }
      @media screen and (max-width: 500px) {
        .Finish .FinishContent .cityImg {
          height: 90px; } }
  .Finish .FinishFooter {
    display: flex;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto; }
    @media screen and (max-width: 800px) {
      .Finish .FinishFooter {
        max-width: 500px; } }
    @media screen and (max-width: 500px) {
      .Finish .FinishFooter {
        max-width: 350px; } }
    .Finish .FinishFooter a {
      color: #008dfb;
      padding: 4px 6px; }
      .Finish .FinishFooter a:hover {
        background-color: rgba(204, 232, 254, 0.3);
        color: #2c2f3c; }
    .Finish .FinishFooter > div {
      flex: 1 0; }
      .Finish .FinishFooter > div.MainUrl {
        padding-top: 15px;
        font-size: 10px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: 2px;
        text-align: center; }
      .Finish .FinishFooter > div.DownloadUrl {
        position: relative;
        padding-top: 10px;
        padding-right: 4px;
        font-size: 13px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right; }
        @media screen and (max-width: 800px) {
          .Finish .FinishFooter > div.DownloadUrl {
            font-size: 10px; } }
  .Finish .socialSharing {
    display: flex;
    justify-content: center;
    position: relative; }
    .Finish .socialSharing .socialButtonContainer {
      height: 40px;
      width: 40px;
      position: relative;
      margin: 25px 14px 20px 14px; }
      .Finish .socialSharing .socialButtonContainer .socialButton {
        outline: none; }
        @media screen and (max-width: 800px) {
          .Finish .socialSharing .socialButtonContainer .socialButton {
            height: 25px;
            width: 25px;
            margin: 10px 14px 20px 14px; } }
        .Finish .socialSharing .socialButtonContainer .socialButton.Facebook {
          background-color: #415a93; }
        .Finish .socialSharing .socialButtonContainer .socialButton.Twitter {
          background-color: #4babe7; }

.FinishSidebar .FinishSidebarButton {
  position: absolute;
  left: 65px;
  top: 33px;
  cursor: pointer; }
  .FinishSidebar .FinishSidebarButton img {
    position: relative;
    z-index: 5; }
  @media screen and (max-width: 600px) {
    .FinishSidebar .FinishSidebarButton {
      top: 10px; } }
  .FinishSidebar .FinishSidebarButton .background {
    transform: scale(0);
    transition: transform 200ms;
    transition-timing-function: ease-out;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: rgba(204, 232, 254, 0.3);
    top: -11px;
    left: -10px; }
  .FinishSidebar .FinishSidebarButton:hover .background {
    transform: scale(1); }

.FinishSidebar .FinishSidebarContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 330px;
  height: calc(100vh - 10px);
  position: fixed;
  top: 5px;
  left: 0;
  z-index: 10;
  transform: translateX(-500px);
  transition: transform 800ms;
  transition-timing-function: ease;
  background: #fff;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  padding: 60px;
  box-sizing: border-box; }
  .FinishSidebar .FinishSidebarContent.open {
    transform: translateX(0px); }
  .FinishSidebar .FinishSidebarContent button.close {
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #6b7c88;
    background: #fff;
    outline: none; }
    .FinishSidebar .FinishSidebarContent button.close:hover {
      color: #008dfb; }
  .FinishSidebar .FinishSidebarContent .FinishSidebarNavigation {
    text-transform: uppercase;
    overflow: hidden;
    padding-top: 40px;
    flex-grow: 1; }
    .FinishSidebar .FinishSidebarContent .FinishSidebarNavigation a {
      font-size: 14px;
      font-weight: 600;
      color: #2c2f3c;
      display: block;
      padding: 9px 0 7px;
      position: relative; }
      .FinishSidebar .FinishSidebarContent .FinishSidebarNavigation a:hover {
        color: #008dfb; }
        .FinishSidebar .FinishSidebarContent .FinishSidebarNavigation a:hover::after {
          transform: translateX(0px);
          transition: transform 300ms;
          transition-timing-function: ease-out; }
      .FinishSidebar .FinishSidebarContent .FinishSidebarNavigation a::after {
        transform: translateX(-50px);
        content: " ";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 30px;
        border-bottom: 1px solid #008dfb; }
  .FinishSidebar .FinishSidebarContent .FinishSidebarFooter {
    min-height: 80px;
    display: flex;
    justify-content: center; }

.FinishSidebarCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 8; }

.WelcomeBib {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  .WelcomeBib h1 {
    color: #008dfb;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 15px; }
    @media screen and (max-width: 600px) {
      .WelcomeBib h1 {
        margin-bottom: 5px;
        font-size: 22px; } }
    .WelcomeBib h1 .athleteName .tooltiptext {
      min-width: 50px !important;
      margin-left: -65px !important;
      top: 129% !important;
      line-height: 15px;
      font-size: 12px;
      padding: 5px 10px; }
      .WelcomeBib h1 .athleteName .tooltiptext em {
        color: #71e1f4;
        font-style: normal;
        display: block;
        white-space: nowrap; }
    .WelcomeBib h1 span:hover {
      background-color: rgba(204, 232, 254, 0.3);
      color: #2c2f3c;
      cursor: default;
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 18px; }
  .WelcomeBib span {
    color: #008DFB;
    margin: 0; }
  .WelcomeBib .bib {
    font-size: 28px;
    font-weight: 700;
    margin: 14px 0 9px 0; }
    @media screen and (max-width: 600px) {
      .WelcomeBib .bib {
        margin: 4px 0 5px 0;
        font-size: 20px; } }
  .WelcomeBib .userAvatar {
    margin: 0 0 18px 0;
    max-width: 90px;
    align-self: center;
    height: 90px; }
  .WelcomeBib .userAvatar2 {
    margin: 20px auto 24px;
    width: 90px; }
  .WelcomeBib .cityStateCountry {
    font-size: 42px;
    margin: 0 0;
    font-weight: 600;
    /* margin: 0 0 10 0; */ }
    .WelcomeBib .cityStateCountry span:hover {
      cursor: default; }
    @media screen and (max-width: 600px) {
      .WelcomeBib .cityStateCountry {
        margin: 0px;
        font-size: 36px; } }
  .WelcomeBib .cityStateCountryLabel {
    font-size: 15px;
    font-weight: 500;
    /* margin: 0 0 10 0; */
    margin: 0px 0 0px 0; }
    .WelcomeBib .cityStateCountryLabel span:hover {
      cursor: default; }
    .WelcomeBib .cityStateCountryLabel .countryFlag {
      font-size: 22px;
      position: relative;
      left: -5px;
      top: 3px; }
  .WelcomeBib .ageGender {
    font-size: 1rem;
    /* font-weight: 600; */
    /* margin: 0 0 10 0; */
    margin: 0; }
  .WelcomeBib .athleteName {
    font-weight: 600; }
  .WelcomeBib .timeContainer {
    padding-bottom: 15px; }
    .WelcomeBib .timeContainer .nextPage {
      position: relative;
      top: 5px;
      left: 15px;
      display: inline-block; }
      .WelcomeBib .timeContainer .nextPage:hover .background {
        transform: scale(1); }
      .WelcomeBib .timeContainer .nextPage .background {
        display: block;
        transform: scale(0);
        transition: transform 200ms;
        transition-timing-function: ease-out;
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: rgba(204, 232, 254, 0.3);
        top: 0px;
        left: 0px;
        z-index: 1; }
      .WelcomeBib .timeContainer .nextPage img {
        z-index: 2;
        position: relative; }
  .WelcomeBib .time {
    display: inline-block;
    background-repeat: no-repeat;
    color: #2c2f3c;
    font-size: 40px;
    font-weight: 600;
    margin-top: 4px;
    padding-left: 56px;
    background-position: 0px 4px;
    background-size: 40px; }
    @media screen and (max-width: 600px) {
      .WelcomeBib .time {
        margin-top: 2px; } }

.Sponsors {
  opacity: 1;
  transition: opacity 500ms; }
  .Sponsors.fadeHidden {
    opacity: 0; }
  .Sponsors .Tabs {
    display: flex;
    justify-content: center;
    z-index: 5;
    position: relative;
    flex-wrap: wrap;
    margin-bottom: -10px; }
    .Sponsors .Tabs .Tab {
      padding: 0 0 5px;
      margin: 0 15px 10px;
      border-bottom: 3px solid transparent;
      font-size: 13px;
      color: #909fa9;
      cursor: pointer;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .Sponsors .Tabs .Tab:hover {
        color: #008dfb; }
      .Sponsors .Tabs .Tab.selected {
        border-bottom-color: #bce2ff;
        color: #2c2f3c;
        cursor: default; }
  .Sponsors .TabContent .ContentTitleSponsor, .Sponsors .TabContent .ContentSponsor {
    min-height: 30px; }
  .Sponsors .TabContent .ContentTitleSponsor {
    max-width: calc(100vw - 200px);
    margin: 0 auto;
    margin-top: -20px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .Sponsors .TabContent .ContentTitleSponsor img {
      max-width: 500px; }
  .Sponsors .TabContent .ContentSponsor {
    margin-bottom: 70px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    max-width: calc(100vw - 100px);
    margin: 0 auto; }
    @media screen and (max-width: 600px) {
      .Sponsors .TabContent .ContentSponsor {
        max-width: calc(100vw - 16px); } }
    .Sponsors .TabContent .ContentSponsor .SponsorImage {
      margin: 0 10px;
      max-width: 200px;
      min-width: 40px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 20px; }
      @media screen and (max-width: 600px) {
        .Sponsors .TabContent .ContentSponsor .SponsorImage {
          margin: 0; } }
      .Sponsors .TabContent .ContentSponsor .SponsorImage:hover {
        border-radius: 4px;
        box-shadow: 0 0 0 1px #e1e5e7; }
        .Sponsors .TabContent .ContentSponsor .SponsorImage:hover .Caption {
          background-color: rgba(204, 232, 254, 0.3); }
        .Sponsors .TabContent .ContentSponsor .SponsorImage:hover img.openIcon {
          display: block;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 5px;
          right: 5px; }
      .Sponsors .TabContent .ContentSponsor .SponsorImage a, .Sponsors .TabContent .ContentSponsor .SponsorImage span {
        display: block;
        padding: 5px 10px 0; }
      .Sponsors .TabContent .ContentSponsor .SponsorImage img.openIcon {
        display: none; }
      .Sponsors .TabContent .ContentSponsor .SponsorImage img.sponsor {
        max-width: 100%;
        max-height: 100%;
        display: block; }
      .Sponsors .TabContent .ContentSponsor .SponsorImage .Caption {
        text-align: center;
        padding: 8px 5px;
        bottom: 0;
        left: 0;
        right: 0;
        color: #2c2f3c; }
        @media screen and (max-width: 600px) {
          .Sponsors .TabContent .ContentSponsor .SponsorImage .Caption {
            font-size: 10px; } }

.Welcome {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .Welcome .container .search-icon-container .tooltip .tooltiptext {
    min-width: 80px;
    margin-left: -50px;
    top: 140%;
    line-height: 15px;
    font-size: 12px;
    padding: 5px 10px; }
    .Welcome .container .search-icon-container .tooltip .tooltiptext em {
      color: #71e1f4;
      font-style: normal;
      display: block;
      white-space: nowrap; }

a.topSponsorLink {
  max-width: 100%;
  text-align: center;
  margin: 0px 0 10px 0;
  display: block; }
  a.topSponsorLink img.topSponsorImage {
    max-width: 200px;
    max-height: 80px;
    margin: 20px 0 0 0; }
  @media screen and (min-width: 951px) {
    a.topSponsorLink {
      /* desktop */
      position: absolute;
      max-width: 240px;
      margin: 0px 0 0 70px;
      top: 20px;
      left: 0;
      z-index: 1; }
      a.topSponsorLink img.topSponsorImage {
        max-width: 100%; } }

.dot {
  padding: 0 5px; }
  @media screen and (min-width: 801px) {
    .dot {
      padding: 0 10px; } }

i {
  /* dot */
  font-style: normal;
  color: #008dfb;
  display: inline-block;
  padding: 0 10px;
  font-weight: 600; }

.InfoCards {
  margin-bottom: 160px;
  margin-top: 40px;
  max-width: 1130px; }
  @media screen and (min-width: 600px) {
    .InfoCards {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (min-width: 900px) {
    .InfoCards {
      grid-template-columns: repeat(3, 1fr); } }
  .InfoCards .InfoCard {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 30px 40px 26px;
    background-repeat: no-repeat;
    background-position: bottom 18px right 25px;
    color: #2c2f3c;
    font-size: 14px;
    line-height: 1.5;
    min-height: 130px;
    margin: 10px; }
    .InfoCards .InfoCard p, .InfoCards .InfoCard h3 {
      margin: 0 0 10px; }
      .InfoCards .InfoCard p:last-child, .InfoCards .InfoCard h3:last-child {
        margin-bottom: 0; }
    .InfoCards .InfoCard h3 {
      font-weight: 600; }
    .InfoCards .InfoCard ul {
      margin: 0;
      padding: 0 0 0 30px; }
    .InfoCards .InfoCard a {
      color: #008dfb;
      border-bottom: 2px solid transparent;
      padding-bottom: 1px; }
      .InfoCards .InfoCard a:hover {
        border-bottom-color: #bce2ff; }

@media screen and (min-width: 801px) {
  .header {
    display: flex;
    justify-content: space-between; }
    .header > div {
      flex: 1 1; } }

.header .logo-container {
  display: flex;
  padding-top: 35px;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  /* cursor: pointer; */
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-left: 26px; }
  @media screen and (max-width: 600px) {
    .header .logo-container {
      padding-top: 12px; } }
  .header .logo-container .container .tooltip .tooltiptext {
    min-width: 80px !important;
    margin-left: -52px !important;
    top: 111% !important; }

.header .menu_button {
  border: 10px solid #fff;
  border-radius: 50px;
  position: relative;
  left: -10px;
  top: 0;
  background-color: #fff;
  display: inline-block;
  z-index: 7;
  cursor: pointer; }
  .header .menu_button:hover .menu_button_img {
    display: none; }
  .header .menu_button .menu_button_img {
    display: block; }
  .header .menu_button .menu_button_img_hover {
    display: none; }
  .header .menu_button:hover .menu_button_img_hover {
    display: block; }

.header .logo-img-container {
  z-index: 7;
  position: relative;
  padding-left: 34px; }
  .header .logo-img-container img {
    width: 140px;
    display: block; }
  @media screen and (max-width: 600px) {
    .header .logo-img-container {
      padding-top: 0px;
      padding-bottom: 15px; } }

.header > .athlete-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #2c2f3c;
  font-size: 15px;
  font-weight: 500;
  flex-grow: 2;
  margin: 0 10px;
  padding-top: 25px; }
  @media screen and (max-width: 600px) {
    .header > .athlete-info-container {
      padding-top: 0px;
      padding-bottom: 5px; } }
  .header > .athlete-info-container .DropdownContainer {
    display: inline-block; }
    .header > .athlete-info-container .DropdownContainer em {
      font-style: normal;
      font-size: 15px; }

.social-icon {
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-decoration: none;
  outline: none; }

.athlete-info span.flag {
  font-size: 20px;
  display: inline-block;
  position: relative;
  top: 3px;
  padding-right: 5px;
  cursor: default; }
  @media screen and (max-width: 600px) {
    .athlete-info span.flag {
      padding-right: 0; } }

.athlete-info a {
  font-weight: 700;
  color: #008dfb;
  text-decoration: none; }

.athlete-info span.bib {
  display: inline-block;
  padding-left: 7px; }
  @media screen and (max-width: 600px) {
    .athlete-info span.bib {
      padding-left: 0;
      margin: 0 0 2px 0; } }

.athlete-info .name {
  font-weight: 600;
  color: #008dfb; }

.athlete-info .event-name {
  font-weight: 700; }

.athlete-info .event-location {
  font-weight: normal; }
  .athlete-info .event-location .event-location-flag {
    position: relative;
    top: 2px; }

.athlete-info .event-date {
  font-weight: normal;
  margin-left: -12px; }
  .athlete-info .event-date .tooltip {
    z-index: 7; }
    .athlete-info .event-date .tooltip .tooltiptext {
      min-width: 50px;
      margin-left: -90px;
      top: 165%;
      font-size: 13px; }

.athlete-info .dot {
  padding: 0 18px; }

.athlete-info i {
  /* dot */
  font-style: normal;
  color: #008dfb;
  display: inline-block;
  padding: 0 10px;
  font-weight: 600; }

.athlete-info span.divisionType {
  font-weight: 600; }

.athlete-info span.athleteName {
  color: #008dfb; }
  .athlete-info span.athleteName:hover {
    background-color: rgba(204, 232, 254, 0.3);
    color: #2c2f3c;
    cursor: pointer; }
  @media screen and (max-width: 600px) {
    .athlete-info span.athleteName {
      margin: 0 0 2px 0; } }

@media screen and (max-width: 600px) {
  .athlete-info .event-name {
    display: block;
    margin-bottom: 5px; }
    .athlete-info .event-name + .dot {
      display: none; }
  .athlete-info .dot {
    color: #008dfb;
    font-size: 11px; }
  .athlete-info .event-location, .athlete-info .event-date {
    font-size: 11px;
    color: #2c2f3c; } }

.toggle-units-container {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px; }

.rightbar-container {
  display: flex;
  justify-content: flex-end; }

.rightbar-container-block.search-icon-container {
  display: flex;
  align-items: center;
  padding: 20px 25px 0 0; }
  .rightbar-container-block.search-icon-container .searchIcon {
    position: relative;
    top: 0;
    left: 0;
    right: auto; }
  @media screen and (max-width: 600px) {
    .rightbar-container-block.search-icon-container {
      display: none; } }

.rightbar-container-block.profile-icon-container {
  padding: 20px 30px 0 0; }

.rightbar-container-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 30px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .rightbar-container-row-1 {
    padding-top: 38px; }
    @media screen and (max-width: 600px) {
      .rightbar-container-row-1 {
        display: none; } }
  .rightbar-container-row-2 {
    position: relative;
    top: 16px;
    justify-content: flex-end; }
    @media screen and (max-width: 600px) {
      .rightbar-container-row-2 {
        display: none; } }
  @media screen and (max-width: 600px) {
    .rightbar-container-row {
      padding-right: 0; } }

.searchIcon {
  position: absolute;
  right: 50px;
  top: 40px;
  width: 30px;
  z-index: 7; }
  @media screen and (max-width: 600px) {
    .searchIcon {
      right: 20px;
      top: 40px; } }

.DropdownContainer {
  position: relative; }
  .DropdownContainer .DropdownValue {
    cursor: pointer;
    display: flex;
    padding: 4px 13px 3px;
    font-size: 12px;
    background-color: #e8e8e0;
    border-radius: 12.5px; }
    @media screen and (min-width: 801px) {
      .DropdownContainer .DropdownValue {
        background-color: transparent; }
        .DropdownContainer .DropdownValue:hover {
          background-color: #e8e8e0; } }
    .DropdownContainer .DropdownValue em {
      white-space: nowrap; }
    .DropdownContainer .DropdownValue.disabled {
      cursor: not-allowed; }
      .DropdownContainer .DropdownValue.disabled em, .DropdownContainer .DropdownValue.disabled span {
        color: #b9b9b9 !important; }
      .DropdownContainer .DropdownValue.disabled:hover {
        background-color: #fff; }
      .DropdownContainer .DropdownValue.disabled img {
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        opacity: 0.7; }
  .DropdownContainer .DropdownList {
    z-index: 7;
    display: none;
    text-align: left; }
    .DropdownContainer .DropdownList.open {
      display: block;
      background: #fff;
      margin: 0;
      padding: 5px 0;
      max-width: 100vw;
      width: calc(100vw - 40px);
      left: 20px;
      position: fixed;
      top: 80px;
      border-radius: 8px; }
      @media screen and (min-width: 801px) {
        .DropdownContainer .DropdownList.open {
          position: absolute;
          top: 25px;
          left: -30px;
          width: auto; } }
      .DropdownContainer .DropdownList.open li {
        font-size: 12px;
        color: #000;
        display: block;
        white-space: nowrap;
        padding: 5px 40px 5px 40px;
        cursor: pointer;
        position: relative;
        background-repeat: no-repeat;
        background-position: 18px 7px; }
        .DropdownContainer .DropdownList.open li .GoTo {
          position: absolute;
          right: 0;
          top: 0;
          width: 30px;
          height: 100%;
          opacity: 0;
          transition: opacity 200ms; }
        .DropdownContainer .DropdownList.open li:hover {
          background-color: rgba(204, 232, 254, 0.3); }
          .DropdownContainer .DropdownList.open li:hover .GoTo {
            opacity: 1; }
        .DropdownContainer .DropdownList.open li.selected {
          font-weight: 800; }

.DropdownCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 5; }

.event-header span {
  display: inline-block; }

a.topSponsorLinkAthleteList {
  max-width: 100%;
  text-align: center;
  margin: 0;
  display: block; }
  a.topSponsorLinkAthleteList img.topSponsorImage {
    max-width: 200px;
    max-height: 60px; }
  @media screen and (min-width: 951px) {
    a.topSponsorLinkAthleteList {
      /* desktop */
      max-width: 240px;
      max-height: 50px;
      top: 20px;
      left: 0;
      z-index: 1; }
      a.topSponsorLinkAthleteList img.topSponsorImage {
        max-width: 100%; } }

.dot {
  padding: 0 5px; }
  @media screen and (min-width: 801px) {
    .dot {
      padding: 0 10px; } }

i {
  /* dot */
  font-style: normal;
  color: #008dfb;
  display: inline-block;
  padding: 0 10px;
  font-weight: 800; }

.ReportHeaderSameHometownAthletes strong {
  color: #008dfb; }

.ReportHeaderSameHometownAthletes .athlete-info-container {
  padding-top: 10px; }

.ReportHeaderSameHometownAthletes span {
  display: inline-block;
  padding: 0 10px; }

.HomePage {
  min-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #f3f3ee; }
  .HomePage .MainContent {
    flex-grow: 1; }
    .HomePage .MainContent .container {
      max-width: 1100px; }
  .HomePage .EventTypeOptions {
    text-align: center;
    margin-top: 20px; }
    .HomePage .EventTypeOptions .EventType {
      padding: 5px 9px;
      border-radius: 15px;
      font-weight: 600;
      font-size: 12px;
      color: #2c2f3c;
      cursor: pointer; }
      .HomePage .EventTypeOptions .EventType.selected {
        background-color: #008dfb;
        color: #fff; }
        .HomePage .EventTypeOptions .EventType.selected:hover {
          background-color: #008dfb;
          color: #fff;
          cursor: default; }
      .HomePage .EventTypeOptions .EventType:hover {
        background-color: #e8e8e0; }
  .HomePage .EventCards {
    margin-top: 30px;
    font-size: 13px;
    color: #2c2f3c; }
    @media screen and (min-width: 600px) {
      .HomePage .EventCards {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (min-width: 900px) {
      .HomePage .EventCards {
        grid-template-columns: repeat(3, 1fr); } }
    .HomePage .EventCards .EventCard {
      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      padding: 20px 30px;
      border-radius: 6px;
      margin: 10px;
      cursor: pointer;
      position: relative; }
      .HomePage .EventCards .EventCard .tooltiptext {
        min-width: 33px !important;
        top: 139% !important;
        left: -25% !important; }
        .HomePage .EventCards .EventCard .tooltiptext em {
          font-size: 11px;
          color: #fcb452;
          font-style: normal;
          font-weight: 500;
          display: block;
          white-space: nowrap; }
      .HomePage .EventCards .EventCard .OpenRaceIcon {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        opacity: 0; }
      .HomePage .EventCards .EventCard:hover {
        box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.2); }
        .HomePage .EventCards .EventCard:hover .OpenRaceIcon {
          opacity: 1; }
      .HomePage .EventCards .EventCard .chevron {
        display: inline-block;
        margin-left: 10px;
        font-size: 30px;
        line-height: 10px;
        position: relative;
        top: 1px; }
      .HomePage .EventCards .EventCard .EventImage {
        margin-bottom: 20px;
        height: 50px; }
        .HomePage .EventCards .EventCard .EventImage img {
          display: block;
          margin: 0 auto;
          max-height: 100%; }
      .HomePage .EventCards .EventCard .EventTitle {
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px; }
      .HomePage .EventCards .EventCard .EventInfo {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: 1fr 1fr; }
        .HomePage .EventCards .EventCard .EventInfo .EventInfoDate {
          white-space: nowrap; }
        .HomePage .EventCards .EventCard .EventInfo div:nth-child(even) {
          text-align: right; }
        .HomePage .EventCards .EventCard .EventInfo div .raceCount {
          font-weight: 500;
          font-family: "Roboto Mono", monospace; }
        .HomePage .EventCards .EventCard .EventInfo div .totalAthleteCount {
          font-weight: bold;
          font-size: 15px;
          font-family: "Roboto Mono", monospace; }
        .HomePage .EventCards .EventCard .EventInfo div .flag {
          font-size: 18px;
          margin-left: 1px;
          margin-right: 2px; }
        .HomePage .EventCards .EventCard .EventInfo div .location {
          font-size: 12px;
          font-weight: normal;
          position: relative;
          top: -3px; }
      .HomePage .EventCards .EventCard .DropdownContainer {
        display: inline-block;
        position: relative;
        top: 2px; }
        .HomePage .EventCards .EventCard .DropdownContainer .DropdownValue {
          justify-content: flex-end;
          padding-right: 10px;
          margin-right: -12px;
          font-size: 12px;
          border: 1px solid #e9e9e9;
          padding-left: 15px; }
          .HomePage .EventCards .EventCard .DropdownContainer .DropdownValue em {
            font-style: normal; }
  .HomePage .Header {
    height: 90px; }
    .HomePage .Header .Sidebar .SidebarButton {
      top: 22px; }
    .HomePage .Header .rightbar-container {
      top: 15px; }

.HomeEventsFooterBackground {
  height: 260px;
  background-position: center;
  background-repeat: no-repeat; }

.AthleteHomeTimeline {
  margin-top: 40px; }
  .AthleteHomeTimeline .TheTimeline {
    margin: 0 auto 20px;
    border-top: 1px solid #000;
    width: 730px;
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: stretch; }
    @media screen and (min-width: 360px) {
      .AthleteHomeTimeline .TheTimeline {
        width: 400px; } }
    @media screen and (min-width: 660px) {
      .AthleteHomeTimeline .TheTimeline {
        width: 600px; } }
    @media screen and (min-width: 900px) {
      .AthleteHomeTimeline .TheTimeline {
        width: 630px; } }
    @media screen and (min-width: 1100px) {
      .AthleteHomeTimeline .TheTimeline {
        width: 730px; } }
    .AthleteHomeTimeline .TheTimeline .Races {
      position: relative; }
      .AthleteHomeTimeline .TheTimeline .Races .Race {
        position: absolute;
        width: 6px;
        height: 6px;
        box-sizing: border-box;
        background: #fff;
        top: -5px;
        display: block;
        z-index: 5;
        cursor: pointer; }
        .AthleteHomeTimeline .TheTimeline .Races .Race .TheDot {
          cursor: pointer;
          border: 2px solid #008dfb;
          border-radius: 10px;
          width: 100%;
          height: 100%;
          background-color: #f8f8f5; }
        .AthleteHomeTimeline .TheTimeline .Races .Race .background {
          background-color: #efefe9;
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 20px;
          left: -7px;
          top: -7px;
          z-index: -1;
          opacity: 0;
          transition: opacity 200ms; }
          .AthleteHomeTimeline .TheTimeline .Races .Race .background::after {
            display: block;
            content: " ";
            width: 100%;
            height: 1px;
            background-color: #000;
            position: absolute;
            top: 11px;
            left: 0; }
        .AthleteHomeTimeline .TheTimeline .Races .Race .RaceLabel {
          color: #fff;
          opacity: 0; }
        .AthleteHomeTimeline .TheTimeline .Races .Race:hover .background {
          opacity: 1; }
        .AthleteHomeTimeline .TheTimeline .Races .Race:hover .TheDot {
          background-blend-mode: multiply;
          background-image: linear-gradient(to bottom, rgba(243, 243, 238, 0.75), rgba(243, 243, 238, 0.75)); }
        .AthleteHomeTimeline .TheTimeline .Races .Race.active {
          z-index: 10; }
          .AthleteHomeTimeline .TheTimeline .Races .Race.active .TheDot {
            border-color: #fcb452;
            overflow: visible;
            z-index: 7; }
          .AthleteHomeTimeline .TheTimeline .Races .Race.active .RaceLabel {
            opacity: 1;
            color: #2c2f3c;
            position: absolute;
            top: -30px;
            left: 0;
            font-size: 11px;
            width: 100px;
            font-weight: 600;
            margin-left: -50px;
            text-align: center; }
          .AthleteHomeTimeline .TheTimeline .Races .Race.active.tooltip .tooltiptext {
            top: 25px !important;
            color: #fcb452;
            min-width: 80px !important;
            left: 38px;
            font-size: 12px;
            font-weight: 500; }
            .AthleteHomeTimeline .TheTimeline .Races .Race.active.tooltip .tooltiptext:after {
              display: none; }
        .AthleteHomeTimeline .TheTimeline .Races .Race.tooltip .tooltiptext {
          min-width: 100px !important;
          margin-left: -60px !important;
          top: -35px !important;
          height: 15px;
          line-height: 15px;
          font-size: 12px;
          margin-top: 0;
          margin-left: -70px;
          font-size: 11px;
          font-weight: 600; }
          .AthleteHomeTimeline .TheTimeline .Races .Race.tooltip .tooltiptext:after {
            border-color: #2c2f3c transparent transparent transparent;
            bottom: -10px; }
    .AthleteHomeTimeline .TheTimeline .Years {
      display: flex;
      height: 10px;
      border-left: 1px solid #000; }
      .AthleteHomeTimeline .TheTimeline .Years .Year {
        position: relative;
        flex-grow: 1;
        flex-basis: 0;
        border-right: 1px solid #000; }
        .AthleteHomeTimeline .TheTimeline .Years .Year .yearname {
          display: block;
          position: absolute;
          top: 15px;
          left: 1px;
          font-size: 11px;
          color: #2c2f3c;
          font-weight: 400; }

.AthleteHomeRaceCard {
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  .AthleteHomeRaceCard .tooltip .tooltiptext {
    min-width: 60px !important;
    top: 100% !important;
    margin-left: -30px !important;
    margin-top: 12px; }
    .AthleteHomeRaceCard .tooltip .tooltiptext em {
      color: #fcb452;
      font-style: normal;
      display: block;
      white-space: nowrap; }
  .AthleteHomeRaceCard .PrevButton, .AthleteHomeRaceCard .NextButton {
    padding-top: 60px; }
    .AthleteHomeRaceCard .PrevButton .tooltip .tooltiptext, .AthleteHomeRaceCard .NextButton .tooltip .tooltiptext {
      margin-left: -48px !important;
      margin-top: 12px; }
      .AthleteHomeRaceCard .PrevButton .tooltip .tooltiptext em, .AthleteHomeRaceCard .NextButton .tooltip .tooltiptext em {
        color: #71e1f4;
        font-style: normal;
        display: block;
        white-space: nowrap; }
    .AthleteHomeRaceCard .PrevButton .nextPage, .AthleteHomeRaceCard .PrevButton .prevPage, .AthleteHomeRaceCard .NextButton .nextPage, .AthleteHomeRaceCard .NextButton .prevPage {
      padding: 5px 4px 1px 5px;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer; }
      .AthleteHomeRaceCard .PrevButton .nextPage.disabled, .AthleteHomeRaceCard .PrevButton .prevPage.disabled, .AthleteHomeRaceCard .NextButton .nextPage.disabled, .AthleteHomeRaceCard .NextButton .prevPage.disabled {
        opacity: 0;
        cursor: default; }
        .AthleteHomeRaceCard .PrevButton .nextPage.disabled img:hover, .AthleteHomeRaceCard .PrevButton .prevPage.disabled img:hover, .AthleteHomeRaceCard .NextButton .nextPage.disabled img:hover, .AthleteHomeRaceCard .NextButton .prevPage.disabled img:hover {
          left: 0; }
      .AthleteHomeRaceCard .PrevButton .nextPage img, .AthleteHomeRaceCard .PrevButton .prevPage img, .AthleteHomeRaceCard .NextButton .nextPage img, .AthleteHomeRaceCard .NextButton .prevPage img {
        position: relative;
        left: 0;
        transition: left 80ms; }
    .AthleteHomeRaceCard .PrevButton .nextPage:hover img, .AthleteHomeRaceCard .NextButton .nextPage:hover img {
      left: 5px; }
    .AthleteHomeRaceCard .PrevButton .nextPage .tooltiptext, .AthleteHomeRaceCard .NextButton .nextPage .tooltiptext {
      margin-left: -38px !important; }
    .AthleteHomeRaceCard .PrevButton .prevPage img, .AthleteHomeRaceCard .NextButton .prevPage img {
      transform: rotate(180deg); }
    .AthleteHomeRaceCard .PrevButton .prevPage:hover img, .AthleteHomeRaceCard .NextButton .prevPage:hover img {
      left: -5px; }
    .AthleteHomeRaceCard .PrevButton .prevPage .tooltiptext, .AthleteHomeRaceCard .NextButton .prevPage .tooltiptext {
      margin-left: -50px !important;
      margin-top: 12px; }
  .AthleteHomeRaceCard .EventCard {
    padding: 15px 25px 20px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    box-sizing: border-box;
    margin: 20px 20px 110px;
    cursor: pointer;
    min-width: 240px; }
    @media screen and (min-width: 660px) {
      .AthleteHomeRaceCard .EventCard {
        width: 355px; } }
    .AthleteHomeRaceCard .EventCard .OpenRaceIcon {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      opacity: 0; }
    .AthleteHomeRaceCard .EventCard .FavIconLink {
      opacity: 0;
      position: absolute;
      top: 7px;
      left: 7px; }
      .AthleteHomeRaceCard .EventCard .FavIconLink.isFavorite {
        opacity: 1; }
        .AthleteHomeRaceCard .EventCard .FavIconLink.isFavorite .IconSwappable .icon {
          display: block !important; }
        .AthleteHomeRaceCard .EventCard .FavIconLink.isFavorite .IconSwappable .hover {
          display: none !important; }
    .AthleteHomeRaceCard .EventCard .FavIconLink, .AthleteHomeRaceCard .EventCard .FavIconLink .IconSwappable, .AthleteHomeRaceCard .EventCard .FavIconLink .IconSwappable > * {
      min-width: 16px;
      min-height: 16px; }
    .AthleteHomeRaceCard .EventCard:hover {
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.2); }
      .AthleteHomeRaceCard .EventCard:hover .OpenRaceIcon {
        opacity: 1; }
      .AthleteHomeRaceCard .EventCard:hover .FavIconLink {
        opacity: 1; }
    .AthleteHomeRaceCard .EventCard .EventImage {
      margin-bottom: 10px;
      height: 25px; }
      .AthleteHomeRaceCard .EventCard .EventImage img {
        display: block;
        margin: 0 auto;
        max-height: 100%; }
    .AthleteHomeRaceCard .EventCard .EventTitle {
      font-weight: 600;
      text-align: center;
      margin-bottom: 10px; }
    .AthleteHomeRaceCard .EventCard .EventLocation {
      text-align: center;
      font-size: 13px;
      color: #2c2f3c; }
      .AthleteHomeRaceCard .EventCard .EventLocation .Flag {
        display: inline-block;
        font-size: 15px;
        position: relative;
        left: -5px;
        top: 2px; }
    .AthleteHomeRaceCard .EventCard .EventInfo {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(4, 1fr);
      border-top: 1px solid #e9e9e9;
      padding-top: 15px;
      margin-bottom: 0; }
      .AthleteHomeRaceCard .EventCard .EventInfo dt {
        font-weight: 400;
        color: #2c2f3c;
        font-size: 11px;
        padding: 0;
        margin: 1px 0px 0px 0px;
        text-align: right; }
      .AthleteHomeRaceCard .EventCard .EventInfo dd {
        text-align: left;
        padding: 0;
        margin: 0;
        font-size: 13px;
        font-weight: 600; }

@charset "UTF-8";
.AthleteHome {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .AthleteHome .WelcomeBib h1 {
    color: #2c2f3c; }
  .AthleteHome .WelcomeBib p {
    font-size: 15px;
    margin: 0 0 10px; }
    .AthleteHome .WelcomeBib p.smaller {
      font-size: 13px; }
  .AthleteHome .WelcomeBib img.smaller {
    width: 18px; }
  .AthleteHome .WelcomeBib .Logo {
    padding-bottom: 20px;
    height: 50px; }
    .AthleteHome .WelcomeBib .Logo img {
      height: 50px; }
  .AthleteHome .WelcomeBib .WelcomeBibHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .AthleteHome .WelcomeBib .WelcomeBibHeader .favoriteSpan:hover .background {
      transform: scale(1); }
    .AthleteHome .WelcomeBib .WelcomeBibHeader .favoriteSpan .background {
      transform: scale(0);
      transition: transform 200ms;
      transition-timing-function: ease-out;
      position: absolute;
      width: 35px;
      height: 35px;
      border-radius: 100%;
      background-color: rgba(204, 232, 254, 0.3);
      top: -5px;
      left: -5px; }
    .AthleteHome .WelcomeBib .WelcomeBibHeader .tooltiptext {
      min-width: 94px !important;
      margin-left: -57px !important;
      top: 125% !important;
      line-height: 15px;
      font-size: 11px;
      padding: 5px 10px; }
      .AthleteHome .WelcomeBib .WelcomeBibHeader .tooltiptext em {
        color: #71e1f4;
        font-style: normal;
        display: block;
        white-space: nowrap; }
    .AthleteHome .WelcomeBib .WelcomeBibHeader .WelcomeBibLocation {
      color: #2c2f3c;
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .AthleteHome .WelcomeBib .WelcomeBibHeader .WelcomeBibLocation .WelcomeBibFlag {
        font-size: 36px;
        margin-right: 14px;
        cursor: default; }
      .AthleteHome .WelcomeBib .WelcomeBibHeader .WelcomeBibLocation .WelcomeBibHometown {
        color: #2c2f3c;
        font-size: 15px;
        font-weight: 500;
        cursor: default; }
        .AthleteHome .WelcomeBib .WelcomeBibHeader .WelcomeBibLocation .WelcomeBibHometown .tooltiptext {
          min-width: 50px !important;
          margin-left: -65px !important;
          top: 152% !important;
          line-height: 15px;
          font-size: 11px;
          padding: 5px 10px; }
          .AthleteHome .WelcomeBib .WelcomeBibHeader .WelcomeBibLocation .WelcomeBibHometown .tooltiptext em {
            color: #fcb452;
            font-style: normal;
            display: block;
            white-space: nowrap; }
  .AthleteHome .AthleteHomeFooter {
    font-size: 13px !important; }
    .AthleteHome .AthleteHomeFooter .dot {
      display: inline-block;
      padding: 0 7px;
      color: #008dfb;
      font-size: 16px; }
    .AthleteHome .AthleteHomeFooter .since {
      display: inline-block;
      padding-left: 4px;
      color: #2c2f3c;
      font-weight: 300; }
  .AthleteHome table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 15px;
    width: 100%; }
    .AthleteHome table th {
      text-align: left;
      vertical-align: bottom;
      color: #6b7c88;
      padding-bottom: 15px;
      font-weight: 600;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .AthleteHome table th.sortable {
        cursor: pointer; }
      .AthleteHome table th .SortIcon img {
        display: none; }
      .AthleteHome table th:hover {
        color: #909fa9; }
        .AthleteHome table th:hover .SortIcon img {
          display: block; }
    .AthleteHome table td {
      padding: 4px 0;
      font-size: 13px;
      font-weight: 400;
      color: #2c2f3c; }
    .AthleteHome table tr:hover td {
      background-color: #e8e8e0;
      cursor: pointer; }
    .AthleteHome table tr .rightAligned {
      text-align: right; }
    .AthleteHome table tr .middleAligned {
      text-align: center; }
    .AthleteHome table tr .padRight {
      padding-right: 40px; }
    .AthleteHome table .td_index {
      text-align: right;
      padding-right: 40px; }
    .AthleteHome table .td_finishtime {
      text-align: right; }
    .AthleteHome table .td_bib {
      text-align: right; }
    .AthleteHome table .td_finishtime {
      text-align: right; }
    .AthleteHome table .td_participants {
      text-align: right; }
    .AthleteHome table .td_location {
      text-align: right; }
    .AthleteHome table .td_date {
      text-align: right;
      padding-right: 40px; }
    @media screen and (max-width: 600px) {
      .AthleteHome table, .AthleteHome table thead, .AthleteHome table tbody, .AthleteHome table tr, .AthleteHome table td, .AthleteHome table th {
        display: block; }
      .AthleteHome table thead {
        display: none; }
      .AthleteHome table tr {
        padding: 10px 16px 14px 10px;
        margin: 10px;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        display: grid;
        grid-template-columns: 28px 1fr 1fr;
        grid-template-areas: "index name name" "index participants participants" "index location date" "index bib finishtime"; }
        .AthleteHome table tr .td_index {
          grid-area: index;
          float: left;
          color: #2c2f3c;
          padding-right: 15px; }
        .AthleteHome table tr .td_name {
          grid-area: name; }
        .AthleteHome table tr .td_participants {
          grid-area: participants;
          text-align: left; }
          .AthleteHome table tr .td_participants:before {
            content: "Athletes \2022   "; }
        .AthleteHome table tr .td_bib {
          grid-area: bib;
          text-align: left; }
          .AthleteHome table tr .td_bib:before {
            content: "Bib \2022   "; }
        .AthleteHome table tr .td_finishtime {
          grid-area: finishtime;
          text-align: right;
          padding-right: 40px; }
          .AthleteHome table tr .td_finishtime:before {
            content: "Finish Time \2022   "; }
        .AthleteHome table tr .td_location {
          grid-area: location;
          text-align: left; }
        .AthleteHome table tr .td_date {
          grid-area: date;
          text-align: right; } }
    .AthleteHome table tr:hover td {
      background-color: #e8e8e0;
      cursor: pointer; }
    .AthleteHome table tr:hover td:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; }
    .AthleteHome table tr:hover td:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px; }
    .AthleteHome table tr .rightAligned {
      text-align: right; }
    .AthleteHome table tr .middleAligned {
      text-align: center; }
    .AthleteHome table tr .padRight {
      padding-right: 40px; }
    .AthleteHome table td + td + td, .AthleteHome table th + th + th {
      padding-left: 8px; }
    .AthleteHome table td {
      font-weight: 400;
      color: #2c2f3c; }
      .AthleteHome table td.name {
        font-weight: 600; }

.ThisIsMeModalCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 15; }

.ThisIsMeModalContent {
  font-family: "Montserrat", sans-serif;
  position: fixed;
  left: 0;
  font-size: 12px;
  background-color: #fff;
  padding: 50px 50px 38px 40px;
  box-sizing: border-box;
  min-height: 150px;
  max-height: 100vh;
  overflow: auto;
  z-index: 17;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3), 0 1px 5px rgba(0, 0, 0, 0.2);
  width: calc(100vw - 40px);
  margin: 20px;
  background-position: center bottom 20px, left 0px bottom 40px;
  background-size: 100px, contain;
  background-repeat: no-repeat, no-repeat;
  transition: top 0.4s, opacity 0.2s;
  top: 110px;
  opacity: 0.2; }
  .ThisIsMeModalContent.activated {
    opacity: 1;
    top: 40px; }
  @media screen and (min-width: 801px) {
    .ThisIsMeModalContent {
      margin: 0;
      margin-left: -260px;
      width: 520px;
      left: 50%;
      border-radius: 12px; } }
  @media screen and (min-width: 801px) {
    .ThisIsMeModalContent.step_2 {
      margin-left: -402.5px;
      width: 805px; } }
  .ThisIsMeModalContent .tooltiptext {
    min-width: 33px !important;
    top: 139% !important;
    left: 65% !important; }
    .ThisIsMeModalContent .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }
  .ThisIsMeModalContent .topLabel {
    position: absolute;
    top: 20px;
    left: 30px;
    color: #2c2f3c;
    font-weight: 400;
    font-size: 13px; }
    .ThisIsMeModalContent .topLabel strong {
      font-weight: 600; }
    .ThisIsMeModalContent .topLabel .dot {
      font-weight: 800;
      display: inline-block;
      padding: 0 2px;
      color: #008dfb;
      margin: 0 6px 0 4px; }
    .ThisIsMeModalContent .topLabel.step2 {
      left: 2px; }
  .ThisIsMeModalContent .ClearIcon {
    cursor: pointer;
    max-width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 25px;
    z-index: 10; }
    .ThisIsMeModalContent .ClearIcon:hover .background {
      transform: scale(1); }
    .ThisIsMeModalContent .ClearIcon .background {
      transform: scale(0);
      transition: transform 200ms;
      transition-timing-function: ease-out;
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: rgba(204, 232, 254, 0.3);
      top: -6px;
      left: -5px;
      z-index: -5; }
  .ThisIsMeModalContent .Step1 {
    text-align: center; }
  .ThisIsMeModalContent h1 {
    margin: 20px 0;
    font-size: 28px; }
  .ThisIsMeModalContent p {
    font-size: 12px;
    font-weight: bold;
    margin: 20px auto;
    max-width: 30em; }
  .ThisIsMeModalContent .Label {
    display: flex;
    margin-top: 0px;
    justify-content: center;
    align-items: center; }
    .ThisIsMeModalContent .Label span {
      padding: 3px 14px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 600;
      margin: 0 10px;
      color: #2c2f3c;
      background-color: #e1f1fe; }
    .ThisIsMeModalContent .Label i {
      flex-grow: 1;
      background: #e9e9e9;
      height: 1px; }
  .ThisIsMeModalContent .control {
    position: relative; }
    .ThisIsMeModalContent .control.PasswordField .RevealButton {
      position: absolute;
      right: 74px;
      top: 29px; }
    .ThisIsMeModalContent .control .react-datepicker-wrapper {
      display: block; }
  .ThisIsMeModalContent .invalid input[type="password"], .ThisIsMeModalContent .invalid input[type="email"], .ThisIsMeModalContent .invalid input[type="text"] {
    background-color: #fcefec;
    border: 2px solid #fcefec; }
    .ThisIsMeModalContent .invalid input[type="password"]:hover, .ThisIsMeModalContent .invalid input[type="email"]:hover, .ThisIsMeModalContent .invalid input[type="text"]:hover {
      border-color: #fae2da; }
  .ThisIsMeModalContent label {
    display: block;
    margin: 20px auto;
    text-align: left;
    box-sizing: border-box;
    max-width: 320px; }
    .ThisIsMeModalContent label .control {
      display: flex;
      align-items: center;
      position: relative; }
      .ThisIsMeModalContent label .control .emailValid {
        background-color: rgba(39, 203, 101, 0.1);
        font-weight: 600; }
      .ThisIsMeModalContent label .control .isValidEmailHash {
        color: #000;
        background-color: #f6f7f7;
        border-color: #f6f7f7 !important; }
      .ThisIsMeModalContent label .control .lockIconContainer {
        width: 32px;
        text-align: right;
        position: relative;
        top: 0;
        left: 0; }
        .ThisIsMeModalContent label .control .lockIconContainer.lockShakes {
          -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
                  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          transform: translate3d(0, 0, 0);
          -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
          perspective: 1000px; }

@-webkit-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }
    .ThisIsMeModalContent label .label {
      display: block;
      color: #4a4a4a;
      font-weight: 400;
      font-size: 11px;
      margin-left: 21px;
      margin-bottom: 5px; }
    .ThisIsMeModalContent label input {
      background-color: #f1f8fd;
      border: 2px solid #f1f8fd;
      color: #000;
      display: block;
      width: 100%;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: right 10px center;
      font-size: 12px;
      font-weight: 600;
      padding: 10px 22px;
      background-size: 20px;
      border-radius: 16px; }
      @media screen and (min-width: 801px) {
        .ThisIsMeModalContent label input {
          font-size: 12px;
          padding: 11px 20px;
          background-size: 16px;
          border-radius: 21px; } }
      .ThisIsMeModalContent label input:hover {
        border-color: #ddeefd; }
      .ThisIsMeModalContent label input:focus {
        background-color: #fff !important;
        border-color: #ddeefd; }
    .ThisIsMeModalContent label.error input {
      background-color: #fcefec;
      border: 2px solid #fcefec; }
      .ThisIsMeModalContent label.error input:hover {
        border-color: #fae2da; }
    @media screen and (min-width: 801px) {
      .ThisIsMeModalContent label {
        margin: 20px auto; } }
  .ThisIsMeModalContent .buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px; }
    .ThisIsMeModalContent .buttons .yesno {
      padding: 1px 27px; }
    .ThisIsMeModalContent .buttons button, .ThisIsMeModalContent .buttons .button {
      box-sizing: border-box;
      display: block;
      background: #008dfb;
      color: #ffffff;
      font-size: 17px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
      padding: 4px 20px;
      margin: 0 10px;
      border: 2px solid #008dfb;
      font-family: inherit;
      cursor: pointer;
      border-radius: 17px; }
      .ThisIsMeModalContent .buttons button:hover, .ThisIsMeModalContent .buttons .button:hover {
        border: solid 2px rgba(0, 0, 0, 0.05);
        background-color: #0086ef; }
      .ThisIsMeModalContent .buttons button.secondary, .ThisIsMeModalContent .buttons .button.secondary {
        background-color: #fff;
        color: #008dfb;
        border-color: #cce8fe; }
        .ThisIsMeModalContent .buttons button.secondary:hover, .ThisIsMeModalContent .buttons .button.secondary:hover {
          border: solid 2px rgba(0, 141, 251, 0.5); }
      .ThisIsMeModalContent .buttons button[disabled], .ThisIsMeModalContent .buttons .button[disabled] {
        background: #e4e4e4;
        color: #ffffff;
        border: 2px solid #d6d6d6;
        cursor: default; }
  .ThisIsMeModalContent input:focus, .ThisIsMeModalContent button:focus {
    outline: none; }
  .ThisIsMeModalContent.step_2 {
    padding: 0; }
    .ThisIsMeModalContent.step_2 .Step2 {
      display: flex; }
      @media screen and (max-width: 750px) {
        .ThisIsMeModalContent.step_2 .Step2 {
          justify-content: center; }
          .ThisIsMeModalContent.step_2 .Step2 .UserCard {
            display: none; } }
      .ThisIsMeModalContent.step_2 .Step2 .UserCard {
        min-width: 355px;
        text-align: center;
        background-color: #f9f9f6;
        padding-bottom: 150px;
        background-repeat: no-repeat;
        background-position: center bottom; }
        .ThisIsMeModalContent.step_2 .Step2 .UserCard .Label {
          margin-top: 60px; }
        .ThisIsMeModalContent.step_2 .Step2 .UserCard .UserInfoTable {
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          line-height: 20px;
          color: #2c2f3c;
          position: relative;
          font-weight: normal;
          color: #2c2f3c;
          margin: 10px 30px; }
          .ThisIsMeModalContent.step_2 .Step2 .UserCard .UserInfoTable strong {
            font-weight: 600; }
          .ThisIsMeModalContent.step_2 .Step2 .UserCard .UserInfoTable .left-aligned {
            text-align: left; }
          .ThisIsMeModalContent.step_2 .Step2 .UserCard .UserInfoTable .right-aligned {
            text-align: right; }
        .ThisIsMeModalContent.step_2 .Step2 .UserCard .Flag {
          font-size: 36px; }
        .ThisIsMeModalContent.step_2 .Step2 .UserCard .Location {
          margin: 2px auto 0;
          font-size: 15px;
          font-weight: 500; }
        .ThisIsMeModalContent.step_2 .Step2 .UserCard .userAvatarContainer {
          border: 5px solid #cce8fe;
          border-radius: 90px;
          width: 90px;
          height: 90px; }
          .ThisIsMeModalContent.step_2 .Step2 .UserCard .userAvatarContainer .userAvatar {
            width: 90px; }
      .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess {
        width: 480px;
        position: relative;
        padding: 50px 0 40px; }
        .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess p {
          font-weight: 400;
          max-width: 280px; }
          .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess p a {
            color: #008dfb; }
          .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess p.strong {
            text-align: center;
            font-weight: 600; }
          .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess p.small {
            font-size: 10px; }
        .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess .Label {
          margin-bottom: 30px; }
        .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess .ClipboardButton {
          background-color: #008dfb;
          border-radius: 20px;
          padding: 2px 10px 3px;
          height: 20px;
          color: #fff;
          padding-left: 15px;
          padding-right: 15px;
          cursor: pointer;
          border: none; }
        .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess .CopyText {
          cursor: pointer; }
          .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess .CopyText.tooltip {
            display: block; }
          .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess .CopyText .tooltiptext {
            min-width: 50px !important;
            top: 44% !important;
            left: 7%; }
            .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess .CopyText .tooltiptext em {
              font-size: 11px;
              color: #fcb452;
              font-style: normal;
              font-weight: 500;
              display: block;
              white-space: nowrap; }
            .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess .CopyText .tooltiptext:after {
              display: none; }
        .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess.clipboardCopied .ClipboardButton {
          background-color: #ecf8f0;
          cursor: default;
          color: #000; }
        .ThisIsMeModalContent.step_2 .Step2 .ClaimProcess.clipboardCopied .CopyText {
          color: #6b7c88;
          cursor: no-drop; }

.FavPageHeader {
  display: flex;
  justify-content: space-between;
  margin: 30px 20px;
  font-size: 13px;
  color: #2c2f3c; }
  .FavPageHeader .itemsnum {
    margin-right: 25px; }
  .FavPageHeader a {
    color: #008dfb;
    font-weight: 600; }

.AthleteHomeRaceListPage {
  grid-gap: 20px;
  margin: 20px 15px 20px 10px;
  color: #2c2f3c;
  position: relative;
  display: grid; }
  @media screen and (min-width: 600px) {
    .AthleteHomeRaceListPage {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (min-width: 900px) {
    .AthleteHomeRaceListPage {
      grid-template-columns: repeat(3, 1fr); } }
  .AthleteHomeRaceListPage .tooltiptext {
    min-width: 33px !important;
    top: 150% !important;
    left: -10% !important; }
    .AthleteHomeRaceListPage .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }
    .AthleteHomeRaceListPage .tooltiptext:after {
      display: none; }
  .AthleteHomeRaceListPage .Card {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 #0000001a;
    padding: 20px 30px;
    position: relative;
    cursor: pointer; }
    .AthleteHomeRaceListPage .Card .OpenRaceIcon {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      opacity: 1; }
      @media screen and (min-width: 800px) {
        .AthleteHomeRaceListPage .Card .OpenRaceIcon {
          opacity: 0; } }
    .AthleteHomeRaceListPage .Card:hover {
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.2); }
      .AthleteHomeRaceListPage .Card:hover .OpenRaceIcon {
        opacity: 1; }
      .AthleteHomeRaceListPage .Card:hover .FavIconLink {
        opacity: 1; }
    .AthleteHomeRaceListPage .Card .CardHeader {
      display: grid;
      grid-template-columns: 1fr 5fr 2.8fr; }
      .AthleteHomeRaceListPage .Card .CardHeader .avatar {
        border: 3px solid #d1e6fc;
        border-radius: 100px;
        overflow: hidden;
        width: 40px;
        height: 40px;
        margin-bottom: 2px; }
        .AthleteHomeRaceListPage .Card .CardHeader .avatar img {
          max-width: 100%; }
      .AthleteHomeRaceListPage .Card .CardHeader .name {
        font-weight: 800;
        font-size: 15px;
        line-height: 1.4;
        margin: 0 0 0 15px; }
      .AthleteHomeRaceListPage .Card .CardHeader .flag {
        text-align: center;
        font-size: 20px; }
      .AthleteHomeRaceListPage .Card .CardHeader .bib-info {
        text-align: right; }
        .AthleteHomeRaceListPage .Card .CardHeader .bib-info .bib {
          margin-bottom: 8px; }
      .AthleteHomeRaceListPage .Card .CardHeader .hometown {
        margin-left: 15px; }
      .AthleteHomeRaceListPage .Card .CardHeader .hometown, .AthleteHomeRaceListPage .Card .CardHeader .time {
        display: flex;
        align-items: center;
        font-size: 13px; }
      .AthleteHomeRaceListPage .Card .CardHeader .time {
        justify-content: flex-end;
        font-weight: 600;
        font-family: "Roboto Mono","Montserrat", sans-serif;
        font-size: 15px;
        padding-bottom: 2px; }
    .AthleteHomeRaceListPage .Card .Label {
      display: flex;
      margin: 10px 0;
      justify-content: center;
      align-items: center; }
      .AthleteHomeRaceListPage .Card .Label i {
        flex-grow: 1;
        background: #e9e9e9;
        height: 1px; }
      .AthleteHomeRaceListPage .Card .Label span {
        padding: 3px 14px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 600;
        margin: 0 10px;
        background-color: #e1f1fe; }
    .AthleteHomeRaceListPage .Card dl.infotable {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 0.7fr;
      grid-gap: 3px 0; }
      .AthleteHomeRaceListPage .Card dl.infotable > * {
        white-space: nowrap; }
      .AthleteHomeRaceListPage .Card dl.infotable dt {
        font-size: 11px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end; }
      .AthleteHomeRaceListPage .Card dl.infotable dd {
        font-family: "Roboto Mono","Montserrat", sans-serif;
        padding: 0;
        margin: 0px 0 0 10px;
        font-weight: 500;
        font-size: 13px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start; }
        .AthleteHomeRaceListPage .Card dl.infotable dd.right-aligned {
          justify-content: flex-end; }
        .AthleteHomeRaceListPage .Card dl.infotable dd.splitsResult {
          padding-left: 10px; }
        .AthleteHomeRaceListPage .Card dl.infotable dd.plus {
          color: #28bb28; }
        .AthleteHomeRaceListPage .Card dl.infotable dd.minus {
          color: #ff3800; }
    .AthleteHomeRaceListPage .Card .categories {
      display: flex;
      justify-content: center;
      margin-bottom: 16px; }
      .AthleteHomeRaceListPage .Card .categories button {
        margin: 0 5px;
        padding: 3px 5px;
        color: inherit;
        font-weight: 600;
        font-family: inherit;
        border: 1px solid #aeaeae;
        border-radius: 2px;
        cursor: pointer; }
        .AthleteHomeRaceListPage .Card .categories button:hover {
          border: 1px solid #e9e9e9; }
        .AthleteHomeRaceListPage .Card .categories button.active {
          border-color: #ff4500; }
        .AthleteHomeRaceListPage .Card .categories button:focus {
          outline: none; }
    .AthleteHomeRaceListPage .Card h3 {
      margin: 15px 0 6px;
      font-weight: 600;
      font-size: 12px; }
    .AthleteHomeRaceListPage .Card .infoline {
      display: flex;
      justify-content: space-between;
      font-size: 11px; }
      .AthleteHomeRaceListPage .Card .infoline :last-child {
        font-size: 13px;
        font-family: "Roboto Mono","Montserrat", sans-serif;
        font-weight: 500;
        position: relative;
        top: -3px; }
      .AthleteHomeRaceListPage .Card .infoline .dot {
        top: 0px; }
    .AthleteHomeRaceListPage .Card .FavIconLink {
      opacity: 1;
      position: absolute;
      top: 7px;
      left: 7px; }
      @media screen and (min-width: 800px) {
        .AthleteHomeRaceListPage .Card .FavIconLink {
          opacity: 0; } }
      .AthleteHomeRaceListPage .Card .FavIconLink.isFavorite {
        opacity: 1; }
        .AthleteHomeRaceListPage .Card .FavIconLink.isFavorite .IconSwappable .icon {
          display: block !important; }
        .AthleteHomeRaceListPage .Card .FavIconLink.isFavorite .IconSwappable .hover {
          display: none !important; }
    .AthleteHomeRaceListPage .Card .FavIconLink, .AthleteHomeRaceListPage .Card .FavIconLink .IconSwappable, .AthleteHomeRaceListPage .Card .FavIconLink .IconSwappable > * {
      min-width: 16px;
      min-height: 16px; }

.userAvatarContainer {
  display: block;
  border-radius: 50%;
  border: 4px solid #cce8fe;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  width: 90px;
  height: 90px; }
  .userAvatarContainer .tooltiptext {
    min-width: 90px !important;
    top: 115% !important;
    left: 7% !important;
    font-size: 12px !important; }
    .userAvatarContainer .tooltiptext em {
      font-size: 12px;
      font-weight: 500;
      display: block;
      white-space: nowrap;
      color: #71e1f4;
      font-style: normal; }
    .userAvatarContainer .tooltiptext:after {
      display: none; }
  .userAvatarContainer .userAvatar {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    cursor: pointer; }
  .userAvatarContainer .UserAvatarHoverMessage {
    position: absolute;
    bottom: -40px;
    font-size: 11px;
    color: #2c2f3c;
    text-align: center;
    width: 122px;
    left: -20px; }
  .userAvatarContainer.uploadedImage {
    padding: 4px; }
    .userAvatarContainer.uploadedImage .userAvatar {
      width: 74px;
      height: 74px; }

.AccountProfile {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .AccountProfile .MainAthleteProfileBlock {
    background-position: bottom -19px center;
    background-size: 1700px;
    background-repeat: no-repeat;
    background-color: #F3F3EE; }
    .AccountProfile .MainAthleteProfileBlock .ProfileCard {
      margin: 40px auto;
      max-width: 800px;
      min-height: 200px;
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      background-color: #fff; }
      @media screen and (min-width: 801px) {
        .AccountProfile .MainAthleteProfileBlock .ProfileCard {
          display: flex; } }
      .AccountProfile .MainAthleteProfileBlock .ProfileCard > * {
        flex-grow: 1; }
      .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard {
        box-sizing: border-box;
        width: 400px;
        max-width: 400px;
        min-height: 606px;
        border-radius: 12px 0px 0px 12px;
        text-align: center;
        background-color: #ffffff;
        padding-bottom: 150px;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 400px;
        display: none; }
        @media screen and (min-width: 801px) {
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard {
            display: block; } }
        .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard h1 {
          color: #008dfb;
          margin: 14px 0 30px;
          font-size: 24px; }
          @media screen and (max-width: 600px) {
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard h1 {
              margin-bottom: 5px;
              font-size: 22px; } }
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard h1 .athleteName .tooltiptext {
            min-width: 50px !important;
            margin-left: -56px !important;
            top: 540% !important;
            line-height: 15px;
            font-size: 12px;
            padding: 5px 10px;
            font-weight: normal; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard h1 .athleteName .tooltiptext em {
              color: #71e1f4;
              font-style: normal;
              display: block;
              white-space: nowrap; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard h1 .athleteName .tooltiptext:after {
              display: none; }
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard h1 span:hover {
            background-color: rgba(204, 232, 254, 0.3);
            color: #2c2f3c;
            cursor: default;
            padding-left: 16px;
            padding-right: 16px;
            border-radius: 18px; }
        .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard .Label {
          margin-top: 60px; }
        .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard .UserInfoTable {
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          line-height: 20px;
          color: #2c2f3c;
          position: relative;
          font-weight: normal;
          color: #2c2f3c;
          margin: 16px 30px; }
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard .UserInfoTable strong {
            font-weight: 600; }
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard .UserInfoTable .left-aligned {
            text-align: left; }
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard .UserInfoTable .right-aligned {
            text-align: right; }
        .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard .Flag {
          font-size: 36px; }
        .AccountProfile .MainAthleteProfileBlock .ProfileCard .UserCard .Location {
          margin: 2px auto 0;
          font-size: 15px;
          font-weight: 500; }
      .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard {
        width: 400px;
        max-width: 400px;
        padding: 30px 10px; }
        .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ToggleButton {
          margin: 0 auto 30px; }
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ToggleButton > div {
            text-transform: none;
            padding: 3px 10px; }
        .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent {
          max-width: 320px;
          margin: 0 auto; }
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .FormFooter, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .FormFooter {
            max-width: 320px; }
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control {
            position: relative;
            max-width: 320px; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .label, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .label {
              display: flex;
              justify-content: space-between;
              color: #4a4a4a;
              font-weight: 400;
              font-size: 11px;
              margin: 0 20px 5px 21px; }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .label.with-right-label, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .label.with-right-label {
                display: flex;
                justify-content: space-between; }
                .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .label.with-right-label a, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .label.with-right-label a {
                  color: #2c2f3c; }
                  .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .label.with-right-label a:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .label.with-right-label a:hover {
                    color: #008dfb; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control input, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control input {
              background-color: #f1f8fd;
              border: 2px solid #f1f8fd;
              color: #000;
              display: block;
              width: 100%;
              box-sizing: border-box;
              background-repeat: no-repeat;
              background-position: right 10px center;
              font-size: 12px;
              font-weight: 600;
              padding: 10px 22px;
              background-size: 20px;
              border-radius: 16px; }
              @media screen and (min-width: 801px) {
                .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control input, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control input {
                  font-size: 12px;
                  padding: 11px 20px;
                  background-size: 16px;
                  border-radius: 21px; } }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control input:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control input:hover {
                border-color: rgba(204, 232, 254, 0.75); }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control input:focus, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control input:focus {
                background-color: #fff !important;
                border-color: #ddeefd;
                outline: none; }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control input[disabled], .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control input[disabled] {
                background: #f6f7f7;
                color: #000000;
                border: 2px solid #f6f7f7;
                cursor: default; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.error input, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.error input {
              background-color: #fcefec;
              border: 2px solid #fcefec; }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.error input:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.error input:hover {
                border-color: #fae2da; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .IconSwappableValid, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .IconSwappableValid {
              position: absolute;
              bottom: 8px;
              right: 8px;
              width: 26px;
              height: 26px; }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .IconSwappableValid .icon, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .IconSwappableValid .icon {
                display: block; }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .IconSwappableValid .valid, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .IconSwappableValid .valid {
                display: none; }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .IconSwappableValid .invalid, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .IconSwappableValid .invalid {
                display: none; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.invalid input[type="password"], .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.invalid input[type="email"], .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.invalid input[type="text"], .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.invalid input[type="password"], .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.invalid input[type="email"], .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.invalid input[type="text"] {
              background-color: #ffe1d9;
              border-color: #ffe1d9; }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.invalid input[type="password"]:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.invalid input[type="email"]:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.invalid input[type="text"]:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.invalid input[type="password"]:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.invalid input[type="email"]:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.invalid input[type="text"]:hover {
                border-color: #f9e2d9; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.invalid .IconSwappableValid .icon, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.invalid .IconSwappableValid .icon {
              display: none !important; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.invalid .IconSwappableValid .valid, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.invalid .IconSwappableValid .valid {
              display: none !important; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.invalid .IconSwappableValid .invalid, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.invalid .IconSwappableValid .invalid {
              display: block; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.valid input[type="password"], .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.valid input[type="email"], .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.valid input[type="text"], .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.valid input[type="password"], .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.valid input[type="email"], .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.valid input[type="text"] {
              background-color: #f0faf3;
              border-color: #f0faf3; }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.valid input[type="password"]:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.valid input[type="email"]:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.valid input[type="text"]:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.valid input[type="password"]:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.valid input[type="email"]:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.valid input[type="text"]:hover {
                border-color: #daf4e0; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.valid .IconSwappableValid .icon, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.valid .IconSwappableValid .icon {
              display: none !important; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.valid .IconSwappableValid .valid, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.valid .IconSwappableValid .valid {
              display: block; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.valid .IconSwappableValid .invalid, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.valid .IconSwappableValid .invalid {
              display: none !important; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .HometownState, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .HometownState {
              position: absolute;
              bottom: 13px;
              right: 35px;
              font-size: 12px;
              font-weight: 600;
              cursor: pointer;
              -webkit-user-select: none;
                  -ms-user-select: none;
                      user-select: none; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .StatesListAnchor, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .StatesListAnchor {
              position: relative; }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .StatesListAnchor .StatesList, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .StatesListAnchor .StatesList {
                display: none; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open input, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open input {
              border-radius: 21px 21px 0 0;
              border-color: #ddeefd;
              background-color: #fff; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open .StatesList.DropdownList, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open .StatesList.DropdownList {
              display: flex;
              margin-top: 15px;
              max-height: 250px;
              position: absolute;
              background: white;
              z-index: 1;
              width: 100%;
              border: 2px solid #ddeefd;
              left: 0px;
              top: -18px;
              border-top: none;
              box-sizing: border-box;
              border-radius: 0 0 18px 18px;
              padding: 4px 10px 10px; }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open .StatesList.DropdownList .DropdownListInner, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open .StatesList.DropdownList .DropdownListInner {
                max-height: 300px;
                overflow: auto;
                flex-grow: 1; }
                .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open .StatesList.DropdownList .DropdownListInner::-webkit-scrollbar-track, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open .StatesList.DropdownList .DropdownListInner::-webkit-scrollbar-track {
                  background: transparent; }
                .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open .StatesList.DropdownList .DropdownListInner::-webkit-scrollbar, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open .StatesList.DropdownList .DropdownListInner::-webkit-scrollbar {
                  width: 6px; }
                .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open .StatesList.DropdownList .DropdownListInner::-webkit-scrollbar-thumb, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open .StatesList.DropdownList .DropdownListInner::-webkit-scrollbar-thumb {
                  width: 6px;
                  border-radius: 3px;
                  background-color: #008dfb; }
                .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open .StatesList.DropdownList .DropdownListInner::-webkit-scrollbar-thumb:window-inactive, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open .StatesList.DropdownList .DropdownListInner::-webkit-scrollbar-thumb:window-inactive {
                  background: #6b7c88; }
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open .StatesList.DropdownList .DropdownOption, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open .StatesList.DropdownList .DropdownOption {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                border-radius: 18px;
                padding: 3px 20px 2px 16px; }
                .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open .StatesList.DropdownList .DropdownOption.selected, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open .StatesList.DropdownList .DropdownOption.selected {
                  font-weight: 600; }
                .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open .StatesList.DropdownList .DropdownOption:hover, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open .StatesList.DropdownList .DropdownOption:hover {
                  background-color: #f1f8fd; }
                .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open .StatesList.DropdownList .DropdownOption .flag, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open .StatesList.DropdownList .DropdownOption .flag {
                  padding-right: 7px;
                  position: relative;
                  top: -2px; }
                .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control.open .StatesList.DropdownList .DropdownOption .title, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control.open .StatesList.DropdownList .DropdownOption .title {
                  flex-grow: 1; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control .RevealButton, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control .RevealButton {
              background-color: none;
              position: absolute;
              right: 0;
              bottom: 0px; }
            @media screen and (min-width: 801px) {
              .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .control, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .control {
                margin: 20px auto; } }
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAthleteContent .change-password-link, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAdminContent .change-password-link {
            margin-left: 21px;
            color: #008dfb;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer; }
        .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard ul, .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard li {
          margin: 0;
          padding: 0;
          list-style: none; }
        .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAvatarContent .PersonalAvatar {
          display: flex;
          max-width: 270px;
          margin: 0 auto; }
        .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAvatarContent .AvatarList {
          display: grid;
          grid-template-columns: repeat(4, 60px);
          grid-gap: 10px;
          margin: 0 auto 30px;
          justify-content: center; }
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAvatarContent .AvatarList .AvatarItem {
            width: 60px;
            cursor: pointer;
            border: 4px solid #f0f1f3;
            border-radius: 60px;
            overflow: hidden;
            box-sizing: border-box;
            width: 60px;
            height: 60px; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAvatarContent .AvatarList .AvatarItem:hover {
              border: 4px solid #d1e6fc; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAvatarContent .AvatarList .AvatarItem img {
              width: 52px;
              height: 52px; }
          .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAvatarContent .AvatarList.disabled {
            display: none; }
            .AccountProfile .MainAthleteProfileBlock .ProfileCard .ProfileManageCard .ManageAvatarContent .AvatarList.disabled .AvatarItem {
              cursor: default; }
      .AccountProfile .MainAthleteProfileBlock .ProfileCard .DatePreview {
        margin: -14px 23px 2px;
        font-size: 12px;
        font-weight: 600;
        color: orange; }

.CountryDropdown {
  background-color: #f0faf3;
  border: 2px solid #f0faf3;
  padding: 8px 20px 9px;
  border-radius: 18px;
  color: #000;
  font-size: 12px; }
  .CountryDropdown:hover {
    border-color: #ddeefd; }
  .CountryDropdown.invalid {
    background-color: #ffe1d9;
    border-color: #ffe1d9; }
  .CountryDropdown.open {
    border-color: #ddeefd;
    border-radius: 18px 18px 0 0;
    background-color: #fff; }
    .CountryDropdown.open .DropdownList {
      display: flex; }
  .CountryDropdown .DropdownValue {
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative; }
    .CountryDropdown .DropdownValue .flag {
      position: absolute;
      right: 0;
      top: -5px;
      font-size: 16px; }
  .CountryDropdown .DropdownList {
    display: none;
    margin-top: 15px;
    max-height: 250px;
    position: absolute;
    background: white;
    z-index: 1;
    width: 100%;
    border: 2px solid #ddeefd;
    left: 0px;
    top: 38px;
    border-top: none;
    box-sizing: border-box;
    border-radius: 0 0 18px 18px;
    padding: 4px 10px 10px; }
    .CountryDropdown .DropdownList .DropdownListInner {
      max-height: 300px;
      overflow: auto; }
      .CountryDropdown .DropdownList .DropdownListInner::-webkit-scrollbar-track {
        background: transparent; }
      .CountryDropdown .DropdownList .DropdownListInner::-webkit-scrollbar {
        width: 6px; }
      .CountryDropdown .DropdownList .DropdownListInner::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 3px;
        background-color: #008dfb; }
      .CountryDropdown .DropdownList .DropdownListInner::-webkit-scrollbar-thumb:window-inactive {
        background: #6b7c88; }
    .CountryDropdown .DropdownList .DropdownOption {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 18px;
      padding: 3px 20px 2px 16px; }
      .CountryDropdown .DropdownList .DropdownOption.selected {
        font-weight: 600; }
      .CountryDropdown .DropdownList .DropdownOption:hover {
        background-color: #f1f8fd; }
      .CountryDropdown .DropdownList .DropdownOption .flag {
        padding-right: 7px;
        position: relative;
        top: -2px; }
      .CountryDropdown .DropdownList .DropdownOption .title {
        flex-grow: 1; }

.ManageAvatarContent .ProfileImage .DropzoneWrapper {
  position: relative;
  cursor: pointer;
  margin: -20px auto 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100px; }
  .ManageAvatarContent .ProfileImage .DropzoneWrapper .AvatarMessage {
    display: none;
    position: absolute;
    width: 150px;
    height: 20px;
    top: 0;
    left: 0;
    text-align: center;
    font-size: 12px;
    color: #2c2f3c; }
  .ManageAvatarContent .ProfileImage .DropzoneWrapper .DeleteAvatarButton {
    position: absolute;
    top: 20px;
    left: 60px;
    width: 13px;
    height: 13px;
    border-radius: 13px;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #cce8fe;
    z-index: 5; }
  .ManageAvatarContent .ProfileImage .DropzoneWrapper div.dropzone {
    position: relative;
    left: 65px;
    top: 7px;
    outline: none;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    padding: 3px 9px;
    border-radius: 15px;
    color: #008dfb; }
    .ManageAvatarContent .ProfileImage .DropzoneWrapper div.dropzone:hover {
      background-color: #f1f7fd;
      color: #2c2f3c; }
    .ManageAvatarContent .ProfileImage .DropzoneWrapper div.dropzone .FileSelectButton {
      font-size: 12px;
      font-weight: 600; }
  .ManageAvatarContent .ProfileImage .DropzoneWrapper .CropperWrapper {
    position: absolute;
    top: 50px;
    left: 0px;
    width: 200px;
    height: 250px;
    z-index: 1; }
    .ManageAvatarContent .ProfileImage .DropzoneWrapper .CropperWrapper .buttons {
      display: flex; }
      .ManageAvatarContent .ProfileImage .DropzoneWrapper .CropperWrapper .buttons button {
        border: 2px solid #cce8fe;
        border-radius: 20px;
        margin: 20px 0 0 0;
        font-size: 15px;
        font-weight: 600;
        padding: 3px 23px 5px;
        color: #008dfb;
        cursor: pointer; }
        .ManageAvatarContent .ProfileImage .DropzoneWrapper .CropperWrapper .buttons button:not(:last-child) {
          margin-right: 10px; }
        .ManageAvatarContent .ProfileImage .DropzoneWrapper .CropperWrapper .buttons button.primary {
          background-color: #008dfb;
          border-color: #008dfb;
          color: #fff; }
        .ManageAvatarContent .ProfileImage .DropzoneWrapper .CropperWrapper .buttons button:focus {
          outline: none; }

.ManageAvatarContent .ProfileImage .AvatarIcon {
  position: absolute;
  left: 0;
  top: 25px;
  border: 3px solid #cce8fe;
  outline: none;
  width: 64px;
  height: 64px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 50px; }
  .ManageAvatarContent .ProfileImage .AvatarIcon .iconDefault, .ManageAvatarContent .ProfileImage .AvatarIcon .iconHover {
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-position: center 15px; }
  .ManageAvatarContent .ProfileImage .AvatarIcon .iconDefault {
    display: block; }
  .ManageAvatarContent .ProfileImage .AvatarIcon .iconHover {
    display: none; }
  .ManageAvatarContent .ProfileImage .AvatarIcon .AvatarImage {
    width: 100%;
    height: 100%;
    border-radius: 50%; }

.ManageAvatarContent .ProfileImage:hover div.dropzone .AvatarIcon {
  border-color: #008dfb; }
  .ManageAvatarContent .ProfileImage:hover div.dropzone .AvatarIcon .iconDefault {
    display: none; }
  .ManageAvatarContent .ProfileImage:hover div.dropzone .AvatarIcon .iconHover {
    display: block; }

.ManageAvatarContent .ProfileImage:hover .DeleteAvatarButton {
  display: flex; }

.ManageAvatarContent .ProfileImage:hover .AvatarMessage {
  display: block; }

.AccountProfileChangePassword .control {
  max-width: 270px;
  margin: 10px auto 20px; }
  .AccountProfileChangePassword .control.error {
    color: #ff3800 !important; }
    .AccountProfileChangePassword .control.error input, .AccountProfileChangePassword .control.error .RevealButton {
      background-color: rgba(255, 56, 0, 0.1) !important; }
  .AccountProfileChangePassword .control label span {
    display: block;
    padding-left: 20px;
    font-size: 12px;
    color: #2c2f3c;
    padding-bottom: 5px; }
  .AccountProfileChangePassword .control input[type="text"], .AccountProfileChangePassword .control input[type="password"] {
    border: none;
    width: 100%;
    height: 36px;
    border-radius: 36px;
    background-color: rgba(204, 232, 254, 0.3);
    box-sizing: border-box;
    padding: 0 20px;
    font-weight: 600;
    font-size: 13px;
    line-height: 36px; }
    .AccountProfileChangePassword .control input[type="text"]:focus, .AccountProfileChangePassword .control input[type="password"]:focus {
      background-color: rgba(204, 232, 254, 0.2); }
    .AccountProfileChangePassword .control input[type="text"]:disabled, .AccountProfileChangePassword .control input[type="password"]:disabled {
      color: #2c2f3c;
      background-color: rgba(225, 229, 231, 0.3); }

.AccountProfileChangePassword .ExitButton {
  position: absolute;
  right: 50px;
  bottom: 40px;
  border: 1px solid #008dfb;
  border-radius: 100px;
  width: 60px;
  height: 60px;
  color: #008dfb;
  box-sizing: border-box;
  line-height: 60px;
  text-align: center; }
  .AccountProfileChangePassword .ExitButton:hover {
    color: #fff;
    background-color: #008dfb; }

.AccountProfileChangePassword .control.PasswordField {
  display: flex;
  align-items: flex-end; }
  .AccountProfileChangePassword .control.PasswordField label {
    flex-grow: 1; }
    .AccountProfileChangePassword .control.PasswordField label input {
      border-radius: 30px 0px 0px 30px; }
  .AccountProfileChangePassword .control.PasswordField .RevealButton {
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    padding-right: 10px;
    padding-top: 4px;
    box-sizing: border-box;
    background-color: rgba(204, 232, 254, 0.3);
    border-radius: 0px 30px 30px 0px; }
    .AccountProfileChangePassword .control.PasswordField .RevealButton:focus {
      background-color: rgba(204, 232, 254, 0.2); }

.AccountProfileChangePassword .SuccessMessage, .AccountProfileChangePassword .ErrorMessage {
  max-width: 270px;
  margin: 10px auto 20px;
  line-height: 1.35;
  font-weight: 600;
  font-size: 12px; }

.AccountProfileChangePassword .SuccessMessage {
  color: #27cb65; }

.AccountProfileChangePassword .ErrorMessage {
  color: #ff3800; }

.AccountProfileChangePassword .ChangePasswordLink {
  color: #008dfb;
  cursor: pointer; }

.AccountProfileChangePassword .FormFooter {
  max-width: 270px;
  margin: 10px auto 20px;
  display: flex;
  justify-content: center; }
  .AccountProfileChangePassword .FormFooter button, .AccountProfileChangePassword .FormFooter [role="button"] {
    border: none;
    color: #008dfb;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .AccountProfileChangePassword .FormFooter button:first-child, .AccountProfileChangePassword .FormFooter [role="button"]:first-child {
      margin-left: 0 !important; }
    .AccountProfileChangePassword .FormFooter button.back, .AccountProfileChangePassword .FormFooter [role="button"].back {
      display: flex;
      align-items: center; }
    .AccountProfileChangePassword .FormFooter button.secondary, .AccountProfileChangePassword .FormFooter button.primary, .AccountProfileChangePassword .FormFooter [role="button"].secondary, .AccountProfileChangePassword .FormFooter [role="button"].primary {
      border: 1px solid #008dfb;
      padding: 5px 25px 6px;
      border-radius: 20px;
      margin-left: 20px;
      cursor: pointer;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .AccountProfileChangePassword .FormFooter button.primary, .AccountProfileChangePassword .FormFooter [role="button"].primary {
      color: #fff;
      background-color: #008dfb; }

.AccountNav {
  margin-top: 30px; }
  .AccountNav a, .AccountNav div {
    border: 1px solid #008dfb;
    border-radius: 30px;
    padding: 3px 18px;
    margin: 3px;
    font-size: 12px;
    color: #008dfb !important; }
  .AccountNav a.active, .AccountNav a:hover {
    background-color: #008dfb;
    color: #fff !important; }
  .AccountNav a.active {
    cursor: default; }
  .AccountNav div {
    border: 1px solid gray;
    color: gray !important; }

.VerifyEmailButton {
  display: block;
  margin: 40px auto;
  border: 1px solid #008dfb;
  padding: 10px 20px;
  border: 1px solid #008dfb;
  border-radius: 30px;
  font-size: 12px;
  color: #008dfb !important;
  cursor: pointer;
  outline: none; }
  .VerifyEmailButton:hover {
    background-color: #008dfb;
    color: #fff !important; }

.AccountFavoritesPageHeader {
  display: flex;
  justify-content: space-between;
  margin: 30px 20px;
  font-size: 13px;
  color: #2c2f3c; }
  .AccountFavoritesPageHeader .itemsnum {
    margin-right: 25px; }
  .AccountFavoritesPageHeader a {
    color: #008dfb;
    font-weight: 600; }

.AccountFavoritesPageCards {
  grid-gap: 20px;
  margin: 20px 15px 20px 10px;
  color: #2c2f3c;
  position: relative;
  display: grid; }
  @media screen and (min-width: 600px) {
    .AccountFavoritesPageCards {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (min-width: 900px) {
    .AccountFavoritesPageCards {
      grid-template-columns: repeat(3, 1fr); } }
  .AccountFavoritesPageCards .tooltiptext {
    min-width: 33px !important;
    top: 150% !important;
    left: -10% !important; }
    .AccountFavoritesPageCards .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }
  .AccountFavoritesPageCards .Card {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 #0000001a;
    padding: 20px 30px;
    position: relative;
    cursor: pointer; }
    .AccountFavoritesPageCards .Card .OpenRaceIcon {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      opacity: 1; }
      @media screen and (min-width: 800px) {
        .AccountFavoritesPageCards .Card .OpenRaceIcon {
          opacity: 0; } }
    .AccountFavoritesPageCards .Card:hover {
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.2); }
      .AccountFavoritesPageCards .Card:hover .OpenRaceIcon {
        opacity: 1; }
      .AccountFavoritesPageCards .Card:hover .FavIconLink {
        opacity: 1; }
    .AccountFavoritesPageCards .Card .CardHeader {
      display: grid;
      grid-template-columns: 1fr 5fr 2.8fr; }
      .AccountFavoritesPageCards .Card .CardHeader .avatar {
        border: 3px solid #d1e6fc;
        border-radius: 100px;
        overflow: hidden;
        width: 40px;
        height: 40px;
        margin-bottom: 2px; }
        .AccountFavoritesPageCards .Card .CardHeader .avatar img {
          max-width: 100%; }
      .AccountFavoritesPageCards .Card .CardHeader .name {
        font-weight: 800;
        font-size: 15px;
        line-height: 1.4;
        margin: 0 0 0 15px; }
      .AccountFavoritesPageCards .Card .CardHeader .flag {
        text-align: center;
        font-size: 20px; }
      .AccountFavoritesPageCards .Card .CardHeader .bib-info {
        text-align: right; }
        .AccountFavoritesPageCards .Card .CardHeader .bib-info .bib {
          margin-bottom: 8px; }
      .AccountFavoritesPageCards .Card .CardHeader .hometown {
        margin-left: 15px; }
      .AccountFavoritesPageCards .Card .CardHeader .hometown, .AccountFavoritesPageCards .Card .CardHeader .time {
        display: flex;
        align-items: center;
        font-size: 13px; }
      .AccountFavoritesPageCards .Card .CardHeader .time {
        justify-content: flex-end;
        font-weight: 600;
        font-family: "Roboto Mono","Montserrat", sans-serif;
        font-size: 15px;
        padding-bottom: 2px; }
    .AccountFavoritesPageCards .Card .Label {
      display: flex;
      margin: 10px 0;
      justify-content: center;
      align-items: center; }
      .AccountFavoritesPageCards .Card .Label i {
        flex-grow: 1;
        background: #e9e9e9;
        height: 1px; }
      .AccountFavoritesPageCards .Card .Label span {
        padding: 3px 14px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 600;
        margin: 0 10px;
        background-color: #e1f1fe; }
    .AccountFavoritesPageCards .Card dl.infotable {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 0.7fr;
      grid-gap: 3px 0; }
      .AccountFavoritesPageCards .Card dl.infotable > * {
        white-space: nowrap; }
      .AccountFavoritesPageCards .Card dl.infotable dt {
        font-size: 11px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end; }
      .AccountFavoritesPageCards .Card dl.infotable dd {
        font-family: "Roboto Mono","Montserrat", sans-serif;
        padding: 0;
        margin: 0px 0 0 10px;
        font-weight: 500;
        font-size: 13px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start; }
        .AccountFavoritesPageCards .Card dl.infotable dd.right-aligned {
          justify-content: flex-end; }
        .AccountFavoritesPageCards .Card dl.infotable dd.splitsResult {
          padding-left: 10px; }
        .AccountFavoritesPageCards .Card dl.infotable dd.plus {
          color: #28bb28; }
        .AccountFavoritesPageCards .Card dl.infotable dd.minus {
          color: #ff3800; }
    .AccountFavoritesPageCards .Card .categories {
      display: flex;
      justify-content: center;
      margin-bottom: 16px; }
      .AccountFavoritesPageCards .Card .categories button {
        margin: 0 5px;
        padding: 3px 5px;
        color: inherit;
        font-weight: 600;
        font-family: inherit;
        border: 1px solid #aeaeae;
        border-radius: 2px;
        cursor: pointer; }
        .AccountFavoritesPageCards .Card .categories button:hover {
          border: 1px solid #e9e9e9; }
        .AccountFavoritesPageCards .Card .categories button.active {
          border-color: #ff4500; }
        .AccountFavoritesPageCards .Card .categories button:focus {
          outline: none; }
    .AccountFavoritesPageCards .Card h3 {
      margin: 15px 0 6px;
      font-weight: 600;
      font-size: 12px; }
    .AccountFavoritesPageCards .Card .infoline {
      display: flex;
      justify-content: space-between;
      font-size: 11px; }
      .AccountFavoritesPageCards .Card .infoline :last-child {
        font-size: 13px;
        font-family: "Roboto Mono","Montserrat", sans-serif;
        font-weight: 500;
        position: relative;
        top: -3px; }
    .AccountFavoritesPageCards .Card .FavIconLink {
      opacity: 0;
      position: absolute;
      top: 7px;
      left: 7px; }
      .AccountFavoritesPageCards .Card .FavIconLink.isFavorite {
        opacity: 1; }
        .AccountFavoritesPageCards .Card .FavIconLink.isFavorite .IconSwappable .icon {
          display: block !important; }
        .AccountFavoritesPageCards .Card .FavIconLink.isFavorite .IconSwappable .hover {
          display: none !important; }
    .AccountFavoritesPageCards .Card .FavIconLink, .AccountFavoritesPageCards .Card .FavIconLink .IconSwappable, .AccountFavoritesPageCards .Card .FavIconLink .IconSwappable > * {
      min-width: 16px;
      min-height: 16px; }

.SuperAdminOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #aeaeae;
  border-bottom: 1px solid #aeaeae;
  padding: 8px 0;
  margin: 0 20px; }
  @media screen and (min-width: 801px) {
    .SuperAdminOptions {
      border-top: none;
      border-bottom: none;
      padding: 0;
      margin: 0; } }
  .SuperAdminOptions .button {
    box-sizing: border-box;
    border: 2px solid #e8e8e0;
    background-color: transparent;
    color: #2c2f3c;
    padding: 0 15px;
    margin: 8px 4px;
    height: 26px;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    border-radius: 13px;
    cursor: pointer;
    white-space: nowrap; }
    .SuperAdminOptions .button:hover {
      background-color: #e8e8e0; }
    @media screen and (min-width: 801px) {
      .SuperAdminOptions .button {
        margin: 0 7px; } }
    .SuperAdminOptions .button.selected {
      border: 1px solid #008dfb;
      color: #fff;
      background-color: #008dfb;
      cursor: default; }
  .SuperAdminOptions .line {
    height: 1px;
    background-color: #aeaeae;
    flex-grow: 1;
    display: none; }
    @media screen and (min-width: 801px) {
      .SuperAdminOptions .line {
        display: block; } }
  .SuperAdminOptions .line + .button {
    margin-left: 24px; }
  .SuperAdminOptions .button + .line {
    margin-left: 20px; }

.SuperAdminPage {
  min-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #f3f3ee; }
  .SuperAdminPage .MainContent {
    flex-grow: 1; }
    .SuperAdminPage .MainContent .container {
      max-width: 1100px; }

.SuperAdminPageFooterBackground {
  height: 260px;
  background-position: center;
  background-repeat: no-repeat; }

.SuperAdminPageUtilities .UtilityCard {
  padding: 15px 25px 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  box-sizing: border-box;
  margin: 20px 20px 20px;
  cursor: pointer;
  min-width: 240px; }

.SuperAdminPageUtilities .Label {
  display: flex;
  margin: 20px 0;
  justify-content: center;
  align-items: center; }
  .SuperAdminPageUtilities .Label i {
    flex-grow: 1;
    background: #e9e9e9;
    height: 1px; }
  .SuperAdminPageUtilities .Label span {
    padding: 3px 14px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
    margin: 0 10px;
    color: #2c2f3c;
    background-color: #e1f1fe; }

.SuperAdminPageUtilities h1 {
  text-align: center;
  margin: 0;
  font-size: 28px;
  color: #2c2f3c; }

.SuperAdminPageUtilities p {
  text-align: center; }

.SuperAdminPageUtilities .tagline {
  font-size: 12px;
  color: #2c2f3c;
  font-weight: 600;
  margin: 20px 0; }

.SuperAdminPageUtilities .control {
  width: calc(100% - 40px);
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 320px; }
  .SuperAdminPageUtilities .control .ControlIcon {
    display: flex;
    width: 38px;
    height: 34px;
    position: absolute;
    top: 1px;
    right: 0px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #929292; }

.SuperAdminPageUtilities input[type="password"], .SuperAdminPageUtilities input[type="email"], .SuperAdminPageUtilities input[type="text"] {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin: 10px 0;
  padding: 10px;
  border-radius: 30px;
  height: 36px;
  padding-left: 20px;
  background-color: #f1f7fd;
  border: 2px solid #f1f7fd; }
  .SuperAdminPageUtilities input[type="password"]:hover, .SuperAdminPageUtilities input[type="email"]:hover, .SuperAdminPageUtilities input[type="text"]:hover {
    border-color: #ddedfd; }
  .SuperAdminPageUtilities input[type="password"]:focus, .SuperAdminPageUtilities input[type="email"]:focus, .SuperAdminPageUtilities input[type="text"]:focus {
    outline: none;
    border-color: #ddedfd !important;
    background-color: #fff !important; }
  .SuperAdminPageUtilities input[type="password"]::-webkit-input-placeholder, .SuperAdminPageUtilities input[type="email"]::-webkit-input-placeholder, .SuperAdminPageUtilities input[type="text"]::-webkit-input-placeholder {
    color: #000;
    font-weight: 600; }
  .SuperAdminPageUtilities input[type="password"]::-ms-input-placeholder, .SuperAdminPageUtilities input[type="email"]::-ms-input-placeholder, .SuperAdminPageUtilities input[type="text"]::-ms-input-placeholder {
    color: #000;
    font-weight: 600; }
  .SuperAdminPageUtilities input[type="password"]::placeholder, .SuperAdminPageUtilities input[type="email"]::placeholder, .SuperAdminPageUtilities input[type="text"]::placeholder {
    color: #000;
    font-weight: 600; }

.SuperAdminPageUtilities input[type="submit"] {
  display: block;
  /* width: 100%; */
  border: none;
  padding: 5px 40px 5px 30px;
  background: #008dfb;
  /* margin-top: 20px; */
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
  cursor: pointer;
  margin: 15px auto 30px;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-position: right 23px top 7px;
  outline: none; }

.SuperAdminPageUtilities .buttons {
  display: flex;
  justify-content: center;
  margin-top: 15px; }
  .SuperAdminPageUtilities .buttons button, .SuperAdminPageUtilities .buttons .button {
    box-sizing: border-box;
    display: block;
    background: #008dfb;
    color: #ffffff;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    padding: 1px 25px;
    margin: 0 10px;
    border: 2px solid #008dfb;
    font-family: inherit;
    cursor: pointer;
    border-radius: 17px; }
    .SuperAdminPageUtilities .buttons button:hover, .SuperAdminPageUtilities .buttons .button:hover {
      border: solid 2px rgba(0, 0, 0, 0.05);
      background-color: #0086ef; }
    .SuperAdminPageUtilities .buttons button.secondary, .SuperAdminPageUtilities .buttons .button.secondary {
      background-color: #fff;
      color: #008dfb;
      border-color: #cce8fe; }
      .SuperAdminPageUtilities .buttons button.secondary:hover, .SuperAdminPageUtilities .buttons .button.secondary:hover {
        border: solid 2px rgba(0, 141, 251, 0.5); }
    .SuperAdminPageUtilities .buttons button[disabled], .SuperAdminPageUtilities .buttons .button[disabled] {
      background: #e4e4e4;
      color: #ffffff;
      border: 2px solid #d6d6d6;
      cursor: default; }

.SuperAdminPageUtilities input:focus, .SuperAdminPageUtilities button:focus {
  outline: none; }

.SelectAthlete {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #f3f3ee; }
  .SelectAthlete .table {
    margin: 8px auto 0; }
    @media screen and (min-width: 801px) {
      .SelectAthlete .table {
        margin-top: 20px; } }

.requests-pending .AthleteListTable .table_body_wrapper {
  opacity: 0.2 !important; }

.AthleteListTable {
  position: relative; }
  .AthleteListTable .countryFlag {
    font-size: 16px;
    line-height: 13px;
    display: inline-block;
    padding-right: 10px;
    position: relative;
    top: 2px; }
  .AthleteListTable .table_body_wrapper {
    max-height: 300px;
    overflow: auto;
    transition: opacity 200ms;
    padding-right: 10px; }
    .AthleteListTable .table_body_wrapper::-webkit-scrollbar-track {
      background: transparent; }
    .AthleteListTable .table_body_wrapper::-webkit-scrollbar {
      width: 6px; }
    .AthleteListTable .table_body_wrapper::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 3px;
      background-color: #008dfb; }
    .AthleteListTable .table_body_wrapper::-webkit-scrollbar-thumb:window-inactive {
      background: #6b7c88; }
  .AthleteListTable .PhotoIconLink, .AthleteListTable .CertificateIconLink, .AthleteListTable .FavIconLink, .AthleteListTable .FavIconStatic {
    display: inline-block;
    opacity: 0;
    transition: opacity 200ms; }
    .AthleteListTable .PhotoIconLink div.IconSwappable, .AthleteListTable .CertificateIconLink div.IconSwappable, .AthleteListTable .FavIconLink div.IconSwappable, .AthleteListTable .FavIconStatic div.IconSwappable {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      min-width: 22px;
      min-height: 15px;
      position: relative; }
      .AthleteListTable .PhotoIconLink div.IconSwappable > *, .AthleteListTable .CertificateIconLink div.IconSwappable > *, .AthleteListTable .FavIconLink div.IconSwappable > *, .AthleteListTable .FavIconStatic div.IconSwappable > * {
        min-height: 15px; }
  .AthleteListTable .PhotoIconLink div.IconSwappable {
    top: 2px;
    left: 3px; }
  .AthleteListTable .PhotoIconLink:hover .tooltiptext {
    min-width: 80px;
    height: 34px;
    margin-left: -45px;
    top: 190%; }
  .AthleteListTable .CertificateIconLink {
    position: absolute;
    left: 70px;
    top: 5px; }
    .AthleteListTable .CertificateIconLink .tooltip .tooltiptext {
      min-width: 80px;
      margin-left: -86px;
      top: 150%;
      height: 32px;
      font-family: "Montserrat", sans-serif;
      font-size: 13px; }
  .AthleteListTable .FavIconStatic, .AthleteListTable .FavIconLink {
    position: absolute;
    right: 3px;
    top: 6px;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    opacity: 0; }
    @media screen and (min-width: 801px) {
      .AthleteListTable .FavIconStatic, .AthleteListTable .FavIconLink {
        opacity: 1; } }
  .AthleteListTable .FavIconStatic {
    cursor: default; }
  .AthleteListTable .FavIconLink.isFavorite {
    opacity: 1; }
    .AthleteListTable .FavIconLink.isFavorite .IconSwappable .icon {
      display: block !important; }
    .AthleteListTable .FavIconLink.isFavorite .IconSwappable .hover {
      display: none !important; }
  .AthleteListTable .FavIconLink.loading {
    opacity: 0.2 !important; }
  .AthleteListTable .FavIconLink .tooltiptext {
    min-width: 33px !important;
    top: 150% !important;
    left: -2% !important; }
    .AthleteListTable .FavIconLink .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }
    .AthleteListTable .FavIconLink .tooltiptext:after {
      display: none; }
  .AthleteListTable table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%; }
    .AthleteListTable table th, .AthleteListTable table td {
      padding: 4px 0;
      font-size: 13px; }
    .AthleteListTable table th {
      font-size: 11px;
      text-align: left;
      vertical-align: top;
      color: #2c2f3c;
      font-weight: 500;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #edede6;
      border-right: 1px solid #ddddd7;
      border-bottom: 1px solid #aeaeae;
      padding: 10px 10px 15px 10px;
      font-family: "Roboto Mono", monospace;
      letter-spacing: -.46px; }
      .AthleteListTable table th:last-child {
        border-right: none; }
    .AthleteListTable table tr.selected {
      /* SELECTED ROW */
      border-top: 1px solid #aeaeae;
      border-bottom: 1px solid #aeaeae; }
      .AthleteListTable table tr.selected td {
        border-top: 1px solid #aeaeae;
        border-bottom: 1px solid #aeaeae; }
        .AthleteListTable table tr.selected td:first-child {
          border-left: 1px solid #aeaeae;
          font-weight: 600; }
        .AthleteListTable table tr.selected td:last-child {
          border-right: 1px solid #aeaeae; }
        .AthleteListTable table tr.selected td .PhotoIconLink, .AthleteListTable table tr.selected td .CertificateIconLink, .AthleteListTable table tr.selected td .FavIconLink, .AthleteListTable table tr.selected td .FavIconStatic {
          opacity: 1; }
        .AthleteListTable table tr.selected td div.IconSwappable .icon {
          display: none !important; }
        .AthleteListTable table tr.selected td div.IconSwappable .hover {
          display: block !important; }
    .AthleteListTable table tr:hover td {
      background-color: #e8e8e0;
      border-color: #e8e8e0 !important;
      cursor: pointer; }
      .AthleteListTable table tr:hover td:first-child {
        font-weight: 400; }
      .AthleteListTable table tr:hover td.name {
        color: #008dfb; }
      .AthleteListTable table tr:hover td .PhotoIconLink, .AthleteListTable table tr:hover td .CertificateIconLink, .AthleteListTable table tr:hover td .FavIconLink, .AthleteListTable table tr:hover td .FavIconStatic {
        opacity: 1; }
    .AthleteListTable table tr td {
      position: relative;
      padding-left: 10px;
      font-weight: 400;
      color: #2c2f3c; }
      .AthleteListTable table tr td:first-child {
        padding-left: 10px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px; }
      .AthleteListTable table tr td:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px; }
      .AthleteListTable table tr td.name {
        font-weight: 600; }
      .AthleteListTable table tr td span.athleteCategory {
        border: 1px solid #aeaeae;
        margin: 2px;
        padding: 0 2px;
        border-radius: 3px;
        font-size: 10px;
        font-weight: 600; }
        .AthleteListTable table tr td span.athleteCategory.selected {
          border-color: #ff4500;
          cursor: default; }
        .AthleteListTable table tr td span.athleteCategory .tooltiptext {
          min-width: 80px;
          margin-left: -45px; }
          .AthleteListTable table tr td span.athleteCategory .tooltiptext.AG {
            min-width: 58px;
            margin-left: -35px;
            top: 190%; }
          .AthleteListTable table tr td span.athleteCategory .tooltiptext.MA {
            min-width: 40px;
            margin-left: -28px;
            top: 190%; }
    .AthleteListTable table td:nth-of-type(2) {
      font-weight: 600; }
    .AthleteListTable table td:nth-of-type(5) {
      font-weight: 600;
      font-family: "Roboto Mono", monospace; }
    .AthleteListTable table td:nth-of-type(6) {
      font-family: "Roboto Mono", monospace; }
    .AthleteListTable table td:nth-of-type(7) {
      font-family: "Roboto Mono", monospace; }
    .AthleteListTable table td:nth-of-type(8) {
      font-family: "Roboto Mono", monospace; }
    .AthleteListTable table td:nth-of-type(9) {
      font-family: "Roboto Mono", monospace; }
    .AthleteListTable table td:nth-of-type(11) {
      font-family: "Roboto Mono", monospace; }
    .AthleteListTable table td:nth-of-type(12) {
      font-weight: 600;
      font-family: "Roboto Mono", monospace; }
    .AthleteListTable table td:nth-of-type(15) {
      font-family: "Roboto Mono", monospace; }
    .AthleteListTable table td:nth-of-type(16) {
      font-family: "Roboto Mono", monospace; }
    @media screen and (max-width: 600px) {
      .AthleteListTable table, .AthleteListTable table thead, .AthleteListTable table tbody, .AthleteListTable table tr, .AthleteListTable table td, .AthleteListTable table th {
        display: block; }
      .AthleteListTable table thead {
        display: none; }
      .AthleteListTable table tr {
        padding: 10px;
        margin: 10px 0;
        border-radius: 4px;
        display: block;
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1); }
        .AthleteListTable table tr td.rightAligned {
          text-align: left; }
        .AthleteListTable table tr td:nth-of-type(1) {
          float: right;
          color: #ccc; }
        .AthleteListTable table tr td:nth-of-type(3):before {
          content: "Location: "; }
        .AthleteListTable table tr td:nth-of-type(4):before {
          content: "Bib: "; }
        .AthleteListTable table tr td:nth-of-type(5):before {
          content: "Finish Time: "; }
        .AthleteListTable table tr td:nth-of-type(6):before {
          content: "Pace: "; }
        .AthleteListTable table tr td:nth-of-type(7):before {
          content: "1st Half: "; }
        .AthleteListTable table tr td:nth-of-type(8):before {
          content: "2nd Half: "; }
        .AthleteListTable table tr td:nth-of-type(9):before {
          content: "Diff: "; }
        .AthleteListTable table tr td:nth-of-type(10):before {
          content: "Division: "; }
        .AthleteListTable table tr td:nth-of-type(11):before {
          content: "Division Place: "; }
        .AthleteListTable table tr td:nth-of-type(12):before {
          content: "Sex Place: "; }
        .AthleteListTable table tr td:nth-of-type(13):before {
          content: "Age Grade: "; } }
  .AthleteListTable .AthleteMobileCard {
    background: #fff;
    margin: 20px;
    border-radius: 6px;
    padding: 20px; }
    .AthleteListTable .AthleteMobileCard .hover {
      cursor: pointer; }
    .AthleteListTable .AthleteMobileCard .Line1 {
      display: flex;
      justify-content: space-between;
      font-size: 13px; }
      .AthleteListTable .AthleteMobileCard .Line1 .Name {
        font-weight: 600;
        flex-grow: 1;
        padding-left: 16px; }
    .AthleteListTable .AthleteMobileCard .Line2 {
      display: flex;
      margin: 3px 0 10px 0;
      padding: 10px 0 9px 10px;
      border-bottom: 1px solid #e9e9e9;
      justify-content: space-between; }
      .AthleteListTable .AthleteMobileCard .Line2 .HometownLocation {
        font-size: 13px;
        padding-left: 10px; }
      .AthleteListTable .AthleteMobileCard .Line2 .FinishTime {
        font-size: 15px;
        font-weight: bold; }
    .AthleteListTable .AthleteMobileCard dl.AthleteCardData {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: 5px;
      align-items: baseline;
      margin: 0;
      padding: 0;
      color: #2c2f3c; }
      .AthleteListTable .AthleteMobileCard dl.AthleteCardData dt, .AthleteListTable .AthleteMobileCard dl.AthleteCardData dd {
        margin: 0;
        padding: 0;
        white-space: nowrap; }
      .AthleteListTable .AthleteMobileCard dl.AthleteCardData dt {
        text-align: right;
        font-size: 11px;
        padding-right: 10px; }
      .AthleteListTable .AthleteMobileCard dl.AthleteCardData dd {
        font-weight: 600;
        font-size: 13px; }

th:hover .SorterButton .sorter_img_inactive {
  display: none; }

th:hover .SorterButton .sorter_img_inactive_hover {
  display: block; }

.SorterButton {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: flex-end; }
  .SorterButton .SorterButtonDir {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    right: 0; }
  .SorterButton .SorterButtonDir:hover {
    background-color: #f6f6f2; }
  .SorterButton img {
    display: none; }
  .SorterButton.inactive .sorter_img_inactive {
    display: block; }
  .SorterButton.inactive:hover .sorter_img_inactive {
    display: none; }
  .SorterButton.inactive:hover .sorter_img_inactive_hover {
    display: block; }
  .SorterButton.active .sorter_img_inactive_hover {
    display: none !important; }
  .SorterButton.active .sorter_img_active_down {
    display: block; }
  .SorterButton.active.up .sorter_img_active_down {
    display: none; }
  .SorterButton.active.up .sorter_img_active_up {
    display: block; }

.PaginationLine {
  margin-top: 16px;
  margin-bottom: 16px;
  justify-content: center; }
  .PaginationLine .dot {
    font-style: normal;
    color: #008dfb;
    display: inline-block;
    padding: 0 4px 0 12px;
    font-weight: 600; }
  .PaginationLine .DropdownContainer {
    display: inline-block; }
    .PaginationLine .DropdownContainer em {
      font-style: normal; }
  @media screen and (min-width: 701px) {
    .PaginationLine {
      display: flex;
      justify-content: space-between; }
      .PaginationLine .totalPages, .PaginationLine .Pagination {
        flex: 1 1; } }
  .PaginationLine strong {
    font-weight: 600; }
  .PaginationLine .totalPages {
    color: #2c2f3c;
    padding-top: 5px;
    padding-left: 15px;
    text-align: left;
    font-size: 13px;
    position: relative;
    top: -10px; }
    .PaginationLine .totalPages .tooltip {
      z-index: 7; }
      .PaginationLine .totalPages .tooltip .tooltiptext {
        min-width: 60px !important;
        margin-left: -36px !important;
        top: 138% !important; }

.Pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  margin-top: 10px; }
  @media screen and (min-width: 701px) {
    .Pagination {
      margin-top: 0; } }
  .Pagination .paginationArrow {
    padding: 3px 3px 0px;
    border-radius: 12.5px;
    cursor: pointer; }
    .Pagination .paginationArrow:hover {
      background-color: rgba(204, 232, 254, 0.3);
      color: #008dfb; }
  .Pagination .chevron, .Pagination .pagenum {
    padding: 5px 5px;
    text-align: center;
    vertical-align: middle;
    margin: 0; }
    .Pagination .chevron:not(.ellipses):hover, .Pagination .pagenum:not(.ellipses):hover {
      background-color: #e8e8e0; }
  .Pagination .chevron {
    color: #008dfb;
    top: -2px;
    cursor: pointer; }
    .Pagination .chevron.disabled {
      cursor: default;
      color: #e1e5e7; }
  .Pagination .pagenum {
    height: 22px;
    line-height: 22px;
    border-radius: 22px;
    min-width: 22px;
    font-size: 13px;
    cursor: pointer; }
    .Pagination .pagenum.current {
      color: #fff;
      background-color: #008dfb;
      cursor: default;
      margin: 0 3px; }
    .Pagination .pagenum.current:hover {
      background-color: #008dfb; }
    .Pagination .pagenum.ellipses {
      cursor: text;
      border-radius: 0; }
      .Pagination .pagenum.ellipses:hover {
        background-color: #e8e8e0; }
  .Pagination .DotsPageInput.tooltip {
    z-index: 4; }
  .Pagination .DotsPageInput form input.numinput {
    border: none;
    border-bottom: 1px solid #c4c4c4;
    padding: 2px 3px;
    width: 26px;
    display: inline-block;
    height: 27px;
    line-height: 27px;
    text-align: center;
    font-weight: 600;
    font-size: 0.9rem;
    background-color: #e8e8e0; }
    .Pagination .DotsPageInput form input.numinput:focus {
      outline: none; }
  .Pagination .DotsPageInput form input[type=submit] {
    display: none; }
  .Pagination .DotsPageInput form.pageNumInvalid input.numinput {
    background-color: #eea3a3;
    border-bottom-color: #f23d3d;
    color: #2c0000; }

.FilterFavoritesButton {
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: 8px;
  padding: 5px;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-position: center 5px;
  background-color: transparent;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .FilterFavoritesButton .LoginLink {
    display: none; }
  .FilterFavoritesButton .CreateAccountLink {
    display: none; }
  .FilterFavoritesButton .dot {
    display: none;
    color: black; }
  .FilterFavoritesButton:hover {
    background-color: #e8e8e0; }
    .FilterFavoritesButton:hover .LoginLink {
      left: 25px;
      display: block;
      position: absolute;
      top: 0px;
      line-height: 25px;
      width: 150px;
      font-size: 10px;
      color: #008dfb;
      padding-left: 15px;
      cursor: pointer; }
      .FilterFavoritesButton:hover .LoginLink:hover {
        text-decoration: underline; }
    .FilterFavoritesButton:hover .dot {
      left: 73px;
      display: block;
      position: absolute;
      top: 0px;
      line-height: 25px;
      font-size: 10px;
      padding: 0px;
      cursor: default; }
    .FilterFavoritesButton:hover .CreateAccountLink {
      left: 80px;
      display: block;
      position: absolute;
      top: 0px;
      line-height: 25px;
      width: 150px;
      font-size: 10px;
      color: #008dfb;
      z-index: 1;
      cursor: pointer; }
      .FilterFavoritesButton:hover .CreateAccountLink:hover {
        text-decoration: underline; }
  .FilterFavoritesButton.loggedOut {
    cursor: default; }

.DropdownContainer .ToggleButton {
  font-size: 12px; }
  .DropdownContainer .ToggleButton > div {
    padding: 0 8px; }
  .DropdownContainer .ToggleButton > div:first-child {
    padding-left: 12px; }
  .DropdownContainer .ToggleButton > div:last-child {
    padding-right: 12px; }

.DropdownContainer .DropdownList.open ul.AllDivisionsOption {
  margin: 0;
  padding: 0; }
  .DropdownContainer .DropdownList.open ul.AllDivisionsOption li {
    font-size: 12px;
    padding: 4px 0 12px 0;
    padding-left: 60px; }

.DropdownContainer .DropdownList.open .DivisionOptions {
  -webkit-column-count: 2;
          column-count: 2;
  padding-left: 0;
  padding-right: 20px; }
  .DropdownContainer .DropdownList.open .DivisionOptions li {
    font-size: 12px;
    padding-right: 0;
    margin-left: -10px; }
    .DropdownContainer .DropdownList.open .DivisionOptions li.disabled {
      color: gray;
      cursor: not-allowed; }
      .DropdownContainer .DropdownList.open .DivisionOptions li.disabled:hover {
        background: none; }
    .DropdownContainer .DropdownList.open .DivisionOptions li:before {
      left: 10px; }

.DropdownContainer.DropdownHometown .ToggleButton {
  margin: 0 20px; }
  .DropdownContainer.DropdownHometown .ToggleButton > div {
    padding: 2px 8px;
    text-transform: none;
    font-size: 12px; }
  .DropdownContainer.DropdownHometown .ToggleButton > div:first-child {
    padding-left: 12px; }
  .DropdownContainer.DropdownHometown .ToggleButton > div:last-child {
    padding-right: 12px; }

.DropdownContainer.DropdownHometown .DropdownList.open {
  padding: 0 0 20px 0;
  box-sizing: border-box;
  min-width: 230px; }
  @media screen and (min-width: 801px) {
    .DropdownContainer.DropdownHometown .DropdownList.open {
      max-width: 240px; } }
  .DropdownContainer.DropdownHometown .DropdownList.open .FilterInput {
    display: flex;
    align-items: center;
    margin: 5px 30px 0; }
    .DropdownContainer.DropdownHometown .DropdownList.open .FilterInput input {
      border: none;
      padding: 7px 0;
      color: #000;
      width: 100%;
      flex-grow: 1;
      font-family: "Montserrat", sans-serif; }
      .DropdownContainer.DropdownHometown .DropdownList.open .FilterInput input:focus {
        outline: none; }
    .DropdownContainer.DropdownHometown .DropdownList.open .FilterInput .ClearIcon {
      cursor: pointer;
      max-width: 10px;
      height: 10px; }
  .DropdownContainer.DropdownHometown .DropdownList.open ul.AllLocationsOption, .DropdownContainer.DropdownHometown .DropdownList.open ul.LocationOptions {
    margin: 0;
    padding: 0 0 0px 0; }
    .DropdownContainer.DropdownHometown .DropdownList.open ul.AllLocationsOption li, .DropdownContainer.DropdownHometown .DropdownList.open ul.LocationOptions li {
      position: relative;
      padding: 4px 10px 4px 30px;
      font-size: 12px;
      left: 0px;
      background-position: 9px center; }
  .DropdownContainer.DropdownHometown .DropdownList.open ul.AllLocationsOption {
    margin-top: 10px;
    margin-bottom: 5px; }
  .DropdownContainer.DropdownHometown .DropdownList.open .LocationOptions {
    padding-left: 0;
    padding-right: 20px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden; }
    .DropdownContainer.DropdownHometown .DropdownList.open .LocationOptions::-webkit-scrollbar-track {
      background: transparent; }
    .DropdownContainer.DropdownHometown .DropdownList.open .LocationOptions::-webkit-scrollbar {
      width: 6px; }
    .DropdownContainer.DropdownHometown .DropdownList.open .LocationOptions::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 3px;
      background-color: #008dfb; }
    .DropdownContainer.DropdownHometown .DropdownList.open .LocationOptions::-webkit-scrollbar-thumb:window-inactive {
      background: #6b7c88; }

.AthleteListFilters {
  display: flex;
  justify-content: center;
  color: #2c2f3c;
  flex-wrap: wrap;
  padding-top: 5px;
  position: relative; }
  .AthleteListFilters .tooltip .tooltiptext {
    min-width: 60px !important;
    margin-left: -40px !important;
    top: 102% !important;
    height: 15px;
    line-height: 15px;
    font-size: 12px; }
  .AthleteListFilters > * {
    padding: 0 10px 15px; }
    @media screen and (min-width: 801px) {
      .AthleteListFilters > * {
        padding: 0 20px 0 0; } }
    .AthleteListFilters > * .chevron {
      color: #008dfb; }
  .AthleteListFilters .ClearFiltersButton {
    padding: 8px !important;
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center 60%;
    position: absolute;
    top: -2px;
    right: -20px;
    background-color: transparent; }
    .AthleteListFilters .ClearFiltersButton:hover {
      background-color: #e8e8e0; }
    .AthleteListFilters .ClearFiltersButton.tooltip {
      z-index: 7; }
    .AthleteListFilters .ClearFiltersButton .tooltiptext {
      min-width: 80px;
      margin-left: -50px;
      top: 135%;
      font-size: 12px; }
  .AthleteListFilters em {
    font-style: normal; }

.RaceListFilters {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #aeaeae;
  border-bottom: 1px solid #aeaeae;
  padding: 8px 0;
  margin: 0 20px; }
  @media screen and (min-width: 801px) {
    .RaceListFilters {
      border-top: none;
      border-bottom: none;
      padding: 0;
      margin: 0; } }
  .RaceListFilters .tooltiptext {
    min-width: 33px !important;
    top: 160% !important;
    left: 40% !important; }
    .RaceListFilters .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }
  .RaceListFilters .button {
    box-sizing: border-box;
    border: 2px solid #e8e8e0;
    background-color: transparent;
    color: #2c2f3c;
    padding: 0 15px;
    margin: 8px 4px;
    height: 26px;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    border-radius: 13px;
    cursor: pointer;
    white-space: nowrap; }
    .RaceListFilters .button:hover {
      background-color: #e8e8e0; }
    @media screen and (min-width: 801px) {
      .RaceListFilters .button {
        margin: 0 7px; } }
    .RaceListFilters .button.selected {
      border: 1px solid #008dfb;
      color: #fff;
      background-color: #008dfb;
      cursor: default; }
  .RaceListFilters .line {
    height: 1px;
    background-color: #aeaeae;
    flex-grow: 1;
    display: none; }
    @media screen and (min-width: 801px) {
      .RaceListFilters .line {
        display: block; } }
  .RaceListFilters .line + .button {
    margin-left: 24px; }
  .RaceListFilters .button + .line {
    margin-left: 20px; }


:root {
  --mr-blue-color:#008dfb; }

body {
  background: #f8f8f5; }

input[type="text"], input[type="email"], input[type="password"] {
  font-family: inherit; }

.myCookieContainer {
  background: #2B373B !important;
  align-items: center !important; }

.myCookieContent {
  margin-left: 75px !important;
  font-size: 14px; }
  .myCookieContent a {
    color: #008dfb !important; }

.CookiesTitle {
  font-weight: bold;
  display: block; }

.numericFont {
  font-family: "Roboto Mono", monospace; }

@media screen and (max-width: 640px) {
  .myCookieContainer {
    flex-direction: column; }
  .myCookieContent {
    flex-basis: 10px !important;
    margin: 16px 75px 0 !important; } }

.myCookieButton {
  margin-right: 75px  !important;
  color: #4e503b;
  font-size: 13px; }

.container {
  max-width: 1300px;
  margin: 0 auto;
  position: relative; }
  .container.table {
    max-width: 1400px;
    padding: 0 10px; }
  .container.flex-child {
    margin: 0; }

.containerRaceLayout {
  margin: 0 auto;
  position: relative; }
  @media screen and (min-width: 1300px) {
    .containerRaceLayout {
      min-width: 1300px; } }
  .containerRaceLayout.table {
    min-width: 1300px;
    padding: 0 10px; }
  .containerRaceLayout.flex-child {
    margin: 0; }

.flexgrow {
  flex-grow: 1; }

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block; }

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #2c2f3c;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  opacity: 0;
  cursor: default;
  min-width: 0px;
  height: 0px;
  width: 0px;
  top: 0%;
  left: 0%;
  padding: 0;
  transition: opacity 1s;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 7;
  margin-left: -80px;
  /* Use half of the width (120/2 = 60), to center the tooltip */ }

.dotsPageInputTooltip {
  margin-left: -65px !important; }

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2c2f3c transparent; }

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover > .tooltiptext {
  visibility: visible;
  min-width: 120px;
  height: auto;
  width: auto;
  top: 100%;
  left: 47%;
  padding: 5px 10px;
  margin-left: -30px;
  line-height: 15px;
  font-size: 12px; }

.tooltip:hover > .tooltiptext {
  opacity: 1; }

.diffMinus {
  color: #28bb28;
  font-weight: 600; }

.diffPlus {
  color: #ff3800;
  font-weight: 600; }

.App {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  position: relative;
  min-height: 100vh;
  /* padding-bottom: 50px; */
  -webkit-tap-highlight-color: transparent; }

a, a:visited, a:hover, a:active {
  text-decoration: none;
  color: inherit; }

.details {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 15px; }
  @media screen and (max-width: 800px) {
    .details {
      font-size: 14px;
      line-height: 18px; } }
  .details dl {
    margin: 0;
    padding: 0px 0px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 15px;
    font-weight: 500; }
    .details dl:first-child {
      margin-right: 20px; }
  .details dt,
  .details dd {
    white-space: nowrap;
    font-size: 12px; }
  .details dt {
    color: #2c2f3c;
    text-align: right;
    font-weight: 400;
    font-size: 12px; }
  .details dd {
    margin: 0;
    color: #2c2f3c;
    font-weight: 400; }
    .details dd strong {
      font-size: 15px; }
    .details dd i {
      font-style: normal;
      color: #008dfb; }
      .details dd i.dot {
        display: inline-block;
        padding: 0 7px;
        font-weight: 800;
        font-size: 15px; }
    .details dd .numericFont {
      font-family: "Roboto Mono", monospace; }
    .details dd .smallFont {
      font-weight: 300;
      font-size: 12px; }
    .details dd .text-space-right {
      padding-right: 3px; }
    .details dd .text-space-left {
      padding-left: 3px; }
    .details dd .slight-bold {
      font-weight: 500; }
    .details dd .paceDiffPos {
      font-size: 11px;
      color: #28bb28; }
    .details dd .paceDiffNeg {
      font-size: 11px;
      color: #ff3800; }
    .details dd .slower {
      font-style: normal;
      font-weight: normal;
      color: #ff3800;
      font-size: 13px; }
    .details dd .faster {
      font-style: normal;
      font-weight: bold;
      color: #85e020;
      font-size: 13px; }

#HSBeaconFabButton {
  bottom: 40px !important;
  left: 50px !important;
  width: 60px !important;
  height: 60px !important; }

.headroom-wrapper {
  z-index: 2;
  /* this allows tooltips to stay on top */ }


.SignUpModalCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 15; }

.SignUpModalContent {
  font-family: "Montserrat", sans-serif;
  position: fixed;
  left: 0;
  font-size: 12px;
  background-color: #fff;
  padding: 50px 50px 38px 40px;
  box-sizing: border-box;
  min-height: 150px;
  max-height: 100vh;
  overflow: auto;
  z-index: 17;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3), 0 1px 5px rgba(0, 0, 0, 0.2);
  width: calc(100vw - 40px);
  margin: 20px;
  background-position: center bottom 20px, left 0px bottom 40px;
  background-size: 100px, contain;
  background-repeat: no-repeat, no-repeat;
  transition: top 0.4s, opacity 0.2s;
  top: 0;
  opacity: 1; }
  @media screen and (min-width: 801px) {
    .SignUpModalContent {
      top: 110px;
      margin: 0;
      margin-left: -260px;
      width: 520px;
      left: 50%;
      border-radius: 12px; } }
  .SignUpModalContent.activated {
    opacity: 1;
    top: 40px; }
  .SignUpModalContent .SignUpImage {
    height: 90px;
    margin: 0 auto; }
  @media screen and (min-width: 801px) {
    .SignUpModalContent.step_2 {
      margin-left: -402.5px;
      width: 805px; } }
  .SignUpModalContent .tooltiptext {
    min-width: 33px !important;
    top: 139% !important;
    left: 65% !important; }
    .SignUpModalContent .tooltiptext em {
      font-size: 11px;
      color: #fcb452;
      font-style: normal;
      font-weight: 500;
      display: block;
      white-space: nowrap; }
  .SignUpModalContent .topLabel {
    position: absolute;
    top: 20px;
    left: 30px;
    color: #2c2f3c;
    font-weight: 400;
    font-size: 13px; }
    .SignUpModalContent .topLabel strong {
      font-weight: 600; }
    .SignUpModalContent .topLabel .dot {
      font-weight: 800;
      display: inline-block;
      padding: 0 2px;
      color: #008dfb;
      margin: 0 6px 0 4px; }
    .SignUpModalContent .topLabel.step2 {
      left: 2px; }
  .SignUpModalContent .ClearIcon {
    cursor: pointer;
    max-width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 25px;
    z-index: 10; }
    .SignUpModalContent .ClearIcon:hover .background {
      transform: scale(1); }
    .SignUpModalContent .ClearIcon .background {
      transform: scale(0);
      transition: transform 200ms;
      transition-timing-function: ease-out;
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: rgba(204, 232, 254, 0.3);
      top: -6px;
      left: -5px;
      z-index: -5; }
  .SignUpModalContent .Step1 {
    text-align: center; }
  .SignUpModalContent h1 {
    margin: 20px 0;
    font-size: 28px; }
  .SignUpModalContent p {
    font-size: 12px;
    font-weight: bold;
    margin: 20px auto;
    max-width: 30em; }
  .SignUpModalContent .Label {
    display: flex;
    margin-top: 0px;
    justify-content: center;
    align-items: center; }
    .SignUpModalContent .Label span {
      padding: 3px 14px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 600;
      margin: 0 10px;
      color: #2c2f3c;
      background-color: #e1f1fe; }
    .SignUpModalContent .Label i {
      flex-grow: 1;
      background: #e9e9e9;
      height: 1px; }
  .SignUpModalContent .control {
    position: relative; }
    .SignUpModalContent .control.PasswordField .RevealButton {
      position: absolute;
      right: 74px;
      top: 29px; }
    .SignUpModalContent .control .react-datepicker-wrapper {
      display: block; }
  .SignUpModalContent .invalid input[type="password"], .SignUpModalContent .invalid input[type="email"], .SignUpModalContent .invalid input[type="text"] {
    background-color: #fcefec;
    border: 2px solid #fcefec; }
    .SignUpModalContent .invalid input[type="password"]:hover, .SignUpModalContent .invalid input[type="email"]:hover, .SignUpModalContent .invalid input[type="text"]:hover {
      border-color: #fae2da; }
  .SignUpModalContent label {
    display: block;
    margin: 20px auto;
    text-align: left;
    box-sizing: border-box;
    max-width: 320px; }
    .SignUpModalContent label .control {
      display: flex;
      align-items: center;
      position: relative; }
      .SignUpModalContent label .control .emailValid {
        background-color: rgba(39, 203, 101, 0.1);
        font-weight: 600; }
      .SignUpModalContent label .control .isValidEmailHash {
        color: #000;
        background-color: #f6f7f7;
        border-color: #f6f7f7 !important; }
      .SignUpModalContent label .control .lockIconContainer {
        width: 32px;
        text-align: right;
        position: relative;
        top: 0;
        left: 0; }
        .SignUpModalContent label .control .lockIconContainer.lockShakes {
          -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
                  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          transform: translate3d(0, 0, 0);
          -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
          perspective: 1000px; }

@-webkit-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }
    .SignUpModalContent label .label {
      display: block;
      color: #4a4a4a;
      font-weight: 400;
      font-size: 11px;
      margin-left: 21px;
      margin-bottom: 5px; }
    .SignUpModalContent label input {
      background-color: #f1f8fd;
      border: 2px solid #f1f8fd;
      color: #000;
      display: block;
      width: 100%;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: right 10px center;
      font-size: 12px;
      font-weight: 600;
      padding: 10px 22px;
      background-size: 20px;
      border-radius: 16px; }
      @media screen and (min-width: 801px) {
        .SignUpModalContent label input {
          font-size: 12px;
          padding: 11px 20px;
          background-size: 16px;
          border-radius: 21px; } }
      .SignUpModalContent label input:hover {
        border-color: #ddeefd; }
      .SignUpModalContent label input:focus {
        background-color: #fff !important;
        border-color: #ddeefd; }
    .SignUpModalContent label.error input {
      background-color: #fcefec;
      border: 2px solid #fcefec; }
      .SignUpModalContent label.error input:hover {
        border-color: #fae2da; }
    @media screen and (min-width: 801px) {
      .SignUpModalContent label {
        margin: 20px auto; } }
  .SignUpModalContent .buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px; }
    .SignUpModalContent .buttons .yesno {
      padding: 1px 27px; }
    .SignUpModalContent .buttons button, .SignUpModalContent .buttons .button {
      box-sizing: border-box;
      display: block;
      background: #008dfb;
      color: #ffffff;
      font-size: 17px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
      padding: 4px 20px;
      margin: 0 10px;
      border: 2px solid #008dfb;
      font-family: inherit;
      cursor: pointer;
      border-radius: 17px; }
      .SignUpModalContent .buttons button:hover, .SignUpModalContent .buttons .button:hover {
        border: solid 2px rgba(0, 0, 0, 0.05);
        background-color: #0086ef; }
      .SignUpModalContent .buttons button.secondary, .SignUpModalContent .buttons .button.secondary {
        background-color: #fff;
        color: #008dfb;
        border-color: #cce8fe; }
        .SignUpModalContent .buttons button.secondary:hover, .SignUpModalContent .buttons .button.secondary:hover {
          border: solid 2px rgba(0, 141, 251, 0.5); }
      .SignUpModalContent .buttons button[disabled], .SignUpModalContent .buttons .button[disabled] {
        background: #e4e4e4;
        color: #ffffff;
        border: 2px solid #d6d6d6;
        cursor: default; }
  .SignUpModalContent input:focus, .SignUpModalContent button:focus {
    outline: none; }
  .SignUpModalContent.step_2 {
    padding: 0; }
    .SignUpModalContent.step_2 .Step2 {
      display: flex; }
      @media screen and (max-width: 750px) {
        .SignUpModalContent.step_2 .Step2 {
          justify-content: center; }
          .SignUpModalContent.step_2 .Step2 .UserCard {
            display: none; } }
      .SignUpModalContent.step_2 .Step2 .UserCard {
        min-width: 355px;
        text-align: center;
        background-color: #f9f9f6;
        padding-bottom: 150px;
        background-repeat: no-repeat;
        background-position: center bottom; }
        .SignUpModalContent.step_2 .Step2 .UserCard .Label {
          margin-top: 60px; }
        .SignUpModalContent.step_2 .Step2 .UserCard .UserInfoTable {
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          line-height: 20px;
          color: #2c2f3c;
          position: relative;
          font-weight: normal;
          color: #2c2f3c;
          margin: 10px 30px; }
          .SignUpModalContent.step_2 .Step2 .UserCard .UserInfoTable strong {
            font-weight: 600; }
          .SignUpModalContent.step_2 .Step2 .UserCard .UserInfoTable .left-aligned {
            text-align: left; }
          .SignUpModalContent.step_2 .Step2 .UserCard .UserInfoTable .right-aligned {
            text-align: right; }
        .SignUpModalContent.step_2 .Step2 .UserCard .Flag {
          font-size: 36px; }
        .SignUpModalContent.step_2 .Step2 .UserCard .Location {
          margin: 2px auto 0;
          font-size: 15px;
          font-weight: 500; }
      .SignUpModalContent.step_2 .Step2 .ClaimProcess {
        width: 480px;
        position: relative;
        padding: 50px 0 40px; }
        .SignUpModalContent.step_2 .Step2 .ClaimProcess p {
          font-weight: 400;
          max-width: 280px; }
          .SignUpModalContent.step_2 .Step2 .ClaimProcess p a {
            color: #008dfb; }
          .SignUpModalContent.step_2 .Step2 .ClaimProcess p.strong {
            text-align: center;
            font-weight: 600; }
          .SignUpModalContent.step_2 .Step2 .ClaimProcess p.small {
            font-size: 10px; }
        .SignUpModalContent.step_2 .Step2 .ClaimProcess .Label {
          margin-bottom: 30px; }
        .SignUpModalContent.step_2 .Step2 .ClaimProcess .ClipboardButton {
          background-color: #008dfb;
          border-radius: 20px;
          padding: 2px 10px 3px;
          height: 20px;
          color: #fff;
          padding-left: 15px;
          padding-right: 15px;
          cursor: pointer;
          border: none; }
        .SignUpModalContent.step_2 .Step2 .ClaimProcess .CopyText {
          cursor: pointer; }
          .SignUpModalContent.step_2 .Step2 .ClaimProcess .CopyText.tooltip {
            display: block; }
          .SignUpModalContent.step_2 .Step2 .ClaimProcess .CopyText .tooltiptext {
            min-width: 50px !important;
            top: 44% !important;
            left: 7%; }
            .SignUpModalContent.step_2 .Step2 .ClaimProcess .CopyText .tooltiptext em {
              font-size: 11px;
              color: #fcb452;
              font-style: normal;
              font-weight: 500;
              display: block;
              white-space: nowrap; }
            .SignUpModalContent.step_2 .Step2 .ClaimProcess .CopyText .tooltiptext:after {
              display: none; }
        .SignUpModalContent.step_2 .Step2 .ClaimProcess.clipboardCopied .ClipboardButton {
          background-color: #ecf8f0;
          cursor: default;
          color: #000; }
        .SignUpModalContent.step_2 .Step2 .ClaimProcess.clipboardCopied .CopyText {
          color: #6b7c88;
          cursor: no-drop; }

