$minDesktop: 801px;

.Sidebar {
    
    .tooltip {
        .tooltiptext {
            min-width: 80px !important;
            margin-left: -48px !important;
            top: 127% !important;
            // height: 32px;
            // line-height: 15px;
            font-family: "Montserrat", sans-serif;
            font-size: 13px;
        }
    }
    
    .SidebarButton {
        position: absolute;
        left: 20px;
        top: 12px;
        // min-width: 230px;
        cursor: pointer;

       

        img {
            position: relative;
            z-index: 5;
        }


        @media screen and (max-width: 600px) {
            // top: 14px;
            // margin: 10px 0 24px 0;
        }

        .background {
            transform: scale(0);
            transition: transform 200ms;
            transition-timing-function: ease-out;
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            // background-color: #93b9ff;
            // background-color: rgba(204, 232, 254, 0.3);
            background-color: #f3f3ee;
            top: -7px;
            left: -7px;
            // z-index: 0;
        }
        &:hover {
            .background {
                transform: scale(1);
            }
        }

    }
}

.SidebarContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-family: "Montserrat", sans-serif;
    

    // height: calc(100vh - 10px);
    position: fixed;
    // top: 5px;
    // left: 0;
    z-index: 10;

    // transform: translateX(-500px);
    transform: translateX(-800px);
    transition: transform 800ms;
    transition-timing-function: ease;

    background: #fff;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);

    padding: 20px;
    box-sizing: border-box;

    left: 10px;
    border-radius: 12px;


    // height: calc(100vh - 20px);
    width: calc(100vw - 20px);
    top: 45px;

    @media screen and (min-width: $minDesktop) {
        width: 330px;
        height: calc(100vh - 20px);
        top: 10px;
        
    }
    
    &.closed {
        
    }
    &.open {
        transform: translateX(0px);
    }

    button.close {
        display: none;
        border: none;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        color: #6b7c88;
        background: #fff;
        // user-select: none;
        outline: none;
        &:hover {
            color: #008dfb;
        }
    }

    .ClearIcon {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    @media screen and (min-width: $minDesktop) {
        button.close {
            display: block;
        }
        .ClearIcon {
            display: none;
        }
    }

    .Separator {
        border-bottom: 1px solid #e9e9e9;
        margin-top: 30px;
    }

    .SidebarMyRaceLogo {
        display: block;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        // margin-left: 60px;
        padding-top: 10px;
        
        @media screen and (min-width: $minDesktop) {
            margin: 0 0 0 30px;
            justify-content: flex-start;
            
        }
    }


    .SidebarNavigation {
        text-transform: uppercase;
        overflow: hidden;
        padding-top: 20px;

        flex-grow: 1;


        a {


            font-size: 14px;
            font-weight: 600;
            color: #2c2f3c;
            text-align: left;

            
            
            // display: block;
            padding: 9px 0 7px 30px;
            position: relative;

            display: flex;
            align-items: center;

            img.LinkIcon {
                margin-right: 10px;
            }
            

            &:hover {
                color: #008dfb;
                &::after {
                    transform: translateX(10px);
                    transition: transform 300ms;
                    transition-timing-function: ease-out;
                }
            }

            &::after {
                transform: translateX(-50px);
                content: " ";
                display: block;
                position: absolute;
                bottom: 0;
                left: 20px;
                width: 30px;
                border-bottom: 1px solid #008dfb;

            }
        }

        .SidebarLinkDisabled {
            color: #008dfb;
            cursor: default;
        }

        .Separator {
            margin-top: 20px;
            margin-bottom: 20px;
        }


        
    }
    .SidebarFooter {
        min-height: 80px;
        display: flex;
        justify-content: center;
    }
    
}


.SidebarCover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 8;
}