.AthleteHomeRaceCard {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .tooltip .tooltiptext {
        min-width: 60px !important;
        top: 100% !important;
        margin-left: -30px !important;
        margin-top: 12px;

        em {
           color: #fcb452;
           font-style: normal;
           display: block;
           white-space: nowrap;
       }
    }

    .PrevButton, .NextButton {

        padding-top: 60px;

        .tooltip .tooltiptext {
            margin-left: -48px !important;
            margin-top: 12px;

            em {
               color: #71e1f4;
               font-style: normal;
               display: block;
               white-space: nowrap;
           }
        }

        .nextPage, .prevPage {
            // position: absolute;
            // top: 160px;
            padding: 5px 4px 1px 5px;
            box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
            background-color: #fff;
            // padding: 5px 4px 1px 5px;
            border-radius: 50%;
            cursor: pointer;
            // z-index: 5;

            &.disabled {
                opacity: 0;
                cursor: default;
                img:hover {
                    left: 0;
                }
            }
            
            img {
                position: relative;
                left: 0;
                transition: left 80ms;
            }
        }
        .nextPage {
            // right: -20px;

      

            &:hover {
                img {
                    left: 5px;
                }
            }
    
            .tooltiptext {
                margin-left: -38px !important;
            }
            
        }
        .prevPage {
            // left: -20px;



            img {
                transform: rotate(180deg);
            }
            &:hover {
                img {
                    left: -5px;
                }
            }
    
            .tooltiptext {
                margin-left: -50px !important;
                margin-top: 12px;
            }
        }
        
    }

    .EventCard {
        padding: 15px 25px 20px;
        border-radius: 6px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        box-sizing: border-box;
        margin: 20px 20px 110px;
        cursor: pointer;
        
        min-width: 240px;

        @media screen and (min-width: 660px) {
            width: 355px;
        }

        .OpenRaceIcon {
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 20px;
            opacity: 0;
        }

        .FavIconLink {
            // display: none;
            opacity: 0;
            position: absolute;
            top: 7px;
            left: 7px;

            &.isFavorite {
                opacity: 1;
                .IconSwappable {
                    .icon { display: block !important; }
                    .hover { display: none !important; }
                }
            }
        }
        .FavIconLink, .FavIconLink .IconSwappable, .FavIconLink .IconSwappable>* {
            
            min-width: 16px;
            min-height: 16px;
        }



        &:hover {
            box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.2);


            .OpenRaceIcon {
                opacity: 1;
            }

            .FavIconLink {
                opacity: 1;
            }
        }

        .EventImage {
            margin-bottom: 10px;
            height: 25px;

            img {
                display: block;
                margin: 0 auto;
                max-height: 100%;
            }
        }
        
        .EventTitle {
            font-weight: 600;
            text-align: center;
            margin-bottom: 10px;
        }

        .EventLocation {
            text-align: center;
            font-size: 13px;
            color: #2c2f3c;

            .Flag {
                display: inline-block;
                font-size: 15px;
                position: relative;
                left: -5px;
                top: 2px;
            }
        }
        
        .EventInfo {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(4, 1fr);
            border-top: 1px solid #e9e9e9;
            padding-top: 15px;
            margin-bottom: 0;

            dt {
                font-weight: 400;
                color: #2c2f3c;
                font-size: 11px;
                padding: 0;
                // margin: 0;
                margin: 1px 0px 0px 0px;
                text-align: right;
            }

            dd {
                text-align: left;
                padding: 0;
                margin: 0;
                font-size: 13px;
                font-weight: 600;
            }
            
        }
        
        
    }
    
}