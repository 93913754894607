$minDesktop: 801px;

.IntervalAnalysisBib {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .tooltiptext {
      min-width: 33px !important;
      top: 40% !important;
      // margin-left: -65px !important;
      // left: 287% !important;
      z-index: 8 !important;
      // line-height: 15px;

      
      // padding: 5px 10px;
      // height: 32px !important;
      // line-height: 15px;
      // font-family: "Montserrat", sans-serif;
      // font-size: 13px;
      
      em {
          font-size: 11px;
          
          color: #fcb452;
          font-style: normal;
          font-weight: 500;
          display: block;
          white-space: nowrap;
      }

      // &:after{
      //     display: none;
      // }
      
  }

    .details {
      flex-grow: 1;
      align-items: center;


      dt {
        font-size: 11px;
        padding: 0px 0px 7px 0px;

        @media screen and (min-width: $minDesktop) {
          font-size: 12px;
          padding: 0px;
        }
      }

      dd {
        font-size: 14px;
        padding: 0px 0px 7px 0px;



        .SegmentNavigation.IntervalControls {
          top: -3px;
          margin-bottom: -5px;
        }
        
        @media screen and (min-width: $minDesktop) {
          font-size: 15px;
          .SegmentNavigation.IntervalControls {
            // top: 0;
          }
          
        }
      }
    }

    h6 {
      display: grid;
      grid-template-columns: 148px 2fr;
      grid-column-gap: 15px;
      font-weight: 600;
      font-size: 12px;
      color: #2c2f3c;
      align-items: center;
      margin: 7px 0 8px;
      span {
        text-align: right;
      }
      span:last-child {
        height: 1px;
        background-color: #e9e9e9;
      }
    }

    dd {
      position: relative;
    }
    
    .InfoModal {
      top: -3px;
      right: -20px;
      position: absolute;

      // top: 4px;
      // left: 5px;
      height: 15px;
      
    }


    .splitsResult {
      padding-left: 10px;
      // padding-bottom: 8px;
    }

    .plus {
      color: #28bb28;

    }

    .minus {
      color: #ff3800;

    }

    .IntervalAnalysisFooter {
      // border-top: 1px solid #aeaeae;
      border-top: 1px solid #e9e9e9;
      padding: 17px 5px 10px 5px;
      position: relative;
      // top: 32px;
      top: 22px;
      display: flex;
      justify-content: space-between;
    }
  }