.OpenButton {
    width: 20px;
    height: 54px;
    background-color: #fff;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px    ;
    position: fixed;
    bottom: 320px;
    left: 0;
    transform: translateX(0px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 5;
    transition: transform 800ms;

    &.hidden {
        // left: -1200px;
        transform: translateX(-800px);
    }

    &:hover {
        background-color: #113b76;
    }
}

.MapControls {
    position: fixed;
    left: 15px;
    bottom: 45px;
    z-index: 7;
    background-color: #fff;
    // width: 280px;
    display: flex;
    font-size: 13px;
    padding-bottom: 10px;
    // border-top: 1px solid #dedede;
    // border-right: 1px solid #dedede;
    // border-bottom: 1px solid #dedede;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
    transform: translateX(0px);
    transition: transform 800ms;
    border-radius: 12px;

    &.hidden {
        transform: translateX(-1000px);
    }


    h3 {
        margin: 0;
        font-size: 13px;
        font-weight: 600;
        padding: 20px 0 30px 0;
        text-transform: uppercase;
    }
    .Legend {
        margin-right: 20px;
        color: #2c2f3c;

        .LegendSeparator {
            position: absolute;
            border-bottom: 1px solid #e9e9e9;
            top: 50px;
            left: 10px;
            right: 10px;

        }
        h3 {
            padding-left: 70px;

        }
        dl {
            display: grid;
            grid-template-columns: 70px 1fr;
            margin: 0;
            max-width: 170px;

            dt {
                margin: 0 0 20px 0;
                display: flex;
                justify-content: center;
                align-items: center;

                img {

                }
            }
            dd {
                margin: 0;

            }
        }
    }
    .Pitch {
        margin-right: 30px;
        ul {
            display: block;
            padding: 0;
            margin: 0;

            li {
                display: block;
                font-weight: 600;
                color: #909fa9;
                
                label {
                    cursor: pointer;
                }
                input[type="radio"] {
                    cursor: pointer;
                    &:checked {
                        cursor: default;
                        &+label {
                            cursor: default;
                            color: #2c2f3c;

                            
                        }
                    }

                }
                label {
                    display: inline-block;
                    margin-left: 8px;
                    margin-bottom: 16px;
                }
            }
        }
    }
    .hideButton {
        position: absolute;
        bottom: 10px;
        right: 15px;
        cursor: pointer;
        color: #6b7c88;

        &:hover {
            color: #008dfb;
        }
    }

    
}

.RadioButtonControl {
    position: relative;

    .radiobtn {
        position: absolute;
        top: 2px;
        left: 5px;
        display: none;

        &.unselected { display: block; }
    }

    input[type=radio] {
        display: none;

        &:checked + label {
            .unselected { display: none; }
            .unselected_hover { display: none; }
            .selected { display: block; }
        }
    }
    label {
        padding: 2px 2px 2px 20px;

        &:hover {
            .unselected { display: none; }
            .unselected_hover { display: block; }
            .selected { display: none; }

            color: #6b7c88;
            
        }
    }
}