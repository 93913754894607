// .black-background {
//     color: #fff;
//     background: #008dfb;
//     height: 120px;
// }

.AccountProfileChangePassword {
    
    .control {
        max-width: 270px;
        // height: 36px;
        margin: 10px auto 20px;

        &.error {
            color: #ff3800 !important;
            input, .RevealButton {
                background-color: rgba(255,56,0,0.1) !important;
            }
        }
        
        label {
            span {
                display: block;
                padding-left: 20px;
                font-size: 12px;
                color: #2c2f3c;
                padding-bottom:5px;
            }
        }

        input[type="text"], input[type="password"] {
            border: none;
            width: 100%;
            height: 36px;
            border-radius: 36px;
            background-color: rgba(204, 232, 254, 0.3);
            box-sizing: border-box;
            padding: 0 20px;
            font-weight: 600;
            font-size: 13px;
            line-height: 36px;

            &:focus {
                background-color: rgba(204, 232, 254, 0.2);
            }

            &:disabled {
                color: #2c2f3c;
                background-color: rgba(225, 229, 231, 0.3);
            }
        }
    }

    .ExitButton {
        position: absolute;
        right: 50px;
        bottom: 40px;
        border: 1px solid #008dfb;
        border-radius: 100px;
        width: 60px;
        height: 60px;
        color: #008dfb;
        box-sizing: border-box;
        line-height: 60px;
        text-align: center;

        &:hover {
            color: #fff;
            background-color: #008dfb;
        }
    }

    .control.PasswordField {
        display: flex;
        align-items: flex-end;

        label {
            flex-grow: 1;

            input {
                // background-color: rgb(204, 232, 254);
                border-radius: 30px 0px 0px 30px;
            }
        }

        .RevealButton {
            cursor: pointer;
            height: 36px;
            line-height: 36px;
            padding-right: 10px;
            padding-top: 4px;
            box-sizing: border-box;

            // background-color: rgb(204, 232, 254);
            background-color: rgba(204, 232, 254, 0.3);
            border-radius: 0px 30px 30px 0px;
            &:focus {
                background-color: rgba(204, 232, 254, 0.2);
            }
        }
    }

    .SuccessMessage, .ErrorMessage {
        max-width: 270px;
        margin: 10px auto 20px;
        line-height: 1.35;
        font-weight: 600;
        font-size: 12px;
    }
    .SuccessMessage {
        color: #27cb65;
    }
    .ErrorMessage {
        color: #ff3800;
    }
    

    .ChangePasswordLink {
        color: #008dfb;
        cursor: pointer;
    }
    
    .FormFooter {
        max-width: 270px;
        margin: 10px auto 20px;
        display: flex;
        justify-content: center;

        button, [role="button"] {
            border: none;
            color: #008dfb;
            font-size: 15px;
            font-weight: 600;
            cursor: pointer;
            user-select: none;

            &:first-child {
                margin-left: 0 !important;
            }


            
            &.back {
                display: flex;
                align-items: center;
            }


            &.secondary, &.primary {
                border: 1px solid #008dfb;
                padding: 5px 25px 6px;
                border-radius: 20px;
                margin-left: 20px;
                cursor: pointer;
                user-select: none;
            }
            &.secondary {

            }
            &.primary {
                color: #fff;
                background-color: #008dfb;
            }
        }

    }
    

}
.AccountNav {
    margin-top: 30px;
    a,div {
        border: 1px solid #008dfb;
        border-radius: 30px;
        padding: 3px 18px;
        margin: 3px;
        font-size: 12px;
        color: #008dfb !important;
    }
    a {        
        &.active, &:hover {
            background-color: #008dfb;
            color: #fff !important;
        }
        &.active {
            cursor: default;
        }
    }
    div {
        border: 1px solid gray;
        color: gray !important;
    }
}


.VerifyEmailButton {
    display: block;
    margin: 40px auto;
    border: 1px solid #008dfb;
    padding: 10px 20px;

    border: 1px solid #008dfb;
    border-radius: 30px;
    font-size: 12px;
    color: #008dfb !important;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: #008dfb;
        color: #fff !important;
    }
}