.ManageAvatarContent .ProfileImage {
    

    .DropzoneWrapper {
        position: relative;
        cursor: pointer;
        // margin: 20px auto;
        margin: -20px auto 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 100px;
        // background-color: rgba(0,0,0,0.03);

        .AvatarMessage {
            display: none;
            position: absolute;
            width: 150px;
            height: 20px;
            top: 0;
            left: 0;
            text-align: center;
            font-size: 12px;
            color: #2c2f3c;
        }

        .DeleteAvatarButton {
            // display: none;
            position: absolute;
            top: 20px;
            left: 60px;
            width: 13px;
            height: 13px;
            border-radius: 13px;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: #cce8fe;
            // z-index: 7;
            z-index: 5;
        }

        div.dropzone {
            position: relative;
            left: 65px;
            top: 7px;
            outline: none;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            white-space: nowrap;
            padding: 3px 9px;
            border-radius: 15px;
            color: #008dfb;

            &:hover {
                background-color: #f1f7fd;
                color: #2c2f3c;
            }
            .FileSelectButton {
                font-size: 12px;
                font-weight: 600;
            }

        }





        .CropperWrapper {
            position: absolute;
            top: 50px;
            left: 0px;
            width: 200px;
            height: 250px;
            z-index: 1;

            .buttons {
                display: flex;
                button {
                    border: 2px solid #cce8fe;
                    border-radius: 20px;
                    margin: 20px 0 0 0;
                    font-size: 15px;
                    font-weight: 600;
                    padding: 3px 23px 5px;
                    color: #008dfb;
                    cursor: pointer;
                    &:not(:last-child) {
                        margin-right: 10px;
                    }

                    &.primary {
                        background-color: #008dfb;
                        border-color: #008dfb;
                        color: #fff;
                    }
                    &.secondary {

                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        
        
    }


    .AvatarIcon {
        position: absolute;
        left: 0;
        top: 25px;
        border: 3px solid #cce8fe;
        outline: none;
        width: 64px;
        height: 64px;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 50px;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        .iconDefault, .iconHover {
            width: 100px;
            height: 100px;
            background-repeat: no-repeat;
            background-position: center 15px;
        }
        .iconDefault { display: block; }
        .iconHover { display: none; }

        .AvatarImage {
            width: 100%;
            height: 100%;
            // border: 7px solid #fff;
            border-radius: 50%;
        }
    }

    &:hover {
        div.dropzone {
            .AvatarIcon {
                border-color: #008dfb;
                .iconDefault { display: none; }
                .iconHover { display: block; }
            }
        }
        .DeleteAvatarButton {
            display: flex;
        }

        .AvatarMessage {
            display: block;
        }
    }
}