$minDesktop: 801px;

.MainAthleteBackground {
    flex-flow: 1;
    

    @media screen and (min-width: 951px) {
        position: absolute;
        height: 460px;
        width: 100vw;
    }

    background-color: #f3f3ee;
    background-size: 1700px;
    background-repeat: no-repeat;
    // background-position: bottom -19px center;
    background-position: bottom 0px center;
    top: 0;
    left: 0;
    z-index: -1;
}

.MainAthleteBlock {

    //LOGO MOVE
    // margin-top: 80px;
    background-position: bottom -19px center;
    background-size: 1700px;
    background-repeat: no-repeat;

    
    display: flex;



    .card {
        position: relative;

        .tooltip {
            .tooltiptext {

      
                
                min-width: 85px;
                // margin-left: -46px;
                
                font-weight: 500;
                top: 120%;
                
                // height: 48px;
                line-height: 15px;

                font-size: 10px;
                
                padding: 5px 10px;

                .lightBlueTooltip {

                    font-size: 12px;
                    color: #71e1f4;
                }
                
                em {
                    color: #fcb452;
                    font-style: normal;
                    font-size: 12px;
                    display: block;
                    white-space: nowrap;
                }
            }
            .welcome {
                height: 30px;
            }
        }
    }

    .nextPage, .prevPage {
        position: absolute;
        top: 160px;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 5px 4px 1px 5px;
        border-radius: 50%;
        z-index: 5;
        cursor: pointer;
        img {
            position: relative;
            left: 0;
            transition: left 80ms;
        }
    }
    .nextPage {
        right: -20px;
        &:hover {
            img {
                left: 5px;
            }
        }

        .tooltiptext {
            // margin-left: -62px !important;
            left: 0px !important;
        }
        
    }
    .prevPage {
        left: -20px;
        img {
            transform: rotate(180deg);
        }
        &:hover {
            img {
                left: -5px;
            }
        }

        .tooltiptext {
            // margin-left: -58px !important;
            left: 0px !important;
        }
    }
    
    
    
    @media screen and (min-width: 951px) { /* desktop */
        // margin-top: 75px;
        margin-top: 50px;
    }

    & > * {
        flex-grow: 1;
    
    }


    .imgLeft, .imgRight  {
        position: relative;
        /* background-size: contain;
        background-repeat: no-repeat;
        transition: background-position 30s;
        top: -50px; */
        // top: -60px;
        top: -28px;
        overflow: hidden;

        .backgroundImage {

            min-height: 260px;
            background-size: cover;
            background-position: left;
            margin-top: 85px;
        }

    //     @media only screen and (max-width: 900px){
    //         & {
    //            display: none;
    //        }
    //    }



        // & > div {
        //     position: absolute;
        //     top:0;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        //     transition: transform 20s ease;
            
        //     background-size: contain;
        //     background-repeat: no-repeat;
        //     /* transition: background-position 30s; */
        // }


    }

    .imgLeft {
        .backgroundImage {
            background-position: left;

        }
    }
    .imgRight  {


        .backgroundImage {
            background-position: right;
        }
    }

    
    .card {
        // height: 400px;
        
        background-color: #fff;
        // border: 1px solid #2c2f3c;
        // border-radius: 8px;
        /* margin: 47px auto; */
        // margin: 25px auto;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        // border: none;
        box-shadow: 0 4px 7px 0 rgba(0,0,0,0.1);
        border-radius: 12px;

        margin: 15px;
        max-width: calc(100vw - 40px);
        box-sizing: border-box;
        min-height: 100px;
        
        @media only screen and (min-width: 950px) {
            max-width: 520px;
            margin: 0px auto 20px auto;
        }


        .cardheader {
            bottom: 15px;
            margin-bottom: -15px;
            font-size: 15px;
            span {
                font-size: 18px;
                margin: 0 5px 0 0;
                cursor: default;
            }
        
            strong {
                font-weight: 600;
                font-size: 15px;
                color: #008dfb;
                &:hover {
                    background-color:rgba(204, 232, 254, 0.3);
                    color: #2c2f3c;
                    cursor: pointer;
                }
            }
            

            & > * {
                background: #fff;
                padding: 0 10px;
                line-height: 30px;
                color: inherit;
                text-decoration: none;
                
                &:not(:first-child):before {
                    display: inline-block;
                    content: '';
                    width: 0.2rem;
                    height: 0.2rem;
                    border-radius: 0.1rem;
                    background-color: #008dfb;
                    margin-right: 0.5rem;
                    margin-left: -10px;
                    position: relative;
                    top: -4px;
                }
            }
        }




    
        .cardfooter {
            top:15px;
            margin-top: -15px;

            & > * {
                background: #fff;
                padding: 0 15px;
                line-height: 30px;
                /* font-size: 25px; */
                background-repeat: no-repeat;
                background-position: 8px 0px;
                background-size: 20%;

                font-size: 13px;
                font-weight: 500;
                color: #2c2f3c;
            }

            .time {
                padding: 0 15px 0 40px;
            }
        }
        .cardheader, .cardfooter {
            height: 30px;
            position: relative;
            display: flex;
            justify-content: center;
        }
        
        .cardcontent {
            flex: 1;
            padding: 20px 29px 20px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;



            .MarathonTitle, .MarathonDates {
                display: block;
                text-align: center;
                line-height: 22px;

                
            }

            .MarathonTitle {

                .tooltip {

                    .tooltiptext {
                        // margin-left: -49px;
                        margin-left: -57px;
                        margin-top: 35px;
                        min-width: 100px;
                        font-weight: 400;
                        font-size: 11px;
                        color: #fff;
                        em {
                            color: #fcb452;
                        }


                        // &:after{
                        //     display: none;
                        // }

                    }
                }
            }

            .MarathonTitle span {

                color: #008dfb;
                font-size: 13px;

                &:hover {
                    // padding-left: 16px;
                    // padding-right: 16px;
                    color: #2c2f3c;
                    border-radius: 18px;
                    background-color: rgba(204, 232, 254, 0.3);
                }

                @media only screen and (min-width: 950px) {
                    font-size: 15px;
                }
            }
            // color: #008dfb;{

                
                
            
            
            .AthleteName {
                font-size: 16px;
                padding-bottom: 10px;
                .AthleteNameLink {
                    &:hover {
                        // padding-left: 16px;
                        // padding-right: 16px;
                        border-radius: 18px;
                        color: #2c2f3c;
                        background-color: rgba(204, 232, 254, 0.3);
                    }
                }
            }

            .AthleteFlag {
                padding-right: 10px;
                
                // margin: 0px;
            }

            .MarathonDates {
                color: #2c2f3c;
                // margin-top: 5px;
                margin-bottom: 10px;

                font-size: 11px;

                @media screen and (min-width: $minDesktop) {
                    font-size: 13px;
                }
            }

            .WelcomeBib {
                flex-grow: 1;
            }


            .Label {

                display: flex;
                margin-top: 0px;
                justify-content: center;
                align-items: center;

                
    
                span {
                    // background-color: #008dfb;
                    padding: 3px 14px;
                    border-radius: 20px;
                    // color: #fff;
                    // text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0 10px;
                    // color: #008dfb;
                    color: #2c2f3c;
                    // background-color: #fff;
                    background-color: #e1f1fe;
                    // border: 1px solid #008dfb;
                }
                i { // the gray line
                    flex-grow: 1;
                    // background: #aeaeae;
                    background: #e9e9e9;
                    height: 1px;
                }

                .tooltip {
                    .tooltiptext {
        
              
                        
                        min-width: 85px;
                        // margin-left: -46px;
                        background-color: black !important;
                        border-radius: 0px;

                        left: -37%;
                        
                        font-weight: 500;
                        top: 150%;
                        
                        // height: 48px;
                        line-height: 15px;
        
                        font-size: 11px;
                        
                        padding: 5px 10px;
    
                        
                        em {
                            color: #fcb452;
                            font-style: normal;
                            font-size: 11px;
                            display: block;
                            white-space: nowrap;
                        }
                    }
                    .welcome {
                        height: 30px;
                    }
                }
            }

            .EdgesLine {
                display: flex;
                justify-content: space-between;
                font-size: 13px;
                line-height: 20px;
                color: #2c2f3c;
                position: relative;
                
                font-weight: normal;
                color: #2c2f3c;
                
                strong {
                    font-weight: 600;
                    
                }

                .left-aligned {
                    text-align: left;
                }
                .right-aligned {
                    text-align: right;
                }

                // .since {
                //     font-weight: normal;
                //     font-size: 14px;
                // }

                // top: -5px;
            }
            .extrapad {

                padding-top: 8px;
            }

            .MakeItYours {
                font-family: "Montserrat", sans-serif;
                font-size: 11px;
                font-weight: normal;
                color: #2c2f3c;

                // @media screen and (min-width: $minDesktop) {
                //     font-size: 11px;
                // }
            }
            

            .ThisIsMe {
                font-family: "Montserrat", sans-serif;
                border: none;
                border-radius: 12px;
                padding: 4px 14px 3px;
                // margin-bottom: 24px;
                margin-bottom: 10px;
                // color: #2c2f3c;
                color: #008DFB;
                font-weight: 800;
                font-size: 15px;
                user-select: none;
                cursor: pointer;
                &:hover {
                    background-color: rgba(204,232,254,0.3);
                    color: #2c2f3c;
                    /* background: #bbdaff; */
                }
                .tooltiptext {
                    // margin-left: -49px;
                    font-family: "Montserrat", sans-serif;
                    margin-left: -57px;
                    margin-top: 5px;
                    min-width: 100px;
                    font-weight: 400;
                    font-size: 10px;
                    em {
                        font-size: 12px;
                    }
                }
                &:focus {
                    outline: none;
                }
            }
            
        }

    }
    

}
