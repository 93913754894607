$minDesktop: 701px;

.PaginationLine {
    margin-top: 16px;
    margin-bottom: 16px;
 
    justify-content: center;     
    
    .dot {
        font-style: normal;
        color: #008dfb;
        display: inline-block;
        padding: 0 4px 0 12px;
        font-weight: 600;
    }
    
    .DropdownContainer {
        display: inline-block;
        em {
            font-style: normal;
        }
    }
    
    @media screen and (min-width: $minDesktop) {
        display: flex;
        justify-content: space-between;

        .totalPages, .Pagination {
            flex: 1;
        }


    }

    strong {
        font-weight: 600;
    }

    
    .totalPages {
        color: #2c2f3c;
        padding-top: 5px;
        padding-left: 15px;
        text-align: left;
        font-size: 13px;

        position: relative;
        top: -10px;



        .tooltip {
            z-index: 7;
            .tooltiptext {
                min-width: 60px !important;
                margin-left: -36px !important;
                top: 138% !important;
                // height: 32px !important;
                // line-height: 15px;
                // font-family: "Montserrat", sans-serif;
                // font-size: 13px;
            }
        }
    }

}




.Pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    margin-top: 10px;
    
    
    @media screen and (min-width: $minDesktop) {
        margin-top: 0;
    }


    .paginationArrow {
        padding: 3px 3px 0px;
        border-radius: 12.5px;
        cursor: pointer;
        &:hover {
            background-color: rgba(204, 232, 254, 0.3);
            color: #008dfb;
        }
    }


    .chevron, .pagenum {
        padding: 5px 5px;
        text-align: center;
        // border: 1px solid gray;
        vertical-align: middle;
        margin: 0;
        &:not(.ellipses):hover {
            // background-color: rgba(204, 232, 254, 0.3);
            background-color:#e8e8e0;
            // color: #008dfb;
            // color: #e8e8e0;
        }
    }
    
    .chevron {
        color: #008dfb;
        top: -2px;
        cursor: pointer;
        &.disabled {
            cursor: default;
            color: #e1e5e7;
        }
    }
    
    .pagenum {
        &.current {
            color: #fff;
            background-color: #008dfb;
            cursor: default;
            margin: 0 3px;
        }
        &.current:hover {
            // background-color: rgba(204, 232, 254, 0.3);
            background-color:#008dfb;
            // color: #008dfb;
            // color: #e8e8e0;
        }
        &.ellipses {
            cursor: text;
            border-radius: 0;
            &:hover {
                background-color: #e8e8e0;
            }
        }
        height: 22px;
        line-height: 22px;
        border-radius: 22px;
        min-width: 22px;
        font-size: 13px;
        cursor: pointer;
    }

    .DotsPageInput {

        &.tooltip {
            z-index: 4;
        }


        form {
            input.numinput {
                border: none;
                border-bottom: 1px solid #c4c4c4;
                padding: 2px 3px;
                // width: 1.9em;
                width: 26px;
                display: inline-block;
                height: 27px;
                line-height: 27px;
                text-align: center;
                font-weight: 600;
                font-size: 0.9rem;
                background-color: #e8e8e0;
                &:focus {
                    outline: none;
                }
            }
            input[type=submit] {
                display: none;
            }

            &.pageNumInvalid {
                input.numinput {
                    background-color: #eea3a3;
                    border-bottom-color: #f23d3d;
                    color: #2c0000;
                }
            }
            
        }
    }

}


.FilterFavoritesButton {
    width: 15px;
    height: 15px;

    display: inline-block;
    position: relative;
    top: 8px;
    padding: 5px;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-position: center 5px;
    background-color: transparent;
    cursor: pointer;
    user-select: none;


    
    .LoginLink {
        display: none;
    }
    .CreateAccountLink {
        display: none;
    }

    .dot {
        display: none;
        color: black;
    }
    
    &:hover {
        background-color: #e8e8e0;
        
        .LoginLink {
            left: 25px;
            display: block;
            position: absolute;
            // top: 7px;
            top: 0px;
            line-height: 25px;
            width: 150px;
            font-size: 10px;
            color: #008dfb;
            padding-left: 15px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }

        .dot {
            left: 73px;
            display: block;
            position: absolute;
            // top: 7px;
            top: 0px;
            line-height: 25px;
            // width: 150px;
            font-size: 10px;
            padding: 0px;
          
            cursor: default; 
        }

        .CreateAccountLink {
            left: 80px;
            display: block;
            position: absolute;
            // top: 7px;
            top: 0px;
            line-height: 25px;
            width: 150px;
            font-size: 10px;
            color: #008dfb;
            z-index: 1;
            // padding-left: 15px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
            
        }




    }

    &.loggedOut {
        cursor: default;
    }
    

    
    
}