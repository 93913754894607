.ReportHeaderSameHometownAthletes {

    strong {
        color: #008dfb;
    }

    .athlete-info-container {
        padding-top: 10px;
    }

    
    span {
        display: inline-block;
        padding: 0 10px;
    }
}