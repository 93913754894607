
$minDesktop: 801px;

// .tippy-tooltip {
//   // background-color: #008dfb;
//   // border-radius: 25px;
//   color: #008dfb;
// }

.toggle-age-grade-container {

  user-select: none;

  .dot {
    font-weight: 600;
    display: inline-block;
    padding: 0 2px;
  }
  .factor {
    font-weight: 600;

  }

  .numericFont {
    font-family: "Roboto Mono", monospace;
  }


  
  &.on  {
    /* color: #008dfb; */
    color: #000000;
  
    .dot {
      color: #008dfb;
    }  
  
    .factor {
      color: #000;
    }
  
  }
  
  &:not(.on)  {
    color: #909fa9;
  }

  &:hover {
    input, label {
      cursor: pointer;
    }
    .label_name {
      color: #6b7c88;
    }
  }


  
  .age-grade-label {
    cursor: pointer;
    font-weight: 600;
  }
  
    

  .CheckboxControl {
    position: relative;

    

    .checkbtn {
        position: absolute;
        top: 1px;
        left: 0px;
        display: none;

        &.unselected { display: block; }
    }

    input[type=checkbox] {
        display: none;

        &:checked + label {
            .unselected { display: none; }
            .unselected_hover { display: none; }
            .selected { display: block; }
        }
    }
    label {
        padding: 2px 2px 2px 25px;


        font-size: 11px;

        @media screen and (min-width: $minDesktop) {
            font-size: 13px;
        }

        

        &:hover {
            .unselected { display: none; }
            .unselected_hover { display: block; }
            .selected { display: none; }
        }
    }


    // @media screen and (max-width: 600px) {
    //   margin-top: -10px;
    // }
  }
  
}