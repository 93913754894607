$minDesktop: 801px;

.toggle-units-container {
  @mixin deselectedLabel(){
    font-weight: 600;
    color: #6b7c88;
  }
  @mixin selectedLabel(){
    color: #000;
    font-weight: 600;
  }

  user-select: none;

  &.on {
    .handle {
      right: 0;

    }
    .units-label-english {
      @include deselectedLabel();
      cursor: pointer;
      
    }
    .units-label-metric {
      @include selectedLabel();
      cursor: default;
    }
    
  }
  .units-label-english {
    @include selectedLabel();
    cursor: default;
  }
  .units-label-metric {
    @include deselectedLabel();
    cursor: pointer;
  }
  &:not(.on) .handle {
    left: 0;
  }
}

.units-label {
  cursor: pointer;

  font-size: 11px;

  @media screen and (min-width: $minDesktop) {
      font-size: 13px;
  }
}
.switch {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 20px;
  cursor: pointer;
  margin: 0 10px;
}
.switch .handle {
  display: inline-block;
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #008dfb;
}
.switch .track {
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 35px;
  height: 14px;
  border-radius: 7px;
  background-color: #cce8fe;
}
.switch.on .handle {
  left: auto;
  right: 0;
}
