.CountryDropdown {
    // background-color: #f1f8fd; // blue
    // border: 2px solid #f1f8fd; // blue
    background-color: #f0faf3; // green
    border: 2px solid #f0faf3; // green 

    padding: 8px 20px 9px;
    border-radius: 18px;
    color: #000;
    font-size: 12px;



    &:hover {
        border-color: #ddeefd;
    }

    &.invalid {
        background-color: #ffe1d9;
        border-color: #ffe1d9;
    }

        
    &.open {
        border-color: #ddeefd;
        border-radius: 18px 18px 0 0;
        background-color: #fff;

        .DropdownList {
            display: flex;            
        }
    }
    
    .DropdownValue {
        font-weight: 600;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        position: relative;

        .flag {
            position: absolute;
            right: 0;
            top: -5px;
            font-size: 16px;
        }
    }
    .DropdownList {
        display: none;

        margin-top: 15px;
        max-height: 250px;
        position: absolute;
        background: white;
        z-index: 1;
        width: 100%;
        border: 2px solid #ddeefd;
        left: 0px;
        top: 38px;
        border-top: none;
        box-sizing: border-box;
        border-radius: 0 0 18px 18px;

        padding: 4px 10px 10px;

        .DropdownListInner {
            max-height: 300px;
            overflow: auto;

            &::-webkit-scrollbar-track {
                background: transparent;
            }
            

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                width: 6px;
                border-radius: 3px;
                background-color: #008dfb;
            }
            
            &::-webkit-scrollbar-thumb:window-inactive {
                background: #6b7c88;
            }
        }
        

        .DropdownOption {
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            border-radius: 18px;
            padding: 3px 20px 2px 16px;


            &.selected {
                font-weight: 600;
                .checkbox {
                }
            }
            
            &:hover {
                background-color: rgb(241,248,253);
            }
            
            .flag {
                padding-right: 7px;
                position: relative;
                top: -2px;
            }
            .title {
                flex-grow: 1;
            }
            .checkbox {
                
            }
        }
    }
}