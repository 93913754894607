@charset "UTF-8";
.AthleteHome {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .AthleteHome .WelcomeBib h1 {
    color: #2c2f3c; }
  .AthleteHome .WelcomeBib p {
    font-size: 15px;
    margin: 0 0 10px; }
    .AthleteHome .WelcomeBib p.smaller {
      font-size: 13px; }
  .AthleteHome .WelcomeBib img.smaller {
    width: 18px; }
  .AthleteHome .WelcomeBib .Logo {
    padding-bottom: 20px;
    height: 50px; }
    .AthleteHome .WelcomeBib .Logo img {
      height: 50px; }
  .AthleteHome .WelcomeBib .WelcomeBibHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .AthleteHome .WelcomeBib .WelcomeBibHeader .favoriteSpan:hover .background {
      transform: scale(1); }
    .AthleteHome .WelcomeBib .WelcomeBibHeader .favoriteSpan .background {
      transform: scale(0);
      transition: transform 200ms;
      transition-timing-function: ease-out;
      position: absolute;
      width: 35px;
      height: 35px;
      border-radius: 100%;
      background-color: rgba(204, 232, 254, 0.3);
      top: -5px;
      left: -5px; }
    .AthleteHome .WelcomeBib .WelcomeBibHeader .tooltiptext {
      min-width: 94px !important;
      margin-left: -57px !important;
      top: 125% !important;
      line-height: 15px;
      font-size: 11px;
      padding: 5px 10px; }
      .AthleteHome .WelcomeBib .WelcomeBibHeader .tooltiptext em {
        color: #71e1f4;
        font-style: normal;
        display: block;
        white-space: nowrap; }
    .AthleteHome .WelcomeBib .WelcomeBibHeader .WelcomeBibLocation {
      color: #2c2f3c;
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .AthleteHome .WelcomeBib .WelcomeBibHeader .WelcomeBibLocation .WelcomeBibFlag {
        font-size: 36px;
        margin-right: 14px;
        cursor: default; }
      .AthleteHome .WelcomeBib .WelcomeBibHeader .WelcomeBibLocation .WelcomeBibHometown {
        color: #2c2f3c;
        font-size: 15px;
        font-weight: 500;
        cursor: default; }
        .AthleteHome .WelcomeBib .WelcomeBibHeader .WelcomeBibLocation .WelcomeBibHometown .tooltiptext {
          min-width: 50px !important;
          margin-left: -65px !important;
          top: 152% !important;
          line-height: 15px;
          font-size: 11px;
          padding: 5px 10px; }
          .AthleteHome .WelcomeBib .WelcomeBibHeader .WelcomeBibLocation .WelcomeBibHometown .tooltiptext em {
            color: #fcb452;
            font-style: normal;
            display: block;
            white-space: nowrap; }
  .AthleteHome .AthleteHomeFooter {
    font-size: 13px !important; }
    .AthleteHome .AthleteHomeFooter .dot {
      display: inline-block;
      padding: 0 7px;
      color: #008dfb;
      font-size: 16px; }
    .AthleteHome .AthleteHomeFooter .since {
      display: inline-block;
      padding-left: 4px;
      color: #2c2f3c;
      font-weight: 300; }
  .AthleteHome table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 15px;
    width: 100%; }
    .AthleteHome table th {
      text-align: left;
      vertical-align: bottom;
      color: #6b7c88;
      padding-bottom: 15px;
      font-weight: 600;
      user-select: none; }
      .AthleteHome table th.sortable {
        cursor: pointer; }
      .AthleteHome table th .SortIcon img {
        display: none; }
      .AthleteHome table th:hover {
        color: #909fa9; }
        .AthleteHome table th:hover .SortIcon img {
          display: block; }
    .AthleteHome table td {
      padding: 4px 0;
      font-size: 13px;
      font-weight: 400;
      color: #2c2f3c; }
    .AthleteHome table tr:hover td {
      background-color: #e8e8e0;
      cursor: pointer; }
    .AthleteHome table tr .rightAligned {
      text-align: right; }
    .AthleteHome table tr .middleAligned {
      text-align: center; }
    .AthleteHome table tr .padRight {
      padding-right: 40px; }
    .AthleteHome table .td_index {
      text-align: right;
      padding-right: 40px; }
    .AthleteHome table .td_finishtime {
      text-align: right; }
    .AthleteHome table .td_bib {
      text-align: right; }
    .AthleteHome table .td_finishtime {
      text-align: right; }
    .AthleteHome table .td_participants {
      text-align: right; }
    .AthleteHome table .td_location {
      text-align: right; }
    .AthleteHome table .td_date {
      text-align: right;
      padding-right: 40px; }
    @media screen and (max-width: 600px) {
      .AthleteHome table, .AthleteHome table thead, .AthleteHome table tbody, .AthleteHome table tr, .AthleteHome table td, .AthleteHome table th {
        display: block; }
      .AthleteHome table thead {
        display: none; }
      .AthleteHome table tr {
        padding: 10px 16px 14px 10px;
        margin: 10px;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        display: grid;
        grid-template-columns: 28px 1fr 1fr;
        grid-template-areas: "index name name" "index participants participants" "index location date" "index bib finishtime"; }
        .AthleteHome table tr .td_index {
          grid-area: index;
          float: left;
          color: #2c2f3c;
          padding-right: 15px; }
        .AthleteHome table tr .td_name {
          grid-area: name; }
        .AthleteHome table tr .td_participants {
          grid-area: participants;
          text-align: left; }
          .AthleteHome table tr .td_participants:before {
            content: "Athletes • "; }
        .AthleteHome table tr .td_bib {
          grid-area: bib;
          text-align: left; }
          .AthleteHome table tr .td_bib:before {
            content: "Bib • "; }
        .AthleteHome table tr .td_finishtime {
          grid-area: finishtime;
          text-align: right;
          padding-right: 40px; }
          .AthleteHome table tr .td_finishtime:before {
            content: "Finish Time • "; }
        .AthleteHome table tr .td_location {
          grid-area: location;
          text-align: left; }
        .AthleteHome table tr .td_date {
          grid-area: date;
          text-align: right; } }
    .AthleteHome table tr:hover td {
      background-color: #e8e8e0;
      cursor: pointer; }
    .AthleteHome table tr:hover td:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px; }
    .AthleteHome table tr:hover td:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px; }
    .AthleteHome table tr .rightAligned {
      text-align: right; }
    .AthleteHome table tr .middleAligned {
      text-align: center; }
    .AthleteHome table tr .padRight {
      padding-right: 40px; }
    .AthleteHome table td + td + td, .AthleteHome table th + th + th {
      padding-left: 8px; }
    .AthleteHome table td {
      font-weight: 400;
      color: #2c2f3c; }
      .AthleteHome table td.name {
        font-weight: 600; }
