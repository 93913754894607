$minDesktop: 801px;

.SignUpModalCover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 15;
}

.SignUpModalContent {
    font-family: "Montserrat", sans-serif;
    position: fixed;
    left: 0;
    font-size: 12px;
    background-color: #fff;
    // padding: 40px;
    // padding: 20px 50px 170px 40px;
    padding: 50px 50px 38px 40px;
    box-sizing: border-box;
    min-height: 150px;
    max-height: 100vh;
    overflow: auto;
    z-index: 17;
    border-radius: 12px;
    box-shadow: 0 0 12px rgba(0,0,0,.3), 0 1px 5px rgba(0,0,0,.2);
    width: calc(100vw - 40px);
    margin: 20px;
    
    // background-position: center bottom 40px, left -100px bottom 80px;
    background-position: center bottom 20px, left 0px bottom 40px;
    // background-size: 100px, 1280px;
    background-size: 100px, contain;
    background-repeat: no-repeat, no-repeat;
    
    
    transition: top 0.4s, opacity 0.2s;
    top: 0;
    // opacity: 0.2;
    opacity: 1;
    // top: 40px;
    @media screen and (min-width: $minDesktop) {
        top: 110px;
        $width: 520px;
        margin: 0; 
        margin-left: -($width/2);
        width: $width;
        left: 50%;
        border-radius: 12px;
    }

    &.activated {
        opacity: 1;
        top: 40px;
    }


    .SignUpImage {
        // width: 90px;
        height: 90px;
        // border: 5px solid #cfe4f9;
        // border-radius: 90px;
        // box-sizing: border-box;
        // position: relative;
        margin: 0 auto;
        // img:first-child {
        //     position: absolute;
        //     top: 15px;
        //     left: 8px;
        //     width: 50px;
        // }
        // img:last-child {
        //     position: absolute;
        //     top: 49px;
        //     left: 54px;
        // }
    }


    &.step_2 {
        @media screen and (min-width: $minDesktop) {
            $width: 805px;
            margin-left: -($width/2);
            width: $width;
        }

    }

    .tooltiptext {
        min-width: 33px !important;
        top: 139% !important;
        // margin-left: -65px !important;
        left: 65% !important;
        // line-height: 15px;

        
        // padding: 5px 10px;
        // height: 32px !important;
        // line-height: 15px;
        // font-family: "Montserrat", sans-serif;
        // font-size: 13px;
        
        em {
            font-size: 11px;
            
            color: #fcb452;
            font-style: normal;
            font-weight: 500;
            display: block;
            white-space: nowrap;
        }

        // &:after{
        //     display: none;
        // }
    }

    .topLabel {
        position: absolute;
        top: 20px;
        left: 30px;
        color: #2c2f3c;
        font-weight: 400;
        font-size: 13px;
        strong {
            font-weight: 600;
        }

        .light {
        }
        .dot {
            font-weight: 800;
            display: inline-block;
            padding: 0 2px;
            color: #008dfb;
            margin: 0 6px 0 4px;
        }

        &.step2 {
            left: 2px;
        }
    }

    .ClearIcon {
        cursor: pointer;
        max-width: 20px;
        height: 20px;

        position: absolute;
        top: 20px;
        right: 25px;
        z-index: 10;



        &:hover {  
            .background {
                transform: scale(1);
            }
        }
    
        .background { 
            transform: scale(0);
            transition: transform 200ms;
            transition-timing-function: ease-out;
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            // background-color: #93b9ff;
            background-color: rgba(204, 232, 254, 0.3);
            top: -6px;
            left: -5px;
            z-index: -5;
        }

    }

    .Step1 {
        text-align: center;


    }

    h1 {
        margin: 20px 0;
        font-size: 28px;
    }
    p {
        font-size: 12px;
        font-weight: bold;
        margin: 20px auto;
        max-width: 30em;

    }

    .Label {

        display: flex;
        margin-top: 0px;
        justify-content: center;
        align-items: center;

        span {
            // background-color: #008dfb;
            padding: 3px 14px;
            border-radius: 20px;
            // color: #fff;
            // text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            margin: 0 10px;
            // color: #008dfb;
            color: #2c2f3c;
            // background-color: #fff;
            background-color: #e1f1fe;
            // border: 1px solid #008dfb;
        }
        i { // the gray line
            flex-grow: 1;
            // background: #aeaeae;
            background: #e9e9e9;
            height: 1px;
        }
    }

    .control {
        // display: flex;
        // align-items: center;
        position: relative;


        &.PasswordField {
            .RevealButton {

                position: absolute;
                right: 74px;
                top: 29px;
            }
        }

        .react-datepicker-wrapper {
            display: block;
        }
    }
    .invalid {
        input[type="password"], input[type="email"], input[type="text"] {
            // background-color: rgba(255, 56, 0, 0.1); // red

            background-color: rgb(252,239,236);
            border: 2px solid rgb(252,239,236);
            
            &:hover {
                // background-color: rgba(255, 56, 0, 0.05); // red
                border-color: rgb(250,226,218);

            }
        }
    }
    
    label {
        display: block;
        margin: 20px auto;
        text-align: left;
        box-sizing: border-box;
        max-width: 320px;

        .control {
            display: flex;
            align-items: center;
            position: relative;

            .emailValid {
                background-color: rgba(39,203,101,0.1);
                font-weight: 600;
            }

            .isValidEmailHash {
                color: #000; // #2c2f3c;
                background-color: rgb(246,247,247);
                border-color: rgb(246,247,247) !important;
            }

            .lockIconContainer {
                width: 32px;
                text-align: right;
                position: relative;
                top: 0;
                left: 0;


                &.lockShakes {
                    // top: -3px;
                    // left: 3px;

                    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
                    transform: translate3d(0, 0, 0);
                    backface-visibility: hidden;
                    perspective: 1000px;
                    // -webkit-animation:active 2s infinite linear;
                    // animation:active 2s infinite linear;
                }

                @keyframes shake {
                    10%, 90% {
                      transform: translate3d(-1px, 0, 0);
                    }
                    
                    20%, 80% {
                      transform: translate3d(2px, 0, 0);
                    }
                  
                    30%, 50%, 70% {
                      transform: translate3d(-4px, 0, 0);
                    }
                  
                    40%, 60% {
                      transform: translate3d(4px, 0, 0);
                    }
                  }
                
            }
        }

        .label {
            display: block;
            color: #4a4a4a;
            font-weight: 400;
            font-size: 11px;
            margin-left: 21px;
            margin-bottom: 5px;
            &.error {
                // color: #c11515;
            }
        }

        input {
            background-color: rgb(241,248,253);
            border: 2px solid rgb(241,248,253);
            color: #000; // #2c2f3c;
            display: block;
            width: 100%;
            box-sizing: border-box;
            background-repeat: no-repeat;
            background-position: right 10px center;

            font-size: 12px;
            font-weight: 600;
            padding: 10px 22px;
            background-size: 20px;
            border-radius: 16px;
            @media screen and (min-width: $minDesktop) {
                font-size: 12px;
                padding: 11px 20px;
                background-size: 16px;
                border-radius: 21px;
            }

            &:hover {
                border-color: rgb(221,238,253);
            }
            
            &:focus {
                background-color: #fff !important;
                border-color: rgb(221,238,253);
            }
        }
        &.error input {
            background-color: rgb(252,239,236);
            border: 2px solid rgb(252,239,236);
            &:hover {
                border-color: rgb(250,226,218);
            }
        }

        @media screen and (min-width: $minDesktop) {
            margin: 20px auto;
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        .yesno {
            padding: 1px 27px;
        }
        
        button, .button  {
            box-sizing: border-box;
            display: block;
            // border-radius: 60px;
            
            background: #008dfb;
            color: #ffffff;
            font-size: 17px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            // padding: 1px 27px;
            padding: 4px 20px;
            margin: 0 10px;
            border: 2px solid #008dfb;
            font-family: inherit;
            cursor: pointer;
    
            border-radius: 17px;

            &:hover {
                border: solid 2px rgba(0, 0, 0, 0.05);
                background-color: #0086ef;
            }
            
            &.secondary {
                background-color: #fff;
                color: #008dfb;
                border-color: #cce8fe;

                &:hover {
                    border: solid 2px rgba(0, 141, 251, 0.5);
                }
            }

            &[disabled] {
                background: #e4e4e4;
                color: #ffffff;
                border: 2px solid #d6d6d6;
                cursor: default;
            }
            
            // @media screen and (min-width: $minDesktop) {
            //     border-radius: 27px;
            //     font-size: 24px;
            //     padding: 15px 48px;
            //     // margin: 50px auto;
            //     margin: 10px auto;
            // }
        }
        
    }

    input, button {
        &:focus {
            outline: none;
        }
    }
    

    &.step_2 {
        padding: 0;

        .Step2  {
            display: flex;

            @media screen and (max-width: 750px) {
                justify-content: center;
                .UserCard {
                    display: none;
                }
            }
            
            .UserCard {
                min-width: 355px;
                text-align: center;
                background-color: #f9f9f6;
                padding-bottom: 150px;
                background-repeat: no-repeat;
                background-position: center bottom;


                .Label {
                    margin-top: 60px;
                }

                .UserInfoTable {
                    display: flex;
                    justify-content: space-between;
                    font-size: 13px;
                    line-height: 20px;
                    color: #2c2f3c;
                    position: relative;
                    
                    font-weight: normal;
                    color: #2c2f3c;
                    margin: 10px 30px;
                    
                    strong {
                        font-weight: 600;
                        
                    }

                    .left-aligned {
                        text-align: left;
                    }
                    .right-aligned {
                        text-align: right;
                    }
                }

                .Flag {
                    font-size: 36px;
                }
                .Location {
                    margin: 2px auto 0;
                    font-size: 15px;
                    font-weight: 500;
                }

                .userAvatar {
                    // width: 90px
                }
            }
            .ClaimProcess {
                width: 480px;
                position: relative;
                padding: 50px 0 40px;

                p {
                    font-weight: 400;
                    max-width: 280px;

                    a {
                        color: #008dfb;
                    }

                    &.strong {
                        text-align: center;
                        font-weight: 600;
                    }


                    &.small {
                        font-size: 10px;
                    }
                }

                .Label {
                    margin-bottom: 30px;
                }


                .ClipboardButton {
                    background-color: #008dfb;
                    border-radius: 20px;
                    padding: 2px 10px 3px;
                    height: 20px;
                    color: #fff;
                    padding-left: 15px;
                    padding-right: 15px;
                    cursor: pointer;
                    border: none;

                }
                
                .CopyText {
                    cursor: pointer;
                    &.tooltip {
                        display: block;

                    }
                    .tooltiptext {
                        min-width: 50px !important;
                        top: 44% !important;
                        // margin-left: -65px !important;
                        left: 7%;
                        // line-height: 15px;
                
                        
                        // padding: 5px 10px;
                        // height: 32px !important;
                        // line-height: 15px;
                        // font-family: "Montserrat", sans-serif;
                        // font-size: 13px;
                        
                        em {
                            font-size: 11px;
                            
                            color: #fcb452;
                            font-style: normal;
                            font-weight: 500;
                            display: block;
                            white-space: nowrap;
                        }
                
                        &:after{
                            display: none;
                        }
                    }

                }

                &.clipboardCopied {

                    .ClipboardButton {
                        background-color: rgb(236,248,240);
                        cursor: default;
                        color: #000;
                    }

                    .CopyText {
                        color: #6b7c88;
                        cursor: no-drop;
                    }
                }
                
                
                
            }
        }
        
    }
    
}