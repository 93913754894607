body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* @font-face {
  font-family: 'Monoid';
  src: local('Monoid'), url(./fonts/Monoid-Regular.ttf) format('truetype');
} */
