.DropdownContainer {
    position: relative;
    
    
    .DropdownValue {
        cursor: pointer;
        display: flex;

        // padding: 1px 13px 3px;
        padding: 4px 13px 3px;
        font-size: 12px;
        
        background-color: #e8e8e0;
        border-radius: 12.5px;
        
        @media screen and (min-width: 801px) {
            background-color: transparent;
            
            &:hover {
                background-color: #e8e8e0;
            }
        }
        

        em {
            white-space: nowrap;
        }

        &.disabled {
            cursor: not-allowed;
            em, span {
                color: #b9b9b9 !important;
            }
            &:hover {
                background-color:#fff;
            }
            img {
                filter: grayscale(100%);
                opacity: 0.7;
            }
        }

    }
    .DropdownList {
        z-index: 7;
        display: none;
        text-align: left;
        
        &.open {
            display: block;

            background: #fff;
            margin: 0;
            padding: 5px 0;



            max-width: 100vw;
            width: calc(100vw - 40px);
            left: 20px;
            position: fixed;
            top: 80px;
            border-radius: 8px;
            @media screen and (min-width: 801px) {
                position: absolute;
                top: 25px;
                left: -30px;
                // max-width: 240px;
                width: auto;
            }




            li {
                font-size: 12px;
                color: #000;
                display: block;
                white-space: nowrap;
                padding: 5px 40px 5px 40px;
                cursor: pointer;
                position: relative;
                background-repeat: no-repeat;
                background-position: 18px 7px;

                .GoTo {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 30px;
                    height: 100%;
                    opacity: 0;
                    transition: opacity 200ms;
                    &:hover {
                    }
                }
                
                &:hover {
                    background-color: rgba(204, 232, 254, 0.3);
                    .GoTo {
                        opacity: 1;
                    }
                }

                &.selected {
                    font-weight: 800;
                    
                    // &:before {
                    //     content: "";
                    //     padding-right: 10px;
                    //     display: inline-block;
                    //     color: #008dfb;
                    //     position: absolute;
                    //     left: 20px;
                    // }
                }
            }

        }
    }
}

.DropdownCover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.1);
    z-index: 5;
}