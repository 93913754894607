.InfoModal {
    display: inline-block;
    position: relative;

}
.ReactModalPortal .info_button_modal,
.InfoModal .info_button_modal {

    // background-color: #cfd8dc;
    // border: 1px solid #cfd8dc;
    width: 24px;
    height: 24px;
    // line-height: 23px;
    // border-radius: 50%;
    // color: #fff;
    // text-align: center;
    // font-size: 14px;
    // font-weight: 600;
    cursor: pointer;
    z-index: 7;
    // box-sizing: border-box;

    @media screen and (max-width: 600px) {
        display: none;
    }

    .tooltiptext {
        min-width: 33px !important;
        top: 0% !important;
        // margin-left: -65px !important;
        left: 225% !important;
        // line-height: 15px;

        
        // padding: 5px 10px;
        // height: 32px !important;
        // line-height: 15px;
        // font-family: "Montserrat", sans-serif;
        // font-size: 13px;
        
        em {
            font-size: 11px;
            
            color: #fcb452;
            font-style: normal;
            font-weight: 500;
            display: block;
            white-space: nowrap;
        }

        &:after{
            display: none;
        }
    }

    
}

.ReactModalPortal .info_button_modal {
    float: left;
    margin-right: 8px;
}

// .InfoModal .info_button_modal:hover{
//     background-color: transparent;
//     border-color: #cce8fe;
//     color: #008dfb;
// }

// .modal_content {
//     /* padding: 0 30px; */
//     padding: 0px 30px 15px 30px;
// }

// .modal_content h3 {
//     padding-bottom: 15px;
//     border-bottom: 1px solid black;
// }
// .modal_content p {

//         position: relative;
    
// }
// .modal_content strong {
//     font-weight: 600;
//     &:hover {
//         background-color: rgba(204,232,254,.3);
//     }
// }

// .ReactModalPortal .ReactModal__Overlay {
//     background-color: rgba(25, 42, 48, 0.5) !important;
//     z-index: 7 !important;
// }

// .ReactModalPortal .ReactModal__Content {
//     font-family: "Montserrat", sans-serif;
//     font-weight: 400;
//     font-size: 0.9rem;

//     position: relative !important;
//     top: auto !important;
//     right: auto !important;
//     bottom: auto !important;
//     left: auto !important;
//     max-width: 660px;
//     margin: 100px auto;
//     border: none !important;
//     box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), inset 10px 0 0 0 #008dfb;
//     border-radius: 0 !important;
    
// }
    
// .ReactModalPortal .ReactModal__Content .close {
//     position: absolute;
//     right: 10px;
//     top: 7px;
//     border: none;
//     color: #6b7c88;
//     padding: 7px 9px;
//     outline: none;
//     cursor: pointer;
//     &:hover {
//         color:#008dfb;
//     }
// }

// .ReactModal__Overlay {
//     opacity: 0;
//     transition: opacity 400ms ease-in-out;
// }

// .ReactModal__Overlay--after-open{
//     opacity: 1;
// }

// .ReactModal__Overlay--before-close{
//     opacity: 0;
// }