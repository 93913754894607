.ElevationGainIcon {
    // display: inline-block;
    
    img {
        display: inline-block;
        margin-left: 5px;
        position: relative;
        top: 2px;
        
    }
    
    // .RollingIconImg {
    //     display: inline-block;
        
    // }
    // .ElevationGainIcon {
    //     display: inline-block;

    // }
    
}