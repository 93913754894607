:root {
  --mr-blue-color:#008dfb;

}

body {
  background: #f8f8f5;
  // background: #f2f3ee;
}

input[type="text"],input[type="email"],input[type="password"] {
  font-family: inherit;
}

.myCookieContainer {
  // background: "#2B373B";
  background: #2B373B !important;
  // padding: 0px 75px !important;
  align-items: center !important;
}

.myCookieContent {
  margin-left: 75px !important;
  font-size: 14px;
  a {
    color: #008dfb !important;
  }

}

.CookiesTitle {
  font-weight: bold;
  display: block;
}

.numericFont {
  font-family: "Roboto Mono", monospace;
}

@media screen and (max-width: 640px) {
  .myCookieContainer {
    flex-direction: column;
    
  }
  .myCookieContent {
    flex-basis: 10px !important;
    margin: 16px 75px 0 !important;
  }
}

.myCookieButton {
  margin-right: 75px  !important;
  color: #4e503b;
  font-size: 13px;

  
  // @media screen and (min-width: 640px) {
  //   margin-left: 475px !important;
  // }


}

.container {
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  &.table {
    max-width: 1400px;
    padding: 0 10px;
  }
  &.flex-child {
    margin: 0;
  }
}

.containerRaceLayout {

  @media screen and (min-width: 1300px) {
    min-width: 1300px;
  }

  margin: 0 auto;
  position: relative;
  &.table {
    // max-width: 1400px;
    min-width: 1300px;
    padding: 0 10px;
  }
  &.flex-child {
    margin: 0;
  }
}

.flexgrow {
  flex-grow: 1;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  // z-index: 200;
  // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #2c2f3c;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  opacity: 0;
  cursor: default;
  
  
  min-width: 0px;
  height: 0px;
  width: 0px;
  // overflow: hidden;
  top: 0%;
  left: 0%; 
  padding: 0;
  transition: opacity 1s;

  
  
  // font-size: 8px;

  
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 7;

  // top: 100%;

  margin-left: -80px; /* Use half of the width (120/2 = 60), to center the tooltip */
  
}
.dotsPageInputTooltip {
  margin-left: -65px !important;
}

// .tooltiptwide {
//   width: 160px;
// }

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2c2f3c transparent;
}




/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover > .tooltiptext {
  visibility: visible;

  min-width: 120px;
  height: auto;
  width: auto;
  top: 100%;
  left: 47%; 
  padding: 5px 10px;

  margin-left: -30px;
  // top: 140% !important;
  line-height: 15px;

  font-size: 12px;
  

}

.tooltip:hover > .tooltiptext {
  opacity: 1;
}

.diffMinus {
  color: #28bb28;
  font-weight: 600;
}

.diffPlus {
  color: #ff3800;
  font-weight: 600;
}


.App {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  // border-top: 5px solid #008dfb;
  // border-bottom: 5px solid #008dfb;

  
  // padding-top: 80px;
  // @media screen and (min-width: 951px) { /* desktop */
  //   padding-top: 60px;
  // }

  // padding-top: 0;
  
  
  position: relative;
  min-height: 100vh;
  /* padding-bottom: 50px; */
  // padding-bottom: 25px;
  -webkit-tap-highlight-color: transparent;

  // @media screen and (max-width: 600px) {
  //   // padding-bottom: 100px;
  //   padding-bottom: 50px;
  // }
}



a, a:visited, a:hover, a:active {
  text-decoration: none;
  color: inherit;
}

.details {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  // padding: 10px;
  font-size: 15px;
  // line-height: 25px;

  
  @media screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 18px;
  }
  
  dl {
    margin: 0;
    padding: 0px 0px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 15px;
    font-weight: 500;
    // letter-spacing: -0.5px;

    &:first-child {
      margin-right: 20px;
    }
  }
  
  dt,
  dd {
    white-space: nowrap;
    font-size: 12px;
  }
  dt {
    color: #2c2f3c;
    text-align: right;
    font-weight: 400;
    font-size: 12px;
  }
  dd {
    margin: 0;
    color: #2c2f3c;
    font-weight: 400;

    strong {
      font-size: 15px;
    }
    
    i {
      font-style: normal;
      color: #008dfb;
      // font-weight: bold;
      

      &.dot {
        display: inline-block;
        padding: 0 7px;
        font-weight: 800;
        font-size: 15px;
      }
    }

    .numericFont {
      font-family: "Roboto Mono", monospace;
    }
    .smallFont {
      font-weight: 300;
      font-size: 12px;
    }

    .text-space-right {
      padding-right: 3px;
    }
    .text-space-left {
      padding-left: 3px;
    }

    .slight-bold {
      font-weight: 500;
    }

    .paceDiffPos {
      font-size: 11px;
      color: #28bb28;
    }
    .paceDiffNeg {
      font-size: 11px;
      color: #ff3800;
    }

    .slower {
      font-style: normal;
      font-weight: normal;
      color: #ff3800;
      font-size: 13px;
    }

    .faster {
      font-style: normal;
      font-weight: bold;
      color: #85e020;
      font-size: 13px;
    }
  }
}



#HSBeaconFabButton {
  bottom: 40px !important;
  left: 50px !important;
  width: 60px !important;
  height: 60px !important;
}


.headroom-wrapper {
  z-index: 2; /* this allows tooltips to stay on top */ 
}