.PageInfoModal {
    
    
    
}

.PageInfoModalPopup {

    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    
    position: fixed;
    right: 20px;
    top: 70px;
    z-index: 7777;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 355px;
    box-sizing: border-box;
    border-radius: 6px;
    max-height: calc(100vh - 80px);
    display: flex;
    padding-top: 30px;

    .IconSwappable {
        &.tooltip {
            .tooltiptext {
                min-width: 40px !important;
                margin-left: -30px !important;
                top: 160% !important;
                line-height: 15px !important;
      
                font-size: 11px !important;
      
                padding: 5px 10px !important;
                // height: 32px !important;
                // line-height: 15px;
                // font-family: "Montserrat", sans-serif;
                // font-size: 13px;
      
                em {
                  color: #fcb452;
                //   color: #71e1f4;
                  font-style: normal;
                  display: block;
                  white-space: nowrap;
                }
            }
          }
    }

    

    header {

        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 40px;
        z-index: 1;
        border-radius: 6px;


        .ClearIcon {
            cursor: pointer;
            max-width: 20px;
            height: 20px;
    
            position: absolute;
            top: 20px;
            right: 25px;
            z-index: 10;
    
            &:hover {  
                .background {
                    transform: scale(1);
                }
            }
        
            .background { 
                transform: scale(0);
                transition: transform 200ms;
                transition-timing-function: ease-out;
                position: absolute;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                // background-color: #93b9ff;
                background-color: rgba(204, 232, 254, 0.3);
                top: -6px;
                left: -5px;
                z-index: -5;
            }
        }
    }

    .modal_content {
        overflow: auto;

        hr {
            border-top: 1px solid #e9e9e9;;

        }

        ul {
            list-style: none;
            margin-left: 0;
            padding-left: 0px;
            li {
                margin-left: 0;
                padding-left: 25px;
                
                &::before {
                    content: "\2022";
                    color: #2c2f3c;
                    display: inline-block;
                    width: 15px;
                    margin-left: -15px;
                    font-weight: 900;
                    font-size: 12px;
                }
            }
        }
        

        .infoBlock {
            display: flex;
            align-items: center;
            font-weight: 500;
            
            ul {
                margin-right: 15px;
               
                li {
                    
                    &:not(:last-child){
                        padding-bottom: 5px;
                    }
                    &::before {
                        color: #008dfb;
                    }
                }
            }
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
            

        }
    }

    
}


.ReactModalPortal .info_button_modal,
.PageInfoModal .info_button_modal {

    &.tooltip {
        .tooltiptext {
            min-width: 40px !important;
            margin-left: -54px !important;
            top: 160% !important;
            line-height: 15px !important;
  
            font-size: 11px !important;
  
            padding: 5px 10px !important;
            // height: 32px !important;
            // line-height: 15px;
            // font-family: "Montserrat", sans-serif;
            // font-size: 13px;
  
            em {
              color: #fcb452;
            //   color: #71e1f4;
              font-style: normal;
              display: block;
              white-space: nowrap;
            }
        }
      }

    // background-color: #cfd8dc;
    // border: 1px solid #cfd8dc;
    width: 24px;
    height: 24px;
    // line-height: 23px;
    // border-radius: 50%;
    // color: #fff;
    // text-align: center;
    // font-size: 14px;
    // font-weight: 600;
    cursor: pointer;
    z-index: 7;
    // box-sizing: border-box;

    @media screen and (max-width: 600px) {
        display: none;
    }

    

    

    
}
.ReactModalPortal .info_button_modal {
    float: left;
    margin-right: 8px;
}

// .PageInfoModal .info_button_modal:hover{
//     background-color: transparent;
//     border-color: #cce8fe;
//     color: #008dfb;
// }

.modal_content {
    /* padding: 0 30px; */
    padding: 0px 30px 15px 30px;
}

.modal_content h3 {
    padding-bottom: 15px;
    border-bottom: 1px solid black;
}
.modal_content p {

        position: relative;
    
}
.modal_content strong {
    font-weight: 600;
    &:hover {
        background-color: rgba(204,232,254,.3);
    }
}

.ReactModalPortal .ReactModal__Overlay {
    background-color: rgba(25, 42, 48, 0.5) !important;
    z-index: 10 !important;
}

.ReactModalPortal .ReactModal__Content {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;

    position: relative !important;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
    max-width: 660px;
    margin: 100px auto;
    border: none !important;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), inset 10px 0 0 0 #008dfb;
    border-radius: 0 !important;
    
    
}
    
.ReactModalPortal .ReactModal__Content .close {
    position: absolute;
    right: 10px;
    top: 7px;
    border: none;
    color: #6b7c88;
    padding: 7px 9px;
    outline: none;
    cursor: pointer;
    &:hover {
        color:#008dfb;
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}