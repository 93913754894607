.SegmentNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    position: relative;

    .label {
        font-size: 11px;
        color:#6b7c88;
        font-weight: 600;
        min-width: 40px;
        text-align: center;
    }

    .IntervalControlBtn {
        margin: 0 0;

        .BtnImage {
            cursor: pointer;

            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;

            img {
                display: block;
                width: 13px;
                height: 18px;
            }
        }
        .BtnImage.active {
            display: flex; 
            &:hover {
                background-color: rgba(204,232,254,0.3);
            }
        } 
        .BtnImage.disabled {
            display: none;
            cursor: default;
        }

        &.disabled {
            .BtnImage.active { display: none; } 
            .BtnImage.disabled { 
                display: flex; 
                cursor: default;
            }
        }

        &.prev {}
        &.next {}

    }
    .IntervalIndex {
        font-size: 11px;
        color: #909fa9;
        // min-width: 40px;
        padding: 0 3px;
        text-align: center;
    }
}