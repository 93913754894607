.SearchModalContent {
    position: fixed;
    top: 15px;

    font-family: "Montserrat", sans-serif;
    
    
    font-size: 12px;
    background-color: #fff;
    // padding: 40px;
    
    box-sizing: border-box;
    min-height: 150px;
    max-height: calc(100vh - 30px);
    // overflow: auto;
    z-index: 10;
    text-align: left;

    
    margin-left: 0;
    
    border-radius: 8px;
    
    width: calc(100vw - 20px);
    left: 10px;
    
    // padding-top: 40px;

    padding: 30px 16px 0;

    display: flex;
    flex-direction: column;




    .SearchModalContentBody {
        flex: 1 1 auto;
        overflow: auto;
    }
    
    
    
    // @media screen and (max-width: 600px) {
        //     left: 0;
        //     margin-left: 0;
        //     width: 100vw;
        //     padding-top: 40px;
        // }
        
    @media screen and (min-width: 600px) {
        left: 50%;
        width: 710px;
        margin-left: -350px;
        padding: 30px 50px 40px 40px;

        // border-radius: 0;
    }
    


    
    .SearchInputContainer {
        // border-bottom: 1px solid #e3e3e3;
        padding-left: 35px;
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 10px 7px;
        display: flex;
        align-items: center;

        background-color: #f9f9f6;
        border-radius: 30px;
        

        .SearchInput {
            box-sizing: border-box;
            width: 100%;
            padding: 3px 6px;
            line-height: 30px;
            border: 0;
            font-size: 18px;
            font-family: "Montserrat", sans-serif;
            background-color: transparent;
    
            &::placeholder {
                color: #909fa9;
            }
            &:focus {
                outline: none;
            }
            
        }

        .ClearIcon {
            cursor: pointer;
            width: 14px;
            height: 14px;
            margin-right: 10px;

            &:hover {  
                .background {
                    transform: scale(1);
                }
            }
        
            .background { 
                transform: scale(0);
                transition: transform 200ms;
                transition-timing-function: ease-out;
                position: absolute;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                // background-color: #93b9ff;
                background-color: rgba(204, 232, 254, 0.3);
                top: -6px;
                left: -5px;
                z-index: -5;
            }
        }
    }


    .close {
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        color: #6b7c88;
        padding: 7px 9px;
        cursor: pointer;
        &:hover {
            color: #008dfb;
            
        }
    }


    .separator {
        display: flex;
        width: 100%;
        align-items: center;

        i {
            height: 2px;
            background: #e3e3e3;
            flex-grow: 1;
        }
        span {
            padding-left: 10px;
            padding-right: 20px;
            color:#6b7c88;
            font-size: 13px;
        }
    }



    .SearchResults {


        .searchResultIcon {
            width: 20px;
            background-size: 15px;
            background-repeat: no-repeat;
            background-position: center left;
            padding-right: 10px;

        }
        
                        

            display: table;
            width: 100%;

            .SearchResult {
                display: table-row;

                .SearchResultTitle {
                    line-height: 24px;
                }

                .AthleteNoOfOccurences {
                    min-width: 100px;
                    .muted {
                        color: #909fa9;
                        margin-left: 3px;
                    }
                }

                & > * {
                    display: table-cell;
                }
            }


            & + .separator {
                margin-top: 20px;
            }
        
    }

    .SearchResult {
        em {
            color: #008dfb;
            font-weight: 600;
            font-style: normal;
        }


    }
    

    .QuickLinks {
        padding-top: 10px;

        h5 {
            margin: 15px 0;
        }
        
        .QuickLink {
            color: #008dfb;
            display: inline-block;
            margin-right: 10px;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }

    .AppliedFilters {
        
        .AppliedFilter {

            display: flex;
            align-items: center;


            .filterIcon {
                width: 14px;
                height: 14px;
                cursor: pointer;
                display: block;
                background-size: 14px;
                margin-right: 7px;

            }

            .filterTitle {
                flex-grow: 1;

                .dot {
                    padding: 0 10px;
                }
            }

            .closeAppliedFilter{
                float: right;
                font-size: 30px;
                line-height: 9px;
                width: 20px;
                height: 20px;
                text-align: center;
                cursor: pointer;
                color: #008dfb;
                font-weight: 100;
                box-sizing: border-box;
                order: 3;
                
                &:hover {
                    background-color: #008dfb;
                    color: #fff;
                }


                & ~ .FilterIcon {
                    max-width: 32px;;
                    
                    .icon, .hover {
                        width: 16px;
                        height: 16px;
                    }
                }
                &:hover ~ .FilterIcon {
                    .hover {
                        display: block;
                    }
                    .icon {
                        display: none;
                    }
                }
                // &:hover ~ .filterIcon {
                //     background-position-y: 14px;
                // }



                &:hover ~ .filterTitle {
                    color: #6b7c88;
                }


            }
        }
    }
    
}
.RaceSearchResult {
    cursor: pointer;
    &:hover {
        background-color: rgba(204, 232, 254, 0.3);
    }   
}

.AthleteSearchResult {
    cursor: pointer;

    

    .AthleteNoOfOccurences .muted {
        display: none;
    }

    .UserIcon {
        width: 24px;
        height: 16px;
        .icon {
            display: none;
        }

        &:hover {
            background-color: #008dfb;
        }

        .tooltiptext {
            min-width: 33px !important;
            top: 139% !important;
            // margin-left: -65px !important;
            left: 30% !important;
            // line-height: 15px;
        
            
            // padding: 5px 10px;
            // height: 32px !important;
            // line-height: 15px;
            // font-family: "Montserrat", sans-serif;
            // font-size: 13px;
            
            em {
                font-size: 11px;
                
                color: #fcb452;
                font-style: normal;
                font-weight: 500;
                display: block;
                white-space: nowrap;
            }
        
            // &:after{
            //     display: none;
            // }
        }

    }

    .padder {
        width: 40px;
    }


    &:hover {
        background-color: rgba(204, 232, 254, 0.3);

        .UserIcon .icon {
            display: block;
        }


        .AthleteNoOfOccurences .muted {
            display: inline-block;
        }
    }  
    
    
}

.SearchCover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 5;
}






// .searchIcon {
//     width: 30px;
//     height: 30px;
//     cursor: pointer;
    
//     &:hover {
//         background-position-y: 30px;
//     }
// }



.searchIcon {
    display: inline-block;
    cursor: pointer;
    


    img {
        display: block;
        // &.hover {
        //     display: none;
        // }
    }
    &:hover{ 
        // img {
        //     display: none;
        //     &.hover {
        //         display: block;
        //     }
        //     // box-shadow: 0px 0px 5px 10px rgba(204, 232, 254, 0.3);
        //     // background-color: rgba(204, 232, 254, 0.3);
        // }
        .background {
            transform: scale(1);
        }
    }

    .background {
        transform: scale(0);
        transition: transform 200ms;
        transition-timing-function: ease-out;
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        // background-color: #93b9ff;
        background-color: rgba(204, 232, 254, 0.3);
        top: -11px;
        left: -10px;
        z-index: -5;
    }
  }




.IconSwappable, .IconSwappable > * {
    background-repeat: no-repeat;
    background-position: center;
    min-width: 10px;
    min-height: 10px;
    width: 100%;
    height: 100%;
}
.IconSwappable {
    .icon {display: block;}
    .hover {display: none;}
    &:hover {
        .icon { display: none !important ; }
        .hover { display: block; }
    }
}



.MyRaceTooltip {
    background-color: #008dfb !important;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    opacity: 1 !important;
    padding: 8px 8px 8px 8px !important;
    &:after { // the color of the arrow
        // border-bottom-color: transparent !important;
        border-bottom-color: #008dfb !important;
    }
}

.MyRaceTooltipNoArrow {
    background-color: #008dfb !important;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    opacity: 1 !important;
    padding: 8px 8px 8px 8px !important;
    &:after { // the color of the arrow
        // border-bottom-color: transparent !important;
        border-color: transparent !important;
    }

}
.MyRaceTooltipDark {
    background-color: #2c2f3c !important;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    opacity: 1 !important;
    padding: 8px 8px 8px 8px !important;
    &:after { // the color of the arrow
        // border-bottom-color: transparent !important;
        border-bottom-color: #2c2f3c !important;
    }
}
.MyRaceTooltipDarkNoArrow {
    background-color: #2c2f3c !important;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    opacity: 1 !important;
    padding: 6px 8px 6px 8px !important;
    &:after { // the color of the arrow
        border-bottom-color: transparent !important;
    }
}
.MyRaceTooltipDarkLeftArrow {
    background-color: #2c2f3c !important;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    opacity: 1 !important;
    padding: 8px 8px 8px 8px !important;
    &:after { // the color of the arrow
        // border-bottom-color: transparent !important;
        border-color: transparent !important;
        // border-bottom-color: #113b76 !important;
    }
}
.MyRaceTooltipDarkRightArrow {
    background-color: #113b76 !important;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    opacity: 1 !important;
    padding: 8px 8px 8px 8px !important;
    &:after { // the color of the arrow
        border-color: transparent !important;
        // border-right-color: #113b76 !important;
        // border-left-color: #113b76 !important;
    }
}
.MyRaceTooltipLight {
    background-color: #cce8fe !important;
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    opacity: 1 !important;
    padding: 8px 8px 8px 8px !important;
    &:after { // the color of the arrow
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        border-left-color: transparent !important;
    }
}
// .MyRaceTooltipClear {
//     background-color: #113b76 !important;
//     color: #fff !important;
//     &:after { // the color of the arrow
//         border-bottom-color: transparent !important;
//     }
//     width: 36px;
//     height: 16px;
//     padding: 8px 10px;
//     margin: 0px;

// }