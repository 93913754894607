.AthleteHomeTimeline {
    margin-top: 40px;

    .TheTimeline {
        margin: 0 auto 20px;
        border-top: 1px solid #000;
        width: 730px;

        width: 230px;

        @media screen and (min-width: 360px) {
            width: 400px;
        }

        @media screen and (min-width: 660px) {
            width: 600px;
        }
        @media screen and (min-width: 900px) {
            width: 630px;
        }
        @media screen and (min-width: 1100px) {
            width: 730px;
        }


        display: flex;
        flex-direction: column;
        align-items: stretch;
        
        .Races {
            position: relative;
            
            .Race {
                position: absolute;
                width: 6px;
                height: 6px;
                box-sizing: border-box;
                background: #fff;
                top: -5px;
                display: block;
                z-index: 5;
                cursor: pointer;

                
                .TheDot {
                    cursor: pointer;
                    border: 2px solid #008dfb;
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                    background-color: #f8f8f5;
                }
                .background {
                    background-color: #efefe9;
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    border-radius: 20px;
                    left: -7px;
                    top: -7px;
                    z-index: -1;
                    opacity: 0;
                    transition: opacity 200ms;

                    &::after {
                        display: block;
                        content: " ";
                        width: 100%;
                        height: 1px;
                        background-color: #000;
                        position: absolute;
                        top: 11px;
                        left: 0;
                    }
                }

                .RaceLabel {
                    color: #fff;
                    opacity: 0;
                }

                &:hover {
                    .background {
                        opacity: 1;
                    }
                    .TheDot {
                        background-blend-mode: multiply;
                        background-image: linear-gradient(to bottom, rgba(243, 243, 238, 0.75), rgba(243, 243, 238, 0.75));
                    }
                }
                
                &.active {
                    z-index: 10;
                    .TheDot {
                        border-color: #fcb452;
                        overflow: visible;
                        z-index: 7;
                    }

                    .RaceLabel {
                        opacity: 1;
                        color: #2c2f3c;
                        position: absolute;
                        top: -30px;
                        left: 0;
                        font-size: 11px;
                        width: 100px;
                        font-weight: 600;
                        margin-left: -50px;
                        text-align: center;
                    }

                    &.tooltip {
                        .tooltiptext {
                            top: 25px !important;
                            color: #fcb452;
                            min-width: 80px !important;
                            left: 38px;
                            font-size: 12px;
                            font-weight: 500;
                            
                            &:after{
                                display: none;
                            }
                        }
                    }
                }


                &.tooltip {
                    .tooltiptext {
                        min-width: 100px !important;
                        margin-left: -60px !important;
                        // margin-left: -70px;
                        // top: 140% !important;
                        top: -35px !important;
                        height: 15px;
                        line-height: 15px;
                        font-size: 12px;

                        margin-top: 0;
                        margin-left: -70px;

                        font-size: 11px;
                        font-weight: 600;

                        &:after {
                            border-color: #2c2f3c transparent transparent transparent;
                            bottom: -10px;
                        }

                    }

                }
                
            }
        }
        
        .Years {
            display: flex;
            height: 10px;
            border-left: 1px solid #000;
            
            .Year {
                position: relative;
                flex-grow: 1;
                flex-basis: 0;
                border-right: 1px solid #000;
    
                .yearname {
                    display: block;
                    position: absolute;
                    top: 15px;
                    left: 1px;
                    font-size: 11px;
                    color: #2c2f3c;
                    font-weight: 400;
                }
            }
        }
        
    }
}