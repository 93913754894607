.requests-pending {
    .AthleteListTable {
        .table_body_wrapper {
            opacity: 0.2 !important;
        }
    }
}

.AthleteListTable {
    position: relative;

    .countryFlag {
        font-size: 16px;
        line-height: 13px;
        display: inline-block;
        padding-right: 10px;
        position: relative;
        top: 2px;
    }

    .table_body_wrapper {
        max-height: 300px;
        overflow: auto;
        transition: opacity 200ms;
        padding-right: 10px;

        &::-webkit-scrollbar-track {
            background: transparent;
        }
        

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            width: 6px;
            border-radius: 3px;
            background-color: #008dfb;
        }
        
        &::-webkit-scrollbar-thumb:window-inactive {
            background: #6b7c88;
        }
        
        
        
    }

    
    
    .PhotoIconLink, .CertificateIconLink, .FavIconLink, .FavIconStatic {
        display: inline-block;
        opacity: 0;
        transition: opacity 200ms;

        div.IconSwappable  {
            position: absolute;
            left: 0;
            top: 0;
            display: inline-block;
            min-width: 22px;
            min-height: 15px;
            position: relative;
            & > * {
                min-height: 15px;
            }
            
        }
    }
    
    .PhotoIconLink {
        div.IconSwappable {
            top: 2px;
            left: 3px;
        }

        &:hover .tooltiptext {
            min-width: 80px;
            height: 34px;
            margin-left: -45px;
            top: 190%;
        }

    }
    .CertificateIconLink {
        position: absolute;
        left: 70px;
        top: 5px;

        .tooltip {
            .tooltiptext {
                min-width: 80px;
                margin-left: -86px;
                top: 150%;
                height: 32px;
                // line-height: 15px;
                font-family: "Montserrat", sans-serif;
                font-size: 13px;
            }
        }
        
        div.IconSwappable {
        }

        // .tooltiptext {
        //     min-width: 50px;
        //     height: 34px;
        //     margin-left: -25px;
        //     top: 190%;
        //     font-family: "Montserrat", sans-serif;
        //     font-size: 10px;
        // }
    }
    
    
    
    .FavIconStatic, .FavIconLink {
        position: absolute;
        right: 3px;
        top: 6px;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        @media screen and (min-width: 801px) {
            opacity: 1;
        }
        opacity: 0;
    }
    .FavIconStatic {
        cursor: default;
    }

    .FavIconLink {

        &.isFavorite {
            opacity: 1;
            .IconSwappable {
                .icon { display: block !important; }
                .hover { display: none !important; }
            }
        }

        &.loading {
            opacity: 0.2 !important;
        }
        
        div.IconSwappable {
        }

        // &:hover .tooltiptext {
        //     min-width: 55px;
        //     margin-left: -34px;
        //     top: 155%;
            
        // }

        .tooltiptext {
            min-width: 33px !important;
            top: 150% !important;
            // margin-left: -65px !important;
            left: -2% !important;
            // line-height: 15px;
    
            
            // padding: 5px 10px;
            // height: 32px !important;
            // line-height: 15px;
            // font-family: "Montserrat", sans-serif;
            // font-size: 13px;
            
            em {
                font-size: 11px;
                
                color: #fcb452;
                font-style: normal;
                font-weight: 500;
                display: block;
                white-space: nowrap;
            }
    
            &:after{
                display: none;
            }
        }
    }

    
    table {
        border-collapse: separate;
        border-spacing: 0;
        // margin-top: 15px;
        width: 100%;

        th,td {
            padding: 4px 0;
            font-size: 13px;
            // font-size: 12px;
        }
        
        th {
            font-size: 11px;
            text-align: left;
            vertical-align: top;
            color: #2c2f3c;
            font-weight: 500;
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: #edede6;
            border-right: 1px solid #ddddd7;
            border-bottom: 1px solid #aeaeae;
            
            &:last-child {
                border-right: none;

            }
            
            padding: 10px 10px 15px 10px;

            font-family: "Roboto Mono", monospace;
            letter-spacing: -.46px;

            &.colHovered {

            }
        }

      
        
        

        tr {
            &.selected { /* SELECTED ROW */
                border-top: 1px solid #aeaeae;
                border-bottom: 1px solid #aeaeae;

                td {
                    border-top: 1px solid #aeaeae;
                    border-bottom: 1px solid #aeaeae;

     

                    &:first-child {
                        border-left: 1px solid #aeaeae;
                        font-weight: 600;
                    }
                    &:last-child {
                        border-right: 1px solid #aeaeae;
                    }

                    .PhotoIconLink, .CertificateIconLink, .FavIconLink, .FavIconStatic {
                        opacity: 1;
                    }


                    div.IconSwappable {
                        .icon {
                            display: none !important;
                        }
                        .hover {
                            display: block !important;

                        }
                    }

 
                } 
                
            }

            &:hover {


                td {
                    background-color: #e8e8e0;
                    border-color: #e8e8e0 !important;
                    cursor: pointer;

                    &:first-child {
                        font-weight: 400;
                    }

                    &.name {
                        color: #008dfb;
                    }
    
                    .PhotoIconLink, .CertificateIconLink, .FavIconLink, .FavIconStatic {
                        opacity: 1;
                    }

                }
            }


            td {
                position: relative;
                padding-left: 10px;
                
                &:first-child {
                    padding-left: 10px;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                &:last-child {
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
    
                font-weight: 400;
                color: #2c2f3c;
                
                // &.col-date, &.rightAligned {
                //     text-align: right;
                // }
                &.name {
                    font-weight: 600;
                }



                span.athleteCategory {
                    border: 1px solid #aeaeae;
                    margin: 2px;
                    padding: 0 2px;
                    border-radius: 3px;
                    font-size: 10px;
                    font-weight: 600;

                    &.selected {
                        border-color: #ff4500;
                        cursor: default;
                    }

                    .tooltiptext {
                        min-width: 80px;
                        margin-left: -45px;
                        
                        &.AG {
                            min-width: 58px;
                            margin-left: -35px;
                            top: 190%;
                        }
                        &.MA {
                            min-width: 40px;
                            margin-left: -28px;
                            top: 190%;
                        }
                        
                    }
                }
                
                
                
            } // td
        }

        td:nth-of-type(2) { //Name
            font-weight: 600;
        }
        // td:nth-of-type(4) { //Name
        //     float: right;
        // }
        td:nth-of-type(4) { //Bib
            // text-align: right;
            // padding-right: 10px;
            // font-weight: 500;
            // font-family: "Roboto Mono", monospace;
            
        }
        td:nth-of-type(5) { //Official Time
            // text-align: right;
            // padding-right: 10px;
            font-weight: 600;
            font-family: "Roboto Mono", monospace;
            
        }
        td:nth-of-type(6) { //Pace
            // text-align: center;
            // font-weight: 500;
            font-family: "Roboto Mono", monospace;
        }
        td:nth-of-type(7) { //1st Half
            // text-align: right;
            // padding-right: 10px;

            // font-weight: 500;
            font-family: "Roboto Mono", monospace;
        }
        td:nth-of-type(8) { //2nd Half
            // text-align: right;
            // padding-right: 10px;
            // font-weight: 500;
            font-family: "Roboto Mono", monospace;
        }
        td:nth-of-type(9) { // Diff
            // text-align: center;
            // padding-right: 10px;
            // font-weight: 500;
            font-family: "Roboto Mono", monospace;
        }
        td:nth-of-type(10) { // Gender
            // text-align: center;
            // padding-right: 10px;
            // font-weight: 500;
            // font-family: "Roboto Mono", monospace;
        }
        td:nth-of-type(11) { // Age
            // text-align: center;
            // padding-right: 10px;
            // font-weight: 500;
            font-family: "Roboto Mono", monospace;
        }
        td:nth-of-type(12) { // Gender Place
            // text-align: center;
            // padding-right: 10px;
            font-weight: 600;
            font-family: "Roboto Mono", monospace;
        }
        td:nth-of-type(13) { // Categories
            // text-align: center;
            // padding-right: 10px;
            // font-weight: 500;
            // font-family: "Roboto Mono", monospace;
        }
        td:nth-of-type(14) { // Category
            // text-align: center;
            // padding-right: 10px;
            // font-weight: 500;
            // font-family: "Roboto Mono", monospace;
        }
        td:nth-of-type(15) { // Category Place
            // text-align: center;
            // padding-right: 10px;
            // font-weight: 500;
            font-family: "Roboto Mono", monospace;
        }
        td:nth-of-type(16) { // Age Grade 
            // text-align: center;
            // padding-right: 10px;
            // font-weight: 500;
            font-family: "Roboto Mono", monospace;
        }

// https://myrace.com/welcome/bhm-fivekm-2016/athletes/8699
// https://myrace.com/welcome/bhm-half-2016/athletes/6184

        @media screen and (max-width: 600px) {
            &, thead, tbody, tr, td, th {
                display: block;
                // padding: 0 15px
            }
            thead {
                display: none;
            }
            tr {
                // border: 1px solid black;
                padding: 10px;
                margin: 10px 0;
                border-radius: 4px;
                display: block;
                background-color: #fff;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

                
                td.rightAligned {
                    text-align: left;
                }
                td:nth-of-type(1){
                    float: right;
                    color: #ccc;
                }
                td:nth-of-type(3):before {
                    content: "Location: ";
                }
                td:nth-of-type(4):before {
                    content: "Bib: ";
                    
                }
                td:nth-of-type(5):before {
                    content: "Finish Time: ";
                }
                td:nth-of-type(6):before {
                    content: "Pace: ";
                }
                td:nth-of-type(7):before {
                    content: "1st Half: ";
                }
                td:nth-of-type(8):before {
                    content: "2nd Half: ";
                }
                td:nth-of-type(9):before {
                    content: "Diff: ";
                }
                td:nth-of-type(10):before {
                    content: "Division: ";
                }
                td:nth-of-type(11):before {
                    content: "Division Place: ";
                }
                td:nth-of-type(12):before {
                    content: "Sex Place: ";
                }
                td:nth-of-type(13):before {
                    content: "Age Grade: ";
                }
            }


        }




        


    }



    .AthleteMobileCard {
        background: #fff;
        margin: 20px;
        border-radius: 6px;
        padding: 20px;

        .hover {
            cursor: pointer;
        }

        .Line1 {
            display: flex;
            justify-content: space-between;
            font-size: 13px;

            .Name {
                font-weight: 600;
                flex-grow: 1;
                padding-left: 16px;
            }
        }
        .Line2 {
            display: flex;
            margin: 3px 0 10px 0;
            padding: 10px 0 9px 10px;
            border-bottom: 1px solid #e9e9e9;
            justify-content: space-between;

            .HometownLocation {
                font-size: 13px;
                padding-left: 10px;
            }
            
            .FinishTime {
                font-size: 15px;
                font-weight: bold;
            }
        }

        dl.AthleteCardData {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-row-gap: 5px;
            // grid-column-gap: 5px;
            align-items: baseline;
            margin:0;
            padding:0;
            color: #2c2f3c;

            dt, dd {
                margin:0;
                padding:0;
                white-space: nowrap;
            }
            dt {
                text-align: right;
                font-size: 11px;
                padding-right: 10px;
            }
            dd {
                font-weight: 600;
                font-size: 13px;
            }
        }
        
    }

}



th:hover {
    .SorterButton {
        //background-color: #f6f6f2;
        // .sorter_img_inactive_hover { display: block; }
        .sorter_img_inactive { display: none; }
        .sorter_img_inactive_hover { display: block; }
    }     
}

.SorterButton {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    
    .SorterButtonDir {
        position: absolute;
        width: 15px;
        height: 15px;
        top: 0;
        right:0;
    }


    .SorterButtonDir:hover {
        background-color: #f6f6f2;
    }


    img {
        display: none;
    }
    &.inactive {
        .sorter_img_inactive { display: block; }
        &:hover {
            .sorter_img_inactive { display: none; }
            .sorter_img_inactive_hover { display: block; }
        }
    }
    &.active {
        .sorter_img_inactive_hover { display: none !important; }
        .sorter_img_active_down { display: block; }
        &.up {
            .sorter_img_active_down { display: none; }
            .sorter_img_active_up { display: block; }

        }
    }

}