.Sponsors {

    opacity: 1;
    transition: opacity 500ms;

    &.fadeHidden {
        opacity: 0;
    }
    
    .Tabs {
        display: flex;
        justify-content: center;
        z-index: 5;
        position: relative;
        flex-wrap: wrap;
        margin-bottom: -10px;



        .Tab {
            padding: 0 0 5px;
            margin: 0 15px 10px;
            border-bottom: 3px solid transparent;
            font-size: 13px;
            color: #909fa9;
            // text-transform: uppercase;
            cursor: pointer;
            user-select: none;
            &:hover {
                color: #008dfb;
            }
            &.selected {
                border-bottom-color: #bce2ff;
                color: #2c2f3c;
                cursor: default;
            }

        }
    }
    .TabContent {
        .ContentTitleSponsor, .ContentSponsor {
            min-height: 30px;

            
        }
        .ContentTitleSponsor {
            max-width: calc(100vw - 200px);
            margin: 0 auto;
            margin-top:-20px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 500px;
            }
        }
        .ContentSponsor {
            margin-bottom: 70px;
            display: flex;
            justify-content: center;
            align-items: stretch;
            max-width: calc(100vw - 100px);
            margin: 0 auto;

            @media screen and (max-width: 600px) {
                max-width: calc(100vw - 16px);
            }

            .SponsorImage {
                
                margin: 0 10px;
                max-width: 200px;
                min-width: 40px;
                // height: 150px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: stretch    ;
                margin-top:20px;

                @media screen and (max-width: 600px) {
                    margin: 0;
                }
                
                &:hover {

                    //border: 2px solid lightblue;
                    border-radius: 4px;
                    box-shadow: 0 0 0 1px #e1e5e7;
                    
                    .Caption {
                        background-color: rgba(204, 232, 254, 0.3);
                    }

                    img.openIcon {
                        display: block;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        top: 5px;
                        right: 5px;
                    }
                }

                a, span {
                    display: block;
                    padding: 5px 10px 0;
                }
                
                img.openIcon {
                    display: none;
                }

                img.sponsor {
                    max-width: 100%;
                    max-height: 100%;
                    display: block;

                }
                .Caption {
                    text-align: center;
                    // position: absolute;
                    padding: 8px 5px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    color: #2c2f3c;

                    @media screen and (max-width: 600px) {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}