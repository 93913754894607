.HeaderSearchBar {
    width: 620px;
    display: flex;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
    background-color: #fff;
    margin: auto;
    position: relative;
    top: 25px;
    border-radius: 45px;
    padding: 0 18px 0 40px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 10px 11px;
    align-items: center;
    
    input[type=text] {
        flex-grow: 1;
        box-sizing: border-box;
        border: none;
        border-radius: 45px;
        height: 40px;

        padding: 3px 6px 2px 6px;
        line-height: 40px;
        border: 0;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        background-color: transparent;

        &::placeholder {
            color: #909fa9;
            font-size: 14px;
        }
        
        &:focus {
            outline: none;
        }
    }

    .SearchFilterOptions {
        font-size: 12px;
        color: #2c2f3c;
        
        .SearchFilterOption {
            color: #008dfb;
            cursor: pointer;
            font-weight: 600;
            &.selected {
                color: #2c2f3c;
                cursor: default;
                font-weight: 400;

            }
        }

        .dot {
            font-size: 13px;
            font-weight: 600;
            padding: 0 7px;
        }
    }
}