$minDesktop: 801px;

body.finish {
    // background-color: #f6fafe; // ,<- the color we want
    // background-color: #daeaf9;
}



.Finish {

    // min-height: calc(100vh - 60px);
    // min-height: calc(100vh);
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    justify-content: flex-end;




    background: #f3f3ee;
    // padding-top: 50px;
    // padding-bottom: 50px;


    .titleHeader {
        margin: -20px 0 24px 0;

        @media screen and (max-width: 850px) {
            margin: 0px 0 24px 0;
        }
        @media screen and (max-width: 500px) {
            margin: 10px 0 24px 0;
        }
    }

    &.open {
        // background-color: rgba(25, 42, 48, 0.1);
        // background-color: #f5fbff;
        // background-color: rgba(25, 42, 48, 0.1);
        // 
    }

    &.closed {
        background-color: #f5fbff;
    }

  





    .FinishContent {

      
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
        // padding-bottom: 205px;
        padding-bottom: 156px;


        //background-image: url(/static/media/sf-skyline-finish@3x.afb653a4.png);
        background-size: 112%;
        background-position: bottom left;
        background-repeat: no-repeat;

        display: block;
        // border: 1px solid blue;
        margin: 50px auto 0px auto;
        min-height: 100px;
        max-width: 350px;




        @media screen and (min-width: 500px) {
            max-width: 500px;
        }
        
        @media screen and (min-width: 801px) {
            // padding-bottom: 225px;
            max-width: 900px;
            // margin-top: 79px;
        }

        &.open {
            // background-color: rgba(25, 42, 48, 0.04);
            background-color: rgba(255,255,255,0.1);
        }
    
        &.closed {
            background-color: #fff;
        }

        
        

        .FinishHeader {
            
            display: flex;
            justify-content: center;
            padding: 10px 40px 10px 40px;
            
            @media screen and (min-width: $minDesktop) {
                padding: 30px 40px 10px 40px;
                // justify-content: space-between;
                justify-content: center;
            }


            img {
                max-width: 100px;
                @media screen and (min-width: $minDesktop) {
                    max-width: 200px;
                }
            }

            .FinishHeaderLogoLink {
                display: none;
                @media screen and (min-width: $minDesktop) {
                    display: block;
                }
            }


            a.FinishTopSponsorLink {

                display: block;
                max-width: 240px;
                max-height: 50px;
                margin: 0;
                position: relative;
                // top: 20px;
                
                img.FinishTopSponsorImage {
                    max-width: 100%;
                    max-height: 45px;
                    // @media screen and (min-width: $minDesktop) {
                    //     margin-left: 70px;
                    // }
                }
            }


        }

        .LineTitle {
            text-align: center;

            margin: 0px 20px;
            
            
            span {
                margin: 0 10px ;
                // font-size: 15px;
                font-weight: bold;
                color: #2c2f3c;
                display: block;
                padding-bottom: 10px;
            }
            i {
                display: none;
            }

            border-bottom: 1px solid #e9e9e9;
            
            @media screen and (min-width: 801px) {
                display: flex;
                align-items: center;
                text-align: left;
                border-bottom: none;

                i {
                    display: block;
                    height: 1px;
                    background-color: #e9e9e9;
                    flex: 1;
                }
                span {
                    font-size: 15px;
                    padding-bottom: 0;
                }
            }
        }

        .SubtitleLine {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin: 6px 40px;
            margin: 6px 25px;

            font-size: 13px;
            font-weight: 600;
            color: #2c2f3c;

            div {

                i {
                    font-style: normal;
                    color: #008dfb;
                    font-weight: bold;
                
                    &.dot {
                        display: inline-block;
                        padding: 0 3px;
                    }
                }

            }

            @media screen and (min-width: 801px) {
                font-size: 13px;
            }
        }

        .AthleteInfo {
            text-align: center;
            // line-height: 2.2em;
            margin-top: -42px;

            font-size: 15px;
            font-weight: 500;
            color: #2c2f3c;

            .nextPage, .prevPage {
                // position: absolute;
                line-height: 1em;
                
                box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
                background-color: #fff;
                // padding: 5px 4px 1px 5px;
                padding: 9px 8px 5px 9px;
                border-radius: 50%;
                z-index: 2;
                img {
                    position: relative;
                    left: 0;
                    transition: left 80ms;
                }
            }
        
            .nextPage {
                right: -448px;
                top: -186px;
                &:hover {
                    img {
                        left: 5px;
                    }
                }

                @media screen and (min-width: 1100px) {
                    left: 448px;
                }
                @media screen and (max-width: 801px) {
                    left: 253px;
                }
                @media screen and (max-width: 500px) {
                    left: 175px;
                }
                
            }
            .prevPage {
                left: -450px;
                top: 77px;
                img {
                    transform: rotate(180deg);
                }
                &:hover {
                    img {
                        left: -5px;
                    }
                }

                @media screen and (min-width: 1100px) {
                    left: -451px;
                }
                @media screen and (max-width: 801px) {
                    left: -253px;
                }
                @media screen and (max-width: 500px) {
                    left: -175px;
                }
 
            }

            // @media screen and (min-width: 1100px) {
            //     .prevPage {
                    
            //     }
            // }
            // @media screen and (min-width: 1000px) {
            //     .prevPage {
            //         left: -250px;
            //     }
            //     .nextPage {
            //         left: -250px;
            //     }
            // }
            // @media screen and (min-width: 500px) {
            //     .prevPage {
            //         left: -452;
            //     }
            // }
            // @media screen and (min-width: 1100px) {
            //     .prevPage {
            //         left: -250px;
            //     }
            // }
            
            // @media screen and (min-width: 801px) {
            //     .prevPage {
            //         left: -380px;
            //     }
            // }

            .tooltip {

                .tooltiptext {
                    // margin-left: -49px;
                    margin-left: -57px;
                    margin-top: 5px;
                    min-width: 100px;
                    font-weight: 400;
                    font-size: 11px;
                    color: #fff;
                    em {
                        color: #fcb452;
                    }

                }
            }
            
            .Hometown {
                font-size: 15px;
                font-weight: 500;


                .countryFlag {
                    font-size: 19px;
                    line-height: 13px;
                    display: inline-block;
                    padding-right: 4px;
                    position: relative;
                    top: 2px;
                    user-select: none;
                }
            }
            .AthleteName {
                font-size: 26px;
                font-weight: 600;
                margin: 26px 0 0px 0;

                color: #008dfb;

   

                a:hover {

                    // cursor: pointer;
                    cursor: pointer;

                    padding-left: 16px;
                    padding-right: 16px;
                    color: #2c2f3c;
                    border-radius: 18px;
                    background-color: rgba(204, 232, 254, 0.3);
                }
            }

            .time {
                display: inline-block;
                background-repeat: no-repeat;
                font-size: 28px;
                font-weight: 500;
                margin-top: 15px;
                margin-bottom: 15px;

                padding-left: 39px;
                background-position: 0 2px;
                background-size: 27px;
            }
            .pace {
                font-size: 13px;
                font-weight: normal;
                margin-top: -4px;

                strong {
                    display: inline-block;
                    margin: 0 4px;
                    font-size: 16px;
                    
                    // padding-top: 5px;
                    position: relative;
                    top: 1.5px;
                    font-weight: 600;
                }

                @media screen and (min-width: 801px) {
                    font-size: 13px;
                    strong {
                        font-size: 16px;
                    }
                }
            }

            .RaceTypeLabelContainer {
                display: flex;
                justify-content: center;
                margin-top: 22px;
                margin-bottom: -34px;

                .RaceTypeLabel {
                    margin: 10px;
                    padding: 3px 14px;
                    border-radius: 20px;
                    border: none;
                    font-size: 12px;
                    // line-height: 12px;
                    line-height: 16px;
                    
                    font-weight: 600;
                    font-style: normal;
                    color: #2c2f3c;
                    box-sizing: border-box;
                    background-color: #e1f1fe;
                }
                @media screen and (min-width: 801px) {
                    font-size: 16px;
                }
            }
        }
        .cityImg {
            height: 150px;
            background-size: cover;
            margin-top: 20px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;

            @media screen and (max-width: 500px) {
                height: 90px;
            }
        }
        
    }

    .FinishFooter {
        display: flex;
        justify-content: center;
        max-width: 800px;
        margin: 0 auto;
        

        @media screen and (max-width: 800px) {
            max-width: 500px;
        }
        @media screen and (max-width: 500px) {
            max-width: 350px;
        }


        a {
            color: #008dfb;
            padding: 4px 6px;
            &:hover {
                background-color: rgba(204, 232, 254, 0.3);
                color: #2c2f3c;
            }
        }



        & > div {
            flex: 1 0 0;



            &.MainUrl {
                padding-top: 15px;
                font-size: 10px;
                font-weight: bold;
                font-style: normal;


                letter-spacing: 2px;
                text-align: center;
                // color: #008dfb;

                // a {
                //     &:hover {
                //         background-color: rgba(204, 232, 254, 0.3);
                //         color: #2c2f3c;
                //         padding: 4px 6px;
                //     }

                // }
            }
            &.DownloadUrl {
                position: relative;
                padding-top: 10px;
                padding-right: 4px;

                font-size: 13px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;

                @media screen and (max-width: 800px) {
                    font-size: 10px;
                }
                

            }
        }
    }

    .socialSharing {
        display: flex;
        justify-content: center;
        position: relative;

        
        .socialButtonContainer {
            height: 40px;
            width: 40px;
            position: relative;
    
            margin: 25px 14px 20px 14px;
            .socialButton {
                outline: none;

                @media screen and (max-width: 800px) {
                    height: 25px;
                    width: 25px;
                    margin: 10px 14px 20px 14px;
                }

                &.Facebook {
                    background-color: #415a93;
                }
                &.Twitter {
                    background-color: #4babe7;
                }
                &.Email {}
            }
        }
    }


    
    
    
}

.FinishSidebar {
    .FinishSidebarButton {
        position: absolute;
        left: 65px;
        top: 33px;
        // min-width: 230px;
        cursor: pointer;

        img {
            position: relative;
            z-index: 5;
        }


        @media screen and (max-width: 600px) {
            top: 10px;
            // margin: 10px 0 24px 0;
        }

        .background {
            transform: scale(0);
            transition: transform 200ms;
            transition-timing-function: ease-out;
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            // background-color: #93b9ff;
            background-color: rgba(204, 232, 254, 0.3);
            top: -11px;
            left: -10px;
            // z-index: 0;
        }
        &:hover {
            .background {
                transform: scale(1);
            }
        }

    }
    .FinishSidebarContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        width: 330px;
        height: calc(100vh - 10px);
        position: fixed;
        top: 5px;
        left: 0;
        z-index: 10;

        transform: translateX(-500px);
        transition: transform 800ms;
        transition-timing-function: ease;

        background: #fff;
        box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);

        padding: 60px;
        box-sizing: border-box;
        
        &.closed {
            
        }
        &.open {
            transform: translateX(0px);
        }

        button.close {
            border: none;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            color: #6b7c88;
            background: #fff;
            // user-select: none;
            outline: none;
            &:hover {
                color: #008dfb;
            }
        }


        .FinishSidebarNavigation {
            text-transform: uppercase;
            overflow: hidden;
            padding-top: 40px;

            flex-grow: 1;

            
            
            a {

                font-size: 14px;
                font-weight: 600;
                color: #2c2f3c;

                
                
                display: block;
                padding: 9px 0 7px;
                position: relative;

                &:hover {
                    color: #008dfb;
                    &::after {
                        transform: translateX(0px);
                        transition: transform 300ms;
                        transition-timing-function: ease-out;
                    }
                }

                &::after {
                    transform: translateX(-50px);
                    content: " ";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 30px;
                    border-bottom: 1px solid #008dfb;

                }
            }

            
        }
        .FinishSidebarFooter {
            min-height: 80px;
            display: flex;
            justify-content: center;
        }
        
    }
}


.FinishSidebarCover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 8;
}