.Footer {
    // margin-top: 100px;
    // margin-top: 90px;
    // margin-top: 40px;
    margin-top: 0px;
    padding: 30px 0 5px;
    background-color: #fff;
    padding: 0 11vw;

    p {
        margin: 6px 0;
    }
    p, a, a:link, a:visited, a:hover, a:active {
        color: #2c2f3c;
        &:hover {
            text-decoration: underline;
        }
    }

    .Footer-logo {

        margin-bottom: 10px;
        display: block;
        
        img {
            display: block;
            width: 105px;
            margin: 0 auto;
        }
        @media only screen and (min-width: 700px) {
            text-align: left;
            margin: 0 0 10px;
            img {
                margin: 0;
            }
        }
    }


    .Footer-columns {

        display: grid;
        grid-template-columns: 2fr 1fr;
        padding-top: 20px;

        & > div:first-child {
            grid-column: 1 / 3;
        }
        

        font-size: 12px;

        .info {
            text-align: center;
        }
        @media only screen and (min-width: 700px) {
            display: flex;
            .info {
                text-align: left;
            }
        }

        .info, h6 {
            color: #909fa9;
            font-size: 11px;
            margin: 0 0 15px 0;
        }
        h6 {
            text-transform: uppercase;
            font-weight: 600;
        }

        & > div {
            flex-grow: 1;
            flex-basis: 0;
            padding: 5px;


        }
        
        & > div.Footer-contact {
            flex-grow: 2;

        }

        
    }
    

    .Footer-copy-line {
        justify-content: space-between;
        font-size: 11px;
        color: #6b7c88;
        padding: 15px 0;
        margin-top: 15px;
        border-top: 1px solid #e9e9e9;
        text-align: center;

        .Footer-copy-container {
            display: flex;
            flex-direction: column;
            .Footer-copyright {
                order: 777;
            }
        }
        
        @media only screen and (min-width: 700px) {
            display: flex;
            text-align: left;
            .Footer-copy-container {
                display: block;
            }
        }

        .Footer-copyright, .Footer-privacy, a {
            display: inline-block;
            @media only screen and (min-width: 700px) {
                margin-right: 20px;
            }
        }

        .Footer-copyright, a {
            color: #6b7c88;
            margin-top: 20px;
            margin-bottom: 8px;
            @media only screen and (min-width: 700px) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .Footer-privacy, a {
            color: #2c2f3c;
        }

        
    }
    
    
}

.footerImage {
    margin-top: 50px;
    max-width: 100%;
    display: block;
}