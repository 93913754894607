.HomePage {
    min-height: calc(100vh - 90px);
    // padding-top: 30px; // additional padding for larger header
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: #f3f3ee;

    .MainContent {
        flex-grow: 1;

        .container {
            max-width: 1100px;
        }
    }

    .EventTypeOptions {
        text-align: center;
        margin-top: 20px;

        .EventType {

            padding: 5px 9px;
            border-radius: 15px;
            font-weight: 600;
            font-size: 12px;
            color: #2c2f3c;
            cursor: pointer;
            
            &.selected {
                background-color: #008dfb;
                color: #fff;

                &:hover {
                    background-color: #008dfb;
                    color: #fff;
                    cursor: default;
                }
            }
            &:hover {
                background-color: #e8e8e0;
            }  
        }
    }

    .EventCards {
        margin-top: 30px;
        font-size: 13px;
        color: #2c2f3c;
        
        @media screen and (min-width: 600px) {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: 900px) {
            grid-template-columns: repeat(3, 1fr);
        }


        
        .EventCard {
            background-color: #fff;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
            padding: 20px 30px;
            border-radius: 6px;
            margin: 10px;
            cursor: pointer;
            position: relative;

            .tooltiptext {
                min-width: 33px !important;
                top: 139% !important;
                // margin-left: -65px !important;
                left: -25% !important;
                // line-height: 15px;
        
                
                // padding: 5px 10px;
                // height: 32px !important;
                // line-height: 15px;
                // font-family: "Montserrat", sans-serif;
                // font-size: 13px;
                
                em {
                    font-size: 11px;
                    
                    color: #fcb452;
                    font-style: normal;
                    font-weight: 500;
                    display: block;
                    white-space: nowrap;
                }
        
                // &:after{
                //     display: none;
                // }
            }

            .OpenRaceIcon {
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                opacity: 0;
            }

            &:hover {
                box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.2);

                .OpenRaceIcon {
                    opacity: 1;
                }
            }

            .chevron {
                display: inline-block;
                // transform: rotate(90deg);
                // margin-left: 20px;
                margin-left: 10px; //changed for the athlete list filters chevron
                font-size: 30px;
                line-height: 10px;
                position: relative;
                top: 1px;
                // &.norotate {
                //     transform: none;
                // }
              }

            .EventImage {
                margin-bottom: 20px;
                height: 50px;

                img {
                    display: block;
                    margin: 0 auto;
                    max-height: 100%;
                }
            }

            .EventTitle {
                font-weight: 600;
                text-align: center;
                margin-bottom: 10px;
            }

            .EventInfo {
                display: grid;
                grid-gap: 5px;
                grid-template-columns: 1fr 1fr;

                .EventInfoDate {
                    white-space: nowrap;
                }

                div {
                    &:nth-child(even) {
                        text-align: right;
                    }

                    .raceCount {
                        font-weight: 500;
                        font-family: "Roboto Mono", monospace;
                    }
                    
                    .totalAthleteCount{
                        font-weight: bold;
                        font-size: 15px;
                        font-family: "Roboto Mono", monospace;
                    }

                    .flag {
                        font-size: 18px;
                        margin-left: 1px;
                        margin-right: 2px;
                    }
                    .location {
                        font-size: 12px;
                        font-weight: normal;
                        position: relative;
                        top: -3px;
                    }
                }
            }

            .DropdownContainer {
                display: inline-block;
                position: relative;
                top: 2px;

                .DropdownValue {
                    justify-content: flex-end;
                    padding-right: 10px;
                    margin-right: -12px;
                    // font-size: inherit;
                    font-size: 12px;
                    border: 1px solid #e9e9e9;
                    padding-left: 15px;

                    em {
                        font-style: normal;
                    }
                }
            }
            
        }
    }
    

    .Header {
        height: 90px;

        .Sidebar {
            .SidebarButton {
                top: 22px;
            }
        }
        
        .rightbar-container {
            top: 15px; 
        }

        
    }
    
}

.HomeEventsFooterBackground {
    height: 260px;
    background-position: center;
    background-repeat: no-repeat;
}