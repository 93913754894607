.IntervalAnalysis {


    // min-height: calc(100vh - 60px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .RaceCourseContainerOuter {
        overflow: hidden;
        position: relative;
        top: -20px;
    }
    .RaceCourseContainer {
        overflow: auto;
        max-width: 100vw;
        padding: 0px 0 70px;
        position: relative;
        top: 20px;
                
    }
    

    .RaceCourse {


        min-width: 1000px;
        display: flex;
        border: 1px solid #e9e9e9;
        // margin: 30px 60px 80px;
        // margin: 35px 0px 80px;
        margin: 35px 0px -30px;
        position: relative;

        // @media screen and (max-width: 600px)  {
        //     // margin: 0;
        //     min-height: 143px;
        // }

        .D3ElevationProfile {
            position: absolute;
            top:0;
            left:0;
        }


        .start,
        .finish {
            /* width: 38px; */
            /* width: 3vw; */
            cursor: pointer;
            user-select: none;
            // width: 40px;
            width: 30px;
            height: 143px;
            box-shadow: 0 1px 0 0 #cce8fe, 0 -1px 0 0 #cce8fe;
            
            font-size: 14px;
            font-weight: 600;
            
            position: relative;
            top: 0px;
            
            color: #113b76;
            background-color: #cce8fe;

            // @media screen and (max-width: 600px)  {
            //     // display: none;
            //     font-size: 8px;
            //     position: absolute;
            // }

            &.disabled {
                color: #757575;
                background-color: #ececec;
                cursor: default;
            }
        }

        // @media screen and (max-width: 600px)  {
        //     .start {
        //         left: -30px;
        //     }
        //     .finish {
        //         right: -30px;
        //     }
        // }




        .start.active,
        .finish.active {
            background-color: #cce8fe;
            color: #113b76;
            
        }
        
        .start > .vertical-text,
        .finish > .vertical-text {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(90deg);
            
        }

        .IntervalLabel {
            // font-size: 12px;
            // color: gray;

            font-size: 10px;
            font-weight: normal;
            color: #6b7c88;

            // @media screen and (max-width: 600px)  {
            //     font-size: 8px;
            // }

        }
        .IntervalLabelZero {
            position: absolute;
            bottom: -20px;
            left: -10px;
            width: 20px;
            text-align: center;
            font-family: "Roboto Mono", monospace;
        }
        
        .Interval {
            flex-grow: 0;
            flex-shrink: 0;
            min-height: 50px;
            min-width: 30px;
            position: relative;
            border-right: 1px solid #e9e9e9;
            cursor: pointer;
            box-sizing: border-box;

            border: 1px solid transparent;
            font-family: "Roboto Mono", monospace;



            &:first-child {
                border-left: 1px solid #e9e9e9;
            }

            &.selected {
                border-top: 2px solid #fcb452;
                // border-left: 1px solid #fcb452;
                border-bottom: 1px solid black;
                cursor: default;

                &.FirstInterval {
                    border-left:2px solid #fcb452;
                }
                &.LastInterval {
                    border-right: 2px solid #fcb452;
                }


                .HighlightFirstSelected, .HighlightLastSelected {
                    background-color: #008dfb;
                    width: 10px;
                    height: 10px;
                    bottom: -5px;
                    border-radius: 5px;
                    // width: 7px;
                    // height: 7px;
                    // bottom: -3.5px;
                    // border-radius: 3.5px;
                    position: absolute;

                    z-index: 7;
                }
                .HighlightFirstSelected {
                    // left: -3.5px;
                    left: -5px;
                }
                .HighlightLastSelected {
                    
                    // right: -3.5px;
                    right: -5px;
                }
            }



            .IntervalLabel {
                position: absolute;
                width: 100px;
                white-space: nowrap;

                
                
                &.In {
                    right: 3px;
                    text-align: right;
                    font-size: 11px;
                    
                    &.Top {
                        color: #008dfb;
                        font-weight: bold;
                    }
                    &.Bottom {
                        // color: black;
                        color: #2c2f3c;
                        
                        bottom: 0;
                    }
                }
                
                &.Out {
                    color: gray;
                    right: -50px;
                    text-align: center;
                    &.Top {
                        top: -20px;
                    }
                    &.Bottom {
                        bottom: -20px;
                        font-weight: normal;
                    }
                }
            }
            

            &.last .Out {
                right: -30px !important;
                text-align: right !important;
            }
            
        } /* .Interval */



        .RaceCourseIntervals {
            display: flex;
            flex-grow: 1;
            position: relative;
            
            .Interval {
                position: relative;

                &:hover {
                    // background-color: rgba(74, 74, 74, 0.02);
                    background-color: rgba(74, 74, 74, 0.05);
                }
                
            }            
        }
        
        
        
    }
    
    
}