$minDesktop: 801px;

.FavPageHeader {
    display: flex;
    justify-content: space-between;
    // margin: 30px 0;
    margin: 30px 20px;
    font-size: 13px;
    color: #2c2f3c;
    .itemsnum {
        margin-right: 25px;
    }
    a {
        color: #008dfb;
        font-weight: 600;
    }
}
.AthleteHomeRaceListPage {

    grid-gap: 20px;
    // margin-top: 20px;
    // margin-bottom: 20px;
    // margin-left: 10px;
    // margin-right: 15px;
    margin: 20px 15px 20px 10px;
    color: #2c2f3c;
    position: relative;
    display: grid;
    
    @media screen and (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
    }

    .tooltiptext {
        min-width: 33px !important;
        top: 150% !important;
        // margin-left: -65px !important;
        left: -10% !important;
        // line-height: 15px;

        
        // padding: 5px 10px;
        // height: 32px !important;
        // line-height: 15px;
        // font-family: "Montserrat", sans-serif;
        // font-size: 13px;
        
        em {
            font-size: 11px;
            
            color: #fcb452;
            font-style: normal;
            font-weight: 500;
            display: block;
            white-space: nowrap;
        }

        &:after{
            display: none;
        }
    }
    

    
    .Card {
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 1px 3px 0 #0000001a;
        padding: 20px 30px;
        position: relative;
        cursor: pointer;

        .OpenRaceIcon {
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 20px;
            @media screen and (min-width: 800px) {
                opacity: 0;
            }
            opacity: 1;
        }


        &:hover {
            box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.2);

            .OpenRaceIcon {
                opacity: 1;
            }

            .FavIconLink {
                opacity: 1;
            }
        }


        .CardHeader {
            display: grid;
            grid-template-columns: 1fr 5fr 2.8fr;

            .avatar {
                border: 3px solid #d1e6fc;
                border-radius: 100px;
                overflow: hidden;
                width: 40px;
                height: 40px;
                margin-bottom: 2px;
                img {
                    max-width: 100%;
                }
            }
            
            .name {
                font-weight: 800;
                font-size: 15px;
                line-height: 1.4;
                margin: 0 0 0 15px;
            }
            .flag {
                text-align: center;
                font-size: 20px;
            }
            .bib-info {
                text-align: right;
                .bib {
                    margin-bottom: 8px;
                }
            }
            .hometown {
                margin-left: 15px;
            }
            .hometown, .time {
                display: flex;
                align-items: center;
                font-size: 13px;
            }
            .time {
                justify-content: flex-end;
                font-weight: 600;
                font-family: "Roboto Mono","Montserrat", sans-serif;
                font-size: 15px;
                padding-bottom: 2px;
            }
            
        }

        .Label {
            display: flex;
            margin: 10px 0;
            justify-content: center;
            align-items: center;
            i {
                flex-grow: 1;
                background: #e9e9e9;
                height: 1px;
            }
            span {
                padding: 3px 14px;
                border-radius: 20px;
                font-size: 12px;
                font-weight: 600;
                margin: 0 10px;
                background-color: #e1f1fe;
            }
        }

        dl.infotable {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 0.7fr;
            grid-gap: 3px 0 ;


            &>* {
                white-space: nowrap;
            }
            
            dt {
                // text-align: right;
                font-size: 11px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }
            dd {
                font-family: "Roboto Mono","Montserrat", sans-serif;
                padding: 0;
                margin: 0px 0 0 10px;
                font-weight: 500;
                // font-size: 15px;
                font-size: 13px;

                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                
                &.right-aligned {
                    justify-content: flex-end;
                    // text-align: right;
                }

                &.splitsResult {
                    padding-left: 10px;
                    // padding-bottom: 8px;
                }
            
                &.plus {
                color: #28bb28;
            
                }
            
                &.minus {
                color: #ff3800;
            
                }
            }

        }

        .categories {
            display: flex;
            justify-content: center;
            margin-bottom: 16px;
            button {
                margin: 0 5px;
                padding: 3px 5px;
                color: inherit;
                font-weight: 600;
                font-family: inherit;
                border: 1px solid #aeaeae;
                border-radius: 2px;
                cursor: pointer;
                &:hover{
                    border: 1px solid #e9e9e9;
                }
                &.active {
                    border-color: #ff4500;
                }
                &:focus {
                    outline: none;
                }
            }
        }

        h3 {
            margin: 15px 0 6px;
            font-weight: 600;
            font-size: 12px;
        }

        .infoline {
            display: flex;
            justify-content: space-between;
            font-size: 11px;

            :last-child {
                font-size: 13px;
                font-family: "Roboto Mono","Montserrat", sans-serif;
                font-weight: 500;
                position: relative;
                top: -3px;
            }
            .dot {
                top: 0px;
            }
        }


        

        
        .FavIconLink {
            // display: none;
      
            @media screen and (min-width: 800px) {
                opacity: 0;
            }
            opacity: 1;
            position: absolute;
            top: 7px;
            left: 7px;

            &.isFavorite {
                opacity: 1;
                .IconSwappable {
                    .icon { display: block !important; }
                    .hover { display: none !important; }
                }
            }
        }
        .FavIconLink, .FavIconLink .IconSwappable, .FavIconLink .IconSwappable>* {
            
            min-width: 16px;
            min-height: 16px;
        }
        // .FavButton {
        //     display: none;
        //     position: absolute;
        //     top: 7px;
        //     left: 7px;
        // }
        
        // &:hover .FavButton {
        //     display: block;
        //     cursor: pointer;

        //     .icon { display: block !important; }
        //     .hover { display: none !important; }
        //     &:hover {
        //         .icon { display: none !important; }
        //         .hover { display: block !important; }
        //     }
        // }

        // &.favorite {
        //     .FavButton {
        //         display: block !important;
        //         .icon { display: none !important; }
        //         .hover { display: block !important; }
        //     }
        // }

        
    }
    
}