.SelectAthlete {

    // min-height: calc(100vh - 60px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    background: #f3f3ee;
    
    .table {
        margin: 8px auto 0;
        @media screen and (min-width: 801px) {
            margin-top: 20px;
        }
    }
}