.WelcomeBib {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    

    h1 {
        color: #008dfb;
        margin: 0;
        // padding: 15px;

        margin-bottom: 10px;
        // margin-top: -5px;
        margin-top: 15px;

        @media screen and (max-width: 600px) {
            margin-bottom: 5px;
            font-size: 22px;
        }

        .athleteName {

            .tooltiptext {
                min-width: 50px !important;
                margin-left: -65px !important;
                top: 129% !important;
                line-height: 15px;
        
                font-size: 12px;
        
                padding: 5px 10px;
                // height: 32px !important;
                // line-height: 15px;
                // font-family: "Montserrat", sans-serif;
                // font-size: 13px;
        
                em {
                  color: #71e1f4;
                  font-style: normal;
                  display: block;
                  white-space: nowrap;
                }
            }
        }
        

        
        span:hover {
            background-color:rgba(204, 232, 254, 0.3);
            color: #2c2f3c;
            // cursor: pointer;
            cursor: default;

            padding-left: 16px;
            padding-right: 16px;
            border-radius: 18px;
        }
    }
    span {
        // color: #2c2f3c;
        color: #008DFB;
        margin: 0;
    }
    .bib {
        font-size: 28px;
        font-weight: 700;
        margin: 14px 0 9px 0;

        @media screen and (max-width: 600px) {
            margin: 4px 0 5px 0;
            font-size: 20px;
        }
    }

    .userAvatar {
        margin: 0 0 18px 0;
        max-width: 90px;
        align-self: center;
        height: 90px;
    }
    .userAvatar2 {
        // margin: 20px 0 18px 0;
        margin: 20px auto 24px;
        width: 90px
    }
    
    .cityStateCountry {
        // font-size: 36px;
        font-size: 42px;
        margin: 0 0;
        font-weight: 600;

        
        /* margin: 0 0 10 0; */
        // margin: 0;
        span:hover {
            cursor: default;
        }

        @media screen and (max-width: 600px) {


            margin: 0px;
            font-size: 36px;
        }
    }

    .cityStateCountryLabel {
        font-size: 15px;
        font-weight: 500;
        /* margin: 0 0 10 0; */
        // margin: 10px 0 20px 0;
        // margin: 0px 0 20px 0;
        margin: 0px 0 0px 0;
        span:hover {
            cursor: default;
        }

        .countryFlag {
            font-size: 22px;
            position: relative;
            left: -5px;
            top: 3px;
        }

        // @media screen and (max-width: 600px) {
        //     margin: -6px;
        //     font-size: 22px;
        // }
    }
    
    .ageGender {
        font-size: 1rem;
        /* font-weight: 600; */
        /* margin: 0 0 10 0; */
        margin: 0;
    }

    .athleteName {
        font-weight: 600;
    }
    .timeContainer {
        padding-bottom: 15px;
        // position: relative;
        // .numericFont {
        //     font-family: "Roboto Mono", monospace;
        // }

        .nextPage {
            position: relative;
            top: 5px;
            left: 15px;
            display: inline-block;



            &:hover{ 
                .background {
                    transform: scale(1);
                }
            }

            .background {
                display: block;
                transform: scale(0);
                transition: transform 200ms;
                transition-timing-function: ease-out;
                position: absolute;
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background-color: rgba(204, 232, 254, 0.3);
                top: 0px;
                left: 0px;
                z-index: 1;
            }
            img {
                z-index: 2;
                position: relative;
            }
            
        }

    }
    .time {
        display: inline-block;

        background-repeat: no-repeat;
        color: #2c2f3c;


        font-size: 40px;
        font-weight: 600;
        margin-top: 4px;

        padding-left: 56px;
        background-position: 0px 4px;
        background-size: 40px;



        @media screen and (max-width: 600px) {


            margin-top: 2px;
            // font-size: 20px;
       
            // width: 15px;
            // height: 15px;
        

        }
    }

    
    
}   

