.SuperAdminOptions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;

    padding: 8px 0;
    margin: 0 20px;
    
    @media screen and (min-width: 801px) {
        border-top: none;
        border-bottom: none;

        padding: 0;
        margin: 0;
    }
    
    



    .button {
        box-sizing: border-box;
        // border: 1px solid #008dfb;
        // background-color: #fff;
        // color: #008dfb;
        border: 2px solid #e8e8e0;
        background-color: transparent;
        color: #2c2f3c;

        padding: 0 15px;
        margin: 8px 4px;
        height: 26px;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        border-radius: 13px;
        cursor: pointer;
        white-space: nowrap;



        &:hover{
            background-color: #e8e8e0;
        }

        @media screen and (min-width: 801px) {
            // margin: 0 14px;
            margin: 0 7px;


        }
        
        &.selected {
            // border: 1px solid #008dfb;
            // border: 1px solid #2c2f3c;
            // color: #2c2f3c;
            border: 1px solid #008dfb;
            color: #fff;
            // color: #fff;
            // background-color: #008dfb;
            // background-color: #ffffff;
            background-color: #008dfb;
            cursor: default;
        }


    }
    .line {
        height: 1px;
        // background-color: #2c2f3c;
        background-color: #aeaeae;
        flex-grow: 1;
        display: none;
        @media screen and (min-width: 801px) {
            display: block;
        
        }
    }

    .line + .button {
        margin-left: 24px;
    }
    .button + .line {
        margin-left: 20px;
    }
}