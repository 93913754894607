.SidebarOpenButton {
    width: 20px !important;
    height: 54px !important;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    position: fixed;
    transform: translateX(0px);
    top: 80px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 800ms;
    z-index: 7;

    &:hover {
        background-color: #113b76;
    }

    &.hidden {
        // right: -1200px;
        transform: translateX(800px);
    }


}



.MapSidebar {
    z-index: 7;
    position: fixed;
    right: 10px;
    top: 70px;
    border-radius: 12px;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    transform: translateX(0px);
    transition: transform 800ms;
    

    &.hidden {
        // right: -1000px;
        transform: translateX(-5000px);
    }



    &.closed {
        // right: -500px;
        transform: translateX(1000px);
    }
    &.open {
        width: 330px;
        height: calc(100vh - 85px);
        overflow: auto;
        border-left: 1px solid #dedede;
    }

    .SidebarOpenButton {
        // right: 1200px;
    }
    


    .HideButton {
        cursor: pointer;
        color: gray;
        display: block;
        position: absolute;
        top: 10px;
        left: 15px;
        font-size: 12px;

        &:hover{
            // color: #008dfb;
            color: #008dfb;
        }
    }


    .MapSidebarContent {

        font-family: "Montserrat", sans-serif;

        .EdgesLine {
            display: flex;
            justify-content: space-between;
            font-size: 13px;
            font-weight: 600;
            color: #2c2f3c;
            position: relative;
            top: -5px;
            margin: 0 20px;
        }

        .toggle-units-container {
            justify-content: center;
            font-size: 13px;

            padding: 0;
            margin: 4px 10px 10px 10px;
            border-top: 1px solid #e9e9e9;
            padding-top: 15px;
        }



        .centered {
            text-align: center;
        }

        .toprow {
            display: flex;
            margin-top: 40px;
            justify-content: space-evenly;

            .searchIcon {
                position: relative;
                right: 0;
                top: 0;
            }
            
        }

        .toggle-age-grade-container {
            display: flex;
            justify-content: center;
            margin-bottom: 25px;
            margin-top: 15px;
        }

        .RaceDistance {

            display: flex;
            margin-top: 20px;
            justify-content: center;
            align-items: center;

            span {
                background-color: #e1f1fe;
                padding: 3px 14px;
                border-radius: 20px;
                color: #2c2f3c;
                text-transform: none;
                font-size: 12px;
                font-weight: 600;
                margin: 0 10px;
            }
            i { // the gray line
                flex-grow: 1;
                background: #e9e9e9;
                height: 1px;
            }
        }

        .raceLogoContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            img.raceLogoImage {
                max-width: 180px;
                margin-top: 15px;
            }
        }


        .RaceName {
            margin-top: 20px;
            font-weight: 600;
            font-size: 13px;
            color: #008dfb;
        }
        .RaceDate {
            color: #2c2f3c;
            font-size: 13px;
            margin-top: 8px;
        }
        .cityStateCountry {
            margin: 5px;
            font-size: 18px;
        }
        
        .AthleteName {
            font-weight: 600;
            font-size: 15px;
            color: #008dfb;
        }
        .AthleteInfo {
            color: #2c2f3c;
            margin-top: 7px;
            .division {
                font-weight: 600;
            }
        }
        .dot {
            color: #008dfb;
            display: inline-block;
            padding: 0 6px;
            margin: 0;
            font-weight: 600;
        }
        .ToggleInterval {
            margin-top: 18px;
        }

        .IntervalAnalysisBib {
            .details {
                font-size: 12px;
                line-height: 22px;

                dl {
                    grid-template-columns: 1.1fr 2fr;
                    &:first-child {
                        margin-right: 0;
                    }
                }
                dd {
                    text-align: left;
                }
            }


            
        }
        
        .D3ElevationProfile {
            svg {
                display: block;
                margin: 0 auto;
            }
        }

        .IntervalsContainer {
            display: flex; 
            width: 330px;
            margin: 0 auto;

            .Interval {
                height: 1px;

                &.selected {
                    box-sizing: border-box;
                    height: 41px;
                    border-bottom: 1px solid #92cf48;
                    position: relative;
                    top: -40px;
                    margin-bottom: -20px;
                    background-color: #fbfbfb;
                    z-index: -1;
                }
            }
        }

        .IntervalControls {
            display: flex;
            justify-content: center;
            align-items: center;

            .label {
                font-size: 11px;
                color:#6b7c88;
                font-weight: 600;
                min-width: 40px;
                text-align: center;
            }

            .IntervalControlBtn {
                margin: 0 16px;

                .BtnImage {
                    cursor: pointer;

                    img {
                        display: block;
                        width: 20px;
                        height: 20px;
                    }
                }
                .BtnImage.active { display: block; } 
                .BtnImage.disabled { display: none; }

                &.disabled {
                    .BtnImage.active { display: none; } 
                    .BtnImage.disabled { display: block; }
                }

                &.prev {}
                &.next {}

            }
            .IntervalIndex {
                font-size: 11px;
                color: #909fa9;
                min-width: 40px;
                text-align: center;
            }
        }
        
        .SponsorsCarousel {
            margin-top: 30px;
            margin-bottom: 5px;

            display: flex;
            width: 200px;
            height: 100px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;

            img {
                margin-top: 20px;
                max-width: 100%;
                max-height: 100px;
            }
        }
        
        .SegmentNavigation.IntervalControls {
            position: absolute;
            top: 0px;
            right: 0;

            .IntervalControlBtn {
                margin: 0 6px;
            }
        }
        
    } /* sidebar content */
    
    
}