.ResultsSummary {

    // min-height: calc(100vh - 60px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    


    
    .RaceCourseContainerOuter {
        overflow: hidden;
        position: relative;
        top: -20px;
    }
    .RaceCourseContainer {
        overflow: auto;
        max-width: 100vw;
        padding: 0px 0 70px;
        position: relative;
        top: 20px;
    }
    
    .RaceCourse {
        
        min-width: 1000px;
        display: flex;
        border: 1px solid #e9e9e9;
        // margin: 30px 60px 80px;
        // margin: 20px 60px 80px;
        // margin: 35px 60px 80px;
        margin: 35px 0px -30px;
        position: relative;

        // @media screen and (max-width: 600px)  {
        //     min-height: 143px;
        // }

        .D3ElevationProfile {
            position: absolute;
            top:0;
            left:0;
        }


        .start,
        .finish {
            /* width: 38px; */
            /* width: 3vw; */
            width: 30px;
            height: 143px;
            box-shadow: 0 1px 0 0 #cce8fe, 0 -1px 0 0 #cce8fe;
            
            font-size: 14px;
            font-weight: 600;
            
            position: relative;
            top: 0px;
            
            color: #113b76;
            background-color: #cce8fe;

            // @media screen and (max-width: 600px)  {
            //     font-size: 8px;
            //     position: absolute;
            // }
        }
        // @media screen and (max-width: 600px)  {
        //     .start {
        //         left: -30px;
        //     }
        //     .finish {
        //         right: -30px;
        //     }
        // }




        .start.active,
        .finish.active {
            background-color: #cce8fe;
            color: #113b76;
            
        }
        
        .start > .vertical-text,
        .finish > .vertical-text {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(90deg);
            
        }

        .IntervalLabel {
            font-size: 11px;
            // color: gray;

            color: #909fa9;
            font-weight: normal;
            font-family: "Roboto Mono", monospace;
            // @media screen and (max-width: 600px)  {
            //     font-size: 8px;
            // }

        }
        .IntervalLabelZero {
            position: absolute;
            bottom: -20px;
            left: -10px;
            width: 20px;
            text-align: center;
            color: #909fa9;
            font-weight: normal;
            font-family: "Roboto Mono", monospace;
        }
        
        .Interval {
            flex-grow: 0;
            flex-shrink: 0;
            min-height: 50px;
            min-width: 30px;
            position: relative;
            border-right: 1px solid #e9e9e9;
            // background-color: #fff;
            // background-color: rgba(255, 255, 255, 0.8);
            &:first-child {
                border-left: 1px solid #e9e9e9;

            }


            .IntervalLabel {
                position: absolute;
                width: 100px;
                white-space: nowrap;

                
                
                &.In {
                    right: 3px;
                    text-align: right;
                    &.Top {
                        color: #008dfb;
                    }
                    &.Bottom {
                        color: black;
                        bottom: 0;
                    }
                }
                
                &.Out {
                    color: gray;
                    right: -50px;
                    text-align: center;
                    &.Top {
                        top: -20px;
                    }
                    &.Bottom {
                        bottom: -20px;
                    }
                }
                
            }
            

            &.last .Out {
                right: -30px !important;
                text-align: right !important;
            }
            
        }/* .Interval */
    

        .RaceCourseIntervals {
            display: flex;
            flex-grow: 1;
            position: relative;
            
            .Interval {
                position: relative;

                &:hover {
                    background-color: rgba(74, 74, 74, 0.02);
                }

                .IntervalInfoBlock {
                    position: absolute;
                    top: 3px;
                    right: 3px;
                    width: 155px;
                    min-height: 40px;
                    background-color: #f0f9ff;
                    border-radius: 2px;
                    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);

                    dl {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-column-gap: 10px;
                        // font-weight: 500;
                        font-weight: 400;
                        font-size: 11px;
                        // letter-spacing: -0.5px;

                        .intervalBold {

                            font-style: normal;
                            font-weight: bold;
                            color: #008dfb;
                        }

                        // i {
                        //     font-style: normal;
                        //     font-weight: bold;
                        //     color: #008dfb;
                        // }
                        .units {
                            font-size: 9px;
                        }

                        dt,dd {
                            // margin: 0;
                            margin: 0 0 0 10px;
                            padding: 0;
                            white-space: nowrap;
                            line-height: 15px;
                            
                            color: #2c2f3c;
                            .plus{
                                color: #28bb28;
                            }
                            .minus {
                                color: #ff3800;
                            }
                        }

                        dt {
                            text-align: right;
                        }
                        dd {
                            
   

                        }
                    }
                }
                
            }            
        }

        .TableContainer {
            flex-grow: 1;
            display: flex;
            font-size: 13px;
            background-color: #fff;

            p {
                margin: 0;
                line-height: 28px;
            }
            .highlight {
                background: rgba(204, 232, 254, 0.3);
            }
            .TableHeader {
                flex-grow: 2;
                min-width: 150px;
                text-align: right;
                min-width: 180px;
                max-width: 180px;
                p {
                    padding-right: 10px;
                }
            }
            .IntervalData {
                flex-grow: 1;
                text-align: center;
                border-left: 1px solid #e9e9e9;


                .splits {
                    // color: #909fa9;
                    color: #2c2f3c;
                    font-weight: normal;
                }
                .distance {
                    
                    font-weight: bold;
                }
                .pace {
                    color: #008dfb;
                    font-weight: bold;
                }
                .diff {
                    // font-size: 80%;
                    font-size: 11px;
                    &.plus {
                        color: #ff3800;
                    }
                    &.minus {
                        color: #28bb28;
                    }
                }
                .time {
                    color: #2c2f3c;

                }
            }
            
            

            @media screen and (max-width: 600px)  {
                /* .TableContainer */
                // & {
                //     display: block;
                // }
                // .IntervalData {
                //     border-bottom: 1px solid #008dfb;
                //     padding: 5px 0;
                // }
                // .TableHeader {
                //     display: none;
                // }
                // .splits:before {
                //     content: "Checkpoint: ";
                // }
                // .distance:before {
                //     content: "Distance: ";
                // }
                // .pace:before {
                //     content: "Pace: ";
                // }
                // .diff:before {
                //     content: "Diff: ";
                // }
                // .time:before {
                //     content: "Time: ";
                // }
            }
        }
    }
}


.MainAthleteBlockHeader {
    display: flex;

    // .userAvatar {
    //     position: absolute;
    //     top: 20px;
    //     left: 30px;
    //     width: 40px;
    // }

    .userAvatarContainer {
        display: block;
        border: 2px solid #cce8fe;
        width: 52px;
        height: 52px;
        img {
            width: 48px;
            height: 48px;
        }

        &.uploadedImage {
            padding: 0px;
            .userAvatar {
                width: 48px;
                height: 48px;
            }
        }
    }
}