.header {
  
  @media screen and (min-width: 801px) {
    display: flex;
    justify-content: space-between;    
    &>div {
      flex: 1;
    }
  }


  .logo-container {
    display: flex;
    // padding-top: 10px;
    padding-top: 35px;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    /* cursor: pointer; */
    user-select: none;
    padding-left: 26px;
    @media screen and (max-width: 600px) {
      padding-top: 12px;
    }

    .container {
      // margin-top: -20px;

      .tooltip {
        .tooltiptext {
            min-width: 80px !important;
            margin-left: -52px !important;
            top: 111% !important;
            // height: 32px !important;
            // line-height: 15px;
            // font-family: "Montserrat", sans-serif;
            // font-size: 13px;
        }
      }


    }
  }

  .menu_button {
    border: 10px solid #fff;
    border-radius: 50px;
    position: relative;
    left: -10px;
    top: 0;
    background-color: #fff;
    display: inline-block;
    z-index: 7;
    cursor: pointer;
    
    
    
      &:hover .menu_button_img {
        display: none;
      }
      .menu_button_img {
        display: block;
      }
      .menu_button_img_hover {
        display: none;
      }
      &:hover .menu_button_img_hover {
        display: block;
      }
  }

  

  .logo-img-container {
    z-index: 7;
    position: relative;
    padding-left: 34px;

    img {
      width: 140px;
      display: block;
    }

    @media screen and (max-width: 600px) {
      padding-top: 0px;
      padding-bottom: 15px;
    }

  }

}

.header > .athlete-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #2c2f3c;
  font-size: 15px;
  font-weight: 500;
  flex-grow: 2;
  margin: 0 10px;
  padding-top: 25px;
  @media screen and (max-width: 600px) {
    padding-top: 0px;
    padding-bottom: 5px;
  }

  .DropdownContainer {
    display: inline-block;
    
    em {
        font-style: normal;
        font-size: 15px;
    }
  }

}


.social-icon {
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  outline: none;
  
}

.athlete-info {
  span.flag {
    font-size: 20px;
    display: inline-block;
    position: relative;
    top: 3px;
    padding-right: 5px;
    cursor: default;
    @media screen and (max-width: 600px) {
      padding-right: 0;
      
    }

  }
  a {
    font-weight: 700;
    color: #008dfb;
    text-decoration: none;
  }
  span.bib {
    display: inline-block;
    padding-left: 7px;
    @media screen and (max-width: 600px) {
      padding-left: 0;
      margin: 0 0 2px 0;
    }
  }
  .name {
    font-weight: 600;
    color: #008dfb;

  }
  .event-name {
    font-weight: 700;
    // color: #008dfb;
  }
  .event-location {
    font-weight: normal;
    .event-location-flag {
      position: relative;
      top: 2px;
    }
  }
  .event-date {
    font-weight: normal;
    margin-left: -12px;

    // .DropdownContainer {
    //   .DropdownValue {
    //     padding-left: 0px;
    //   }
    // }

    .tooltip {
      z-index: 7;
      .tooltiptext {
          min-width: 50px;
          margin-left: -90px;
          top: 165%;
          // font-family: "Montserrat",sans-serif;
          font-size: 13px;
          
      }
    }

  }
  .dot {
    padding: 0 18px;
  }
  i { /* dot */
    font-style: normal;
    color: #008dfb;
    display: inline-block;
    padding: 0 10px;
    font-weight: 600;
  }
  span.divisionType {
    font-weight: 600;
  }
  span.athleteName {
    color: #008dfb;
    &:hover {
      // background-color: #eff8fe;
      background-color:rgba(204, 232, 254, 0.3);
      color: #2c2f3c;
      cursor: pointer;
    }
    @media screen and (max-width: 600px) {
      margin: 0 0 2px 0;
    }
  }

  @media screen and (max-width: 600px) {
    .event-name {
      display: block;
      margin-bottom: 5px;
      &+.dot {
        display: none;
      }
    }
    .dot {
      color: #008dfb;
      font-size: 11px;
    }
    .event-location, .event-date {
      font-size: 11px;
      color: #2c2f3c;
    }
  }

  
}
.toggle-units-container {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

.rightbar-container {
  display: flex;
  justify-content: flex-end;
}

.rightbar-container-block {

  &.search-icon-container {
    display: flex;
    align-items: center;
    // padding: 25px 30px 0 0;
    padding: 20px 25px 0 0;

    .searchIcon {
      position: relative;
      top: 0;
      left: 0;
      right: auto;
    }

    @media screen and (max-width: 600px) {
      // padding-right: 0;
      display: none;
    }
    
  }

  &.profile-icon-container {
    padding: 20px 30px 0 0;
  }

  
}
.rightbar-container-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 30px;
  user-select: none;
  
  
  &-1 {
    padding-top: 38px;
    @media screen and (max-width: 600px) {
      // padding-top: 0;
      display: none;
    }
  }
  &-2 {
    position: relative;
    top: 16px;
    justify-content: flex-end;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    padding-right: 0;
  }
}

.searchIcon {
  position: absolute;
    right: 50px;
    top: 40px;
    width: 30px;
    z-index: 7;
    
    @media screen and (max-width: 600px) {
      right: 20px;
      top: 40px;
    }
}