$minDesktop: 801px;

.LoginPopupContainer {
    // padding-left: 100px;
    position: relative;
    

    // @media screen and (max-width: 600px) {
    //     display: none;
    // }

    .LoginIcon {
        width: 40px;
        height: 40px;
        // box-shadow: inset 0 0 0 3px #cce8fe;
        border: 3px solid #cce8fe;
        border-radius: 100%;
        cursor: pointer;
        &:hover {
            background-color: #008dfb;
            border-color: #008dfb;
        }
    }
    .isDropdownOpen {
        background-color: #FFF;
        border-color: #cce8fe;
    }
}


.LoginModalContent {
    $width: 480px;
    position: fixed;
    top: 0;
    left: 50%;
    width: $width;
    margin-left: -($width/2);
    padding: 0 30px 40px;

    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    background-color: #fff;
    // padding: 40px;
    // padding: 20px 50px 40px 40px;
    box-sizing: border-box;
    min-height: 150px;
    max-height: 100vh;
    overflow: auto;
    z-index: 900;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);

    .tooltiptext {
        min-width: 33px !important;
        top: 139% !important;
        // margin-left: -65px !important;
        left: 65% !important;
        // line-height: 15px;

        
        // padding: 5px 10px;
        // height: 32px !important;
        // line-height: 15px;
        // font-family: "Montserrat", sans-serif;
        // font-size: 13px;
        
        em {
            font-size: 11px;
            
            color: #fcb452;
            font-style: normal;
            font-weight: 500;
            display: block;
            white-space: nowrap;
        }

        // &:after{
        //     display: none;
        // }
    }

    @media screen and (min-width: $minDesktop) {
        top: 110px;
    }

    .Label {
        display: flex;
        margin: 20px 0;
        justify-content: center;
        align-items: center;
        i {
            flex-grow: 1;
            background: #e9e9e9;
            height: 1px;
        }
        span {
            padding: 3px 14px;
            border-radius: 20px;
            font-size: 12px;
            font-weight: 600;
            margin: 0 10px;
            color: #2c2f3c;
            background-color: #e1f1fe;
        }
    }


    img.runner {
        display: block;
        margin: 45px auto 10px;
    }
    
    h1 {
        text-align: center;
        margin: 0;
        font-size: 28px;
        color: #2c2f3c;
    }

    .logo {
        display: block;
        text-align: center;
        margin: 20px 0 10px;
    }

    p {
        text-align: center;
    }
    
    .tagline {
        font-size: 12px;
        color: #2c2f3c;
        font-weight: 600;
        margin: 20px 0;
    }


    @media screen and (max-width: 600px) {
        left: 0;
        margin-left: 0;
        width: 100vw;
        padding-top: 40px;
    }

    .FormErrorMessage {
        color: #c11515;
        // padding: 0px 20px 10px;
        padding: 0px 20px 0px;
        text-align: center;
    }

    .secure-message{
        text-align: center;
        font-size: 13px;
        color: #2c2f3c;
        margin-bottom: 0;
        margin-left: 30px;
        margin-right: 30px;
    }


    // ✉
    // 🔒
    
    .control {
        width: calc(100% - 40px);

        position: relative;
        margin: 0 auto;
        box-sizing: border-box;
        max-width: 320px;

        .ControlIcon {
            display: flex;
            width: 38px;
            height: 34px;

            position: absolute;
            top: 1px;
            right: 0px;

            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;

            justify-content: center;
            align-items: center;
            font-size: 18px;
            color: #929292;
        }


        // &.email {
        //     &::before {
        //         content: '✉';
        //     }
        // }
        // &.password {
        //     &::before {
        //         content: '🔒';
        //     }

        // }
    }

    .control.PasswordField {
        display: flex;
        align-items: center;

        label {
            flex-grow: 1;

            input {
                // background-color: rgb(204, 232, 254);
                border-radius: 30px; // 0px 0px 30px;
            }
        }

        .RevealButton {
            cursor: pointer;
            height: 36px;
            line-height: 36px;
            padding-right: 10px;
            padding-top: 4px;
            box-sizing: border-box;
            position: absolute;
            right: 0;
            top: 10;

            
            // background-color: rgb(204, 232, 254);
            // background-color: rgba(204, 232, 254, 0.3);
            // border-radius: 0px 30px 30px 0px;
            &:focus {
                // background-color: rgba(204, 232, 254, 0.2);
            }
        }
        
        
        
        .IconSwappableReveal {
            .icon { display: block; }
            .valid { display: none; }
            .invalid { display: none; }
        }
        
        &.invalid {
            .IconSwappableReveal {
                .icon { display: none ; }
                .valid { display: none; }
                .invalid { display: block; }
            }
            
        }
        &.valid {
            .IconSwappableReveal {
                .icon { display: none ; }
                .valid { display: block; }
                .invalid { display: none; }
            }
        }

    }

    .rememberpass {
        text-align: center;
        margin: 20px 0;
        cursor: pointer;
        user-select: none;
    }

    input[type="password"], input[type="email"], input[type="text"] {
        width: 100%;
        display: block;
        box-sizing: border-box;
        margin: 10px 0;
        padding: 10px;
        border-radius: 30px;
        // border: 1px solid lightgray;
        height: 36px;
        padding-left: 20px;
        // background-color: rgba(204, 232, 254, 0.3); // blue
        background-color: #f1f7fd; // blue
        border: 2px solid #f1f7fd;
        
        &:hover {
            
            border-color: #ddedfd;
            // background-color: rgba(204, 232, 254, 0.2); // blue
        }
        
        &:focus {
            outline: none;
            border-color: #ddedfd !important;
            background-color: #fff !important;
        }

        &::placeholder {
            color: #000;
            font-weight: 600;
        }
    }

    .invalid {
        input[type="password"], input[type="email"], input[type="text"] {
            // background-color: #f7ebe7;
            // border-color: #f7ebe7;
            background-color: #ffe1d9;
            border-color: #ffe1d9;
            &:hover {
                border-color: #f9e2d9;
            }
        }
    }
    .valid {
        input[type="password"], input[type="email"], input[type="text"] {
            // &:-webkit-autofill {
            //     background-color: #f0faf3 !important;
            //     border-color: #f0faf3;
            //     &:hover {
            //         border-color: #daf4e0;
            //     }
            // }
            background-color: #f0faf3;
            border-color: #f0faf3;
            &:hover {
                border-color: #daf4e0;
            }
        }
    }

    input[type="submit"] {
        display: block;
        /* width: 100%; */
        border: none;
        padding: 5px 40px 5px 30px;
        background: #008dfb;
        /* margin-top: 20px; */
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 2px;
        cursor: pointer;
        // margin: 35px auto 30px;
        margin: 15px auto 30px;
        border-radius: 30px;
        background-repeat: no-repeat;
        background-position: right 23px top 7px;
        outline: none;
    }


    .ForgotPasswordContainer {

        width: calc(100% - 40px);
        max-width: 320px;
        margin: 0 auto;
        text-align: right;
        padding-right: 25px;
        
        .ForgotPasswordLink {
            display: inline-block;
            cursor: pointer;
            font-size: 11px;
            color: #2c2f3c;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        // margin-top: 40px;
        margin-top: 15px;
        button, .button  {
            box-sizing: border-box;
            display: block;
            // border-radius: 60px;
            
            background: #008dfb;
            color: #ffffff;
            font-size: 15px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            // padding: 1px 27px;
            padding: 1px 25px;
            margin: 0 10px;
            border: 2px solid #008dfb;
            font-family: inherit;
            cursor: pointer;
    
            border-radius: 17px;

            &:hover {
                border: solid 2px rgba(0, 0, 0, 0.05);
                background-color: #0086ef;
            }
            
            &.secondary {
                background-color: #fff;
                color: #008dfb;
                border-color: #cce8fe;

                &:hover {
                    border: solid 2px rgba(0, 141, 251, 0.5);
                }
            }

            &[disabled] {
                background: #e4e4e4;
                color: #ffffff;
                border: 2px solid #d6d6d6;
                cursor: default;
            }

        }
    }

    input, button {
        &:focus {
            outline: none;
        }
    }


    .ClearIcon {
        cursor: pointer;
        max-width: 20px;
        height: 20px;

        position: absolute;
        top: 20px;
        right: 25px;
        z-index: 10;

        &:hover {  
            .background {
                transform: scale(1);
            }
        }
    
        .background { 
            transform: scale(0);
            transition: transform 200ms;
            transition-timing-function: ease-out;
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            // background-color: #93b9ff;
            background-color: rgba(204, 232, 254, 0.3);
            top: -6px;
            left: -5px;
            z-index: -5;
        }
    }
}


.LoginTabs {
    display: flex;
    margin-bottom: 20px;
    
    & > * {
        padding: 15px;
        flex: 1;
        border-bottom: 1px solid gray;
        text-align: center;
        cursor: pointer;
        color: #008dfb;

        font-size: 14px;
        font-weight: bold;
        
        
        &.active {
            border-bottom-color: black;
            color: #2c2f3c;
            cursor: default;
        }

    }


}

.withLine {
    display: flex;
    justify-content: center;
    align-items: center;

    &:before, &:after {
        display: block;
        content: " ";
        flex-grow: 1;
        border-bottom: 1px solid #e9e9e9;
    }
    &:before {
        margin-right: 7px;
    }
    &:after {
        margin-left: 7px;
    }
}



.LoggedInUserInitials {
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    /* display: flex; */
    height: 50px;
    width: 50px;
    /* justify-content: center; */
    /* align-items: center; */
    font-size: 21px;
    border-radius: 100%;
    line-height: 43px;
    text-align: center;
    color: #008dfb;
    // box-shadow: inset 0 0 0 3px #cce8fe;

    border: 3px solid #cfd8dc;

    img { // the avatar image
        width: 36px;
        height: 36px;
        border: 0px solid #fff;
        border-radius: 39px;
        position: absolute;
        top: 4px;
        left: 4px;
    }

    &:hover {
        background-color: #008dfb;
        // border-color: #008dfb;
        border-color: #cce8fe;
        color: #fff;
    }
    &.withAvatarImage {
        &:hover {
            background-color: transparent;
        }
    }

    &.uploadedImage {
        padding: 4px;
        .userAvatar {
            width: 74px;
            height: 74px;
        }
    }
    
}


.LoginCover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 11;
}

.LoginSocial {
    text-align: center;
    color: #2c2f3c;
    margin-bottom: 25px;

    .LoginSocialIcons {
        display: flex;
        justify-content: center;

        .Icon {
            box-sizing: border-box;
            width: 40px;
            height: 40px;
            margin: 0px 6px;
            border: 1px solid #008dfb;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
                border-color: transparent;
                box-shadow: 0px 0px 0px 4px rgba(121, 158, 187, 0.3);
            }
        }
    }
}


.IconSwappableValid, .IconSwappableValid > * {
    background-repeat: no-repeat;
    background-position: center;
    min-width: 10px;
    min-height: 10px;
    width: 100%;
    height: 100%;
    background-size: 16px;
}
.invalid .IconSwappableValid {
    .icon { display: none !important ; }
    .valid { display: none !important; }
    .invalid { display: block; }
}
.valid .IconSwappableValid {
    .icon { display: none !important ; }
    .valid { display: block; }
    .invalid { display: none !important; }
}
.IconSwappableValid {
    .icon {display: block;}
    .valid { display: none; }
    .invalid {display: none;}
}


.DropdownMenu {
    box-sizing: border-box;
    position: absolute;
    top: -7777px;
    left: -7777px;
    z-index: 7;
    min-width: 200px;
    min-height: 10px;
    background-color: #fff;
    // border: 1px solid black;
    border-radius: 6px;
    opacity: 0;
    transition: opacity 200ms;
    padding: 10px 15px 15px 15px;
    font-family: Montserrat,sans-serif;
    font-weight: 400;



    
    
    &.show {
        opacity: 1;
        top: 60px;
        left: -18px;
    }

    .hr {
        height: 1px;
        background-color: #e9e9e9;
        margin: 5px 0 8px;
        &.spacer {
            background-color: transparent;
            height: 5px;
            margin: 0;
        }
    }
    .Option {
        text-align: left;
        font-size: 14px;
        cursor: pointer;
        display: block;
        font-weight: 500;
        border-radius: 20px;
        color: #008dfb;
        padding: 3px 10px;
        margin: 3px 0;



        &:hover {
            background-color: #f1f7fd;
            color: #2c2f3c;
        }

        &.primary {
            color: #2c2f3c;
        }

        
        &.secondary {
            font-size: 12px;
            color: #2c2f3c;
            font-weight: 400;
            display: flex;
            
            &:hover {
                background-color: transparent;
                .title {
                    text-decoration: underline;
                    color: #2c2f3c;
                }
            }
        }

        &.bold {
            font-weight: 600;
        }


        .icon {
            margin-right: 15px;
            &.hover {
                display: none;
            }
            img {
                width: 16px;
                // width: 18px;
            }
        }

        &:hover {
            .icon {
                display: none;
                &.hover {
                    display: block;
                }
            }
        }

        &.logout {
            font-weight: 600;
            &:hover {
                color: #fff;
                background-color: #008dfb;
            }
        }

    }
}

.LoginDropdownCover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(25, 42, 48, 0.1);
    z-index: 7;
}
