.DropdownContainer {
    .ToggleButton {
        font-size: 12px;
        & > div {
            padding: 0 8px;
        }
        & > div:first-child {
            padding-left: 12px;
        }
        & > div:last-child {
            padding-right: 12px;
        }
    }

    
    
    .DropdownList.open{
        ul.AllDivisionsOption {

            margin: 0;
            padding: 0;
            li {
                font-size: 12px;
                padding: 4px 0 12px 0;
                padding-left: 60px;
                &:before {
                    // left: 0;
                }
            }
            
        }
        
        .DivisionOptions {
            column-count: 2;
            padding-left: 0;
            padding-right: 20px;
            li {
                font-size: 12px;
                padding-right: 0;
                // padding-left: 30px;
                margin-left: -10px;

                &.disabled {
                    color: gray;
                    cursor: not-allowed;
                    &:hover {
                        background: none;
                    }

                }

                &:before {
                    left: 10px;
                }
            }
        }
    }
}