.DropdownContainer.DropdownHometown {
    
    .ToggleButton {
        margin: 0 20px;
        & > div {
            padding: 2px 8px;
            text-transform: none;
            font-size: 12px;
        }
        & > div:first-child {
            padding-left: 12px;
        }
        & > div:last-child {
            padding-right: 12px;
        }
    }
    
    
    
    .DropdownList.open {
        
        padding: 0 0 20px 0;
        box-sizing: border-box;
        min-width: 230px;
        
        @media screen and (min-width: 801px) {
            max-width: 240px;
        }
        

        .FilterInput {
            display: flex;
            align-items: center;
            margin: 5px 30px 0;

            input {
                border: none;
                padding: 7px 0;
                color: #000;
                width: 100%;
                flex-grow: 1;
                &:focus {
                    outline: none;
                }
                font-family: "Montserrat", sans-serif;
            }

            .ClearIcon {
                cursor: pointer;
                max-width: 10px;
                height: 10px;;
            }
        }
            
        
        
        
        
        
        ul.AllLocationsOption, ul.LocationOptions {

            margin: 0;
            padding: 0 0 0px 0;

            li {
                position: relative;
                padding: 4px 10px 4px 30px;
                font-size: 12px;
                left: 0px;
                background-position: 9px center;
            }
        }
        
        ul.AllLocationsOption {
            margin-top: 10px;
            margin-bottom: 5px;
            
        }
        
        .LocationOptions {
            // column-count: 2;
            padding-left: 0;
            padding-right: 20px;
            max-height: 300px;
            overflow-y: auto;
            overflow-x: hidden;

            &::-webkit-scrollbar-track {
                background: transparent;
            }
            
    
            &::-webkit-scrollbar {
                width: 6px;
            }
    
            &::-webkit-scrollbar-thumb {
                width: 6px;
                border-radius: 3px;
                background-color: #008dfb;
            }
            
            &::-webkit-scrollbar-thumb:window-inactive {
                background: #6b7c88;
            }
        }





    }
}