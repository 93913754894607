$minDesktop: 801px;

.Welcome {
  // min-height: calc(100vh - 60px);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .container {

    .search-icon-container {
      .tooltip {
        .tooltiptext {
            min-width: 80px;
            margin-left: -50px;
            top: 140%;
            line-height: 15px;
  
            font-size: 12px;
  
            padding: 5px 10px;
            // height: 32px !important;
            // line-height: 15px;
            // font-family: "Montserrat", sans-serif;
            // font-size: 13px;
  
            em {
              color: #71e1f4;
              font-style: normal;
              display: block;
              white-space: nowrap;
            }
        }
      }
    }


}
  
}

a.topSponsorLink {
  

  max-width: 100%;
  text-align: center;
  margin: 0px 0 10px 0;  
  display: block;
  
  
  img.topSponsorImage {
    max-width: 200px;
    // max-height: 80%;
    max-height: 80px;
    margin: 20px 0 0 0;
    // max-height: 80px !important;
  }  

  @media screen and (min-width: 951px) { /* desktop */
    position: absolute;
    max-width: 240px;
    // max-width: 280px;
    
    // max-width: 140px;
    // LOGO MOVE
    margin: 0px 0 0 70px;
    // margin: -60px 0 0 70px;
    top: 20px;
    left: 0;
    z-index: 1;
    
  
    
    img.topSponsorImage {
      // max-height: 100px;
      max-width: 100%;
      // max-height: 100%;
    }


  }


}
  .dot {
    padding: 0 5px;
    
    @media screen and (min-width: $minDesktop) {
      padding: 0 10px;

    }
  }
  i { /* dot */
    font-style: normal;
    color: #008dfb;
    display: inline-block;
    padding: 0 10px;
    font-weight: 600;
  }
