
    .LabelUI {
        display: flex;
        margin: 20px 0;
        justify-content: center;
        align-items: center;
        i {
            flex-grow: 1;
            background: #e9e9e9;
            height: 1px;
        }
        span {
            padding: 3px 14px;
            border-radius: 20px;
            font-size: 12px;
            font-weight: 600;
            margin: 0 10px;
            color: #2c2f3c;
            background-color: #e1f1fe;
        }
    }