


  .keepInTouchIcon {
    display: inline-block;
    cursor: pointer;

    

    img {
        display: block;
        width: 24px;
        height: 24px;

    }

  }

  
  .Header {
    height: 60px;
    text-align: center;
    // background-color: #f8f8f5;

    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);

    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // z-index: 5;
    background-color: #f8f8f5e0;


    .HeaderTabs {
        display: flex;
        height: 61px;
        // justify-content: center;
        justify-content: left;
        padding-left: 90px;

        .athletes {
            transform: scale(-1,1);
            // margin-left: 10px;
            // margin-right: -15px;
            margin-top: 18px;

            .tooltiptext {
                transform: scale(-1,1);
                min-width: 80px;
                margin-left: -50px;
                top: 180%;
                line-height: 15px;
      
                font-size: 12px;
      
                padding: 5px 10px;
                z-index:5;
                // height: 32px !important;
                // line-height: 15px;
                // font-family: "Montserrat", sans-serif;
                // font-size: 13px;
      
                em {
                  color: #71e1f4;
                  font-style: normal;
                  display: block;
                  white-space: nowrap;
                }
            }
        }
        .athletesNoneSelected {
            transform: scale(-1,1);
            // margin-left: 10px;
            // margin-right: -15px;
            margin-right: -5px;
            margin-top: 18px;
        }

        .tabSeparator {
            width: 1px;
            background-color: #cfd8dc;
            height: 30px;
            margin-top: 15px;
            margin-right: 15px;
        }
    }

    .HeaderTab {
        outline: none;
        height: 61px;
        line-height: 61px;
        padding: 0 15px;
        font-weight: 500;
        font-size: 14px;
        color: #2c2f3c;
        position: relative;
        overflow: hidden;
        user-select: none;


        @media screen and (max-width: 500px) {
            // text-align: left;
            // padding-left: 70px;
            // text-align: center;
            font-size: 12px;
        }

        




        &:hover {
            color: #008dfb;
            &::after {
                transform: translateX(10px);
                transition: transform 300ms;
                transition-timing-function: ease-out;
            }
        }

        &::after {
            // transform: translateX(-50px);
            transform: translateX(-80px);
            content: " ";
            display: block;
            position: absolute;
            bottom: 15px;
            // left: 14px;
            // width: 30px;
            left: 5px;
            width: 50px;
            border-bottom: 1px solid #008dfb;
        }
        

        &.active {
            color: #000000;
            font-weight: 600;
            // background: #f3f3ee;
            // border-left: 1px solid #dadada;
            // border-right: 1px solid #dadada;
            cursor: default;
            

            &::after {
                display: block;

                transform: translateX(10px);
                // transition: transform 300ms;
                // transition-timing-function: ease-out;
            }
        }
        &:hover{
            color: #000000;
        }
    }



    .container {
    }
    
    .HeaderTitle {
        line-height: 60px;
        font-size: 23px;
        font-weight: 600;
        color: #2c2f3c;

        @media screen and (max-width: 700px) {
            // text-align: left;
            // padding-left: 200px;
            text-align: center;
            font-size: 16px;
        }

        @media screen and (max-width: 500px) {
            // text-align: left;
            // padding-left: 70px;
            text-align: center;
            font-size: 14px;
        }
    }


    .rightbar-container {
        position: absolute;
        top: 0;
        right: 0;

        // @media screen and (max-width: 600px) {
        //     position: fixed;
        //     // display: flex;
        //     text-align: left;
        // }

        .rightbar-container-block {


            

            &.search-icon-container {
                padding: 2px 25px 0 0;

                .tooltiptext {
                    min-width: 80px;
                    margin-left: -50px;
                    top: 155%;
                    line-height: 15px;
            
                    font-size: 12px;
            
                    padding: 5px 10px;
                    // height: 32px !important;
                    // line-height: 15px;
                    // font-family: "Montserrat", sans-serif;
                    // font-size: 13px;
            
                    em {
                      color: #71e1f4;
                      font-style: normal;
                      display: block;
                      white-space: nowrap;
                    }
                }
            }
            &.profile-icon-container {
                padding: 7px 25px 0 0;

                @media screen and (max-width: 600px) {
                    padding: 7px 10px 0 0;
                    // display: none;
                }
            }
            .rightbar-container-row-1 {
                padding-top: 18px;
            }

            &.PagesNavigation {
                height: 40px;
                display: flex;
                align-items: center;
                border-right: 1px solid #cfd8dc;
                margin-top: 10px;
                margin-right: 20px;
                padding-right: 20px;
                font-size: 12px;

                @media screen and (max-width: 600px) {
                    // margin-right: 30px;
                    // padding-right: 30px;
                    padding-right: 0;
                    border-right: 0;
                }

                // @media screen and (max-width: 600px) {
                //     // align-items: left;
                //     // margin-right: 0px;
                //     // padding-right: 0px;
                //     font-size: 10px;
                //     img {
                //         width: 15px;
                //         height: 15px;
                //     }
                // }

                strong {
                    padding: 0 5px;
                    @media screen and (max-width: 600px) {
                        padding: 0 4px;
                    }
                }

                a {
                    border: none;
                    padding: 0 5px;
                    cursor: pointer;
                    background: transparent;
                    position: relative;




                    &:hover{ 
                        .background {
                            transform: scale(1);
                        }
                    }


                    .background {
                        display: block;
                        transform: scale(0);
                        transition: transform 200ms;
                        transition-timing-function: ease-out;
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        // background-color: #93b9ff;
                        background-color: rgba(204, 232, 254, 0.3);
                        // background-color: rgba(91, 91, 88, 0.3);
                        // background-color:#e8e8e0;;
                        top: 0px;
                        left: 4px;
                        z-index: 8;

  
                    }

                }
            }
        }

    }

    
}
