.AthleteListFilters {
    display: flex;
    justify-content: center;
    color: #2c2f3c;
    flex-wrap: wrap;
    padding-top: 5px;
    position: relative;



    .tooltip {
        .tooltiptext {
            min-width: 60px !important;
            margin-left: -40px !important;
            // margin-left: -70px;
            // top: 140% !important;
            top: 102% !important;
            height: 15px;
            line-height: 15px;
            font-size: 12px;
        }
    }

    &>* {

        padding: 0 10px 15px;
        
        @media screen and (min-width: 801px) {
            padding: 0 20px 0 0;
        }

        .chevron {
            color: #008dfb;
        }
    }

    .ClearFiltersButton {
        padding: 8px !important;
        width: 15px;
        height: 15px;
        cursor: pointer;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center 60%;
        position: absolute;
        top: -2px;
        right: -20px;
        background-color: transparent;
        &:hover {
            background-color: #e8e8e0;
        }
        &.tooltip {
            z-index: 7;
        }

        .tooltiptext {
            min-width: 80px;
            margin-left: -50px;
            top: 135%;
            font-size: 12px;
            
        }
    }

    em {
        // font-weight: 700;
        // color: #008dfb;
        font-style: normal;
    }

}