$minDesktop: 801px;

.event-header {
    span {
        display: inline-block;
    }
}

a.topSponsorLinkAthleteList {
  

  max-width: 100%;
  text-align: center;
  margin: 0;  
  display: block;
  
  
  img.topSponsorImage {
    max-width: 200px;
    max-height: 60px;
  }  

  @media screen and (min-width: 951px) { /* desktop */
    // position: absolute;
    max-width: 240px;
    max-height: 50px;
    // LOGO MOVE
    // margin: 0px 0 0 70px;
    // margin: -60px 0 0 70px;
    top: 20px;
    left: 0;
    z-index: 1;

  
    img.topSponsorImage {
      max-width: 100%;
      // max-height: 100%;
    }


  }


}

// a.topHeaderSponsorLink {
  
  
//   display: block;
//   max-width: 240px;
//   max-height: 50px;
//   // margin: -60px 0 0 70px;
//   position: relative;

  
//   position: absolute;
//   top: 0px;
//   left: 0;

  
//   img.topSponsorImage {
//     max-width: 100%;
//     max-height: 100%;

//     @media screen and (max-width: 600px) {
//         max-width: 80%;
//         max-height: 80%;
//     }
//   }
// }


    .dot {
      padding: 0 5px;
    
      @media screen and (min-width: $minDesktop) {
        padding: 0 10px;
  
      }
    }
    i { /* dot */
      font-style: normal;
      color: #008dfb;
      display: inline-block;
      padding: 0 10px;
      font-weight: 800;
    }