$minDesktop: 801px;

.PartnerModalCover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 5;
}

.PartnerModalForm {
    
    position: fixed;

    
    left: 0;
    font-size: 12px;
    background-color: #fff;
    // padding: 40px;
    // padding: 20px 50px 40px 40px;
    
    box-sizing: border-box;
    min-height: 150px;
    max-height: 100vh;
    overflow: auto;
    z-index: 10;
    border-radius: 8px;
    
    box-shadow: 0 0 12px rgba(0,0,0,.3), 0 1px 5px rgba(0,0,0,.2);
    width: calc(100vw - 40px);
    margin: 20px;
    top: 0px;
    &>* {
        flex: 1 0 0;
    }
    
    @media screen and (min-width: $minDesktop) {
        display: flex;
        top: 28px;
    }

    .LeftPane {
        position: relative;
        background-color: #fff;
        @media screen and (min-width: $minDesktop) {
            background-color: #f8f9f5;
        }
    }
    .MainPane {
        
        .MainModalContent {

            padding: 0 30px 40px 30px;
            @media screen and (min-width: $minDesktop) {
                padding: 40px 30px;
            }
        }
    }
    
    
    @media screen and (min-width: $minDesktop) {
        top: 40px;
        margin: 0 0 0 -400px;
        // width: 670px;
        width: 800px;
        left: 50%;
        border-radius: 12px;
    }

    .ClearIcon {
        cursor: pointer;
        max-width: 20px;
        height: 20px;

        position: absolute;
        top: 20px;
        right: 25px;

        &:hover {  
            .background {
                transform: scale(1);
            }
        }
    
        .background { 
            transform: scale(0);
            transition: transform 200ms;
            transition-timing-function: ease-out;
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            // background-color: #93b9ff;
            background-color: rgba(204, 232, 254, 0.3);
            top: -6px;
            left: -5px;
            z-index: -5;
        }
    }

    .Label {
        display: flex;
        margin: 20px 0;
        justify-content: center;
        align-items: center;
        i {
            flex-grow: 1;
            background: #e9e9e9;
            height: 1px;
        }
        span {
            padding: 3px 14px;
            border-radius: 20px;
            font-size: 12px;
            font-weight: 600;
            margin: 0 10px;
            color: #2c2f3c;
            background-color: #e1f1fe;
        }
    }

    .closePartnerModal {
        position: absolute;
        top: 10px;
        right: 25px;
        font-size: 25px;
        color: #525151;
        cursor: pointer;
    }

    .Logo {
        display: none;
        margin-top: 35px;
        text-align: center;
        max-width: 108px;
        margin: 20px auto 0;
        
        img {
            display: inline-block !important;
            max-width: 100%;
            width: 175px;
        }
        @media screen and (min-width: $minDesktop) {
            max-width: none;
            display: block;
        }
    }

    .title {
        font-size: 12px;
        font-weight: 800;
        color: #2c2f3c;
        text-align: center;
        margin: 40px 0 0;
        @media screen and (min-width: $minDesktop) {
            font-size: 24px;
            margin: 70px 20px;
        }
    }

    p {
        font-size: 12px;
        color: #2c2f3c;
        line-height: 1.5;
        display: block;
        margin: 1em auto !important;
        max-width: 500px;
        font-weight: 600;
        text-align: center;
        @media screen and (min-width: $minDesktop) {
            // font-size: 16px;
        }
    }

    label {
        display: block;
        margin: 10px auto;
        text-align: left;
        box-sizing: border-box;
        max-width: 460px;

        .label {
            display: block;
            font-size: 11px;
            color: #2c2f3c;
            font-weight: normal;
            margin-left: 21px;
            margin-bottom: 5px;
            &.error {
                // color: #c11515;
            }
        }

        input {
            font-family: "Montserrat", sans-serif;
            background-color: #e3f2ff;
            color: #2c2f3c;
            border: none;
            
            display: block;
            width: 100%;
            box-sizing: border-box;
            background-repeat: no-repeat;
            background-position: right 10px center;

            font-size: 12px;
            padding: 10px 22px;
            background-size: 20px;
            border-radius: 16px;

            &::placeholder {
                color: #000;
                font-weight: 600;
            }

            @media screen and (min-width: $minDesktop) {
                // font-size: 14px;
                padding: 10px 22px;
                background-size: 16px;
                border-radius: 21px;
            }
        }
        &.error input {
            padding: 14px 22px;
            background-color: rgba(255, 56, 0, 0.1);
        }
        
        @media screen and (min-width: $minDesktop) {
            font-size: 14px;
            margin: 15px auto;
        }
    }


    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        button, .button  {
            box-sizing: border-box;
            display: block;
            // border-radius: 60px;
            
            background: #008dfb;
            color: #ffffff;
            font-size: 15px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            // padding: 1px 27px;
            padding: 4px 20px;
            margin: 0 10px;
            border: 2px solid #008dfb;
            font-family: inherit;
            cursor: pointer;
    
            border-radius: 17px;

            &:hover {
                border: solid 2px rgba(0, 0, 0, 0.05);
                background-color: #0086ef;
            }
            
            &.secondary {
                background-color: #fff;
                color: #008dfb;
                border-color: #cce8fe;

                &:hover {
                    border: solid 2px rgba(0, 141, 251, 0.5);
                }
            }

            &[disabled] {
                background: #e4e4e4;
                color: #ffffff;
                border: 2px solid #d6d6d6;
                cursor: default;
            }
        }
    }


    input, button {
        &:focus {
            outline: none;
        }
    }
    

    .ThankYou {
        .ThankYouFinishIcon {
            margin: 30px auto 10px;
            width: 100px;
        }
    }


    .HeroImage {
        position: absolute;
        max-width: 100%;
        width: 100%;
        bottom: 30px;

        display: none;
        @media screen and (min-width: $minDesktop) {
            display: block;
        }

        img.CityBackground {
            width: 100%;
            margin-bottom: 18px;
        }
        img.Runner {
            position: absolute;
            width: 80px;
            height: 80px;
            bottom: -5px;
            left: 50%;
            margin-left: -40px;
        }
    }

    .tooltiptext {
        min-width: 33px !important;
        top: 139% !important;
        // margin-left: -65px !important;
        left: 65% !important;
        // line-height: 15px;

        
        // padding: 5px 10px;
        // height: 32px !important;
        // line-height: 15px;
        // font-family: "Montserrat", sans-serif;
        // font-size: 13px;
        
        em {
            font-size: 11px;
            
            color: #fcb452;
            font-style: normal;
            font-weight: 500;
            display: block;
            white-space: nowrap;
        }

        // &:after{
        //     display: none;
        // }
    }
    
    
} // .PartnerModalForm