.ToggleButton {
    justify-content: center;
    display: flex;
    


    
    & > div {
        background-color: #fff;
        color: #008dfb;
        // border: 1px solid #008dfb;
        border-top: 1px solid #008dfb;
        border-bottom: 1px solid #008dfb;
        border-left: 1px solid #008dfb;
        padding: 5px 10px;
        user-select: none;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        position: relative;

        &:hover {
            // background-color: #eff8fe;
            background-color: #2c2f3c;
            color: #71e1f4;
        }

    
        &:first-child {
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
            border-right-width: 0;
            padding-left: 18px;
            &:not(.on):hover {
                border-left-color: #eff8fe;
            }
            
        }
        &:last-child {
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
            padding-right: 18px;
            border-top: 1px solid #008dfb;
            border-bottom: 1px solid #008dfb;
            border-right: 1px solid #008dfb;
            &:not(.on):hover {
                // border-right-color: #eff8fe;
                border-right-color: #2c2f3c;
            }
        }
        &.on {
            background-color: #008dfb;
            color: #fff;
        }
        
        &:not(.on) {
            cursor: pointer;
            &:hover {
                // border-top-color: #eff8fe;
                // border-bottom-color: #eff8fe;
                border-top-color: #2c2f3c;
                border-bottom-color: #2c2f3c;
            }
        }

    }

    &.ToggleGraphTable, &.ToggleInterval, &.ToggleLoginSignup {
        

        z-index: 3;
        position: relative;

        padding: 0;
        & > div {
            padding: 2px 12px;
            text-transform: none;
            font-size: 12px;
            font-weight: 600;
        }
    }

}    

