
.BeaconMR{
    position:absolute;
    top:0;
    // left:0;
    left:-8px;

    // .adjust {
    //   left: -8px;
    // }
    // background-color:#2c3e50;
    // background-color:#008DFB;
    
    // background-color:#FF3800;
    // height:1em;
    // width:1em;
    height:0;
    width:0;
    // border-radius:50%;


    /*
    position: absolute;
    top: 0;
    left: 0;
    / * background-color: #FF3800; * /
    height: 0;
    width: 0;
    / * border-radius: 50%; * /
    z-index: 5;
    border-width: 9px 12px 14px 4px;
    border-color: transparent transparent red transparent;
    border-style: solid;
    transform: rotate(-73deg);
    */

    .pointer {

      position:absolute;
      top:0;
      left:0;
      height:0;
      width:0;

      


      &:before { /* the pulse */
        position:absolute;
        content:"";
        height:16px;
        width:16px;
        // height:1em;
        // width:1em;
        left:0;
        top:0;
    
        background-color:transparent;
        border-radius:50%;
        // box-shadow:0px 0px 2px 2px #2980b9;
        box-shadow:0px 0px 2px 2px #FF3800;
        -webkit-animation:active 2s infinite linear;
        animation:active 2s infinite linear;
      }

      
      // border-color: transparent transparent #FF3800 transparent;
      
      border-style: solid;
      
      &.right {
        // border-width: 12px 12px 12px 0px; // the triangle
        border-color: transparent transparent transparent #FF3800;
        border-width: 8px 0px 8px 16px; // the triangle
        // transform: rotate(-135deg);
        // transform: rotate(0deg);
  
        &:before { /* the pulse offset */
          // left: -4px;
          left: -18px;
          top: -8px;
        }

        -webkit-animation:jitter-right 3s infinite linear;
        animation:jitter-right 3s infinite linear;
      }


      &.down {
        border-color: #FF3800 transparent transparent transparent;
        border-width: 16px 8px 0px 8px; // the triangle
        // transform: rotate(-45deg);
  
        &:before { /* the pulse offset */
          left: -8px;
          top: -18px;
        }

        -webkit-animation:jitter-down 3s infinite linear;
        animation:jitter-down 3s infinite linear;
      }

      &.left {
        border-color: transparent #FF3800 transparent transparent;
        border-width: 8px 16px 8px 0px; // the triangle
        // transform: rotate(45deg);
  
        &:before { /* the pulse offset */
          left: 4px;
          top: -8px;
        }
        -webkit-animation:jitter-left 3s infinite linear;
        animation:jitter-left 3s infinite linear;
      }

      &.up {
        border-color: transparent transparent #FF3800 transparent;
        border-width: 0px 8px 16px 8px; // the triangle
        // transform: rotate(135deg);
  
        &:before { /* the pulse offset */
          left: -4px;
          top: 0px;
        }

        -webkit-animation:jitter-up 3s infinite linear;
        animation:jitter-up 3s infinite linear;
      }
  
  
      
    } /* .pointer */
    

    -webkit-transform:translateX(-50%) translateY(-50%);
    z-index: 5;

    &:hover {
      .BeaconTooltip {
        display: block;
      }
    }

    .BeaconTooltip {
      display: none;
      
      .BeaconText {
        position: absolute;
        top: 22px;
        // left: 0;
        left: 0;
        width: 100px;
        background-color: #222;
        color: #fff;
        border-radius: 3px;
        padding: 5px 10px;
        // margin-left: -50px;
        margin-left: -50px;
        text-align: center;

        &.left {
          top: 40px;
        }

        &.right {
          top: 24px;
        }
        &.down {
          top: 40px;
        }
  
  
      }
    }

  }

  

  
  @-webkit-keyframes active{
    0%{
      -webkit-transform:scale(.1);
      opacity:1;
    }
    70%{
      -webkit-transform:scale(2.5);
      opacity:0;
    }
    100%{
      opacity:0;
    }
  }
  
  @keyframes active{
    0%{
      transform:scale(.1);
      opacity:1;
    }
    70%{
      transform:scale(2.5);
      opacity:0;
    }
    100%{
      opacity:0;
    }
  }



  // @-webkit-keyframes jitter {
  //   0% { transform: translate(0, 0); }
  //   5% { transform: translate(-2px, -50); }
  //   20% { transform: translate(2px, 0); }
  //   40% { transform: translate(3px, 2px); }
  //   80% { transform: translate(-2px, 3px); }
  //   100%{ transform: translate(0, 0); }
  // }

  @keyframes jitter-down {
    
    0% { transform: translate(0, 0px); }
    5% { transform: translate(2px, 0px); }
    10% { transform: translate(-2px, 0px); }
    15% { transform: translate(2px, 0px); }
    20% { transform: translate(-2px, 0px); }
    25% { transform: translate(2px, 0px); }
    30% { transform: translate(-2px, 0px); }
    35% { transform: translate(2px, 0px); }
    40% { transform: translate(-2px, 0px); }
    45% { transform: translate(2px, 0px); }
    50% { transform: translate(-2px, 0px); }
    55% { transform: translate(0px, 0px); }
    100%{ transform: translate(0, 0); }
  }
  @keyframes jitter-up {
    
    0% { transform: translate(0, 0); }
    50% { transform: translate(2px, 0px); }
    55% { transform: translate(-2px, 0px); }
    60% { transform: translate(2px, 0px); }
    65% { transform: translate(-2px, 0px); }
    70% { transform: translate(2px, 0px); }
    75% { transform: translate(-2px, 0px); }
    80% { transform: translate(2px, 0px); }
    85% { transform: translate(-2px, 0px); }
    90% { transform: translate(2px, 0px); }
    95% { transform: translate(-2px, 0px); }
    100%{ transform: translate(0, 0px); }
  }

  @keyframes jitter-right {
    
    
    0% { transform: translate(0, 0); }
    50% { transform: translate(0, 2px); }
    55% { transform: translate(0px, -2px); }
    60% { transform: translate(0px, 2px); }
    65% { transform: translate(0px, -2px); }
    70% { transform: translate(0px, 2px); }
    75% { transform: translate(0px, -2px); }
    80% { transform: translate(0px, 2px); }
    85% { transform: translate(0px, -2px); }
    90% { transform: translate(0px, 2px); }
    95% { transform: translate(0px, -2px); }
    100%{ transform: translate(0, 0px); }
  }
  @keyframes jitter-left {
    
    0% { transform: translate(0, 0px); }
    5% { transform: translate(0, 2px); }
    10% { transform: translate(0, -2px); }
    15% { transform: translate(0, 2px); }
    20% { transform: translate(0, -2px); }
    25% { transform: translate(0, 2px); }
    30% { transform: translate(0, -2px); }
    35% { transform: translate(0, 2px); }
    40% { transform: translate(0, -2px); }
    45% { transform: translate(0, 2px); }
    50% { transform: translate(0, -2px); }
    55% { transform: translate(0px, 0px); }
    100%{ transform: translate(0, 0); }
  }





